import {useNavigate} from 'react-router-dom';

import Logo from '../../../assets/icons/cashia_logo.svg';
import Electronics from '../../../assets/images/electronics.svg';
import Food from '../../../assets/images/food.svg';
import Groceries from '../../../assets/images/groceries.svg';
import OnboardingCover from '../../../assets/images/on-Boarding.svg';
import Party from '../../../assets/images/party.svg';
import Pharmacy from '../../../assets/images/pharmacy.svg';
import Supermarket from '../../../assets/images/supermarket.svg';
import Thanks from '../../../assets/images/thanks.svg';
import Button from '../../../components/tailwind/Button';
import {cn} from '../../../utils/reusableFunctions';
import {useUserAuth} from '../../../utils/user';

const WelcomeOrThanks = ({thanks}: {thanks?: boolean}) => {
  const {merchant} = useUserAuth();
  const navigate = useNavigate();
  return (
    <div className="flex sm:flex-col md:flex-row w-screen h-screen overflow-hidden">
      <div
        className={cn('sm:flex md:hidden w-full bg-cashiaBlue h-[35%]', {
          'bg-smoothRed': thanks,
        })}>
        <img src={thanks ? Thanks : OnboardingCover} className="w-full" />
      </div>
      <div className="flex-1 md:pl-24 md:pt-12 flex flex-col justify-between h-full gap-10 overflow-y-scroll no-scrollbar">
        <img src={Logo} className="sm:hidden md:block w-14 h-14" />
        <div className="flex flex-col justify-between gap-12 sm:h-full md:h-[40%] lg:h-[60%] sm:py-8 sm:px-4 mb-6 md:py-0 md:px-0 md:mb-0">
          <div className="flex flex-col gap-12">
            <h1 className="text-neutral-800 sm:text-3xl sm:font-semibold sm:leading-9 md:text-[45px] md:font-bold md:leading-[60px]">
              {thanks ? (
                <>
                  Thanks for doing <br className="sm:hidden md:block" />
                  your part!
                </>
              ) : (
                <>
                  Welcome! Your store’s <br />
                  journey starts here.
                </>
              )}
            </h1>
            <p className="text-zinc-700 text-base font-normal leading-tight sm:w-full md:w-[80%]">
              {thanks ? (
                <>
                  Our team will be reviewing the information you provided. You
                  will receive a response from us through email/text in a few
                  minutes.
                  <br />
                  <br />
                  Meanwhile you can continue setting up your business as we
                  confirm the information you shared with the relevant
                  stakeholders.
                </>
              ) : (
                <>
                  Cashia is an online platform that connects businesses to
                  customers and vice versa.
                  <br />
                  <br />
                  All shops onboarded on our platform will be reviewed by a
                  small dedicated team at Cashia. If your shop meets all{' '}
                  <span className="text-cyan-500">requirements</span>
                  , you will begin selling your goods on Cashia immediately.
                  <br />
                  <br />
                  We are excited that you decided to join us and we are looking
                  forward to a long lasting relationship.
                </>
              )}
            </p>
          </div>
          <Button
            label="Continue"
            className="rounded-md sm:py-6 lg:py-5"
            labelStyle="text-neutral-50 text-base font-normal"
            onClick={() =>
              navigate(
                thanks
                  ? merchant?.tag
                    ? '/store'
                    : '/activation/'
                  : '/self-onboarding'
              )
            }
          />
        </div>
        <div className="sm:hidden md:block w-full h-3" />
      </div>
      <div
        className={cn(
          'sm:hidden flex-1 lg:flex overflow-y-scroll no-scrollbar',
          {
            'bg-smoothRed': thanks,
          }
        )}>
        {thanks ? (
          <img src={Thanks} className="w-full" />
        ) : (
          <>
            <div>
              <img src={Party} />
              <img src={Food} />
              <img src={Supermarket} />
            </div>
            <div>
              <img src={Pharmacy} />
              <img src={Groceries} />
              <img src={Electronics} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WelcomeOrThanks;
