import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as LogoSvg} from './../../assets/icons/cashia_logo.svg';
import SearchResult from './SearchResult';
import {useAuth} from '../../utils/auth';
import {useUserAuth} from '../../utils/user';
import Modal from '../tailwind/Modal';

interface MenuItem {
  text: string;
  path: string;
}

interface CustomMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  navigate: (path: string) => void;
  onLogout: () => void;
}

const menu: MenuItem[] = [
  {text: 'Profile', path: '/profile'},
  {text: 'Get help', path: '/get-help'},
  {text: 'Log out', path: '/logout'},
];
const CustomMenu = ({
  anchorEl,
  onClose,
  navigate,
  onLogout,
}: CustomMenuProps) => {
  const getTopPosition = (): number => {
    const profileImageHeight = 35;
    return anchorEl
      ? anchorEl.getBoundingClientRect().bottom +
          window.scrollY +
          profileImageHeight
      : 0;
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-black opacity-10"
        onClick={onClose}
      />
      <div
        className="fixed top-16 right-4 flex items-center justify-center z-50"
        style={{top: getTopPosition()}}>
        <div className="bg-trueWhite rounded-lg shadow-md w-50 p-4">
          <ul className="px-8 py-2">
            {menu.map((item) => (
              <li
                key={item.text}
                className="cursor-pointer px-3 py-2 hover:bg-white"
                onClick={() => {
                  if (item.text === 'Log out') {
                    onLogout();
                  } else {
                    navigate(item.path);
                  }
                }}>
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const menuItems = [
  {
    text: 'Store',
    path: '/store',
  },
  {
    text: 'Orders',
    path: '/orders',
  },
  {
    text: 'Wallet',
    path: '/wallet',
  },
  {
    text: 'Reels',
    path: '/reels',
  },
];

const Navbar = ({
  removeProductFromContext,
  isAddingProduct,
}: {
  removeProductFromContext?: (to: string) => void;
  isAddingProduct?: boolean;
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [clickedPath, setClickedPath] = useState<string>('');

  const handleProfileClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleProfileClose = () => {
    setMenuAnchor(null);
  };

  const {logout} = useAuth();

  const {merchant} = useUserAuth();

  const location = useLocation();

  const handleLinkClick = (path: string) => {
    setClickedPath(path);
    if (location.pathname !== path) {
      setModalOpen(true);
    } else {
      navigate(path);
    }
  };

  const handleModalConfirm = () => {
    removeProductFromContext?.(clickedPath);

    navigate(clickedPath);

    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const inProfileSection = location.pathname
      .toLowerCase()
      .startsWith('/profile');
    setIsProfileActive(inProfileSection);
  }, [location.pathname]);

  const isStorePath = location.pathname === '/store';

  return (
    <header className="flex w-full items-center gap-4 justify-between px-4 py-3 border-b border-gray-200">
      <div className="sm:hidden lg:flex items-center gap-16">
        <LogoSvg
          onClick={() => navigate('/store')}
          className="h-8 mr-6 cursor-pointer"
        />

        <ul className="hidden lg:flex gap-12">
          {menuItems.map((item) => {
            const activePath = location.pathname
              .toLowerCase()
              .startsWith(item.path);
            return (
              <li
                key={item.text}
                className={`relative cursor-pointer mr-4 text-sm text-gray-500 hover:text-cashiaBlue${
                  activePath ? 'border-b-3 border-cashiaBlue' : ''
                }`}
                onClick={() => {
                  isAddingProduct
                    ? handleLinkClick(item.path)
                    : navigate(item.path);
                }}>
                <p className={`${activePath ? 'text-cashiaBlue' : ''}`}>
                  {item.text}
                </p>
                {activePath && (
                  <span className="absolute bottom-[-22px] left-0 inline-block w-full h-0.5 bg-cashiaBlue" />
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <SearchResult />

      <div className="sm:hidden lg:flex shrink-0 items-center gap-5 justify-between">
        <div className="hidden md:block">
          <button
            onClick={() => {
              navigate('/store/add-product');
            }}
            disabled={!isStorePath}
            className={`rounded-lg px-5 py-2 text-sm text-white ${
              isStorePath
                ? 'bg-cashiaBlue hover:bg-cashiaBlue'
                : 'bg-Greyish cursor-not-allowed'
            }`}>
            Add Item +
          </button>
        </div>

        <div className="h-[35px] w-[35px] rounded-full">
          <img
            className={`rounded-full h-9 w-9 border border-cashiaBlue ${
              isProfileActive ? 'border-cashiaBlue border-[2px]' : ''
            }`}
            onClick={handleProfileClick}
            src={merchant?.logo?.medium || ''}
          />
          {menuAnchor && (
            <CustomMenu
              anchorEl={menuAnchor}
              onClose={handleProfileClose}
              navigate={navigate}
              onLogout={logout}
            />
          )}
        </div>
      </div>
      <Modal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
        className="">
        <div className="text-center">
          <h3 className="text-3xl font-semibold mb-2 mt-6">
            Save Your Progress
          </h3>
          <p className="text-sm text-darkGrey ml-6 mr-6">
            Click `Save & Exit` to store your work and continue later. All
            unsaved information will be lost permanently.
          </p>
        </div>
        <div className="flex justify-center mt-4 mb-6">
          <button
            onClick={handleModalConfirm}
            className="px-4 py-2 mr-2 bg-cashiaBlue text-white rounded-md  focus:outline-none ">
            Save & Exit
          </button>

          <button
            onClick={() => setModalOpen(false)}
            className="px-4 py-2 bg-greyish text-white rounded-md  focus:outline-none  ">
            Cancel
          </button>
        </div>
      </Modal>
    </header>
  );
};

export default Navbar;
