import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';

import ProfileLayout from './Layout';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import ImgPlaceHolder from '../../assets/icons/blue-img-placeholder.svg';
import {ReactComponent as Case} from '../../assets/icons/case_icon.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as Location} from '../../assets/icons/location_icon.svg';
import {ReactComponent as Lock} from '../../assets/icons/lock_icon.svg';
import {ReactComponent as Phone} from '../../assets/icons/phone_icon.svg';
import {ReactComponent as Star} from '../../assets/icons/star_black.svg';
import {ReactComponent as Store} from '../../assets/icons/store_dark.svg';
import {ReactComponent as Web} from '../../assets/icons/web_icon.svg';
import Footer from '../../components/common/Footer';
import LoadingModal from '../../components/common/LoadingModal';
import ImageModal from '../../components/tailwind/ImageModal';
import TextArea from '../../components/tailwind/TextArea';
import TextInput from '../../components/tailwind/TextInput';
import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  useUpdateMerchantMutation,
} from '../../graphql/generated';
import {isMerchant} from '../../utils/apollo/helpers';
import formatDate from '../../utils/formatDate';
import {useUserAuth} from '../../utils/user';

type ValidationErr = {
  value: {
    name: string;
    description: string;
  };
  errors?: string[];
  inner?: {
    value: string;
    path: 'name' | 'description' | 'website';
    type: string;
    errors: string[];
    params: {
      value: string;
      originalValue: string;
      path: string;
    };
    inner: [];
    name: string;
    message: string;
  }[];
  name: string;
  message: string;
};
interface ProfileRowProps {
  icon: ReactNode;
  label: string;
  value?: string;
  isEditing: boolean;
}

const ProfileRow = ({icon, label, value, isEditing}: ProfileRowProps) => {
  return (
    <div
      className={`flex flex-col mt-2 gap-4 ${
        !isEditing ? 'border-b-[1px]' : ''
      }`}>
      <h3 className="text-black text-xl font-normal">{label}</h3>
      <div className="flex justify-start items-center gap-2 mt-2 pb-4">
        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-white">
          {icon}
        </div>
        <p className="text-base text-cashiaDark">{value}</p>
      </div>
    </div>
  );
};

const limits = {
  name: 40,
  description: 150,
};

const updateStringStateWithLimit = (
  value: string,
  valueLimit: number,
  setterFunction: (value: string) => void
): string => {
  const trimmedValue = value.substring(0, valueLimit);
  setterFunction(trimmedValue);
  return trimmedValue;
};

const EditProfile = () => {
  const {merchant} = useUserAuth();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [nameInput, setName] = useState('');
  const [websiteInput, setWebsite] = useState('');
  const [emailInput, setEmail] = useState('');
  const [descriptionInput, setDescription] = useState('');
  const [isValid, setIsvalid] = useState(false);
  const [updateMerchant, {loading}] = useUpdateMerchantMutation();
  const [validationErrors, setValidationErrors] = useState<
    | {
        name: string[];
        description: string[];
        website: string[];
        email: string[];
        phone: string[];
      }
    | undefined
  >({
    name: [],
    description: [],
    website: [],
    email: [],
    phone: [],
  });
  const handleReset = useCallback(() => {
    setWebsite(merchant?.website || '');
    setName(merchant?.name || '');
    setEmail(merchant?.email || '');
    setDescription(merchant?.description || '');
    setIsEditing(false);
    validateInput(
      merchant?.name || '',
      merchant?.description || '',
      merchant?.email || '',
      merchant?.website || '',
      []
    );
  }, [
    merchant?.description,
    merchant?.email,
    merchant?.name,
    merchant?.website,
  ]);

  const validateInput = (
    name: string,
    description: string,
    email: string,
    website: string,
    fieldsToValidate: ('name' | 'description' | 'email' | 'website')[]
  ) => {
    const validationSchema = yup.object().shape({
      name: fieldsToValidate.includes('name')
        ? yup
            .string()
            .required('This field is required')
            .min(3, 'Name should be at least 3 characters long.')
            .max(40, 'Name should not exceed 40 characters.')
        : yup.string(),
      description: fieldsToValidate.includes('description')
        ? yup.string().max(150, '150 characters needed.')
        : yup.string(),
      website: fieldsToValidate.includes('website')
        ? yup
            .string()
            .min(5, 'Website link should be at least 5 characters long.')
            .max(100, 'Website link should not exceed 100 characters.')
        : yup.string(),
      email: fieldsToValidate.includes('email')
        ? yup.string().email('Invalid email address')
        : yup.string(),
    });

    validationSchema
      .validate({name, description, email, website}, {abortEarly: false})
      .then(() => {
        setIsvalid(true);
        setValidationErrors({
          name: [],
          description: [],
          website: [],
          email: [],
          phone: [],
        });
      })
      .catch((err: ValidationErr) => {
        setIsvalid(false);
        setValidationErrors(
          err?.inner?.reduce(
            (errors, vError) => ({
              ...errors,
              [vError.path]: [...errors[vError.path], vError.message],
            }),
            {name: [], description: [], website: [], email: [], phone: []}
          )
        );
      });
  };

  const submit = useCallback(async () => {
    await updateMerchant({
      variables: {
        id: merchant?.id || '',
        input: {
          name: nameInput,
          description: descriptionInput,
          email: emailInput,
          website: websiteInput,
        },
      },
      update: (cache, res) => {
        const cached = cache.readQuery<MeQuery>({
          query: MeDocument,
        });
        if (
          cached?.me?.__typename !== 'Merchant' ||
          !res.data?.updateMerchant ||
          !res.data.updateMerchant
        ) {
          return;
        }
        cache.writeQuery<MeQuery, MeQueryVariables>({
          query: MeDocument,
          data: {
            me: {
              ...cached?.me,
              ...res?.data?.updateMerchant,
            },
          },
        });
      },
    });
  }, [
    descriptionInput,
    emailInput,
    merchant?.id,
    nameInput,
    updateMerchant,
    websiteInput,
  ]);

  const handleSubmit = useCallback(async () => {
    validateInput(nameInput, descriptionInput, emailInput, websiteInput, [
      'name',
      'description',
      'email',
      'website',
    ]);
    if (!isValid) return;
    await submit();
    setIsEditing(false);
  }, [descriptionInput, emailInput, isValid, nameInput, submit, websiteInput]);

  useEffect(() => {
    if (!merchant) return;
    merchant.name && setName(merchant?.name);
    merchant.description && setDescription(merchant.description);
    merchant.email && setEmail(merchant.email);
    merchant.website && setDescription(merchant.website);
  }, [merchant]);

  const handleImageUpload = async (type: 'logo' | 'coverImage', id: string) => {
    try {
      await updateMerchant({
        variables: {
          id: merchant?.id || '',
          input: {
            [type]: id,
          },
        },
        update: (cache, {data}) => {
          const cached = cache.readQuery<MeQuery>({
            query: MeDocument,
          });
          if (!data?.updateMerchant || !cached?.me || !isMerchant(cached.me)) {
            return;
          }
          cache.writeQuery<MeQuery, MeQueryVariables>({
            query: MeDocument,
            data: {
              me: {
                ...cached.me,
                ...data.updateMerchant,
              },
            },
          });
        },
      });
    } catch (error) {
      console.error('Error updating merchant:', error);
    }
  };

  const handleImageRemove = async (type: 'logo' | 'coverImage') => {
    try {
      await updateMerchant({
        variables: {
          id: merchant?.id || '',
          input: {
            [type]: null,
          },
        },
        update: (cache, {data}) => {
          const cached = cache.readQuery<MeQuery>({
            query: MeDocument,
          });
          if (!data?.updateMerchant || !cached?.me || !isMerchant(cached.me)) {
            return;
          }
          cache.writeQuery<MeQuery, MeQueryVariables>({
            query: MeDocument,
            data: {
              me: {
                ...cached.me,
                ...data.updateMerchant,
              },
            },
          });
        },
      });
    } catch (error) {
      console.error('Error updating merchant:', error);
    }
  };

  const toggleEdit = () => setIsEditing((state) => !state);

  const breadcrumbs = [
    <a href="/profile" className="text-foggy hover:underline" key="1">
      Profile
    </a>,
    <span className="text-foggy mx-2" key="">
      {'>'}
    </span>,
    <a href="edit" className="text-foggy hover:underline" key="2">
      Edit profile
    </a>,
  ];

  return (
    <ProfileLayout>
      <div className="flex flex-col sm:px-4 lg:pt-6 lg:px-16 sm:pt-10 overflow-y-scroll">
        <div className="sm:hidden lg:flex mt-2">{breadcrumbs}</div>
        <div className="flex lg:flex-row sm:flex-col">
          <div className="flex flex-col lg:w-7/12 mb-16 lg:overflow-y-scroll sm:no-scrollbar">
            <div className="sm:flex justify-between gap-20 lg:hidden">
              <button
                className="flex items-center"
                onClick={() => {
                  if (isEditing) {
                    setIsEditing(false);
                  } else {
                    navigate('/profile');
                  }
                }}>
                <ArrowBackIcon />
              </button>
              {isEditing ? (
                <button className="text-smoothRed" onClick={handleSubmit}>
                  Save
                </button>
              ) : (
                <button
                  className=" text-white px-4 py-1 rounded-lg"
                  onClick={toggleEdit}>
                  <EditIcon />
                </button>
              )}
            </div>

            <h2 className="text-2xl font-medium mt-4">{merchant?.name}</h2>
            <p className="text-gray-500 text-sm mt-1 sm:mb-6 lg:mb-20">
              Joined on{' '}
              {merchant?.createdAt && formatDate(merchant?.createdAt as Date)}
            </p>

            {!isEditing ? (
              <ProfileRow
                isEditing={isEditing}
                icon={<Case />}
                label="Business Name"
                value={merchant?.name ?? ''}
              />
            ) : (
              <TextInput
                id="Name"
                name="Name"
                label="Business Name"
                labelStyle="pb-8 text-Cashiablack text-2xl font-medium"
                containerStyle="px-1"
                onChange={(e) => {
                  const updatedValue = updateStringStateWithLimit(
                    e.target.value,
                    limits.name,
                    setName
                  );
                  validateInput(
                    updatedValue,
                    descriptionInput,
                    emailInput,
                    websiteInput,
                    ['name']
                  );
                }}
                value={nameInput}
                error={!!validationErrors?.name.length}
                endAdornment={
                  <span className="text-cashiaBlue">
                    {Math.max(limits.name - nameInput.length, 0)}
                  </span>
                }
              />
            )}

            {!isEditing ? (
              <ProfileRow
                isEditing={isEditing}
                icon={<Store />}
                label="Business Description"
                value={merchant?.description ?? ''}
              />
            ) : (
              <div className="flex flex-col">
                <div className="pt-8 border-b-[1px]" />
                <TextArea
                  id="Description"
                  name="Description"
                  label="Business Description"
                  labelStyle="pb-8 pt-8 text-neutral-800 sm:text-base lg:text-xl font-semibold"
                  containerStyle="px-1"
                  onChange={(e) => {
                    const updatedValue = updateStringStateWithLimit(
                      e.target.value,
                      limits.description,
                      setDescription
                    );
                    validateInput(
                      nameInput,
                      updatedValue,
                      emailInput,
                      websiteInput,
                      ['description']
                    );
                  }}
                  helperText={
                    merchant?.description ? (
                      <span className="text-[13px] pt-2 font-medium text-brightRed">{`${descriptionInput.length}/${limits.description}`}</span>
                    ) : (
                      <span className="text-red-700 pt-2 text-[13px] font-medium">
                        {limits.description} characters needed
                      </span>
                    )
                  }
                  value={descriptionInput}
                  error={!!validationErrors?.description.length}
                />
              </div>
            )}

            {!isEditing && (
              <ProfileRow
                isEditing={isEditing}
                icon={<Location />}
                label="Location"
                value={merchant?.addresses?.[0]?.location?.formattedAddress}
              />
            )}

            {!isEditing ? (
              <ProfileRow
                isEditing={isEditing}
                icon={<Phone />}
                label="Phone Number"
                value={`${merchant?.phone?.countryCode || ''} ${
                  merchant?.phone?.number || ''
                }`}
              />
            ) : (
              <div>
                <div className="pt-8 border-b-[1px]" />
                <TextInput
                  id="Phone"
                  label="Phone Number"
                  containerStyle="px-1"
                  labelStyle="pb-8 pt-8 text-neutral-800 sm:text-base lg:text-xl font-semibold"
                  name="Phone"
                  disabled
                  inputStyle="text-wallGrey text-lg font-normal"
                  value={`${merchant?.phone?.countryCode || ''} ${
                    merchant?.phone?.number || ''
                  }`}
                  endAdornment={
                    <div className="">
                      <Lock />
                    </div>
                  }
                />
              </div>
            )}

            {!isEditing ? (
              <ProfileRow
                isEditing={isEditing}
                icon={<Web />}
                label="Email Address"
                value={merchant?.user?.email || ''}
              />
            ) : (
              <div className="flex flex-col">
                <div className="pt-8 border-b-[1px]" />
                <TextInput
                  id="email"
                  name="email"
                  label="Email Address"
                  labelStyle="pb-8 pt-8 text-neutral-800 sm:text-base lg:text-xl font-semibold"
                  containerStyle="px-1"
                  disabled
                  value={merchant?.user?.email || ''}
                  inputStyle="text-darkGrey text-lg font-normal"
                  endAdornment={
                    <div className="">
                      <Web />
                    </div>
                  }
                />
              </div>
            )}

            {isEditing && (
              <div className="flex justify-between mt-2">
                <button
                  className="text-sunsetBlue underline"
                  onClick={handleReset}>
                  Cancel
                </button>
                <button
                  className="bg-sunsetBlue text-white px-4 py-1 rounded-lg"
                  onClick={handleSubmit}>
                  Save
                </button>
              </div>
            )}
          </div>

          <div className="flex flex-col sm:mt-4 lg:mt-0 lg:w-1/5 sm:w-full lg:ml-44">
            <button
              className={`${
                isEditing
                  ? 'bg-greyish'
                  : 'bg-sunsetBlue w-40 ml-40 lg:flex sm:hidden justify-center items-center text-white px-4 py-1 rounded-lg'
              } w-40 h-[38px] ml-40 justify-center lg:flex sm:hidden items-center text-white px-4 py-1 rounded-md`}
              disabled={isEditing}
              onClick={toggleEdit}>
              Edit profile
            </button>

            <div className="flex-col sm:mt-0 lg:mt-16 mb-8 border border-gray-400 rounded-lg flex p-6 items-stretch w-full lg:ml-32">
              <div className="flex flex-col flex-1">
                <div className="flex flex-col items-center">
                  <ImageModal
                    omitFrontDeleteIcon
                    title="Upload a profile photo (optional)"
                    thumbnailStyle="cursor-pointer"
                    thumbnailImageStyle="rounded-full"
                    description="A profile picture enables customers to identify your business
              easily. It creates trust between you and your clients."
                    noPhotoImg={ImgPlaceHolder}
                    helperText="Update photo"
                    imgUrl={merchant?.logo?.medium || ''}
                    imgId={merchant?.logo?.id || ''}
                    getUploadedImgId={(id: string) =>
                      handleImageUpload('logo', id)
                    }
                    removeImgId={() => handleImageRemove('logo')}
                  />
                </div>

                <div className="border-t border-gray-300 my-8" />

                <div className="flex flex-col items-center">
                  <ImageModal
                    omitFrontDeleteIcon
                    title="Add a cover picture (optional)"
                    thumbnailStyle="cursor-pointer"
                    description="A cover image can say a lot about your brand, and make an impression
            on page visitors."
                    helperText="Update cover image"
                    imgUrl={merchant?.coverImage?.medium || ''}
                    imgId={merchant?.coverImage?.id || ''}
                    getUploadedImgId={(id: string) =>
                      handleImageUpload('coverImage', id)
                    }
                    removeImgId={() => handleImageRemove('coverImage')}
                  />
                </div>

                <div className="border-t border-gray-300 my-8" />

                <div className="flex items-center justify-center">
                  <Star className="w-5 h-5" />
                  <p className="ml-2">
                    {merchant?.reviews?.length || '0'} reviews
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingModal open={loading} />
        <div className="lg:flex sm:hidden">
          <Footer />
        </div>
      </div>
    </ProfileLayout>
  );
};

export default EditProfile;
