import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  RadioGroup,
  RadioGroupItem,
} from '@cashiaApp/web-components';
import React, {useCallback, useEffect, useReducer} from 'react';

import LogoSvg from './../../assets/icons/cashia_logo.svg';
import {ReactComponent as EyeSlash} from './../../assets/icons/eye-slash.svg';
import {ReactComponent as Eye} from './../../assets/icons/eye.svg';
import lockIcon from './../../assets/images/lockIcon.svg';
import pinLogin from './../../assets/images/pinLogin.svg';
import pinverifyBg from './../../assets/images/pinverifyBg.svg';
import smsIcon from './../../assets/images/smsIcon.svg';
import tickSvg from './../../assets/images/tickSvg.svg';
import xSvg from './../../assets/images/xSvg.svg';
import smallWalletBg from './../../assets/smallWalletBg.svg';
import {PasswordState} from './Wallet';
import {greet} from './helper';
import OTPInput from '../../components/common/OTPInput/Input';
import CircularProgress from '../../components/tailwind/Spinner';
import TextInput from '../../components/tailwind/TextInput';
import {
  useSendVerificationCodeMutation,
  useSetWalletPinMutation,
  useVerifyPhoneMutation,
  useVerifyPinOrSignatureMutation,
  VerificationId,
} from '../../graphql/generated';
import {cn, updateStringStateWithLimit} from '../../utils/reusableFunctions';
import {useUserAuth} from '../../utils/user';
import useWindowSize from '../inbox/hooks/useWindowSize';

import '../../components/common/OTPInput/OTPInput.css';

interface PinProps {
  values: PasswordState;
  setValues: React.Dispatch<React.SetStateAction<PasswordState>>;
  setVerified: React.Dispatch<React.SetStateAction<boolean>>;
}
const initialState = {
  name: '',
  IDType: '',
  idNum: '',
  smsCode: '',
  newPin: '',
  checkError: '',
  confirmPin: '',
  openModal: false,
  intialPinModal: true,
  phoneValidateState: false,
  resetPin: false,
  askPin: false,
};
type State = typeof initialState;
enum ActionKind {
  USERNAME = 'USERNAME',
  IDTYPE = 'IDTYPE',
  IDNUM = 'IDNUM',
  SMSCODE = 'SMSCODE',
  NEWPIN = 'NEWPIN',
  CHECKERROR = 'CHECKERROR',
  CONFIRMPIN = 'CONFIRMPIN',
  GOTOPHONEVERIFICATION = 'GOTOPHONEVERIFICATION',
  OPENMODAL = 'OPENMODAL',
  CLOSEMODAL = 'CLOSEMODAL',
  CLOSEINITIALMODAL = 'CLOSEINITIALMODAL',
  GOTOPINRESET = 'GOTOPINRESET',
  RESET = 'RESET',
}
interface Action {
  type: ActionKind;
  payload: string;
}

const reducer = (state: State, action: Action) => {
  const {type, payload} = action;
  const value = {
    USERNAME: {...state, name: payload},
    IDTYPE: {...state, IDType: payload},
    IDNUM: {...state, idNum: payload},
    SMSCODE: {...state, smsCode: payload},
    NEWPIN: {...state, newPin: payload},
    CHECKERROR: {...state, checkError: payload},
    CONFIRMPIN: {...state, confirmPin: payload},
    OPENMODAL: {...state, openModal: true},
    CLOSEMODAL: {...state, openModal: false},
    CLOSEINITIALMODAL: {...state, intialPinModal: false},
    GOTOPHONEVERIFICATION: {
      ...state,
      openModal: false,
      phoneValidateState: true,
      resetPin: true,
    },
    GOTOPINRESET: {
      ...state,
      phoneValidateState: false,
      askPin: true,
      smsCode: payload,
    },
    RESET: initialState,
  };
  return value[type] || state;
};

const PinVerification = ({values, setValues, setVerified}: PinProps) => {
  const {width} = useWindowSize();
  const {user, merchant} = useUserAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    name,
    IDType,
    idNum,
    smsCode,
    newPin,
    checkError,
    confirmPin,
    openModal,
    intialPinModal,
    phoneValidateState,
    resetPin,
    askPin,
  } = state;
  const [verifyPinOrSignatureMutation, {data, loading}] =
    useVerifyPinOrSignatureMutation();

  const [
    verifyPhoneMutation,
    {data: verifyPhone, loading: verifyPhoneLoading},
  ] = useVerifyPhoneMutation();

  const [
    setWalletPinMutation,
    {loading: setWalletPinLoading, error: setWalletPinError},
  ] = useSetWalletPinMutation();

  const [sendVerificationCodeMutation, {data: sendCode, loading: loadCode}] =
    useSendVerificationCodeMutation();

  const submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    const res = await verifyPinOrSignatureMutation({
      variables: {
        input: {
          pin: values.password,
        },
      },
    });
    if (res.data?.verifyPinOrSignature) {
      setVerified(true);
      sessionStorage.setItem('verified', JSON.stringify(true));
    }
  };

  const sendCodeFunc = useCallback(async () => {
    if (!user?.phone) return;
    await sendVerificationCodeMutation({
      variables: {
        input: {
          phone: {
            number: user?.phone.number,
            countryCode: user?.phone.countryCode,
          },
        },
      },
    });
  }, [sendVerificationCodeMutation, user?.phone]);

  const verifySmsCode = useCallback(async () => {
    if (smsCode.length < 5) return;
    const res = await verifyPhoneMutation({
      variables: {
        input: {
          code: smsCode,
        },
      },
    });
    if (res.data?.verifyPhone) {
      dispatch({type: ActionKind.GOTOPINRESET, payload: ''});
    }
  }, [verifyPhoneMutation, smsCode]);

  const resetPinFunc = useCallback(async () => {
    if (!merchant?.idNumber || !merchant?.idType || newPin !== confirmPin) {
      return;
    }
    const res = await setWalletPinMutation({
      variables: {
        input: {
          idNumber: merchant?.idNumber,
          idType: merchant?.idType,
          pin: confirmPin,
        },
      },
    });
    if (res.data?.setWalletPin) dispatch({type: ActionKind.RESET, payload: ''});
  }, [
    confirmPin,
    newPin,
    setWalletPinMutation,
    merchant?.idNumber,
    merchant?.idType,
  ]);

  const checkPersonalInfo = () => {
    if (
      name === merchant?.applicantsName &&
      IDType === merchant.idType &&
      idNum === merchant.idNumber
    ) {
      dispatch({
        type: ActionKind.GOTOPHONEVERIFICATION,
        payload: '',
      });
    } else {
      dispatch({
        type: ActionKind.CHECKERROR,
        payload: 'Invalid input, try again!',
      });
    }
  };

  useEffect(() => {
    if (phoneValidateState) void sendCodeFunc();
  }, [phoneValidateState, sendCodeFunc]);

  useEffect(() => {
    if (smsCode.length === 5) void verifySmsCode();
  }, [smsCode, verifySmsCode]);

  useEffect(() => {
    if (newPin.length === 4 && intialPinModal) {
      dispatch({
        type: ActionKind.CLOSEINITIALMODAL,
        payload: '',
      });
    }
    if (confirmPin.length === 4 && newPin === confirmPin) void resetPinFunc();
  }, [confirmPin, intialPinModal, newPin, resetPinFunc]);
  const isDisabled = !(!!name && !!IDType && !!idNum);

  return (
    <>
      {!resetPin ? (
        <>
          <div
            className="w-screen h-screen md:h-[calc(100svh - 64px)] bg-no-repeat bg-center bg-cover flex flex-col justify-center overflow-hidden"
            data-testid="app-home"
            style={{
              backgroundImage:
                width > 1279 ? `url(${pinLogin})` : `url(${smallWalletBg})`,
            }}>
            <div className="flex sm:justify-center px-10 md:justify-center lg:justify-start items-center xl:ml-16">
              <div className="flex flex-col">
                <div className="bg-gradient-to-b from-cashiaBlue to-paleRed text-transparent bg-clip-text mb-8 flex flex-col gap-0">
                  <p className="text-4xl xl:text-2xl font-semibold">
                    {greet()}
                  </p>
                  <p className="text-4xl xl:text-2xl font-semibold">
                    {merchant?.name}
                  </p>
                </div>
                <p className="mb-5 text-lg xl:text-20 font-normal">
                  Please log in to proceed
                </p>
                <form>
                  <TextInput
                    placeholder="PIN"
                    value={values.password}
                    type={values.showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^[0-9]*$/.test(inputValue)) {
                        updateStringStateWithLimit(inputValue, 4, (value) =>
                          setValues({...values, password: value})
                        );
                      }
                    }}
                    endAdornment={
                      <div
                        onClick={() =>
                          setValues({
                            ...values,
                            showPassword: !values.showPassword,
                          })
                        }>
                        {values.showPassword ? <Eye /> : <EyeSlash />}
                      </div>
                    }
                  />
                  {data?.verifyPinOrSignature === false &&
                    values.password.length === 4 && (
                      <p className="text-smoothRed">Incorrect Pin</p>
                    )}
                  <button
                    type="submit"
                    disabled={!values.password.length}
                    onClick={submit}
                    className={`w-full h-12 rounded text-white mt-9 ${
                      !values.password.length ? 'bg-greyish' : 'bg-cashiaBlue'
                    }`}>
                    {loading ? (
                      <CircularProgress
                        className="size={20}"
                        fillColor="fill-white"
                      />
                    ) : (
                      'Login'
                    )}
                  </button>
                </form>
                <div className="items-start">
                  <Button
                    variant="ghost"
                    className="text-cashiaBlue text-base font-normal"
                    onClick={() =>
                      dispatch({type: ActionKind.OPENMODAL, payload: ''})
                    }>
                    Forgot pin
                  </Button>
                  <Modal
                    open={openModal}
                    onOpenChange={() =>
                      dispatch({type: ActionKind.CLOSEMODAL, payload: ''})
                    }>
                    <ModalContent
                      className="sm:max-w-[425px]"
                      overlay={
                        <ModalOverlay
                          className="bg-rgba(12, 13, 52, 0.84)"
                          style={{
                            backgroundColor: openModal
                              ? 'rgba(12, 13, 52, 0.84)'
                              : 'transparent',
                          }}
                        />
                      }>
                      <ModalHeader>
                        <ModalTitle className="font-metropolis font-normal text-xl text-center leading-base pb-2">
                          Reset your PIN
                        </ModalTitle>
                      </ModalHeader>
                      <>
                        <div className="px-4 flex flex-col">
                          <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base pl-3">
                            Full name
                          </p>
                          <TextInput
                            placeholder="Full name"
                            containerStyle="border-none outline-none p-2 rounded-10 box-border mt-1"
                            type="text"
                            value={name}
                            onChange={(e) => {
                              dispatch({
                                type: ActionKind.USERNAME,
                                payload: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="px-4 mt-25 flex flex-col gap-3 pt-3">
                          <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base pl-3">
                            Add your identification number
                          </p>
                          <div className="pl-3">
                            <RadioGroup
                              className="flex"
                              name="row-radio-buttons-group"
                              onValueChange={(value) => {
                                dispatch({
                                  type: ActionKind.IDTYPE,
                                  payload: value,
                                });
                              }}>
                              <div className="flex items-center space-x-2 pr-7">
                                <RadioGroupItem
                                  value={VerificationId.NationalId}
                                  className="border-darkGrey border-2 w-5 h-5"
                                  id="National ID"
                                  fillColor="fill-black w-3 h-3"
                                />
                                <Label
                                  htmlFor="National ID"
                                  className="font-Metropolis cursor-pointer font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                                  National ID
                                </Label>
                              </div>
                              <div className="flex items-center space-x-2 pr-7">
                                <RadioGroupItem
                                  id="Passport No"
                                  className="border-darkGrey border-2 w-5 h-5"
                                  value={VerificationId.Passport}
                                  fillColor="fill-black w-3 h-3"
                                />
                                <Label
                                  htmlFor="Passport No"
                                  className="font-Metropolis cursor-pointer font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                                  Passport No.
                                </Label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  id="Alien Card"
                                  className="border-darkGrey border-2 w-5 h-5"
                                  value={VerificationId.AlienCard}
                                  fillColor="fill-black w-3 h-3"
                                />
                                <Label
                                  htmlFor="Alien Card"
                                  className="font-Metropolis cursor-pointer font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                                  Alien Card
                                </Label>
                              </div>
                            </RadioGroup>
                          </div>
                          <TextInput
                            placeholder="12345678"
                            type="text"
                            value={idNum}
                            containerStyle="border-none outline-none p-2 rounded-10 box-border mt-1"
                            onChange={(e) => {
                              dispatch({
                                type: ActionKind.IDNUM,
                                payload: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {checkError && name && IDType && idNum && (
                          <div className="px-4 mt-25 flex flex-col gap-5">
                            <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base styles.redText]}">
                              {checkError}
                            </p>
                          </div>
                        )}
                        <div className="flex justify-end items-end w-full mx-4 mt-[25px] pr-4">
                          <button
                            disabled={isDisabled}
                            className={`text-white px-4 py-2 w-full h-12 rounded-lg ${
                              isDisabled
                                ? 'bg-greyish cursor-not-allowed'
                                : 'bg-cashiaBlue cursor-pointer'
                            }`}
                            onClick={checkPersonalInfo}>
                            Continue
                          </button>
                          <div />
                        </div>
                      </>
                    </ModalContent>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={cn(
            `fixed top-0 left-0 w-screen h-screen z-auto bg-no-repeat bg-cover bg-center`,
            {
              'bg-white bg-none': phoneValidateState,
            }
          )}
          style={{
            backgroundImage: phoneValidateState
              ? 'none'
              : `url(${pinverifyBg})`,
          }}>
          <div className="sm:hidden md:flex">
            <img
              src={LogoSvg}
              alt="logo"
              className=" hidden xl:flex mx-10 my-10 top-50 left-100"
            />
          </div>
          {phoneValidateState ? (
            <>
              <div className="flex flex-col justify-center items-center gap-2 md:gap-8 text-center">
                <div>
                  <img
                    src={smsIcon}
                    alt="lock icon"
                    className="w-[50px] h-[50px] p-2 md:w-[81px] md:h-[81px]"
                  />
                </div>
                <p className="font-metropolis font-semibold text-xl xl:text-2xl leading-9">
                  Verify your phone number
                </p>
                <div className="flex flex-col gap-5 text-center">
                  <p className="font-Metropolis px-6 xl:px-0 font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                    We have sent a 5-digit code sent via SMS to
                  </p>
                  <p className="font-Metropolis font-normal px-6 md:px-0 md:text-base xs:text-xs md:leading-base xs:leading-base">
                    {`+${user?.phone?.countryCode || ''}${
                      user?.phone?.number || ''
                    }`}
                    . Please enter it below.
                  </p>
                </div>
                <OTPInput
                  autoFocus={phoneValidateState}
                  isNumberInput
                  value={smsCode}
                  length={5}
                  inputClassName="otpInput"
                  onChangeOTP={(otp: string) =>
                    dispatch({type: ActionKind.SMSCODE, payload: otp})
                  }
                />
                <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base text-darkGrey">
                  Didn’t get a code?{' '}
                  <button
                    className="{[styles.resend font-Metropolis font-normal md:text-semibold xs:text-xs md:leading-base xs:leading-base underline text-black"
                    onClick={() => {
                      dispatch({type: ActionKind.SMSCODE, payload: ''});
                      void sendCodeFunc();
                    }}>
                    Send again{' '}
                    {loadCode && <CircularProgress className="ml-2" />}
                  </button>
                </p>
                {verifyPhone?.verifyPhone === false && (
                  <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base text-red-500">
                    Invalid code, try again
                  </p>
                )}
                {verifyPhoneLoading && <CircularProgress />}
                {sendCode?.sendVerificationCode && (
                  <div className="md:left-[27%] mt-4 md:mt-28 right-10 left-5 p-5 border border-green-500 rounded-2xl w-screen xl:w-[693px] md:w-[85%] flex justify-between items-center">
                    <div className="flex items-center gap-4 xl:gap-10">
                      <img src={tickSvg} alt="tick" />
                      <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                        Check your text messages for your security code
                      </p>
                    </div>
                    <img src={xSvg} alt="" />
                  </div>
                )}
              </div>
            </>
          ) : askPin && intialPinModal ? (
            <div className="flex flex-col justify-center items-center gap-8 text-center">
              <div>
                <img
                  src={lockIcon}
                  alt="lock icon"
                  className="w-[50px] h-[50px] p-2 md:w-[81px] md:h-[81px]"
                />
              </div>
              <p className="font-metropolis font-semibold text-xl md:text-2xl leading-9">
                Set a new pin for your wallet
              </p>
              <div className="flex flex-col gap-5 text-center text-lazyGreen">
                <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                  This is a one time PIN. Please try not to forget this PIN.
                  This PIN will be
                </p>
                <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base">
                  required for all transactions throughout all Cashia platforms.
                </p>
              </div>
              <OTPInput
                autoFocus={askPin}
                value={newPin}
                isNumberInput
                length={4}
                inputClassName="otpInput"
                onChangeOTP={(otp: string) =>
                  dispatch({type: ActionKind.NEWPIN, payload: otp})
                }
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-8  text-center">
              <div>
                <img src={lockIcon} alt="lock icon" />
              </div>
              <p className="font-metropolis font-semibold text-2xl leading-9">
                Confirm your PIN
              </p>
              <OTPInput
                autoFocus={askPin}
                isNumberInput
                value={confirmPin}
                length={4}
                inputClassName="otpInput"
                onChangeOTP={(otp: string) =>
                  dispatch({type: ActionKind.CONFIRMPIN, payload: otp})
                }
              />
              {setWalletPinLoading && <CircularProgress />}
              {setWalletPinError && (
                <p className="font-Metropolis font-normal md:text-base xs:text-xs md:leading-base xs:leading-base text-red-500">
                  {checkError}
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PinVerification;
