import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Avatar,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
} from '@mui/material';

import CursorPagination from './CursorPagination';
import TaskIcon from '../../../assets/icons/task-square.svg';
import ProgressIcon from '../../../assets/images/In-progress-icon.svg';
import Exlamation from '../../../assets/images/exclamation.svg';
import FailedSvg from '../../../assets/images/failed.svg';
import InvoiceSvg from '../../../assets/images/invoices 2.svg';
import MessageIcon from '../../../assets/images/message-icon.svg';
import Div from '../../../components/common/Div';
import {
  OrderBatchesQuery,
  OrderBatchFieldsFragment,
  OrderStatus,
} from '../../../graphql/generated';
import {colours} from '../../../utils/constants';

type Props = {
  orders: OrderBatchesQuery | undefined;
  loadMore: (next: boolean) => Promise<void>;
  count: number;
  requested?: boolean;
  delivered?: boolean;
  failed?: boolean;
  orderID?: string;
  orderLoading?: boolean;
  selectOrder?: (order: OrderBatchFieldsFragment) => void;
  openCancelModal?: (order: OrderBatchFieldsFragment) => void;
};

const OrderTable = ({
  orders,
  loadMore,
  count,
  requested,
  delivered,
  failed,
  orderID,
  orderLoading,
  selectOrder,
  openCancelModal,
}: Props) => {
  return (
    <Div sx={styles.main}>
      <Div sx={[styles.width100, styles.bottomWidth100]}>
        <Div sx={styles.tableHeader}>ITEM</Div>
        <Div sx={styles.tableHeader}>ORDERED BY</Div>
        {requested || <Div sx={styles.tableHeader}>DELIVERED ON</Div>}
        {requested && <Div sx={styles.tableHeader}>LOCATION</Div>}
        <Div sx={styles.tableHeader}>ACTION</Div>
      </Div>
      <Divider sx={styles.hr} />
      <Div sx={[styles.width100, styles.colmFlex]}>
        <>
          {orders?.orderBatches.edges.length ? (
            <>
              {orders?.orderBatches.edges.map((row) => (
                <Div key={row.node.id}>
                  <Div sx={styles.flex100More}>
                    <Div sx={styles.tableRowItem}>
                      <Div
                        sx={styles.disFlex}
                        onClick={() => {
                          selectOrder && selectOrder(row.node);
                        }}>
                        {requested && <OpenInNewIcon sx={styles.openIcon} />}
                        <Div>
                          {row.node.items.map((item) => (
                            <Typography key={item.variant.id}>
                              {item.variant.product?.title} -x{item.count}
                            </Typography>
                          ))}
                        </Div>
                      </Div>
                    </Div>
                    <Div sx={styles.tableRow}>
                      {row.node.buyer.user?.name?.substring(0, 20) || 'unknown'}
                    </Div>
                    {requested || (
                      <Div sx={styles.tableRow}>
                        {failed && (
                          <Div sx={styles.trueAction}>
                            <img
                              src={FailedSvg}
                              style={styles.rightSpace10}
                              alt="FailedSvg"
                            />
                            <Typography>Delivery failed</Typography>
                          </Div>
                        )}
                        {delivered && <Typography>-</Typography>}
                      </Div>
                    )}
                    {requested && (
                      <Div sx={styles.tableRow}>
                        {row.node.buyer.currentAddress?.location
                          .formattedAddress || '-'}
                      </Div>
                    )}
                    <Div sx={[styles.tableRow, styles.tableRow4]}>
                      {delivered && (
                        <Div sx={styles.delAction}>
                          <Div
                            sx={styles.trueAction}
                            onClick={() =>
                              selectOrder && selectOrder(row.node)
                            }>
                            <img
                              src={InvoiceSvg}
                              alt="Invoice svg"
                              style={styles.rightSpace}
                            />
                            <Typography>Receipt</Typography>
                          </Div>
                          <img
                            src={Exlamation}
                            alt="Exlamation"
                            style={styles.moreRightSpace}
                          />
                        </Div>
                      )}
                      {requested && (
                        <>
                          {row.node.id === orderID && orderLoading ? (
                            <Div sx={styles.crload}>
                              <CircularProgress size={29} />
                            </Div>
                          ) : (
                            <>
                              {row.node.status === 'NEW' ? (
                                <Div sx={styles.flexGap}>
                                  <Tooltip title="Confirm">
                                    <Div
                                      sx={styles.btnConfirm}
                                      onClick={() =>
                                        selectOrder && selectOrder(row.node)
                                      }>
                                      <CheckCircleIcon sx={styles.actionBtn} />
                                      <Typography>Confirm</Typography>
                                    </Div>
                                  </Tooltip>
                                  <Tooltip title="Cancel">
                                    <Div
                                      sx={styles.btnCancel}
                                      onClick={() => {
                                        openCancelModal &&
                                          openCancelModal(row.node);
                                      }}>
                                      <CancelIcon sx={styles.actionBtn} />
                                      <Typography>Cancel</Typography>
                                    </Div>
                                  </Tooltip>
                                </Div>
                              ) : (
                                <Div sx={styles.spaceBtw}>
                                  <Div
                                    sx={styles.trueAction}
                                    onClick={() =>
                                      selectOrder && selectOrder(row.node)
                                    }>
                                    <img
                                      src={ProgressIcon}
                                      style={styles.hourglassBottomIcon}
                                    />
                                    <Typography>in progress</Typography>
                                  </Div>
                                  <img
                                    src={MessageIcon}
                                    style={styles.messageIcon}
                                  />
                                </Div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Div>
                  </Div>
                  <Divider sx={styles.hr} />
                  <Div
                    sx={
                      row.node.status === OrderStatus.New
                        ? styles.smallNew
                        : styles.small
                    }
                    onClick={() => {
                      selectOrder && selectOrder(row.node);
                    }}>
                    <Div sx={styles.newOrderCont}>
                      <Div>
                        {row.node.items.map((item) => (
                          <Typography key={item.variant.id}>
                            {item.variant.product?.title} -x{item.count}
                          </Typography>
                        ))}
                      </Div>
                      {requested && row.node.status === OrderStatus.New && (
                        <Div sx={styles.newWrapper}>
                          <Typography sx={styles.newTxt}>New Order</Typography>
                        </Div>
                      )}
                    </Div>
                    <Div sx={styles.byWrapper}>
                      <Div sx={styles.f1}>
                        <Typography sx={styles.blueText}>
                          Ordered by:
                        </Typography>
                        <Typography>
                          {row.node.buyer.user?.name?.substring(0, 15) ||
                            'unknown'}
                        </Typography>
                        <Typography sx={styles.smallText}>
                          {row.node.buyer.currentAddress?.location
                            .formattedAddress || '-'}
                        </Typography>
                      </Div>

                      <Div sx={styles.xyz}>
                        <Divider orientation="vertical" sx={styles.vertical} />
                        {requested && (
                          <>
                            {row.node.status === OrderStatus.InDelivery ? (
                              <Div>
                                <Typography sx={styles.blueText}>
                                  Scheduled for:
                                </Typography>
                                <Typography sx={styles.smallText}>-</Typography>
                              </Div>
                            ) : (
                              <Div>
                                <Typography sx={styles.blueText}>
                                  Fulfillment by:
                                </Typography>
                                <Typography sx={styles.smallText}>
                                  {row.node.fulfillment.toLocaleLowerCase()}
                                </Typography>
                              </Div>
                            )}
                          </>
                        )}
                        {delivered && (
                          <Div>
                            <Typography sx={styles.blueText}>
                              Delivered on:
                            </Typography>
                            <Typography sx={styles.smallText}>-</Typography>
                          </Div>
                        )}
                        {failed && (
                          <Div>
                            <Typography sx={[styles.blueText, styles.redColor]}>
                              Failed:
                            </Typography>
                            <Div sx={styles.trueAction}>
                              <img
                                src={FailedSvg}
                                style={styles.rightSpace10}
                                alt="FailedSvg"
                              />
                              <Typography sx={styles.smallText}>
                                Delivery failed
                              </Typography>
                            </Div>
                          </Div>
                        )}
                      </Div>
                    </Div>
                  </Div>
                </Div>
              ))}
              {orders?.orderBatches.pageInfo.hasNextPage ||
              orders?.orderBatches.pageInfo.hasPrevPage ? (
                <Div sx={styles.pagination}>
                  <CursorPagination
                    loadMore={loadMore}
                    hasNextPage={orders?.orderBatches.pageInfo.hasNextPage}
                    hasPrevPage={orders?.orderBatches.pageInfo.hasPrevPage}
                    count={count}
                  />
                </Div>
              ) : (
                <Div sx={styles.em} />
              )}
            </>
          ) : (
            <Div sx={styles.empty}>
              <Div sx={styles.inEmpty}>
                <Avatar src={TaskIcon} />
                <Typography sx={styles.emptext}>
                  You have no orders today. Keep refreshing to get new updates.
                </Typography>
              </Div>
            </Div>
          )}
        </>
      </Div>
    </Div>
  );
};

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: {md: '50px', xs: '20px'},
    overflow: 'hidden',
  },
  em: {marginBottom: {md: '30px', xs: '50px'}},
  pagination: {
    bottom: {xs: '50', lg: '10'},
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  smallText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '20px',
    color: colours.foggy,
  },
  blueText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '13px',
    color: colours.cashiaBlue,
    marginBottom: '13px',
  },
  redColor: {color: colours.smoothRed},
  f1: {flex: 1},
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colours.lightBlue,
    borderRadius: '10px',
    width: '100%',
    paddingY: '40px',
    marginTop: '17px',
  },
  vertical: {
    background: colours.lightenedGrey,
    borderRadius: '5px',
    marginRight: '8px',
    width: '3px',
    border: 'none',
  },
  mrgLeft: {marginLeft: '50px'},
  inEmpty: {
    width: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  byWrapper: {display: 'flex', marginTop: '17px'},
  emptext: {
    textAlign: 'center',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '13px',
  },
  newOrderCont: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  xyz: {flex: 1, display: 'flex', marginLeft: '5px'},
  hr: {
    height: '1px',
    background: colours.light,
    display: {md: 'flex', xs: 'none'},
  },
  crload: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newWrapper: {
    width: '72px',
    height: '28px',
    background: colours.grenish,
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeader: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: colours.darkGrey,
    flex: 3,
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    flexWrap: 'wrap',
    flex: 3,
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: colours.black,
    paddingY: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  trueAction: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tableRowItem: {
    flex: 3,
    flexWrap: 'wrap',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '5',
    color: colours.black,
    cursor: 'pointer',
    paddingY: '25px',
    '&:hover': {
      color: colours.cashiaBlue,
    },
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    background: colours.absoluteWhite,
    display: {md: 'none', xs: 'flex'},
    boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    marginY: '17px',
    flexDirection: 'column',
    padding: '10px',
    marginX: '4px',
    cursor: 'pointer',
  },
  smallNew: {
    background: colours.absoluteWhite,
    display: {md: 'none', xs: 'flex'},
    borderRadius: '7px',
    marginY: '17px',
    flexDirection: 'column',
    padding: '10px',
    marginX: '4px',
    border: `1px solid ${colours.smoothRed}`,
    cursor: 'pointer',
  },
  disFlex: {display: 'flex'},
  rightSpace: {marginRight: '5px'},
  rightSpace10: {marginRight: '10px'},
  moreRightSpace: {marginRight: '15px'},
  hourglassBottomIcon: {
    color: colours.black,
    fontSize: '30px',
    marginRight: '5px',
  },
  messageIcon: {
    color: colours.smoothRed,
    fontSize: '30px',
    marginRight: '60px',
  },
  newTxt: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '10px',
    color: colours.white,
  },
  flexGap: {display: 'flex', gap: '90px', marginLeft: '50px'},
  flex100: {display: 'flex', width: '100%'},
  flex100More: {
    display: {md: 'flex', xs: 'none'},
    width: '100%',
  },
  width100: {width: '100%'},
  spaceBtw: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '50px',
  },
  openIcon: {
    marginRight: '10px',
  },
  actionBtn: {
    marginRight: '7px',
    cursor: 'pointer',
  },
  delAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginLeft: '50px',
  },
  btnConfirm: {
    display: 'flex',
    color: colours.grenish,
    cursor: 'pointer',
  },
  btnCancel: {
    display: 'flex',
    color: colours.smoothRed,
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flex4: {flex: 4},
  tableRow4: {
    flex: 4,
    display: 'flex',
    alignItems: 'center',
  },
  bottomWidth100: {
    display: {md: 'flex', xs: 'none'},
    marginBottom: '10px',
  },
  colmFlex: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: {md: 'calc(100svh - 310px)', xs: 'calc(100svh - 220px)'},
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
  },
};

export default OrderTable;
