import TextInput from '../../../components/tailwind/TextInput';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

const ItemInStock = () => {
  const {productData, isUpdating} = useAddOrUpdateProductContentContext();

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <h3 className="text-neutral-800 mb-9 text-2xl font-semibold leading-[30px]">
          Items in Stock
        </h3>
        <div className="w-full flex flex-col gap-10">
          <TextInput
            type="number"
            label="How many items do you have in stock?"
            labelStyle="text-neutral-800 text-base font-normal leading-tight"
            description="This will help us manage your store and update you once your stock is running low."
            descriptionStyle="text-zinc-500 text-base font-normal leading-tight text-sm"
          />
        </div>
      </div>
      <Footer
        className="flex-5"
        next={
          isUpdating
            ? `/store/product/${productData?.product?.id || ''}`
            : undefined
        }
      />
    </div>
  );
};

export default ItemInStock;
