import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '../../../components/tailwind/Button';
import Spinner from '../../../components/tailwind/Spinner';
import {cn} from '../../../utils/reusableFunctions';
import {useAddOrUpdateProductContentContext} from '../layout';

type Props = {
  className?: string;
  next?: string;
  disabled?: boolean;
  updateLoading?: boolean;
  addOrUpdate?: () => Promise<void>;
};
const Footer = ({
  className,
  next,
  addOrUpdate,
  disabled = false,
  updateLoading = false,
}: Props) => {
  const {step, totalSteps, productId, addOrUpdateProductMenu, isUpdating} =
    useAddOrUpdateProductContentContext();
  const navigate = useNavigate();
  const progressBarWidth = ((step + 1) * 100) / totalSteps;

  useEffect(() => {
    if (!productId && !isUpdating) navigate('/store/add-product');
  }, [isUpdating, navigate, productId]);

  return (
    <div className="w-full h-[70px]">
      {isUpdating || (
        <div
          className="h-0.5 bg-gradient-to-r from-rose-600 via-pink-600 to-yellow-500"
          style={{width: `${String(progressBarWidth)}%`}}
        />
      )}
      <div
        className={cn(
          'px-6 flex justify-between w-full items-center py-4 bg-neutral-100',
          {'justify-end': isUpdating},
          className
        )}>
        {isUpdating || (
          <Button
            label="Back"
            labelStyle="text-base font-normal text-black"
            className="border border-slate-900 bg-transparent rounded-xl"
            onClick={() =>
              navigate(addOrUpdateProductMenu[step - 1]?.link || '/store')
            }
          />
        )}
        {isUpdating || (
          <p className="text-neutral-500 text-[13px] font-normal">
            {step + 1}/{totalSteps}
          </p>
        )}
        <Button
          label={
            updateLoading ? (
              <Spinner fillColor="fill-white" width={36.5} />
            ) : isUpdating ? (
              'Save'
            ) : (
              'Next'
            )
          }
          className="rounded-xl"
          disabled={disabled}
          labelStyle="text-neutral-50 text-base font-normal"
          onClick={() => {
            if (disabled) return;
            addOrUpdate
              ? void addOrUpdate()
              : navigate(next || addOrUpdateProductMenu[step + 1]?.link || '');
          }}
        />
      </div>
    </div>
  );
};

export default Footer;
