import {Alert} from '@cashiaApp/web-components';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as ErrorIcon} from '../../assets/images/error_icon.svg';
import {ReactComponent as MessageIcon} from '../../assets/images/message_icon.svg';
import {ReactComponent as LogoSvg} from '../../assets/logos/cashia_logo.svg';
import LoadingModal from '../../components/common/LoadingModal';
import OTPInput from '../../components/common/OTPInput/Input';
import {
  useSendVerificationCodeMutation,
  useVerifyPhoneMutation,
} from '../../graphql/generated';
import {useUserAuth} from '../../utils/user';

const WalletVerification: React.FC = () => {
  const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState('');
  const [validPhoneError, setValidPhoneError] = useState('');
  const {user, merchant} = useUserAuth();
  const [
    sendVerificationCode,
    {
      data: sendOTPCode,
      error: sendVerificationCodeError,
      loading: sendVerificationCodeLoading,
    },
  ] = useSendVerificationCodeMutation();
  const [
    verifyPhoneMutation,
    {data: verifyPhone, loading: verifyPhoneLoading, error: verifyPhoneError},
  ] = useVerifyPhoneMutation();

  const sendCode = useCallback(async () => {
    if (!user?.phone) {
      setValidPhoneError(
        'Sorry, we are unable to verify your phone number at this time'
      );
      return;
    }
    await sendVerificationCode({
      variables: {
        input: {
          phone: {
            countryCode: user?.phone.countryCode,
            number: user?.phone.number,
          },
        },
      },
    });
  }, [sendVerificationCode, user?.phone]);
  const handleSubmit = useCallback(async () => {
    const res = await verifyPhoneMutation({
      variables: {
        input: {
          code: otpCode,
        },
      },
    });
    if (res.data?.verifyPhone) {
      navigate('/activation/cashtag');
    }
  }, [navigate, otpCode, verifyPhoneMutation]);

  useEffect(() => {
    if (otpCode.length !== 5) return;
    void handleSubmit();
  }, [otpCode, handleSubmit]);

  useLayoutEffect(() => {
    void sendCode();
  }, [sendCode]);

  const loading = useMemo(
    () => sendVerificationCodeLoading || verifyPhoneLoading,
    [sendVerificationCodeLoading, verifyPhoneLoading]
  );
  const error = useMemo(
    () => sendVerificationCodeError || verifyPhoneError,
    [sendVerificationCodeError, verifyPhoneError]
  );
  const handleResend = () => {
    setOtpCode('');
    void sendCode();
  };

  return (
    <>
      <div className="hidden lg:flex py-10 px-10">
        <LogoSvg />
      </div>
      <div className="w-screen flex justify-center items-center">
        <div className="mt-8 flex flex-col items-center">
          <div className="hidden lg:flex py-6">
            <MessageIcon />
          </div>
          <h1 className="font-semibold lg:font-bold text-2xl lg:text-4xl mt-4">
            Verify your phone number
          </h1>
          <p className="w-80 lg:w-96 text-center mt-4 mb-4">
            We have sent a 5-digit code sent to over SMS to{' '}
            {`+${merchant?.phone?.countryCode || ''}${
              merchant?.phone?.number || ''
            }`}
            . Please enter it below.
          </p>
          <OTPInput
            autoFocus
            isNumberInput
            value={otpCode}
            length={5}
            inputClassName="otpInput"
            onChangeOTP={setOtpCode}
          />
          {!verifyPhone?.verifyPhone && error && validPhoneError && (
            <p className="flex items-center text-red-500 text-sm mt-4">
              <ErrorIcon />
              Sorry, we are unable to verify the code you entered. Please make
              sure you input the right phone number and security code.
            </p>
          )}
          <p className="text-sm mt-4">
            Didn’t get a code?
            <button
              className="cursor-pointer underline ml-1 text-black"
              onClick={handleResend}>
              Send again
            </button>
          </p>
          {sendOTPCode?.sendVerificationCode && (
            <Alert severity="success">
              Check your text messages for your security code
            </Alert>
          )}
          <LoadingModal open={loading} />
        </div>
      </div>
    </>
  );
};
export default WalletVerification;
