import {toast} from '@cashiaApp/web-components';
import {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import {ReactComponent as Invoices} from './../../assets/icons/Invoices.svg';
import {ReactComponent as CardIcon} from './../../assets/icons/card.svg';
import {ReactComponent as CardCashiaLogo} from './../../assets/images/cardCashiaLogo.svg';
import lockIcon from './../../assets/images/lockIcon.svg';
import pinverifyBg from './../../assets/images/pinverifyBg.svg';
import swgliLines from './../../assets/images/swgliLines.svg';
import {ReactComponent as Withdraw} from './../../assets/withdrawIcon.svg';
import ReceipModal from './ReceiptModal';
import {WalletContextType} from './Wallet';
import WithDrawModal from './WithDrawModal';
import {getMethodNameImage, greet, statusColor} from './helper';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import {ReactComponent as ArrowForwardIosIcon} from '../../assets/icons/chevronGrey_icon.svg';
import {ReactComponent as Logout} from '../../assets/icons/log_out.svg';
import {ReactComponent as TrendingUpIcon} from '../../assets/icons/trendingup.svg';
import LoadingModal from '../../components/common/LoadingModal';
import OTPInput from '../../components/common/OTPInput/Input';
import CircularProgress from '../../components/tailwind/Spinner';
import {
  CurrencyCode,
  PaymentMethodType,
  useGetWalletScreenInfoQuery,
  useRecentTransactionsQuery,
  useVerifyPinOrSignatureMutation,
  TransactionsFieldsFragment,
  useWithdrawMutation,
  AuthenticationType,
  usePaymentMethodsQuery,
  PaymentMethodsFieldsFragment,
  WalletActivationStatus,
} from '../../graphql/generated';
import formatDate from '../../utils/formatDate';
import formatMoney from '../../utils/formatMoney';
import {cn} from '../../utils/reusableFunctions';
import {useUserAuth} from '../../utils/user';

import '../../components/common/OTPInput/OTPInput.css';

const initialState = {
  pin: '',
  withdrawType: '',
  mpesaValues: {
    amount: '',
    phone: '',
  },
  bankID: '',
  bankAmount: '',
  withdrawOpen: {
    initial: false,
    middle: {initial: false, mpesa: false, bank: false},
    final: {initial: false, mpesa: false, bank: false},
  },
  askPin: false,
  loader: false,
  transactionError: false,
};
export type State = typeof initialState;

export type BankDetails = {
  id?: string;
  name?: string | null;
  account?: string;
  logo?: string | null;
};

export enum ActionKind {
  PIN = 'PIN',
  WITHDRAWTYPE = 'WITHDRAWTYPE',
  MPAMOUNT = 'MPAMOUNT',
  MPPHONE = 'MPPHONE',
  BANKID = 'BANKID',
  BANKAMOUNT = 'BANKAMOUNT',
  OPENWITHDRAWMODAL = 'OPENWITHDRAWMODAL',
  MIDDLESTEP = 'MIDDLESTEP',
  FINALSTEP = 'FINALSTEP',
  BACKTOINTIAL = 'BACKTOINTIAL',
  BACKTOMIDDLE = 'BACKTOMIDDLE',
  PROCEEDTOPIN = 'PROCEEDTOPIN',
  OFFASKPIN = 'OFFASKPIN',
  ERROROCCURED = 'ERROROCCURED',
  ONLOADING = 'ONLOADING',
  RESET = 'RESET',
}
export type Action = {
  type: ActionKind;
  payload: string;
};

const reducer = (state: State, action: Action) => {
  const {type, payload} = action;
  const value = {
    PIN: {...state, pin: payload},
    WITHDRAWTYPE: {...state, withdrawType: payload},
    BANKID: {...state, bankID: payload},
    BANKAMOUNT: {...state, bankAmount: payload},
    MPAMOUNT: {...state, mpesaValues: {...state.mpesaValues, amount: payload}},
    MPPHONE: {...state, mpesaValues: {...state.mpesaValues, phone: payload}},
    OFFASKPIN: {...state, askPin: false},
    ONLOADING: {...state, loader: true},
    ERROROCCURED: {...initialState, transactionError: true},
    OPENWITHDRAWMODAL: {
      ...state,
      withdrawOpen: {...state.withdrawOpen, initial: true},
    },
    MIDDLESTEP: {
      ...state,
      withdrawOpen: {
        ...state.withdrawOpen,
        middle:
          payload === 'mpesa'
            ? {...state.withdrawOpen.middle, initial: true, mpesa: true}
            : {...state.withdrawOpen.middle, initial: true, bank: true},
      },
    },
    FINALSTEP: {
      ...state,
      withdrawOpen: {
        ...state.withdrawOpen,
        final:
          payload === 'mpesa'
            ? {...state.withdrawOpen.middle, initial: true, mpesa: true}
            : {...state.withdrawOpen.middle, initial: true, bank: true},
      },
    },
    BACKTOINTIAL: {
      ...state,
      withdrawOpen: {
        ...state.withdrawOpen,
        middle: {initial: false, mpesa: false, bank: false},
      },
    },
    BACKTOMIDDLE: {
      ...state,
      withdrawOpen: {
        ...state.withdrawOpen,
        final: {initial: false, mpesa: false, bank: false},
      },
    },
    PROCEEDTOPIN: {
      ...state,
      withdrawOpen: {...initialState.withdrawOpen},
      askPin: true,
      withdrawType: payload,
    },

    RESET: initialState,
  };
  return value[type] || state;
};

const WalletDashboard = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    pin,
    withdrawType,
    mpesaValues,
    bankID,
    bankAmount,
    askPin,
    loader,
    transactionError,
  } = state;
  const [openReceipt, setOpenReceipt] = useState(false);
  const {merchant} = useUserAuth();
  const {data: paymentMethods} = usePaymentMethodsQuery();

  const {
    data: transactionsData,
    loading,
    error: transactionsError,
    refetch: refetchTransaction,
  } = useRecentTransactionsQuery();
  const [verifyPinOrSignatureMutation, {data, error}] =
    useVerifyPinOrSignatureMutation();

  const {
    data: balance,
    loading: balanceLoading,
    refetch: balanceRefetch,
  } = useGetWalletScreenInfoQuery();

  const [
    withdraw,
    {data: withdrawData, loading: withdrawLoading, error: withdrawError},
  ] = useWithdrawMutation();

  const pinVerification = useCallback(async () => {
    const res = await verifyPinOrSignatureMutation({
      variables: {
        input: {
          pin: pin,
        },
      },
    });
    return res;
  }, [verifyPinOrSignatureMutation, pin]);

  const bankAccounts: (PaymentMethodsFieldsFragment | null)[] | undefined =
    paymentMethods?.paymentMethods.filter(
      (item) => item?.__typename === 'InterswitchBankAccountPaymentMethod'
    );

  const selectedBankAccount = useMemo(() => {
    const result: BankDetails = {};
    bankAccounts?.forEach((item) => {
      if (
        item?.id === bankID &&
        item.__typename === 'InterswitchBankAccountPaymentMethod'
      ) {
        result.id = item.interswitchBank?.id;
        result.name = item.interswitchBank?.name;
        result.account = item.bankAccountNumber;
        result.logo = item.interswitchBank?.logo?.small;
      }
    });
    return result;
  }, [bankAccounts, bankID]);

  const paymentType = useMemo(() => {
    if (withdrawType === 'Mpesa') {
      return {
        amount: mpesaValues.amount,
        paymentVariables: {
          type: PaymentMethodType.Phone,
          phone: {
            countryCode: '254',
            number: mpesaValues.phone,
          },
        },
      };
    }
    if (
      withdrawType === 'Bank' &&
      selectedBankAccount.name &&
      selectedBankAccount.account &&
      selectedBankAccount.id
    ) {
      return {
        amount: bankAmount,
        paymentVariables: {
          type: PaymentMethodType.InterswitchBank,
          interswitchBankAccount: {
            name: selectedBankAccount.name,
            bankAccountNumber: selectedBankAccount.account,
            interswitchBankId: selectedBankAccount.id,
          },
        },
      };
    }
  }, [
    bankAmount,
    mpesaValues.amount,
    mpesaValues.phone,
    selectedBankAccount.account,
    selectedBankAccount.id,
    selectedBankAccount.name,
    withdrawType,
  ]);

  const withDrawTransaction = useCallback(async () => {
    try {
      const res = await pinVerification();
      if (res.data?.verifyPinOrSignature) {
        dispatch({type: ActionKind.OFFASKPIN, payload: ''});
        const datainfo = await withdraw({
          variables: {
            input: {
              money: {
                amountInCents: Number(paymentType?.amount) * 100,
                currencyCode: CurrencyCode.Kes,
              },
              type: AuthenticationType.VerificationCode,
              authenticationCode: pin,
              paymentMethod: paymentType?.paymentVariables,
            },
          },
        });
        if (datainfo.data) {
          closeWithdrawModal();
          setOpenReceipt(true);
        }
      }
    } catch (err) {
      console.error('');
    } finally {
      closeWithdrawModal();
      await balanceRefetch();
      await refetchTransaction();
    }
  }, [
    pinVerification,
    withdraw,
    paymentType,
    pin,
    balanceRefetch,
    refetchTransaction,
  ]);

  const lastestTransactions = (): TransactionsFieldsFragment[] => {
    if (
      transactionsData?.recentTransactions &&
      transactionsData?.recentTransactions.length < 3
    ) {
      return transactionsData?.recentTransactions;
    } else {
      return transactionsData?.recentTransactions.slice(0, 3) || [];
    }
  };

  const closeWithdrawModal = () =>
    dispatch({type: ActionKind.RESET, payload: ''});

  const goTo = (str: string) => navigate(str);

  const statusBgColor = (status: WalletActivationStatus) => {
    return {
      VERIFIED: {
        backgroungColor: 'bg-cashiaBlue',
        statusname: 'Active',
        textColor: 'text-cashiaBlue',
      },
      PENDING: {
        backgroungColor: 'bg-orangeLight',
        statusname: 'Pending',
        textColor: 'text-orangeLight',
      },
      IN_PROGRESS: {
        backgroungColor: 'bg-orangeLight',
        statusname: 'In progress',
        textColor: 'text-orangeLight',
      },
    }[status];
  };

  useEffect(() => {
    if (askPin && pin.length === 4) void withDrawTransaction();
  }, [askPin, pin, withDrawTransaction]);

  useEffect(() => {
    if (withdrawError) {
      dispatch({type: ActionKind.ERROROCCURED, payload: ''});
      toast.error(withdrawError.message);
    }
    if (withdrawLoading) dispatch({type: ActionKind.ONLOADING, payload: ''});
  }, [withdrawError, withdrawLoading]);

  const {handleLogout} = useOutletContext<WalletContextType>();

  return (
    <>
      {!askPin ? (
        <>
          <div className="lg:w-full lg:box-border lg:relative lg:bg-transparent lg:select-none lg:tap-transparent">
            <div className="lg:flex lg:flex-col lg:pb-30 lg:pt-50">
              <div className="pt-5 md:pt-5 pl-5 md:pl-5 lg:hidden">
                <ArrowBackIcon onClick={() => navigate('/profile')} />
              </div>
              <div className="lg:px-35 xs:px-20 lg:pt-10 lg:pl-10">
                <p className="pt-10 md:pt-10 lg:pt-0 font-metropolis font-light text-base leading-base pl-5 md:pl-10 lg:pl-0 text-black">
                  {greet()}
                </p>
                <p className="pl-5 md:pl-10 lg:pl-0 text-xl md:text-xl lg:text-4xl font-semibold">
                  {merchant?.name}
                </p>
              </div>
              <div
                className={`${
                  merchant?.wallet?.activation?.status
                    ? statusBgColor(merchant?.wallet?.activation?.status)
                        .backgroungColor
                    : ''
                } grid sm:w-[90%] md:w-[450px] lg:w-[500px] h-[214px] rounded-xl mt-5 ml-5 md:ml-8`}>
                <img
                  src={swgliLines}
                  className="col-start-1 row-start-1 self-center"
                />
                <div className="flex justify-between col-start-1 row-start-1 p-8 h-full">
                  <div className="flex flex-col justify-between h-full">
                    <div className="flex flex-col gap-6 md:gap-2">
                      <p className="text-zinc-100 text-sm md:text-base font-normal font-['Metropolis']">
                        Available Wallet balance
                      </p>
                      {balanceLoading ? (
                        <span className="pt-[12px]">
                          <CircularProgress
                            width={30}
                            height={30}
                            fillColor="fill-white"
                          />
                        </span>
                      ) : (
                        <>
                          <p className="text-neutral-50 text-2xl md:text-[34px] font-semibold font-['Metropolis']">
                            {balance?.me &&
                              balance?.me.__typename === 'Merchant' &&
                              `${
                                balance?.me?.wallet?.credit?.currencyCode || ''
                              } 
                ${
                  balance?.me?.wallet?.credit?.currencyCode
                    ? formatMoney(balance?.me?.wallet?.credit, 2)
                    : '---'
                }`}
                          </p>
                          <p className="bg-sky-300 rounded-full p-2 space-x-1 text-cyan-600 text-sm">
                            <span className="font-medium font-['Metropolis']">
                              On Hold:
                            </span>
                            <span className="font-semibold font-['Metropolis']">
                              {balance?.me &&
                                balance?.me.__typename === 'Merchant' &&
                                `${
                                  balance?.me?.wallet?.totalHeld
                                    ?.currencyCode || ''
                                }  
                        ${
                          balance?.me?.wallet?.totalHeld?.currencyCode
                            ? formatMoney(balance?.me?.wallet?.totalHeld, 2)
                            : '---'
                        }`}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                    <p className="text-neutral-50 sm:text-base lg:text-xl font-medium font-['Metropolis']">
                      @{merchant?.tag}
                    </p>
                  </div>
                  <div className="flex flex-col justify-between h-full items-end">
                    <div
                      className={cn(
                        'py-1 px-1.5 h-fit text-center text-xs md:text-sm bg-neutral-50 rounded-[5px]',
                        merchant?.wallet?.activation?.status &&
                          statusBgColor(merchant?.wallet?.activation?.status)
                            .textColor
                      )}>
                      {merchant?.wallet?.activation?.status &&
                        statusBgColor(merchant?.wallet?.activation?.status)
                          .statusname}
                    </div>
                    <CardCashiaLogo />
                  </div>
                </div>
              </div>
              <div className="lg:mt-15 xs:mt-15 lg:mb-5 xs:mb-0 flex gap-40 lg:px-35 xs:px-20">
                <div
                  className="flex flex-col justify-center items-center gap-2 cursor-pointer pl-5 md:pl-10 lg:pl-10 pt-5"
                  onClick={() =>
                    dispatch({type: ActionKind.OPENWITHDRAWMODAL, payload: ''})
                  }>
                  <Withdraw className="w-70 h-70" />
                  <p className="font-metropolis font-normal text-base">
                    Withdraw
                  </p>
                </div>
              </div>
              <div className="w-full h-5 bg-offWhite my-5 sm:flex lg:hidden" />
              <div className="sm:flex flex-row lg:hidden">
                <div
                  className="flex flex-col cursor-pointer w-20 pl-5 md:pl-10 gap-2"
                  onClick={() => goTo('track-usage')}>
                  <div className="w-16 h-16 bg-offWhite flex justify-center items-center rounded-lg">
                    <TrendingUpIcon className="" />
                  </div>
                  <p className="w-60 font-normal text-xs leading-4">
                    Track usage
                  </p>
                </div>
                <div
                  className="flex flex-col cursor-pointer w-20 pl-9 md:pl-16 gap-2"
                  onClick={() => goTo('banks')}>
                  <div className="w-16 h-16 bg-offWhite flex justify-center items-center rounded-lg">
                    <CardIcon />
                  </div>
                  <p className="w-60 font-normal text-xs leading-4">My Cards</p>
                </div>
                <div
                  className="flex flex-col cursor-pointer w-20 pl-12 md:pl-20 gap-2"
                  onClick={() => goTo('receipts')}>
                  <div className="w-16 h-16 bg-offWhite flex justify-center items-center rounded-lg">
                    <Invoices />
                  </div>
                  <p className="w-60 font-normal text-xs leading-4">Receipts</p>
                </div>
              </div>
              <div className="w-full h-5 bg-offWhite my-5 sm:flex lg:hidden" />
              {transactionsData?.recentTransactions.length && (
                <div className="flex flex-col pl-5 space-y-4 mt-4 py-5">
                  <div className="justify-between lg:flex">
                    <p className="font-metropolis font-semibold text-base lg:text-2xl leading-6  pt-5 sm:pb-1 lg:pb-0">
                      Recent Transactions
                    </p>
                    <p
                      className="font-metropolis font-normal text-base leading-base text-cashiaBlue cursor-pointer hidden lg:flex xs:hidden pt-5 pr-5"
                      onClick={() => goTo('track-usage')}>
                      View All &gt;
                    </p>
                  </div>
                  {loading ? (
                    <CircularProgress className="m-20" fillColor="fill-white" />
                  ) : (
                    <div className="sm:border-2 lg:border-none sm:pt-3 lg:pt-0 rounded-lg pl-2 mr-4 mb-6 space-y-5">
                      {lastestTransactions().length ? (
                        <>
                          {lastestTransactions().map((item) => (
                            <div
                              key={item.id}
                              className="flex justify-between items-center sm:border-b-2 lg:border-b-0 sm:pb-3 lg:pb-0">
                              <div className="flex sm:gap-[8px] lg:gap-5 items-center">
                                <img
                                  src={
                                    getMethodNameImage(
                                      item,
                                      merchant?.id ? merchant.id : ''
                                    )?.logo
                                  }
                                  className="sm:w-10 sm:h-10 lg:w-14 lg:h-14 rounded-5"
                                />
                                <div className="flex flex-col gap-1">
                                  <p className="font-metropolis font-normal text-base lg:text-xl sm:text-base leading-5 lg:leading-5 sm:leading-4">
                                    {
                                      getMethodNameImage(
                                        item,
                                        merchant?.id ? merchant.id : ''
                                      )?.name
                                    }
                                  </p>
                                  <p className="sm:flex lg:hidden font-metropolis font-normal text-sm text-foggy">
                                    {formatDate(item.date as Date, {
                                      withDate: true,
                                      withShortMonth: true,
                                      withTime: true,
                                      hour12: true,
                                    })}
                                  </p>
                                </div>
                              </div>
                              <p className="sm:hidden lg:flex font-metropolis font-normal text-base lg:text-base xs:text-base lg:leading-base xs:leading-4 text-foggy cursor-pointer">
                                {formatDate(item.date as Date, {
                                  withDate: true,
                                  withShortMonth: true,
                                  withTime: true,
                                  hour12: true,
                                })}
                              </p>
                              <p
                                className="font-metropolis font-normal lg:text-lg sm:text-sm lg:leading-base sm:leading-sm text-right cursor-pointer pr-5"
                                style={{color: statusColor(item.status)}}>
                                {item.amount.currencyCode}{' '}
                                {formatMoney(item.amount, 2)}
                              </p>
                            </div>
                          ))}
                          <div
                            className="justify-between items-center cursor-pointer lg:hidden sm:flex flex-row pb-4"
                            onClick={() => goTo('track-usage')}>
                            <p className="font-semibold font-base leading-base text-cashiaBlue">
                              More Transactions
                            </p>
                            <div className="pr-5">
                              <ArrowForwardIosIcon className="cursor-pointer" />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {transactionsError ? (
                        <p className="font-metropolis font-normal text-base leading-base text-red">
                          Failed to load recent transactions
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <div
                    onClick={handleLogout}
                    className="sm:flex justify-between items-center mr-4 lg:hidden border rounded-lg cursor-pointer shadow-md p-4">
                    <Logout />
                    <div className="font-semibold text-base text-red mr-28">
                      Log Out
                    </div>
                    <div className="pr-5">
                      <ArrowForwardIosIcon className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <WithDrawModal
            state={state}
            dispatch={dispatch}
            closeWithdrawModal={closeWithdrawModal}
            bankAccounts={bankAccounts}
            selectedBankAccount={selectedBankAccount}
          />
        </>
      ) : (
        <div
          className="fixed top-0 left-0 w-screen h-screen z-50 bg-cover bg-center bg-no-repeat"
          style={{backgroundImage: `url(${pinverifyBg})`}}>
          <div className="flex flex-col justify-center items-center gap-30 mt-[50px]">
            <div>
              <img src={lockIcon} alt="lock icon" className="pb-5" />
            </div>
            <p className="font-metropolis font-semibold lg:text-3xl xs:text-xl px-10 text-center pb-5">
              Enter your PIN to authorize this transaction
            </p>
            <OTPInput
              autoFocus={askPin}
              isNumberInput
              value={pin}
              length={4}
              inputClassName="otpInput"
              isPassword
              onChangeOTP={(opt: string) =>
                dispatch({type: ActionKind.PIN, payload: opt})
              }
            />
            <div className="flex gap-50">
              <button
                onClick={closeWithdrawModal}
                className="pt-10 text-cashiaBlue">
                Cancel
              </button>
            </div>
            {error && pin.length > 3 && (
              <p className="font-metropolis font-normal text-base leading-base text-red">
                {error.message}
              </p>
            )}
            {data?.verifyPinOrSignature == false && pin.length === 4 && (
              <p className="font-metropolis font-normal text-base leading-base text-red">
                Incorrect pin
              </p>
            )}
          </div>
        </div>
      )}
      <LoadingModal open={loader} />
      {transactionError || (
        <ReceipModal
          open={openReceipt}
          setOpen={setOpenReceipt}
          receipt={withdrawData?.withdraw}
        />
      )}
    </>
  );
};

export default WalletDashboard;
