import {useCallback, useState} from 'react';
import {Link, useLocation, Outlet} from 'react-router-dom';

import PinVerification from './PinVerification';
import {ReactComponent as ActiveAccountBalanceWalletIcon} from '../../assets/icons/card-blue.svg';
import {ReactComponent as InactiveAccountBalanceWalletIcon} from '../../assets/icons/card-foggy.svg';
import {ReactComponent as InactiveHomeIcon} from '../../assets/icons/dashboard.svg';
import {ReactComponent as ActiveHomeIcon} from '../../assets/icons/dashboard_active.svg';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import {ReactComponent as ActiveReceiptIcon} from '../../assets/icons/receipt-blue.svg';
import {ReactComponent as InactiveReceiptIcon} from '../../assets/icons/receipt-foggy.svg';
import {ReactComponent as ActiveTrendingUpIcon} from '../../assets/icons/stats_active.svg';
import {ReactComponent as InactiveTrendingUpIcon} from '../../assets/icons/stats_icon.svg';
import Navbar from '../../components/common/Navbar';
import {cn} from '../../utils/reusableFunctions';
import useWindowSize from '../inbox/hooks/useWindowSize';

export interface PasswordState {
  password: string;
  showPassword: boolean;
}

export type WalletContextType = {
  handleLogout: () => void;
};

type NavItemsType = {
  name: string;
  to: string;
  activeIcon: JSX.Element;
  inactiveIcon: JSX.Element;
}[];

const navItems: NavItemsType = [
  {
    name: 'Dashboard',
    to: '/wallet',
    activeIcon: <ActiveHomeIcon />,
    inactiveIcon: <InactiveHomeIcon />,
  },
  {
    name: 'Track usage',
    to: '/wallet/track-usage',
    activeIcon: <ActiveTrendingUpIcon />,
    inactiveIcon: <InactiveTrendingUpIcon />,
  },
  {
    name: 'My Cards',
    to: '/wallet/banks',
    activeIcon: <ActiveAccountBalanceWalletIcon />,
    inactiveIcon: <InactiveAccountBalanceWalletIcon />,
  },
  {
    name: 'Receipts',
    to: '/wallet/receipts',
    activeIcon: <ActiveReceiptIcon />,
    inactiveIcon: <InactiveReceiptIcon />,
  },
];

const Wallet = () => {
  const location = useLocation();
  const {width} = useWindowSize();

  const [values, setValues] = useState<PasswordState>({
    password: '',
    showPassword: false,
  });

  const [verified, setVerified] = useState(
    JSON.parse(sessionStorage.getItem('verified') || 'false') as boolean
  );

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem('verified');
    setValues({
      password: '',
      showPassword: false,
    });
    setVerified(false);
  }, []);

  return (
    <div className="overflow-hidden h-screen w-screen">
      <div className="sm:hidden lg:flex">
        <Navbar />
      </div>
      {verified ? (
        <div className="flex flex-nowrap w-[100svw] sm:h-screen lg:h-[calc(100svh-64px)] overflow-hidden">
          <div className="flex-none w-[300px] p-5 h-[calc(100svh-64px)] bg-gray-200 sm:hidden lg:flex flex-col">
            <div className="flex flex-col h-full justify-between">
              <div className="h-[300px] flex flex-col justify-between">
                {navItems.map((nav, i) => (
                  <Link to={nav.to} key={i}>
                    <div
                      className={cn(
                        `flex items-center gap-2.5 my-6 cursor-pointer font-normal text-base leading-4 text-foggy`,
                        {'text-cashiaBlue': location.pathname === nav.to}
                      )}>
                      {location.pathname === nav.to
                        ? nav.activeIcon
                        : nav.inactiveIcon}
                      <p>{nav.name}</p>
                    </div>
                  </Link>
                ))}
              </div>
              <div
                className="flex pb-[20px] cursor-pointer w-fit"
                onClick={() => {
                  sessionStorage.removeItem('verified');
                  setValues({
                    password: '',
                    showPassword: false,
                  });
                  setVerified(false);
                }}>
                <LogoutIcon />
                <button className="pl-[10px] select-none">Log out</button>
              </div>
            </div>
          </div>
          <div
            className={`flex-auto overflow-y-scroll overflow-x-hidden p-0 ${
              width < 900 ? 'h-[100svh]' : 'h-calc(100svh - 60px)'
            }`}>
            <div className="w-[100%] h-[100%]">
              <Outlet context={{handleLogout}} />
            </div>
          </div>
        </div>
      ) : (
        <PinVerification
          values={values}
          setValues={setValues}
          setVerified={setVerified}
        />
      )}
    </div>
  );
};
export default Wallet;
