import {useEffect, useMemo, useState} from 'react';

import DangerIcon from '../../../assets/icons/danger-icon.svg';
import {ReactComponent as Phone} from '../../../assets/icons/phoneInput_icon.svg';
import TextInput from '../../../components/tailwind/TextInput';
import {
  MerchantOnboardingInput,
  VerificationId,
} from '../../../graphql/generated';
import {cn, updateStringStateWithLimit} from '../../../utils/reusableFunctions';
import Footer from '../components/Footer';
import {useSelfOnboardingContentContext} from '../layout';

type BusinessDetails = {
  idNumber?: string;
  applicantsName?: string;
  idType?: VerificationId | null;
  phone?: number;
};

const BusinessDetails = () => {
  const {merchant, update, updateLoading} = useSelfOnboardingContentContext();
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>();
  const readableVerificationType = (status?: VerificationId) => {
    if (!status) return [];
    return {
      NATIONAL_ID: 'National ID',
      ALIEN_CARD: 'Alien Card',
      PASSPORT: 'Passport No.',
    }[status];
  };

  const merchantOnboardingInput = useMemo(() => {
    const input: MerchantOnboardingInput = {
      idType: businessDetails?.idType,
      idNumber: businessDetails?.idNumber,
      applicantsName: businessDetails?.applicantsName,
      phone: {
        countryCode: '254',
        number: String(businessDetails?.phone),
      },
    };
    return input;
  }, [businessDetails]);

  const updateMerchant = async () => await update?.(merchantOnboardingInput);

  useEffect(() => {
    setBusinessDetails({
      idNumber: merchant?.idNumber || '',
      applicantsName: merchant?.applicantsName || '',
      idType: merchant?.idType || VerificationId.NationalId,
      phone: Number(merchant?.phone?.number),
    });
  }, [merchant]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <h1 className="text-neutral-800 text-xl md:text-[34px] font-semibold mb-6 md:mb-12">
          Enter your personal details
        </h1>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-0">
              <h3 className="text-neutral-800 text-base md:text-xl font-semibold">
                Add your identification
              </h3>
              <p className="text-zinc-700 text-sm md:text-base font-normal">
                Add an identification number authorized by the Government of
                Kenya.
              </p>
            </div>
            <div className="flex gap-7">
              {Object.values(VerificationId).map((item) => (
                <div
                  key={item}
                  className="flex items-center w-fit gap-2 cursor-pointer transition duration-300 select-none rounded-md"
                  onClick={() =>
                    setBusinessDetails({...businessDetails, idType: item})
                  }>
                  <div
                    className={cn(
                      'w-5 h-5 rounded-full border border-stone-400 transition',
                      {
                        'border-[6px] border-green-500 bg-white shadow':
                          businessDetails?.idType === item,
                      }
                    )}
                  />
                  <p className="text-neutral-500 text-xs md:text-base font-normal">
                    {readableVerificationType(item)}
                  </p>
                </div>
              ))}
            </div>
            <TextInput
              value={businessDetails?.idNumber || ''}
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  idNumber: e.target.value,
                })
              }
            />
          </div>
          <TextInput
            value={businessDetails?.applicantsName || ''}
            label="What is your name?"
            labelStyle="text-neutral-800 text-base md:text-xl font-semibold"
            description="Enter the name associated with the above ID number."
            descriptionStyle="text-zinc-700 text-sm md:text-base font-normal"
            onChange={(e) =>
              setBusinessDetails({
                ...businessDetails,
                applicantsName: e.target.value,
              })
            }
          />
          <TextInput
            value={businessDetails?.phone || ''}
            type="number"
            label="Enter your phone number"
            labelStyle="text-neutral-800 text-base md:text-xl font-semibold"
            description={
              <div className="text-red-700 flex gap-1 items-center text-sm md:text-base font-normal leading-tight">
                <img src={DangerIcon} className="w-3 h-3 md:w-4 md:h-4" />
                <span className="text-xs md:text-sm">
                  This phone number will be used for your business wallet
                  activation.
                </span>
              </div>
            }
            startAdornment={
              <div className="text-neutral-800 text-base font-semibold ml-3">
                +254
              </div>
            }
            onChange={(e) =>
              updateStringStateWithLimit(
                e.target.value.replace(/^254/, ''),
                9,
                (stringPhone) =>
                  setBusinessDetails({
                    ...businessDetails,
                    phone: Number(stringPhone),
                  })
              )
            }
            endAdornment={
              <div className="">
                <Phone />
              </div>
            }
          />
        </div>
      </div>
      <Footer
        className="flex-5"
        update={updateMerchant}
        updateLoading={updateLoading}
        disabled={
          !(
            !!businessDetails?.applicantsName?.length &&
            !!(businessDetails?.idNumber?.length || 0) &&
            !!businessDetails.phone
          )
        }
      />
    </div>
  );
};

export default BusinessDetails;
