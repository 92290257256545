import {
  Button,
  Sheet,
  SheetContent,
  useMediaQuery,
} from '@cashiaApp/web-components';
import {useState} from 'react';

import {Action, ActionKind, BankDetails, State} from '.';
import ModalComponent from './ModalComponent';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import mpesaLogo from '../../assets/images/mpesaLogo.svg';
import TextInput from '../../components/tailwind/TextInput';
import {PaymentMethodsFieldsFragment} from '../../graphql/generated';
import formatMoney from '../../utils/formatMoney';

interface Props {
  closeWithdrawModal: () => void;
  state: State;
  dispatch: React.Dispatch<Action>;
  bankAccounts: (PaymentMethodsFieldsFragment | null)[] | undefined;
  selectedBankAccount: BankDetails;
}

const tabs = [
  {name: 'Mobile Money', key: 'mobile'},
  {name: 'Banks', key: 'banks'},
];

const WithDrawModal = ({
  closeWithdrawModal,
  state,
  dispatch,
  bankAccounts,
  selectedBankAccount,
}: Props) => {
  const {mpesaValues, bankAmount, withdrawOpen} = state;
  const [activeTab, setActiveTab] = useState('mobile');
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const singleBankDetails = (
    bank: PaymentMethodsFieldsFragment | null | undefined
  ) => {
    if (!bank) return;
    const result: BankDetails = {};
    if (bank.__typename === 'InterswitchBankAccountPaymentMethod') {
      result.id = bank.interswitchBank?.id;
      result.name = bank.interswitchBank?.name;
      result.account = bank.bankAccountNumber;
      result.logo = bank.interswitchBank?.logo?.small;
      return result;
    }
  };

  const renderTabContent = () => {
    if (activeTab === 'mobile') {
      return (
        <div>
          <div
            className="flex items-center justify-between cursor-pointer gap-8"
            onClick={() =>
              dispatch({
                type: ActionKind.MIDDLESTEP,
                payload: 'mpesa',
              })
            }>
            <img
              className="w-[40px] h-[40px] rounded-full"
              src={mpesaLogo}
              alt="logo"
            />
            <p className="text-lg">MPESA</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full h-full overflow-y-auto">
          {bankAccounts?.length ? (
            bankAccounts.map((item) => (
              <div
                key={item?.id}
                className="flex items-center w-full p-4 cursor-pointer border-b"
                onClick={() => {
                  dispatch({
                    type: ActionKind.BANKID,
                    payload: item?.id || '',
                  });
                  dispatch({
                    type: ActionKind.MIDDLESTEP,
                    payload: 'bank',
                  });
                }}>
                <img
                  src={singleBankDetails(item)?.logo || ''}
                  alt="logo"
                  className="w-[40px] h-[40px] rounded-full mr-4"
                />
                <div className="flex flex-col flex-grow">
                  <p className="text-lg font-semibold">
                    {singleBankDetails(item)?.name}
                  </p>
                  <p className="text-sm text-gray-600">
                    {singleBankDetails(item)?.account}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="p-4 text-center">No bank account found</p>
          )}
        </div>
      );
    }
  };
  const renderDivider = () => {
    return (
      <div
        className="w-full h-1"
        style={{
          background:
            'linear-gradient(90deg, #DC1F5C 0%, #EE2D89 28.57%, #EA4335 57.81%, #FBBC04 98.44%)',
        }}
      />
    );
  };

  const renderTabs = () => {
    return (
      <div className="flex border-b">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`w-1/2 text-center py-2 cursor-pointer ${
              activeTab === tab.key ? 'border-b-2 border-smoothRed' : ''
            }`}
            onClick={() => setActiveTab(tab.key)}>
            {tab.name}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {isDesktop ? (
        <div className="sm:hidden lg:flex">
          <ModalComponent
            onOpenChange={closeWithdrawModal}
            open={withdrawOpen.initial}>
            {withdrawOpen.final.initial ? (
              <>
                {withdrawOpen.final.mpesa && (
                  <div className="flex flex-col justify-around sm:w-[400px] md:w-full sm:h-[450px] md:h-[550px] lg:h-[450px] rounded-lg p-4">
                    <div className="flex items-center justify-between">
                      <ArrowBackIcon
                        className="cursor-pointer"
                        onClick={() =>
                          dispatch({type: ActionKind.BACKTOMIDDLE, payload: ''})
                        }
                      />
                      <p className="text-sm md:text-lg text-cashiaBlue">
                        Confirm and withdraw to...
                      </p>
                    </div>
                    <div className="border-b" />
                    <div className="space-y-2 mt-[25px] flex justify-between items-center gap-[5px]">
                      <p className="text-sm">Mobile No:</p>
                      <p className="text-sm">{`+254${mpesaValues.phone}`}</p>
                    </div>
                    <div className="border-b" />
                    <div className="flex justify-between items-center">
                      <p className="text-sm">Amount:</p>
                      <p className="text-sm">{`KES ${formatMoney(
                        Number(mpesaValues.amount),
                        2
                      )}`}</p>
                    </div>
                    <div className="border-b" />
                    <div className="flex justify-between items-center">
                      <p className="text-sm">Transaction cost:</p>
                      <p className="text-sm">---</p>
                    </div>
                    <div className="border-b" />
                    <div>
                      <Button
                        className="bg-cashiaBlue text-white p-4 md:mx-0 sm:w-full md:w-[240px] h-[48px] float-right rounded-lg"
                        onClick={() =>
                          dispatch({
                            type: ActionKind.PROCEEDTOPIN,
                            payload: 'Mpesa',
                          })
                        }>
                        Confirm
                      </Button>
                    </div>
                  </div>
                )}
                {withdrawOpen.final.bank && (
                  <div className="flex flex-col justify-around sm:w-[400px] md:w-full sm:h-[450px] md:h-[550px] lg:h-[450px] rounded-lg p-4">
                    <div className="flex items-center justify-between">
                      <ArrowBackIcon
                        className="cursor-pointer"
                        onClick={() =>
                          dispatch({type: ActionKind.BACKTOMIDDLE, payload: ''})
                        }
                      />
                      <p className="text-sm md:text-lg text-cashiaBlue">
                        Confirm and withdraw to...
                      </p>
                    </div>
                    <div className="border-b" />
                    <div className="flex justify-between items-center gap-[5px]">
                      <div className="flex items-center gap-4">
                        <img
                          src={selectedBankAccount.logo || ''}
                          className="sm:w-[20px] sm:h-[20px] lg:w-[40px] lg:h-[40px] rounded-full"
                        />
                        <p className="text-sm">{selectedBankAccount.name}</p>
                      </div>
                      <p className="text-sm">{selectedBankAccount.account}</p>
                    </div>
                    <div className="border-b" />
                    <div className="flex justify-between items-center gap-[5px]">
                      <p className="text-sm">Amount:</p>
                      <p className="text-sm">{`KES ${formatMoney(
                        Number(bankAmount),
                        2
                      )}`}</p>
                    </div>
                    <div className="border-b" />
                    <div>
                      <Button
                        className="bg-cashiaBlue text-white sm:mx-8 md:mx-0 px-4 py-2 h-[48px] sm:w-full md:w-[240px] float-right rounded-lg"
                        onClick={() =>
                          dispatch({
                            type: ActionKind.PROCEEDTOPIN,
                            payload: 'Bank',
                          })
                        }>
                        Confirm
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ) : withdrawOpen.middle.initial ? (
              <div className="flex flex-col justify-around sm:w-[400px] lg:w-full sm:h-[450px] md:h-[550px] lg:h-[450px] rounded-lg p-4">
                <div className="flex items-center lg:gap-24 justify-between">
                  <ArrowBackIcon
                    className="cursor-pointer"
                    onClick={() =>
                      dispatch({type: ActionKind.BACKTOINTIAL, payload: ''})
                    }
                  />
                  <p className="text-sm md:text-lg text-cashiaBlue cursor-pointer">
                    Withdraw to{' '}
                    {withdrawOpen.middle.mpesa
                      ? 'Mpesa'
                      : selectedBankAccount.name}
                  </p>
                </div>
                <div className="border-b" />
                {withdrawOpen.middle.mpesa ? (
                  <div className="flex flex-col gap-4">
                    <p className="text-sm p-1 md:p-0 md:px-4">Enter Amount</p>
                    <TextInput
                      placeholder="0"
                      containerStyle="border-none p-2 rounded-lg"
                      type="number"
                      value={mpesaValues.amount}
                      onChange={(e) =>
                        dispatch({
                          type: ActionKind.MPAMOUNT,
                          payload: e.target.value,
                        })
                      }
                      startAdornment={
                        <span className="text-foggy text-[10px] lg:text-[13px]">
                          KES
                        </span>
                      }
                    />
                    <p className="text-sm p-1 md:p-0 md:px-4">Phone Number</p>
                    <TextInput
                      placeholder="700000000"
                      type="number"
                      containerStyle="border-none p-2 rounded-lg"
                      value={mpesaValues.phone}
                      onChange={(e) =>
                        dispatch({
                          type: ActionKind.MPPHONE,
                          payload: e.target.value,
                        })
                      }
                      startAdornment={
                        <span className="text-foggy text-[10px] lg:text-[13px]">
                          +254
                        </span>
                      }
                    />
                    <Button
                      className={`${
                        !!(mpesaValues.amount.length > 1) &&
                        !!(mpesaValues.phone.length === 9)
                          ? 'bg-cashiaBlue text-white'
                          : 'bg-greyish text-white'
                      } rounded-lg h-[48px] sm:w-full lg:w-[240px] ml-[175px]`}
                      onClick={() =>
                        dispatch({type: ActionKind.FINALSTEP, payload: 'mpesa'})
                      }
                      disabled={
                        !(
                          !!(mpesaValues.amount.length > 1) &&
                          !!(mpesaValues.phone.length === 9)
                        )
                      }>
                      {`Transfer KES ${formatMoney(
                        Number(mpesaValues.amount),
                        2
                      )}`}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col gap-16">
                    <p className="text-sm">Enter Amount</p>
                    <TextInput
                      placeholder="0"
                      type="number"
                      containerStyle="border-none rounded-lg"
                      value={bankAmount}
                      onChange={(e) =>
                        dispatch({
                          type: ActionKind.BANKAMOUNT,
                          payload: e.target.value,
                        })
                      }
                      startAdornment={
                        <span className="text-foggy text-[10px] lg:text-[13px]">
                          KES
                        </span>
                      }
                    />
                    <Button
                      className={`${
                        bankAmount
                          ? 'bg-cashiaBlue text-white'
                          : 'bg-greyish text-white'
                      } rounded-lg h-[48px] sm:w-full lg:w-[240px] ml-[175px]`}
                      disabled={!bankAmount}
                      onClick={() =>
                        dispatch({type: ActionKind.FINALSTEP, payload: 'bank'})
                      }>
                      {`Transfer KES ${formatMoney(Number(bankAmount), 2)}`}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="sm:w-[400px] lg:w-full sm:h-[450px] md:h-[550px] lg:h-[450px] rounded-lg p-4">
                  <div className="flex items-center justify-between p-4">
                    <p className="text-lg">Withdraw to...</p>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 border-b bg-smoothRed" />
                    <div className="w-1/2 border-b" />
                  </div>
                  <div className="flex items-center justify-between p-4">
                    <p className="w-1/2">Mobile Money</p>
                    <p className="w-1/2 px-20">Banks</p>
                  </div>
                  <div className="border-b" />
                  <div className="flex justify-between sm:w-[400px] lg:w-full sm:h-[70%] lg:h-4/6 p-4 overflow-scroll">
                    <div className="w-1/2">
                      <div
                        className="flex items-center cursor-pointer gap-2"
                        onClick={() =>
                          dispatch({
                            type: ActionKind.MIDDLESTEP,
                            payload: 'mpesa',
                          })
                        }>
                        <img
                          className="w-[40px] h-[40px] rounded-full"
                          src={mpesaLogo}
                          alt="logo"
                        />
                        <p className="text-sm">MPESA</p>
                      </div>
                    </div>
                    <div className="w-1/2 sm:h-86 lg:h-96 p-4 overflow-scroll">
                      <div className="space-y-4">
                        {bankAccounts?.length ? (
                          bankAccounts.map((item) => (
                            <div
                              key={item?.id}
                              className="flex flex-col sm:flex-row items-center cursor-pointer gap-4 overflow-y-scroll"
                              onClick={() => {
                                dispatch({
                                  type: ActionKind.BANKID,
                                  payload: item?.id || '',
                                });
                                dispatch({
                                  type: ActionKind.MIDDLESTEP,
                                  payload: 'bank',
                                });
                              }}>
                              <img
                                src={singleBankDetails(item)?.logo || ''}
                                alt="logo"
                                className="w-[20px] sm:h-[20px] lg:w-[40px] lg:h-[40px] rounded-full"
                              />
                              <div className="flex flex-col">
                                <p className="text-xs sm:text-sm">
                                  {singleBankDetails(item)?.name}
                                </p>
                                <p className="text-xs">
                                  {singleBankDetails(item)?.account}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No bank account found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </ModalComponent>
        </div>
      ) : (
        <div className="flex flex-col max-h-screen w-screen">
          <Sheet open={withdrawOpen.initial} onOpenChange={closeWithdrawModal}>
            <SheetContent className="w-full h-full " side="bottom">
              {withdrawOpen.final.initial ? (
                <>
                  {withdrawOpen.final.mpesa && (
                    <div className="flex flex-col gap-6 w-full h-full rounded-lg p-4">
                      <div className="flex items-center justify-between">
                        <ArrowBackIcon
                          className="cursor-pointer"
                          onClick={() =>
                            dispatch({
                              type: ActionKind.BACKTOMIDDLE,
                              payload: '',
                            })
                          }
                        />
                        <p className="text-sm md:text-lg text-cashiaBlue mr-20">
                          Confirm and withdraw to...
                        </p>
                      </div>
                      {renderDivider()}
                      <div className="space-y-2 mt-[25px] flex justify-between items-center gap-[5px]">
                        <p className="text-sm">Mobile No:</p>
                        <p className="text-sm">{`+254${mpesaValues.phone}`}</p>
                      </div>
                      <div className="border-b" />
                      <div className="flex justify-between items-center">
                        <p className="text-sm">Amount:</p>
                        <p className="text-sm">{`KES ${formatMoney(
                          Number(mpesaValues.amount),
                          2
                        )}`}</p>
                      </div>
                      <div className="border-b" />
                      <div className="flex justify-between items-center">
                        <p className="text-sm">Transaction cost:</p>
                        <p className="text-sm">---</p>
                      </div>
                      <div className="border-b" />
                      <div className="py-20 bottom-0 w-full">
                        <Button
                          className="bg-cashiaBlue text-white p-4 md:mx-0 w-full h-[48px] float-right rounded-lg"
                          onClick={() =>
                            dispatch({
                              type: ActionKind.PROCEEDTOPIN,
                              payload: 'Mpesa',
                            })
                          }>
                          Confirm
                        </Button>
                      </div>
                    </div>
                  )}
                  {withdrawOpen.final.bank && (
                    <div className="flex flex-col gap-6 w-full h-full rounded-lg p-4">
                      <div className="flex items-center justify-between">
                        <ArrowBackIcon
                          className="cursor-pointer"
                          onClick={() =>
                            dispatch({
                              type: ActionKind.BACKTOMIDDLE,
                              payload: '',
                            })
                          }
                        />
                        <p className="text-sm md:text-lg text-cashiaBlue mr-20">
                          Confirm and withdraw to...
                        </p>
                      </div>
                      {renderDivider()}
                      <div className="flex justify-between items-center gap-[5px]">
                        <div className="flex items-center gap-4">
                          <img
                            src={selectedBankAccount.logo || ''}
                            className="sm:w-[20px] sm:h-[20px] lg:w-[40px] lg:h-[40px] rounded-full"
                          />
                          <p className="text-sm">{selectedBankAccount.name}</p>
                        </div>
                        <p className="text-sm">{selectedBankAccount.account}</p>
                      </div>
                      <div className="border-b" />
                      <div className="flex justify-between items-center gap-[5px]">
                        <p className="text-sm">Amount:</p>
                        <p className="text-sm">{`KES ${formatMoney(
                          Number(bankAmount),
                          2
                        )}`}</p>
                      </div>
                      <div className="border-b" />
                      <div className="py-10">
                        <Button
                          className="bg-cashiaBlue text-white md:mx-0 px-4 py-2 h-[48px] w-full float-right rounded-lg"
                          onClick={() =>
                            dispatch({
                              type: ActionKind.PROCEEDTOPIN,
                              payload: 'Bank',
                            })
                          }>
                          Confirm
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              ) : withdrawOpen.middle.initial ? (
                <div className="flex flex-col gap-6 sm:w-full sm:h-full rounded-lg">
                  <div className="flex justify-between items-center">
                    <ArrowBackIcon
                      className="cursor-pointer"
                      onClick={() =>
                        dispatch({type: ActionKind.BACKTOINTIAL, payload: ''})
                      }
                    />
                    <p className="text-sm md:text-lg text-cashiaBlue cursor-pointer mr-28">
                      Withdraw to{' '}
                      {withdrawOpen.middle.mpesa
                        ? 'Mpesa'
                        : selectedBankAccount.name}
                    </p>
                  </div>
                  {renderDivider()}
                  {withdrawOpen.middle.mpesa ? (
                    <div className="flex flex-col gap-4">
                      <p className="text-sm p-1 md:p-0 md:px-4">Enter Amount</p>
                      <TextInput
                        placeholder="0"
                        containerStyle="border-none p-2 rounded-lg"
                        type="number"
                        value={mpesaValues.amount}
                        onChange={(e) =>
                          dispatch({
                            type: ActionKind.MPAMOUNT,
                            payload: e.target.value,
                          })
                        }
                        startAdornment={
                          <span className="text-foggy text-[10px] lg:text-[13px]">
                            KES
                          </span>
                        }
                      />
                      <p className="text-sm p-1 md:p-0 md:px-4">Phone Number</p>
                      <TextInput
                        placeholder="700000000"
                        type="number"
                        containerStyle="border-none p-2 rounded-lg"
                        value={mpesaValues.phone}
                        onChange={(e) =>
                          dispatch({
                            type: ActionKind.MPPHONE,
                            payload: e.target.value,
                          })
                        }
                        startAdornment={
                          <span className="text-foggy text-[10px] lg:text-[13px]">
                            +254
                          </span>
                        }
                      />
                      <div className="py-20 bottom-0 w-full">
                        <Button
                          className={`${
                            !!(mpesaValues.amount.length > 1) &&
                            !!(mpesaValues.phone.length === 9)
                              ? 'bg-cashiaBlue text-white'
                              : 'bg-greyish text-white'
                          } rounded-lg h-[48px] sm:w-full`}
                          onClick={() =>
                            dispatch({
                              type: ActionKind.FINALSTEP,
                              payload: 'mpesa',
                            })
                          }
                          disabled={
                            !(
                              !!(mpesaValues.amount.length > 1) &&
                              !!(mpesaValues.phone.length === 9)
                            )
                          }>
                          {`Transfer KES ${formatMoney(
                            Number(mpesaValues.amount),
                            2
                          )}`}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-6 w-full h-full">
                      <p className="text-sm">Enter Amount</p>
                      <TextInput
                        placeholder="0"
                        type="number"
                        containerStyle="border-none rounded-lg"
                        value={bankAmount}
                        onChange={(e) =>
                          dispatch({
                            type: ActionKind.BANKAMOUNT,
                            payload: e.target.value,
                          })
                        }
                        startAdornment={
                          <span className="text-foggy text-[10px] lg:text-[13px]">
                            KES
                          </span>
                        }
                      />
                      <div className="py-20 bottom-0 w-full">
                        <Button
                          className={`${
                            bankAmount
                              ? 'bg-cashiaBlue text-white'
                              : 'bg-greyish text-white'
                          } rounded-lg h-[48px] w-full`}
                          disabled={!bankAmount}
                          onClick={() =>
                            dispatch({
                              type: ActionKind.FINALSTEP,
                              payload: 'bank',
                            })
                          }>
                          {`Transfer KES ${formatMoney(Number(bankAmount), 2)}`}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex flex-col h-full">
                    <div className="flex flex-col items-center justify-between p-4">
                      <p className="text-lg">Withdraw to...</p>
                    </div>
                    {renderDivider()}
                    {renderTabs()}
                    <div className="flex justify-between w-full h-4/6 p-4 overflow-scroll">
                      {renderTabContent()}
                    </div>
                  </div>
                </>
              )}
            </SheetContent>
          </Sheet>
        </div>
      )}
    </>
  );
};

export default WithDrawModal;
