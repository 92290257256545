import {createTheme, responsiveFontSizes} from '@mui/material/styles';

import '../../index.css';
import {colours} from '../constants';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      'Metropolis',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    common: {},
    primary: {
      main: '#00A4D4',
      light: '#52bddd',
      dark: '#0083b4',
    },
    secondary: {
      main: '#dc1f5b',
      dark: '#c61b56',
      light: '#f04275',
    },
    info: {
      main: '#757575',
      dark: '#C8171D',
      light: '#494949',
    },
    success: {
      main: '#000000',
      dark: '#0C0D34',
      light: '#E5E5E5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderWidth: '1px',
          color: colours.cashiaBlue,
        },
        contained: {
          color: colours.white,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '86px',
          position: 'static',
          borderBottom: 'solid 1px #D0CECD',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&:hover': {
            border: '0px',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#494949',
          fontFamily: 'Metropolis-light',
          fontSize: '15px',
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Metropolis',
          fontWeight: '400',
          color: '#212121',
          '&:focus': {
            color: '#000',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Metropolis',
          fontWeight: '600',
          color: '#494949',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Metropolis',
          fontWeight: '400',
          padding: '0px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: '0px solid',
          backgroundColor: colours.lightenedGrey,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: colours.grenish,
          color: colours.white,
        },
        standardWarning: {
          backgroundColor: colours.orangeish,
          color: colours.white,
        },
        standardInfo: {
          backgroundColor: colours.cashiaBlue,
          color: colours.white,
        },
      },
    },
  },
});
export default responsiveFontSizes(theme);
