import {PropsWithChildren} from 'react';

type Props = {
  rotation: number;
};
const CustomMarker = ({children, rotation}: PropsWithChildren<Props>) => (
  <div
    style={{
      transform: `rotate(${rotation}deg)`,
      transformOrigin: '50% 50%',
    }}>
    {children}
  </div>
);

export default CustomMarker;
