import {useCallback, useEffect, useState} from 'react';

import {
  CursorInput,
  useProductsWithCursorPaginationQuery,
} from '../../../graphql/generated';
function useGetProducts(merchantId: string, categoryId?: string[]) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const {
    data,
    loading: dataLoading,
    error,
    refetch,
  } = useProductsWithCursorPaginationQuery({
    variables: {
      input: {
        cursor: {
          first: 10,
        },
        merchantId: merchantId || '',
        categoryIds: categoryId,
      },
    },
  });
  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after =
          data?.productsWithCursorPagination.pageInfo.endCursor;
        cursorLoad.first = 10;
      } else {
        cursorLoad.before =
          data?.productsWithCursorPagination.pageInfo.startCursor;
        cursorLoad.last = 10;
      }
      const res = await refetch({
        input: {
          cursor: cursorLoad,
          merchantId: merchantId || '',
          categoryIds: categoryId,
        },
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      refetch,
      merchantId,
      categoryId,
      data?.productsWithCursorPagination.pageInfo.endCursor,
      data?.productsWithCursorPagination.pageInfo.startCursor,
      count,
    ]
  );
  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);
  const refetchProducts = useCallback(() => {
    return refetch({
      input: {
        cursor: {first: 10},
        merchantId: merchantId || '',
        categoryIds: categoryId,
      },
    });
  }, [refetch, merchantId, categoryId]);
  return {
    data,
    loading,
    error,
    loadMore,
    count,
    refetch: refetchProducts,
  };
}

export default useGetProducts;
