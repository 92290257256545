import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FlagIcon from '@mui/icons-material/Flag';
import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useInboxContext} from './Inbox';
import Message from './components/Message';
import goSvg from '../../assets/images/goSvg.svg';
import Div from '../../components/common/Div';
import {
  useSendMessageMutation,
  MessageEdgeFieldsFragment,
  useReadMessagesMutation,
  useMessagesQuery,
  NewMessageDocument,
  NewMessageFieldsFragment,
  MessagesDocument,
  MessagesQuery,
} from '../../graphql/generated';
import {colours} from '../../utils/constants';
import {useUserAuth} from '../../utils/user';

const ChatArea = () => {
  const {merchant} = useUserAuth();
  const {chatRoom, handleToggle, chatMembers, memberImg, data, open} =
    useInboxContext();
  const [text, setText] = useState('');
  const [checkError, setCheckError] = useState(false);
  const chatId = useMemo(() => chatRoom?.node?.id || '', [chatRoom?.node.id]);
  const {
    loading,
    fetchMore,
    data: messageData,
    subscribeToMore,
    refetch,
  } = useMessagesQuery({
    variables: {input: {first: 30}, chatId},
    skip: !chatId,
  });
  const [sendMessageMutation, {loading: sendLoading, error: sendError}] =
    useSendMessageMutation();
  const [readMessagesMutation, {error: rdError}] = useReadMessagesMutation();

  const allMessages = useMemo(() => {
    const uniqueIds = new Set();
    return messageData?.messages.edges.filter(
      (item) => !uniqueIds.has(item.node.id) && uniqueIds.add(item.node.id)
    );
  }, [messageData?.messages.edges]);

  const readMsgs = useCallback(
    async (messages: MessageEdgeFieldsFragment[]) => {
      const ids = messages
        .filter((msg) => msg.node.isRead === false)
        .map((item) => item.node.id);
      if (!ids.length) return;
      await readMessagesMutation({
        variables: {
          messageIds: ids,
        },
      });
    },
    [readMessagesMutation]
  );

  useEffect(() => {
    if (!messageData?.messages.edges) return;
    void readMsgs(messageData.messages.edges);
  }, [messageData, readMsgs]);

  useEffect(() => {
    subscribeToMore<{newMessage: NewMessageFieldsFragment}>({
      document: NewMessageDocument,
      updateQuery: (prev, {subscriptionData, variables}) => {
        if (subscriptionData.data.newMessage.chatId !== variables?.chatId) {
          return prev;
        }
        const newMsg = subscriptionData.data.newMessage.message;
        if (newMsg.author.user?.id === merchant?.user?.id) return prev;
        return Object.assign({}, prev, {
          messages: {
            ...prev.messages,
            edges: [
              {
                __typename: 'MessageEdge',
                cursor: crypto.randomUUID(),
                node: newMsg,
              },
              ...prev.messages.edges,
            ],
          },
        });
      },
    });
  }, [chatId, merchant?.user?.id, subscribeToMore]);

  useEffect(() => {
    void refetch({
      input: {first: 30},
      chatId,
    });
  }, [chatId, open, refetch]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!text.length) return;
    const res = await sendMessageMutation({
      variables: {
        input: {
          text: text,
          chatId: chatId,
        },
      },
      update: (cache, result) => {
        const cached = cache.readQuery<MessagesQuery>({
          query: MessagesDocument,
          variables: {input: {first: 30}, chatId},
        });
        if (!cached?.messages) return;
        cache.writeQuery({
          query: MessagesDocument,
          variables: {input: {first: 30}, chatId},
          data: {
            ...cached,
            messages: {
              ...cached.messages,
              edges: [
                {
                  __typename: 'MessageEdge',
                  cursor: crypto.randomUUID(),
                  node: result.data?.sendMessage,
                },
                ...cached.messages.edges,
              ],
            },
          },
        });
      },
    });
    if (res.data?.sendMessage) setText('');
  };

  const loadMore = async () => {
    const res = await fetchMore({
      variables: {
        input: {
          after: messageData?.messages.edges.at(-1)?.cursor,
          first: 30,
        },
        chatId,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult.messages) return prev;
        return Object.assign({}, prev, {
          messages: {
            ...prev.messages,
            edges: [...prev.messages.edges, ...fetchMoreResult.messages.edges],
            pageInfo: fetchMoreResult.messages.pageInfo,
          },
        });
      },
    });
    if (res.data) void readMsgs(res.data.messages.edges);
  };

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const scrollTopValue = -event.currentTarget.scrollTop;
    const scrollTopMax =
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
    const isTopReached = Math.round(scrollTopValue) === scrollTopMax;
    if (isTopReached && messageData?.messages.pageInfo.hasNextPage) {
      void loadMore();
    }
  };

  useEffect(() => {
    if (sendError || rdError) setCheckError(true);
  }, [rdError, sendError]);

  return (
    <Div sx={styles.main}>
      <Divider orientation="vertical" sx={styles.divider} />
      {chatRoom ? (
        <Div sx={styles.content}>
          <Div>
            <Div sx={styles.titleDiv}>
              <Avatar
                sx={styles.smImg}
                src={memberImg(chatRoom.node.members)}
              />
              <Typography sx={styles.title}>
                {chatMembers(chatRoom.node.members)[0]?.user?.name}
              </Typography>
              <ArrowBackIcon sx={styles.smIcon} onClick={handleToggle} />
            </Div>
            <Divider sx={styles.divide3} />
            {loading ? (
              <Div sx={styles.initialLoading}>
                <CircularProgress color="primary" size={45} />
              </Div>
            ) : (
              <Div sx={styles.chatContent} onScroll={handleScroll}>
                {messageData?.messages.edges.length ? (
                  allMessages?.map((msg) => (
                    <Message msg={msg} key={msg.cursor} />
                  ))
                ) : (
                  <Typography sx={[styles.selectChatTxt, styles.chatting]}>
                    Start Chatting
                  </Typography>
                )}
                {messageData?.messages.pageInfo.hasNextPage && (
                  <Div sx={styles.loadMore}>
                    <CircularProgress color="success" size={27} />
                  </Div>
                )}
              </Div>
            )}
          </Div>
          <Div sx={styles.center}>
            <form style={styles.inputDiv} onSubmit={onSubmit}>
              <TextField
                variant="standard"
                placeholder="Write message"
                sx={styles.inputField}
                autoComplete="off"
                value={text}
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {sendLoading && (
                        <CircularProgress color="success" size={20} />
                      )}
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
              <Button sx={styles.sendBtn} type="submit">
                <img src={goSvg} alt="send" />
              </Button>
            </form>
          </Div>
        </Div>
      ) : data?.chats.edges.length ? (
        <Div sx={styles.content}>
          <Divider sx={styles.divide} />
          <Div sx={styles.centerEmpty}>
            <Typography sx={styles.selectChatTxt}>Select Chat Room</Typography>
            <FlagIcon sx={styles.flagIcon} />
          </Div>
        </Div>
      ) : (
        <Div sx={styles.content}>
          <Divider sx={styles.divide} />
        </Div>
      )}
      <Divider orientation="vertical" sx={styles.divider} />
      <Snackbar
        open={checkError}
        autoHideDuration={6000}
        onClose={() => setCheckError(false)}>
        <Alert
          onClose={() => setCheckError(false)}
          severity="error"
          sx={styles.errDis}>
          Error Occured
        </Alert>
      </Snackbar>
    </Div>
  );
};

const styles = {
  main: {
    flex: 4,
    overflowY: 'none',
    height: {md: 'calc(100svh - 64px)', xs: '100svh'},
    display: 'flex',
  },
  errDis: {width: '100%'},
  divide: {marginTop: '50px', height: '0.5px', background: colours.greyish},
  divide3: {
    height: '0.5px',
    background: {md: colours.greyish, xs: 'red'},
  },
  centerEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '200px',
  },
  chatting: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  selectChatTxt: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '30px',
    color: colours.foggy,
  },
  flagIcon: {color: colours.foggy, marginLeft: '10px'},
  initialLoading: {
    width: '100%',
    height: 'calc(100% - 150px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {width: '100%', scrollBehavior: 'smooth'},
  chatContent: {
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'scroll',
    height: 'calc(100% - 150px)',
    overflowAnchor: 'none',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleDiv: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: {md: 'flex-start', xs: 'space-between'},
    paddingX: {md: '0px', xs: '15px'},
    paddingY: {md: '0px', xs: '5px'},
  },
  title: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: colours.black,
    marginLeft: {md: '20px', xs: '0px'},
  },
  smImg: {
    display: {md: 'none'},
  },
  smIcon: {
    cursor: 'pointer',
    display: {md: 'none'},
  },
  divider: {
    width: '2px',
    background: colours.greyish,
    display: {xs: 'none', md: 'flex'},
  },
  inputDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    gap: '15px',
    marginTop: '15px',
    marginBottom: '10px',
    '@media only screen and (maxWidth: 900px)': {
      padding: '25px',
    },
  },
  inputField: {
    width: '75%',
    border: 'none',
    outline: 'none',
    background: '#EEEEEE',
    height: '100%',
    padding: '11px',
    paddingLeft: '20px',
    borderRadius: '10px',
  },
  sendBtn: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    background: colours.black,
    '&:hover': {background: colours.black},
  },
  loadMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px',
  },
};

export default ChatArea;
