import {useEffect, useMemo, useState} from 'react';

import RadioGroup from '../../../components/tailwind/radioGroup/RadioGroup';
import {Fulfillment, ProductInput} from '../../../graphql/generated';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

const Shipping = () => {
  const {
    productData,
    removeProductFromContext,
    isUpdating,
    updateLoading,
    update,
  } = useAddOrUpdateProductContentContext();

  const [value, setValue] = useState<number>();

  const productInput = useMemo(() => {
    const input: ProductInput = {
      fulfillment:
        (value && Object.values(Fulfillment)[value]) || Fulfillment.Delivery,
    };
    if (productData) {
      input.id = productData.product?.id;
    }
    return input;
  }, [productData, value]);

  const sideEffect = () => {
    removeProductFromContext?.(
      isUpdating ? `/store/product/${productData?.product?.id || ''}` : '/store'
    );
  };

  const addOrUpdate = async () =>
    await update?.(productInput, sideEffect, true);

  useEffect(() => {
    setValue(
      productData?.product?.fulfillment === Fulfillment.Delivery ? 0 : 1
    );
  }, [productData?.product]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <h3 className="text-neutral-800 mb-6 text-2xl font-semibold leading-[30px]">
          Shipping
        </h3>
        <div className="w-[90%] md:w-96 px-5 py-10 shadow my-5">
          <RadioGroup
            key={value}
            value={value}
            onChange={(i) => setValue(i)}
            labelText="Fulfillment method:"
            options={[
              <div key="Delivery" className="flex flex-1 justify-around">
                <span>Delivery</span>
              </div>,
              <div key="Pick up" className="flex flex-1 justify-around">
                <span>None</span>
              </div>,
            ]}
          />
        </div>
      </div>
      <Footer
        className="flex-5"
        addOrUpdate={addOrUpdate}
        updateLoading={updateLoading}
      />
    </div>
  );
};

export default Shipping;
