import {Navigate, Outlet} from 'react-router-dom';

import {MeFieldsFragment} from '../../../graphql/generated';
import {useUserAuth} from '../../../utils/user';

const continueOnbordingFrom = (merchant: MeFieldsFragment) => {
  if (!merchant.agreedToTerms) {
    return '/terms';
  } else if (!merchant.name) {
    return '/welcome';
  } else if (
    !(!!merchant?.applicantsName && !!merchant?.idNumber && !!merchant.phone)
  ) {
    return '/self-onboarding/business-details';
  } else if (!merchant.merchantType) {
    return '/self-onboarding/documents';
  } else if (!merchant.merchantCategories) {
    return '/self-onboarding/type-of-store';
  } else return null;
};

const ProtectedOnbordingRoute = () => {
  const {merchant} = useUserAuth();
  if (merchant && continueOnbordingFrom(merchant)) {
    return (
      <Navigate to={(merchant && continueOnbordingFrom(merchant)) || ''} />
    );
  }

  return <Outlet />;
};

export default ProtectedOnbordingRoute;
