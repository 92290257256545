import {useCallback, useEffect, useState} from 'react';

import {
  ChangeOrdersStatusInput,
  CursorInput,
  OrderBatchesDocument,
  OrderBatchesQuery,
  OrderBatchFieldsFragment,
  OrderFulfillment,
  OrderStatus,
  useChangeOrdersStatusMutation,
  useOrderBatchesQuery,
} from '../../../graphql/generated';

const useGetOrdersHook = (status: OrderStatus[]) => {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [cursor, setCursor] = useState<CursorInput>({
    first: 30,
  });

  const {
    data,
    loading: dataLoading,
    error,
    refetch,
  } = useOrderBatchesQuery({
    variables: {
      input: {
        cursor,
        status: status,
      },
    },
  });

  const [
    changeOrdersStatusMutation,
    {data: cgoData, loading: cgoLoading, error: cgoError},
  ] = useChangeOrdersStatusMutation();

  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after = data?.orderBatches.pageInfo.endCursor;
        cursorLoad.first = 30;
      } else {
        cursorLoad.before = data?.orderBatches.pageInfo.startCursor;
        cursorLoad.last = 30;
      }
      setCursor(cursorLoad);
      const res = await refetch({
        input: {cursor: cursorLoad, status},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      count,
      data?.orderBatches.pageInfo.endCursor,
      data?.orderBatches.pageInfo.startCursor,
      refetch,
      status,
    ]
  );

  const changeStatus = useCallback(
    async (
      cancel: boolean,
      order: OrderBatchFieldsFragment,
      cancelReason: string,
      callBack?: () => void,
      isSmallScreen?: boolean,
      snackFeedback?: (change: OrderStatus) => void,
      courierId?: string
    ) => {
      const input: ChangeOrdersStatusInput = {
        ids: [order?.id || ''],
        status: OrderStatus.InDelivery,
        courierId,
      };

      if (order?.fulfillment === OrderFulfillment.Pickup) {
        input.status = OrderStatus.Completed;
      }

      if (cancel) {
        input.reason = cancelReason;
        input.status = OrderStatus.Cancelled;
      }
      if (isSmallScreen) callBack?.();

      await changeOrdersStatusMutation({
        variables: {
          input,
        },
        update: (cache) => {
          const variables = {
            input: {cursor, status},
          };
          const cached = cache.readQuery<OrderBatchesQuery>({
            query: OrderBatchesDocument,
            variables: variables,
          });
          if (!cached) return;
          const newEdge =
            input.status === OrderStatus.InDelivery
              ? cached?.orderBatches.edges.map((item) => {
                  if (item.node.id === order?.id) {
                    return {
                      ...item,
                      node: {
                        ...item.node,
                        status: OrderStatus.InDelivery,
                      },
                    };
                  }
                  return item;
                })
              : cached?.orderBatches.edges.filter(
                  (item) => item.node.id !== order?.id
                );
          cache.writeQuery({
            query: OrderBatchesDocument,
            variables: variables,
            data: {
              ...cached,
              orderBatches: {
                ...cached?.orderBatches,
                edges: [...newEdge],
              },
            },
          });
          callBack?.();
          if (input.status !== OrderStatus.InDelivery) {
            snackFeedback?.(input.status);
          }
        },
      });
    },
    [changeOrdersStatusMutation, cursor, status]
  );

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  return {
    data,
    loading,
    error,
    loadMore,
    count,
    cgoData,
    cgoLoading,
    cgoError,
    changeStatus,
  };
};

export default useGetOrdersHook;
