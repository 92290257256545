import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as LockIcon} from '../../assets/images/pin_lock_icon.svg';
import WalletBackground from '../../assets/images/wallet_pin_background.svg';
import {ReactComponent as LogoSvg} from '../../assets/logos/cashia_logo.svg';
import LoadingModal from '../../components/common/LoadingModal';
import OTPInput from '../../components/common/OTPInput/Input';
import {useSetWalletPinMutation, VerificationId} from '../../graphql/generated';
import '../../components/common/OTPInput/OTPInput.css';
import {useUserAuth} from '../../utils/user';

function WalletPin() {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [firstPin, setFirstPin] = useState<string>('');
  const [finalPin, setFinalPin] = useState<string>('');
  const {merchant} = useUserAuth();
  const [setWalletPinMutation, {loading}] = useSetWalletPinMutation();

  const submit = useCallback(async () => {
    const res = await setWalletPinMutation({
      variables: {
        input: {
          idType: VerificationId.NationalId,
          idNumber: merchant?.idNumber || '',
          pin: finalPin,
        },
      },
    });
    if (!res.data?.setWalletPin) {
      setError('Failed to set wallet pin');
    } else {
      navigate('/store');
    }
  }, [finalPin, merchant?.idNumber, navigate, setWalletPinMutation]);

  useEffect(() => {
    if (firstPin !== finalPin && finalPin.length === 4) {
      setError('Sorry the PIN does not match. Try again');
    } else if (firstPin === finalPin && finalPin.length === 4) {
      void submit();
    }
  }, [firstPin, finalPin, submit, navigate]);

  return (
    <>
      <div
        className="flex flex-col h-screen lg:bg-center lg:bg-no-repeat bg-none lg:bg-cover"
        style={{
          backgroundImage: `url(${WalletBackground})`,
        }}>
        <div className="hidden lg:flex py-10 px-10">
          <LogoSvg />
        </div>
        <div className="flex flex-col items-center py-20 lg:py-0">
          <div className="hidden lg:flex">
            <LockIcon />
          </div>
          <h1 className="font-semibold text-4xl text-center lg:mt-10 mb-4">
            Set a pin for your wallet
          </h1>
          <p className="text-lazyGreen w-96 text-xs text-center px-8 mb-4">
            Please try not to forget this PIN. This PIN will be required for all
            transactions throughout all Cashia platforms.
          </p>
          <OTPInput
            autoFocus
            isNumberInput
            length={4}
            inputClassName="otpInput"
            onChangeOTP={(otp: string) => {
              setFirstPin(otp);
              setError('');
            }}
          />
          <h1 className="font-semibold text-2xl text-center mt-4 mb-4">
            Confirm your PIN
          </h1>
          <OTPInput
            autoFocus={firstPin.length === 4}
            isNumberInput
            length={4}
            inputClassName="otpInput"
            onChangeOTP={(otp: string) => {
              setFinalPin(otp);
              setError('');
            }}
          />
          {finalPin && error && (
            <div className="flex flex-col item-center gap-1">
              <p className="text-lazyRed pt-5">{error}</p>
              <a className="text-xs text-center text-cashiaBlue" href="/store">
                try again later!
              </a>
            </div>
          )}
        </div>
      </div>
      <LoadingModal open={loading} />
    </>
  );
}
export default WalletPin;
