import {Button, Typography} from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as Emoji} from './../../assets/images/emoji_face.svg';
import Background from './../../assets/images/shophome_background.svg';
import Div from '../../components/common/Div';
import MiniNavbar from '../../components/common/bottomNavbar';
import {useProductsQuery} from '../../graphql/generated';
import {colours} from '../../utils/constants';
import theme from '../../utils/theme/Theme';
import {useUserAuth} from '../../utils/user';

const styles = {
  main: {
    display: 'flex',
    minHeight: {xs: `calc(100% - 100px)`, lg: `calc(100% - 70px)`},
    overflowY: 'none',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    backgroundImage: {
      xs: 'none',
      sm: 'none',
      md: 'none',
      lg: `url(${Background})`,
      xl: `url(${Background})`,
    },
    '@media (max-width: 1024px)': {
      backgroundImage: 'none',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% auto',
    backgroundAttachment: 'fixed',
    backgroundPositionY: '100%',
  },
  emoji: {
    height: '40px',
    position: 'absolute',
    marginTop: '100px',
    backgroundImage: {
      xs: 'true',
      sm: 'true',
      md: 'true',
      lg: 'none',
      xl: 'none',
    },
    display: {xs: 'true', sm: 'true', md: 'true', lg: 'none', xl: 'none'},
  },
  bodyContent: {
    width: '100svw',
    height: 'inherit',
    overflowY: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: '1',
    color: theme.palette.success.main,
    marginTop: {md: '100px'},
  },
  bodyText: {
    display: 'flex',
    marginTop: {xs: '200px', sm: '200px', md: '200px', lg: '0px'},
    fontWeight: 600,
    letterSpacing: '-0.3',
    fontSize: {xs: '24px', md: '50px'},
    lindeHeight: '50px',
  },
  addItemText: {
    marginTop: '40px',
    fontWeight: 600,
    fontSize: '16px',
    color: colours.darkGrey,
    backgroundImage: {lg: 'none', xl: 'none'},
    display: {lg: 'none', xl: 'none'},
  },
  addItem2: {
    width: '206px',
    height: '48px',
    borderRadius: {xs: '10px', md: '10px', lg: '30px', xl: '30px'},
    mt: {xs: '60px', md: '60px', lg: '0px', xl: '0px'},
    display: 'inline-block',
    color: colours.white,
  },
  yourFirstItem: {
    fontFamily: 'Metropolis',
    textTransform: 'none',
    fontStyle: 'normal',
    weight: '400',
    fontSize: '16px',
    align: 'center',
    color: colours.white,
  },

  navB: {
    bottom: 10,
    display: {lg: 'none'},
  },
} as const;

const Dashboard = () => {
  const navigate = useNavigate();
  const {merchant} = useUserAuth();
  const {data: productsData} = useProductsQuery({
    variables: {
      merchantId: merchant?.id || '',
    },
  });

  const products = useMemo(
    () => productsData?.merchant?.products.items,
    [productsData]
  );

  useEffect(() => {
    if (products?.length) {
      navigate('/');
    } else {
      navigate('/store');
    }
  }, [navigate, products?.length]);

  return (
    <Div sx={styles.main} data-testid="app-home">
      <Div sx={styles.bodyContent}>
        <Div sx={styles.emoji}>
          <Emoji />
        </Div>
        <Typography sx={styles.bodyText} variant="h3" component="div">
          Your store is empty.
        </Typography>
        <Typography sx={styles.addItemText} variant="h6" component="div">
          Add an item
        </Typography>
        <Button
          variant="contained"
          sx={styles.addItem2}
          color="primary"
          disableElevation
          onClick={() => {
            navigate('/Store/addProduct');
          }}>
          <Div sx={styles.yourFirstItem}>Add your first item + </Div>
        </Button>
      </Div>
      <Div sx={styles.navB}>
        <MiniNavbar />
      </Div>
    </Div>
  );
};
export default Dashboard;
