import {Toaster, useMediaQuery} from '@cashiaApp/web-components';
import React from 'react';
import {Route, Routes} from 'react-router-dom';

import RequireAuthLayout from './AuthRequired';
import MainLayout from '../components/common/Layout';
import Profile from '../pages/Profile';
import ArchivedProducts from '../pages/Profile/Archived';
import DeliveryIssues from '../pages/Profile/DeliveryIssues';
import EditProfile from '../pages/Profile/EditProfile';
import Feedback from '../pages/Profile/Feedback';
import GetHelp from '../pages/Profile/GetHelp';
import ProfileLocation from '../pages/Profile/Location';
import NotificationSettings from '../pages/Profile/Notifications';
import ProfileOperatingHours from '../pages/Profile/OperatingHours';
import AddOrUpdateProductLayout from '../pages/addOrUpdateProduct/layout';
import Categories from '../pages/addOrUpdateProduct/pages/Categories';
import ProductImages from '../pages/addOrUpdateProduct/pages/Images';
import ItemInStock from '../pages/addOrUpdateProduct/pages/ItemInStock';
import Options from '../pages/addOrUpdateProduct/pages/Options';
import Price from '../pages/addOrUpdateProduct/pages/Price';
import ProductTitle from '../pages/addOrUpdateProduct/pages/ProductTitle';
import Shipping from '../pages/addOrUpdateProduct/pages/Shipping';
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/Signup';
import ManageCategories from '../pages/category/pages/ManageCategories';
import Dashboard from '../pages/dashboard/DashboardPage';
import NotFound from '../pages/errors/NotFound';
import Inbox from '../pages/inbox/Inbox';
import Delivered from '../pages/orders/Delivered';
import Failed from '../pages/orders/Failed';
import MyCouriers from '../pages/orders/MyCouriers';
import Orders from '../pages/orders/Orders';
import Requested from '../pages/orders/Requested';
import ReelsLayout from '../pages/reels/layout';
import UploadOrEditReelsLayout from '../pages/reels/layout/UploadOrEditReelsLayout';
import Reels from '../pages/reels/pages';
import ReelsAnalytics from '../pages/reels/pages/ReelsAnalytics';
import TagProducts from '../pages/reels/pages/TagProducts';
import UploadOrEditReel from '../pages/reels/pages/UploadOrEditReel';
import NewSelfOnBoardingLayout from '../pages/selfOnboarding/layout';
import ProtectedOnbordingRoute from '../pages/selfOnboarding/layout/ProtectedOnbordingRoute';
import AboutYourBusiness from '../pages/selfOnboarding/pages/AboutYourBusiness';
import NewBusinessDetails from '../pages/selfOnboarding/pages/BusinessDetails';
import NewDocuments from '../pages/selfOnboarding/pages/Documents';
import NewLocation from '../pages/selfOnboarding/pages/Location';
import SelectOperatingHours from '../pages/selfOnboarding/pages/SelectOperatingHours';
import TypeOfBusiness from '../pages/selfOnboarding/pages/TypeOfBusiness';
import NewTypeofStore from '../pages/selfOnboarding/pages/TypeOfStore';
import WelcomeOrThanks from '../pages/selfOnboarding/pages/WelcomeOrThanks';
import MobileViewStore from '../pages/store/MobileViewStore';
import Product from '../pages/store/Product';
import ProductPreview from '../pages/store/Product/ProductPreview';
import Store from '../pages/store/store';
import Terms from '../pages/terms/terms';
import Tracker from '../pages/tracker/trackerPage';
import WalletDashboard from '../pages/wallet';
import Banks from '../pages/wallet/Banks';
import Receipts from '../pages/wallet/Receipts';
import TrackUsage from '../pages/wallet/TrackUsage';
import Wallet from '../pages/wallet/Wallet';
import Activation from '../pages/walletActivation';
import Cashtag from '../pages/walletActivation/CashTag';
import WalletActivation from '../pages/walletActivation/WalletActivation';
import WalletPin from '../pages/walletActivation/WalletPin';
import WalletVerification from '../pages/walletActivation/WalletVerification';

const AppRoutes = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  return (
    <>
      <Toaster
        richColors
        toastOptions={{}}
        position={isDesktop ? 'bottom-left' : 'top-right'}
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/get-help" element={<GetHelp />} />
        <Route path="/delivery-issues" element={<DeliveryIssues />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/" element={<RequireAuthLayout />}>
          <Route path="self-onboarding" element={<NewSelfOnBoardingLayout />}>
            <Route index element={<AboutYourBusiness />} />
            <Route path="business-details" element={<NewBusinessDetails />} />
            <Route path="type-of-business" element={<TypeOfBusiness />} />
            <Route path="documents" element={<NewDocuments />} />
            <Route path="location" element={<NewLocation />} />
            <Route path="type-of-store" element={<NewTypeofStore />} />
            <Route
              path="select-operating-hours"
              element={<SelectOperatingHours />}
            />
          </Route>
          <Route path="welcome" element={<WelcomeOrThanks />} />
          <Route path="thanks" element={<WelcomeOrThanks thanks />} />
          <Route element={<ProtectedOnbordingRoute />}>
            <Route path="activation" element={<Activation />}>
              <Route index element={<WalletActivation />} />
              <Route path="verification" element={<WalletVerification />} />
              <Route path="pin" element={<WalletPin />} />
              <Route path="cashtag" element={<Cashtag />} />
            </Route>
            <Route path="*" element={<NotFound />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="store">
              <Route index element={<Store />} />
              <Route path="MobileViewStore" element={<MobileViewStore />} />
              <Route path="empty" element={<Dashboard />} />
              <Route path="manage-categories" element={<ManageCategories />} />
              <Route path="product/:productId">
                <Route index element={<Product />} />
                <Route path="preview" element={<ProductPreview />} />
              </Route>
              <Route path="add-product" element={<AddOrUpdateProductLayout />}>
                <Route index element={<ProductTitle />} />
                <Route path="images" element={<ProductImages />} />
                <Route path="select-category" element={<Categories />} />
                <Route path="price" element={<Price />} />
                <Route path="items-in-stock" element={<ItemInStock />} />
                <Route path="options" element={<Options />} />
                <Route path="shipping" element={<Shipping />} />
              </Route>
              <Route
                path="update-product/:id"
                element={<AddOrUpdateProductLayout isUpdating />}>
                <Route index element={<ProductTitle />} />
                <Route path="images" element={<ProductImages />} />
                <Route path="select-category" element={<Categories />} />
                <Route path="price" element={<Price />} />
                <Route path="items-in-stock" element={<ItemInStock />} />
                <Route path="options" element={<Options />} />
                <Route path="shipping" element={<Shipping />} />
              </Route>
            </Route>
            <Route path="/profile">
              <Route index element={<Profile />} />
              <Route path="edit" element={<EditProfile />} />
              <Route path="archived" element={<ArchivedProducts />} />
              <Route path="notifications" element={<NotificationSettings />} />
              <Route
                path="operating_hours"
                element={<ProfileOperatingHours />}
              />
              <Route path="archived_products" element={<ArchivedProducts />} />
              <Route path="locations" element={<ProfileLocation />} />
            </Route>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="/orders" element={<Orders />}>
                <Route index element={<Requested />} />
                <Route path="delivered" element={<Delivered />} />
                <Route path="failed" element={<Failed />} />
                <Route path="my-couriers" element={<MyCouriers />} />
              </Route>
              <Route path="/analytics" element={<NotFound />} />
              <Route path="/orders/tracker/:id" element={<Tracker />} />
            </Route>
            <Route path="/reels" element={<ReelsLayout />}>
              <Route index element={<Reels />} />
              <Route path="upload" element={<UploadOrEditReelsLayout />}>
                <Route index element={<UploadOrEditReel />} />
                <Route path="tag-products" element={<TagProducts />} />
              </Route>
              <Route
                path="edit/:id"
                element={<UploadOrEditReelsLayout isUpdating />}>
                <Route index element={<UploadOrEditReel />} />
                <Route path="tag-products" element={<TagProducts />} />
              </Route>
              <Route path="analytic/:id" element={<ReelsAnalytics />} />
            </Route>
            <Route path="/wallet" element={<Wallet />}>
              <Route index element={<WalletDashboard />} />
              <Route path="track-usage" element={<TrackUsage />} />
              <Route path="receipts" element={<Receipts />} />
              <Route path="banks" element={<Banks />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};
export default AppRoutes;
