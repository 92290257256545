import {useApolloClient} from '@apollo/client';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

import BlackLock from '../../../assets/icons/black-lock.svg';
import BlackTick from '../../../assets/icons/black-tick.svg';
import Navbar from '../../../components/common/Navbar';
import Button from '../../../components/tailwind/Button';
import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  AddOrUpdateProductMutation,
  ProductDocument,
  ProductInput,
  ProductQuery,
  ProductWithVariantsFieldsFragment,
  ProductsWithCursorPaginationDocument,
  ProductsWithCursorPaginationQuery,
  useAddOrUpdateProductMutation,
  useProductLazyQuery,
} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import {useUserAuth} from '../../../utils/user';

type AddOrUpdateProductContent = {
  step: number;
  productId?: string | null;
  totalSteps: number;
  isUpdating?: boolean;
  goToNextPage?: (to?: string) => void;
  productData?: ProductQuery;
  addProductToContext?: (productId: string, to?: string) => Promise<void>;
  updateLoading: boolean;
  productLoading: boolean;
  update?: (
    input: ProductInput,
    callBackFunction?: (
      updatedProduct?: ProductWithVariantsFieldsFragment
    ) => void,
    emptyCache?: boolean
  ) => Promise<void>;
  updateData?: AddOrUpdateProductMutation | null;
  removeProductFromContext?: (to: string) => void;
  addOrUpdateProductMenu: {
    title: string;
    link: string;
  }[];
};

type Stats = {
  productId?: string | null;
  step: number;
};

type Props = {
  isUpdating?: boolean;
};

const AddOrUpdateProductContentContext =
  createContext<AddOrUpdateProductContent>({
    step: 0,
    totalSteps: 0,
    addOrUpdateProductMenu: [],
    updateLoading: false,
    productLoading: false,
  });

export const useAddOrUpdateProductContentContext = () =>
  useContext(AddOrUpdateProductContentContext);

const AddOrUpdateProductLayout = ({isUpdating}: Props) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const {merchant} = useUserAuth();
  const {id} = useParams();
  const {addToast} = useToast();
  const [stats, setStats] = useState<Stats>({
    productId: sessionStorage.getItem('newProductId'),
    step: 0,
  });
  const {pathname} = useLocation();
  const isActive = useCallback((path: string) => pathname === path, [pathname]);
  const [getProduct, {data: productData, loading: productLoading}] =
    useProductLazyQuery();
  const [
    addOrUpdateProductMutation,
    {data: updateData, loading: updateLoading, error: updateMutationError},
  ] = useAddOrUpdateProductMutation();
  const slashId = `/${id || ''}`;
  const commonPath = `/store/${isUpdating ? 'update' : 'add'}-product${
    (isUpdating && slashId) || ''
  }`;
  const addOrUpdateProductMenu = useMemo(
    () => [
      {title: 'Product details', link: commonPath},
      {title: 'Images', link: `${commonPath}/images`},
      {title: 'Select category', link: `${commonPath}/select-category`},
      {title: 'Pricing', link: `${commonPath}/price`},
      // {title: 'Items in stock', link: `${commonPath}/items-in-stock`},
      {title: 'Options', link: `${commonPath}/options`},
      {title: 'Shipping', link: `${commonPath}/shipping`},
    ],
    [commonPath]
  );

  const goToNextPage = useCallback(
    (to?: string) =>
      navigate(to || addOrUpdateProductMenu[stats.step + 1]?.link || ''),
    [addOrUpdateProductMenu, navigate, stats.step]
  );

  const addProductToContext = useCallback(
    async (productId: string, to?: string) => {
      if (to) {
        goToNextPage(to);
        return;
      }
      const result = await getProduct({
        variables: {
          id: productId,
        },
      });
      if (result.data) {
        setStats((prev) => ({
          ...prev,
          productId: result?.data?.product?.id,
        }));
        sessionStorage.setItem('newProductId', result.data.product?.id || '');
        goToNextPage();
      }
    },
    [goToNextPage, getProduct]
  );

  const removeProductFromContext = (to: string) => {
    sessionStorage.removeItem('newProductId');
    client.writeQuery({
      query: ProductDocument,
      variables: {
        id: '',
      },
      data: {
        product: {},
      },
    });

    client.writeQuery({
      query: ProductDocument,
      variables: {
        id: stats.productId,
      },
      data: {
        product: {},
      },
    });
    goToNextPage(to);
    setStats({
      productId: null,
      step: 0,
    });
  };

  const update = useCallback(
    async (
      input: ProductInput,
      callBackFunction?: (
        updatedProduct?: ProductWithVariantsFieldsFragment
      ) => void,
      emptyCache?: boolean
    ) => {
      if (productData) {
        input.id = productData.product?.id;
      }
      const res = await addOrUpdateProductMutation({
        variables: {
          input,
        },
        update: (cache, result) => {
          const variables = {
            id: productData?.product?.id || '',
          };
          const cached = cache.readQuery<ProductQuery>({
            query: ProductDocument,
            variables,
          });
          const cachedEmptyId = cache.readQuery<ProductQuery>({
            query: ProductDocument,
            variables: {
              id: '',
            },
          });
          cache.writeQuery({
            query: ProductDocument,
            variables,
            data: {
              ...cached,
              product: emptyCache
                ? {}
                : {
                    ...result.data?.addOrUpdateProduct,
                  },
            },
          });
          cache.writeQuery({
            query: ProductDocument,
            variables: {
              id: '',
            },
            data: {
              ...cachedEmptyId,
              product: emptyCache
                ? {}
                : {
                    ...result.data?.addOrUpdateProduct,
                  },
            },
          });
          const productsWithCursorVaribles = {
            input: {
              cursor: {
                first: 10,
              },
              merchantId: merchant?.id || '',
            },
          };
          const cachedProductsWithCursor =
            cache.readQuery<ProductsWithCursorPaginationQuery>({
              query: ProductsWithCursorPaginationDocument,
              variables: productsWithCursorVaribles,
            });

          if (!result.data?.addOrUpdateProduct) return;
          if (!cachedProductsWithCursor?.productsWithCursorPagination) return;
          cache.writeQuery({
            query: ProductsWithCursorPaginationDocument,
            variables: productsWithCursorVaribles,
            data: {
              ...cachedProductsWithCursor,
              productsWithCursorPagination: {
                ...cachedProductsWithCursor.productsWithCursorPagination,
                edges: input.id
                  ? [
                      ...(cachedProductsWithCursor?.productsWithCursorPagination
                        .edges || []),
                    ].map((prod) =>
                      input.id === prod.node.id
                        ? {
                            ...prod,
                            node: {
                              ...result?.data?.addOrUpdateProduct,
                            },
                          }
                        : prod
                    )
                  : [
                      {
                        __typename: 'ProductEdge',
                        cursor: '',
                        node: {
                          ...result?.data?.addOrUpdateProduct,
                        },
                      },
                      ...(cachedProductsWithCursor?.productsWithCursorPagination
                        .edges || []),
                    ],
              },
            },
          });
        },
      });
      if (res.data?.addOrUpdateProduct) {
        callBackFunction
          ? callBackFunction(res.data.addOrUpdateProduct)
          : goToNextPage(
              isUpdating
                ? `/store/product/${productData?.product?.id || ''}`
                : undefined
            );
      }
    },
    [
      addOrUpdateProductMutation,
      goToNextPage,
      isUpdating,
      merchant?.id,
      productData,
    ]
  );

  const initialFetch = useCallback(async () => {
    await getProduct({
      variables: {
        id: (isUpdating ? id : stats.productId) || '',
      },
    });
  }, [getProduct, id, isUpdating, stats.productId]);

  useEffect(() => {
    if (isUpdating) sessionStorage.removeItem('newProductId');
    void initialFetch();
  }, [initialFetch, isUpdating]);

  useEffect(() => {
    setStats((prev) => ({
      ...prev,
      step: addOrUpdateProductMenu.map((menu) => menu.link).indexOf(pathname),
    }));
  }, [addOrUpdateProductMenu, pathname]);

  useEffect(() => {
    if (!updateMutationError?.message) return;
    addToast({
      icon: true,
      type: 'error',
      message: updateMutationError?.message,
      duration: 3000,
    });
  }, [addToast, updateMutationError?.message]);

  return (
    <div className="w-full h-full">
      <div className="sm:w-full lg:block hidden">
        <Navbar
          isAddingProduct
          removeProductFromContext={removeProductFromContext}
        />
      </div>
      <div
        className={cn(
          'w-full sm:flex justify-between items-center p-4 lg:hidden h-[64px] border-b-[0.3px] border-black',
          {'justify-center': isUpdating}
        )}>
        <p className="text-lg capitalize">
          {isUpdating ? productData?.product?.title : 'New Productss'}
        </p>
        {isUpdating || (
          <Button
            label="Save and exit"
            text
            labelStyle="text-cyan-500 text-[13px] font-normal"
            className="px-0"
            onClick={() => removeProductFromContext('/store')}
          />
        )}
      </div>
      <div className="flex h-[calc(100svh-64px)] w-full overflow-hidden bg-slate-500">
        <div className="lg:flex sm:hidden flex-col gap-10 w-96 bg-offWhite p-5 pt-10 duration-300 select-none">
          {isUpdating || (
            <Button
              label="Save and exit"
              text
              labelStyle="text-black text-[13px] font-normal"
              className="px-0"
              onClick={() => removeProductFromContext('/store')}
            />
          )}
          <div
            className={cn('text-neutral-800 text-2xl font-normal', {
              'mt-20': isUpdating,
            })}>
            {isUpdating ? productData?.product?.title : 'New Product'}
          </div>
          <ul className="flex w-full flex-col overflow-y-auto no-scrollbar">
            {addOrUpdateProductMenu.map((menu, index) => (
              <li
                key={index}
                className={`mt-2 flex items-center gap-x-4 justify-between rounded-md py-2 text-zinc-700 text-base font-normal font-['Metropolis'] leading-tight`}>
                <p className={`${isActive(menu.link) ? 'text-cyan-500' : ''}`}>
                  {menu.title}
                </p>
                {isActive(menu.link) ? (
                  <></>
                ) : isUpdating ? (
                  <></>
                ) : stats.step > index ? (
                  <img className="w-5 h-5" src={BlackTick} />
                ) : (
                  <img className="w-5 h-5" src={BlackLock} />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full bg-white">
          <AddOrUpdateProductContentContext.Provider
            value={{
              step: stats.step,
              totalSteps: addOrUpdateProductMenu.length,
              goToNextPage,
              productData,
              isUpdating,
              update,
              updateData,
              updateLoading,
              productLoading,
              addProductToContext,
              removeProductFromContext,
              addOrUpdateProductMenu,
              productId: stats.productId,
            }}>
            <Outlet />
          </AddOrUpdateProductContentContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default AddOrUpdateProductLayout;
