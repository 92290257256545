import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {Avatar, Divider, Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ReceipModal from './ReceiptModal';
import {displayStatus, statusColor, getMethodNameImage} from './helper';
import blueCards from '../../assets/icons/blueCards.svg';
import Div from '../../components/common/Div';
import {
  useRecentTransactionsQuery,
  TransactionsFieldsFragment,
} from '../../graphql/generated';
import {colours} from '../../utils/constants';
import formatDate from '../../utils/formatDate';
import formatMoney from '../../utils/formatMoney';
import theme from '../../utils/theme/Theme';
import {useUserAuth} from '../../utils/user';

const Receipts = () => {
  const navigate = useNavigate();
  const {data, loading, error} = useRecentTransactionsQuery();
  const [receipt, setReceipt] = useState<TransactionsFieldsFragment | null>();
  const [open, setOpen] = useState(false);

  const {merchant} = useUserAuth();
  const openModalInformation = (item: TransactionsFieldsFragment) => {
    setOpen(true);
    setReceipt(item);
  };
  const goToWalletHome = () => navigate('/wallet');

  return (
    <>
      <Div sx={styles.main}>
        <Div sx={styles.header}>
          <ArrowBackIcon sx={styles.disYes} onClick={goToWalletHome} />
          <Typography sx={styles.titleMain}>Receipts</Typography>
          <Avatar
            src={blueCards}
            sx={[styles.blueCard, styles.disYes]}
            onClick={goToWalletHome}
          />
        </Div>
        <Div sx={[styles.headerGap, styles.disYes]} />
        {loading ? (
          <Div sx={styles.loader}>
            <CircularProgress size={50} />
          </Div>
        ) : !data?.recentTransactions?.length ? (
          <Div sx={styles.empty}>
            <Div sx={styles.inEmpty}>
              <ReceiptIcon sx={{color: colours.cashiaBlue}} />
              <Typography sx={styles.emptext}>
                You have no receipts to view today.
              </Typography>
            </Div>
          </Div>
        ) : (
          <Div sx={styles.start}>
            {data?.recentTransactions?.map((item, i) => (
              <Div key={i}>
                <Div
                  sx={styles.mainReceipt}
                  key={i}
                  onClick={() => openModalInformation(item)}>
                  <Div sx={styles.firstSection}>
                    <Div sx={styles.avDiv}>
                      <Avatar
                        src={
                          getMethodNameImage(
                            item,
                            merchant?.id ? merchant?.id : ''
                          )?.logo
                        }
                        sx={styles.methodLogo}
                        variant="square"
                      />
                    </Div>
                    <Div sx={styles.rcpInfo}>
                      <Typography sx={styles.methodName}>
                        {
                          getMethodNameImage(
                            item,
                            merchant?.id ? merchant?.id : ''
                          )?.name
                        }
                      </Typography>
                      <Typography
                        sx={styles.small}
                        onClick={() => setOpen(true)}>
                        {formatDate(item.date as Date, {
                          withShortMonth: true,
                          withYear: true,
                          withTime: false,
                        })}
                      </Typography>
                    </Div>
                  </Div>
                  <Div sx={styles.secondSection}>
                    <Typography
                      sx={{...styles.small, color: statusColor(item.status)}}>
                      {displayStatus(item.status)}
                    </Typography>
                    <Typography sx={styles.amount}>
                      {item.amount.currencyCode} {formatMoney(item.amount, 2)}
                    </Typography>
                  </Div>
                </Div>
                <Divider />
              </Div>
            ))}
          </Div>
        )}
        {error && (
          <Div sx={styles.loader}>
            <Typography>{error.message}</Typography>
          </Div>
        )}
      </Div>
      <ReceipModal open={open} setOpen={setOpen} receipt={receipt && receipt} />
    </>
  );
};

const styles = {
  start: {
    margin: {md: '0px 60px 60px 60px', xs: '0px'},
    marginTop: {md: '30px', xs: '15px'},
    paddingTop: '0px',
    paddingX: {md: '0px', xs: '20px'},
  },
  main: {
    width: {md: '100%', xs: '100svw'},
    boxSizing: 'border-box',
    position: 'relative',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colours.lightBlue,
    borderRadius: '10px',
    width: '100%',
    paddingY: '40px',
    marginTop: '20px',
  },
  inEmpty: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptext: {
    textAlign: 'center',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '13px',
  },
  header: {
    display: 'flex',
    justifyContent: {md: 'flex-start', xs: 'space-between'},
    alignItems: 'center',
    paddingTop: {md: '50px', xs: '15px'},
    paddingX: {md: '50px', xs: '20px'},
    boxShadow: {md: 'none', xs: `0px 4px 4px ${colours.veryLightBlue}`},
    paddingBottom: {md: 0, xs: '10px'},
  },
  avDiv: {flex: 1},
  titleMain: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: {md: 600, xs: 400},
    fontSize: {md: '34px', xs: '18px'},
    lineHeight: {md: '34px', xs: '18px'},
  },
  blueCard: {marginBottom: '-7px', height: '45px', width: '45px'},
  disYes: {display: {md: 'none', xs: 'flex'}},
  headerGap: {
    height: '10px',
    width: 'inherit',
    background: colours.lightenedGrey,
    boxShadow: {md: 'none', xs: `0px 2px 2px ${colours.veryLightBlue}`},
  },
  bnkMain: {marginTop: {md: '25px', xs: '10px'}},
  mainReceipt: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: {md: '120px', xs: 0},
    width: '100%',
    paddingY: '20px',
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: '50px',
  },
  firstSection: {
    display: 'flex',
    gap: '13px',
    flex: {md: 1, xs: 3},
    justifyContent: 'space-between',
  },
  secondSection: {
    display: 'flex',
    flexDirection: {md: 'row', xs: 'column-reverse'},
    gap: {md: 0, xs: '10px'},
    flex: {md: 1, sx: 2},
    justifyContent: {md: 'space-between', xs: 'flex-end'},
    alignItems: {md: 'center', xs: 'flex-end'},
  },
  rcpInfo: {
    flex: 8,
    display: 'flex',
    flexDirection: {md: 'row', xs: 'column'},
    justifyContent: {md: 'space-between', xs: 'flex-start'},
    gap: {md: 0, xs: '8px'},
    alignItems: {md: 'center', xs: 'flex-start'},
  },
  method: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '34px',
  },
  methodLogo: {
    width: {md: '58px', xs: '40px'},
    height: {md: '58px', xs: '40px'},
    boxShadow: `0px 0px 10px -5px ${colours.sunSetBlue}`,
    borderRadius: '5px',
  },
  methodName: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: {md: '20px', xs: '16px'},
    lineHeight: {md: '20px', xs: '16px'},
  },
  small: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: {md: '16px', xs: '12px'},
    lineHeight: {md: '16px', xs: '12px'},
    color: colours.foggy,
    cursor: 'pointer',
  },
  amount: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: {md: '20px', xs: '15px'},
    lineHeight: {md: '20px', xs: '15px'},
    textAlign: 'right',
    color: colours.foggy,
    cursor: 'pointer',
  },
  cancelIcon: {
    position: 'absolute',
    top: 20,
    right: 25,
    color: 'white',
    cursor: 'pointer',
    fontSize: '30px',
  },
  tableRow: {
    width: '300px',
    flexWrap: 'wrap',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.secondary.dark,
  },
  modelInfo: {
    maxHeight: '244px',
    p: 4,
  },
  des: {
    paddingX: '30px',
  },
} as const;

export default Receipts;
