import {useEffect, useMemo, useState} from 'react';

import ImgPlaceHolder from '../../../assets/icons/blue-img-placeholder.svg';
import ImageModal from '../../../components/tailwind/ImageModal';
import TextArea from '../../../components/tailwind/TextArea';
import TextInput from '../../../components/tailwind/TextInput';
import {MerchantOnboardingInput} from '../../../graphql/generated';
import {updateStringStateWithLimit} from '../../../utils/reusableFunctions';
import Footer from '../components/Footer';
import {useSelfOnboardingContentContext} from '../layout';

type Image = {
  id?: string;
  smallUrl?: string;
  file?: File;
};

type AboutBussiness = {
  name?: string;
  description?: string;
  coverImage?: Image | null;
  logoImage?: Image | null;
};
const limits = {
  name: 40,
  description: 150,
};
const AboutYourBusiness = () => {
  const {merchant, update, updateLoading} = useSelfOnboardingContentContext();
  const [aboutBusiness, setAboutBusiness] = useState<AboutBussiness>();

  const merchantOnboardingInput = useMemo(() => {
    const input: MerchantOnboardingInput = {
      name: aboutBusiness?.name,
      description: aboutBusiness?.description,
      logo: aboutBusiness?.logoImage?.id,
      coverImage: aboutBusiness?.coverImage?.id,
    };
    return input;
  }, [aboutBusiness]);

  const updateMerchant = async () => await update?.(merchantOnboardingInput);

  useEffect(() => {
    setAboutBusiness({
      name: merchant?.name || '',
      description: merchant?.description || '',
      logoImage: {
        id: merchant?.logo?.id || '',
        smallUrl: merchant?.logo?.small || '',
      },
      coverImage: {
        id: merchant?.coverImage?.id || '',
        smallUrl: merchant?.coverImage?.small || '',
      },
    });
  }, [merchant]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="p-5 pt-8 lg:p-24 lg:pt-14 md:p-9 w-full flex-1 overflow-y-scroll no-scrollbar flex flex-col gap-6 md:gap-10">
        <TextInput
          value={aboutBusiness?.name || ''}
          label="What is the name of your business?"
          labelStyle="text-neutral-800 text-base md:text-xl font-semibold"
          description="Make it short, descriptive and exciting."
          descriptionStyle="text-zinc-700 text-sm md:text-base font-normal"
          endAdornment={
            <span className="text-cashiaBlue">
              {limits.name - (aboutBusiness?.name?.length || 0)}
            </span>
          }
          onChange={(e) =>
            updateStringStateWithLimit(e.target.value, limits.name, (name) =>
              setAboutBusiness({...aboutBusiness, name})
            )
          }
        />
        <TextArea
          value={aboutBusiness?.description || ''}
          label="Write a short description about your business (optional)"
          labelStyle="text-neutral-800 text-base md:text-xl font-semibold"
          description="What makes your store the best option for as a shop? Tell customers why your business will be the best choice 
for them. Don’t be afraid to brag!"
          descriptionStyle="text-zinc-700 text-sm md:text-base font-normal"
          onChange={(e) =>
            updateStringStateWithLimit(
              e.target.value,
              limits.description,
              (description) => setAboutBusiness({...aboutBusiness, description})
            )
          }
          helperText={
            aboutBusiness?.description ? (
              <span className="text-[13px] pt-2 font-medium text-darkGrey">{`${
                aboutBusiness?.description?.length || 0
              }/${limits.description}`}</span>
            ) : (
              <span className="text-red-700 pt-2 text-[13px] font-medium">
                {limits.description} characters needed
              </span>
            )
          }
        />
        <div className="flex flex-col gap-1">
          <p className="text-neutral-800 text-base md:text-xl font-semibold">
            Upload a profile photo (optional)
          </p>
          <p className="text-zinc-700 text-sm md:text-base font-normal leading-tight">
            A profile picture enables customers to identify your business
            easily. It creates trust between you and your clients.
          </p>
          <ImageModal
            omitFrontDeleteIcon
            title="Add a logo picture"
            thumbnailStyle="cursor-pointer"
            thumbnailImageStyle="rounded-full"
            description="A profile picture enables customers to identify your business
              easily. It creates trust between you and your clients."
            noPhotoImg={ImgPlaceHolder}
            imgUrl={aboutBusiness?.logoImage?.smallUrl}
            helperText="Update photo"
            imgId={aboutBusiness?.logoImage?.id}
            getUploadedImgId={(id: string, smallUrl?: string) =>
              setAboutBusiness({...aboutBusiness, logoImage: {id, smallUrl}})
            }
            removeImgId={() =>
              setAboutBusiness({...aboutBusiness, logoImage: null})
            }
          />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-neutral-800 text-base md:text-xl font-semibold">
            Add a cover photo (optional)
          </p>
          <p className="text-zinc-700 text-sm md:text-base font-normal leading-tight">
            A cover image can say a lot about your brand, and make an impression
            on page visitors.
          </p>
          <ImageModal
            omitFrontDeleteIcon
            title="Add a cover picture (optional)"
            thumbnailStyle="cursor-pointer"
            description="A cover image can say a lot about your brand, and make an impression
            on page visitors."
            imgUrl={aboutBusiness?.coverImage?.smallUrl}
            imgId={aboutBusiness?.coverImage?.id}
            getUploadedImgId={(id: string, smallUrl?: string) =>
              setAboutBusiness({...aboutBusiness, coverImage: {id, smallUrl}})
            }
            removeImgId={() =>
              setAboutBusiness({...aboutBusiness, coverImage: null})
            }
          />
        </div>
      </div>
      <Footer
        className="flex-5"
        update={updateMerchant}
        updateLoading={updateLoading}
        disabled={!aboutBusiness?.name}
      />
    </div>
  );
};

export default AboutYourBusiness;
