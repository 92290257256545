import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  split,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import {createLink} from 'apollo-absinthe-upload-link';
import {createClient} from 'graphql-ws';
import React, {PropsWithChildren, useMemo} from 'react';

import typePolicies from './apollo/typePolicies';
import {useAuth, promiseToObservable} from './auth';
import {localStorageAccessTokenKey, apiUrl, apiUrlSocket} from './constants';
import resolvers from './mocks/resolvers';
import typeDefs from './mocks/typeDefs';
import {ErrorTypes} from '../graphql/generated';
import result from '../graphql/introspection-result';

interface Props {
  terminatingLink?: ApolloLink;
}

const httpLink = createLink({uri: apiUrl});

const GraphQLProvider = ({
  children,
  terminatingLink = httpLink,
}: PropsWithChildren<Props>) => {
  const {getNewTokens, logout} = useAuth();

  const authMiddleware = setContext(() => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    return {
      headers: {
        ...(token ? {authorization: `Bearer ${token}`} : {}),
      },
    };
  });

  const errorLink = onError(({graphQLErrors, operation, forward}) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const errorType = err.extensions?.['type'] as ErrorTypes;
        switch (errorType) {
          case ErrorTypes.TokenExpired:
            return promiseToObservable(getNewTokens()).flatMap(() =>
              forward(operation)
            );
          case ErrorTypes.TokenInvalid:
            void logout();
            break;
        }
      }
    }
  });

  const wsLink = useMemo(() => {
    const token = localStorage.getItem(localStorageAccessTokenKey) || '';
    return new GraphQLWsLink(
      createClient({
        url: apiUrlSocket,
        connectionParams: () => ({Authorization: `Bearer ${token}`}),
        on: {
          connecting: () => console.warn('connecting'),
          connected: () => console.warn('connected'),
          error: (err) => console.error(err),
        },
      })
    );
  }, []);

  const splitLink = useMemo(
    () =>
      split(
        ({query}) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        terminatingLink
      ),
    [terminatingLink, wsLink]
  );

  const client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: result.possibleTypes,
      typePolicies,
    }),
    link: errorLink.concat(authMiddleware).concat(splitLink),
    resolvers,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
