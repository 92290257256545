import React, {PropsWithChildren, useEffect} from 'react';

import {cn} from '../../utils/reusableFunctions';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  className?: string;
  containerStyle?: string;
};

const Modal = ({
  isVisible,
  onClose,
  className,
  containerStyle,
  children,
}: PropsWithChildren<Props>) => {
  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.currentTarget.id === 'wrapper') onClose();
  };

  const handleModal = (event: React.MouseEvent<HTMLDivElement>) =>
    event.stopPropagation();

  useEffect(() => {
    const body = document.querySelector('body');
    isVisible
      ? body?.classList.add('overflow-hidden')
      : body?.classList.remove('overflow-hidden');
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className={cn(
        'fixed inset-0 z-50 flex justify-center items-center transition-colors invisible',
        {'visible bg-black/60': isVisible},
        containerStyle
      )}
      id="wrapper"
      onClick={handleClose}>
      <div
        className={cn(
          'bg-white shadow transition-all h-inherit rounded-2xl w-[90%] md:w-[600px] scale-120 opacity-0',
          {'scale-100 opacity-100': isVisible},
          className
        )}
        id="modal"
        onClick={handleModal}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
