import {cn} from '../../utils/reusableFunctions';

type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  containerStyle?: string;
  description?: string;
  descriptionStyle?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  error?: boolean;
  id?: string;
  inputStyle?: string;
  label?: string;
  labelStyle?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  type?: string;
  value?: string;
  rows?: number;
  maxRows?: number;
  minRows?: number;
  startAdornment?: React.ReactNode;
  helperText?: React.ReactNode;
};

const TextArea = ({
  containerStyle,
  description,
  descriptionStyle,
  endAdornment,
  helperText,
  id,
  inputStyle,
  label,
  labelStyle,
  placeholder,
  startAdornment,
  rows = 4,
  ...other
}: Props) => {
  return (
    <div className={cn('flex flex-col', containerStyle)}>
      <h3 className={cn('text-xl font-semibold leading-5', labelStyle)}>
        {label}
      </h3>
      {description && (
        <p className={cn('pt-2.5 leading-5 text-darkGrey', descriptionStyle)}>
          {description}
        </p>
      )}
      <div
        className={cn(
          'peer flex basis-full flex-row items-center rounded-lg border-half border-greyish bg-offWhite px-2.5 py-2 focus-within:!bg-white focus-within:ring-1 focus-within:ring-cashiaBlue',
          {'mt-2.5': description || label}
        )}>
        {startAdornment}
        <textarea
          className={cn(
            'peer flex basis-full appearance-none  bg-offWhite px-2.5 py-2 text-sm font-medium text-black placeholder-foggy autofill:!bg-offWhite focus:!bg-white focus:outline-none',
            inputStyle
          )}
          placeholder={placeholder}
          rows={rows}
          {...other}
        />
        {endAdornment}
      </div>
      {helperText}
    </div>
  );
};

export default TextArea;
