interface OptionProps {
  index: number;
  selectedIndex?: number;
  onSelect: (index: number) => void;
  children: React.ReactNode;
}
const Option = ({index, selectedIndex, onSelect, children}: OptionProps) => {
  const isSelected = index === selectedIndex;
  return (
    <div
      className={`flex items-center gap-2 shadow cursor-pointer transition duration-300 mx-1 rounded-md p-2 py-3  flex-1 text-xs font-bold text-slate-600 lg:font-normal lg:text-sm hover:shadow-md ${
        isSelected ? 'bg-sky-50' : ''
      }`}
      onClick={() => onSelect(index)}>
      <div
        className={`rounded-full w-4 h-4 border transition ${
          isSelected ? 'border-4 border-sky-400 bg-white' : ''
        } `}
      />
      {children}
    </div>
  );
};

export default Option;
