import React, {useEffect, useRef, useState} from 'react';

import {SelectOption} from '../../utils/types';

type SelectProps = {
  options: SelectOption[] | undefined;
  placeholder: string;
  dropdownBottomOffset?: number;
  onChange?: (option: SelectOption | null) => void;
};

const Select = ({
  options,
  placeholder,
  dropdownBottomOffset = 0,
  onChange,
}: SelectProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase()?.includes(filterTerm?.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const availableSpace = window.innerHeight - dropdownRect.top;
      dropdownRef.current.style.maxHeight = `${
        availableSpace - dropdownBottomOffset
      }px`;
    }
  }, [dropdownBottomOffset, isOpen]);

  const handleOptionClick = (option: SelectOption) => {
    if (selectedOption?.value === option.value) {
      setSelectedOption(null);
      onChange?.(null);
      setSearchTerm('');
    } else {
      setSelectedOption(option);
      onChange?.(option);
      setSearchTerm(option.label);
    }
    setIsOpen(false);
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setFilterTerm(e.target.value);
    setIsOpen(true);
  };

  return (
    <div className="relative group w-full mx-2">
      <div className="relative">
        <input
          ref={inputRef}
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500 pointer-events-none ${
            isOpen ? 'rotate-180' : ''
          }`}
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fillRule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="select-scrollbar-visible absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1 w-full overflow-auto">
          {filteredOptions?.map((option) => (
            <a
              key={option?.value}
              href="#"
              onClick={() => handleOptionClick(option)}
              className={`block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md ${
                selectedOption?.value === option?.value ? 'bg-blue-100' : ''
              }`}>
              {option?.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
