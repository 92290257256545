import Individual from '../../../assets/images/icons/individual-business.svg';
import LimitedCompany from '../../../assets/images/icons/limitedcompany.svg';
import Partnership from '../../../assets/images/icons/partnership.svg';
import SoleProprietor from '../../../assets/images/icons/sole-proprietor.svg';
import Checkbox from '../../../components/tailwind/Checkbox';
import {MerchantType} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import Footer from '../components/Footer';
import {useSelfOnboardingContentContext} from '../layout';

const cardContent = (status?: MerchantType) => {
  if (!status) return {};
  return {
    INDIVIDUAL: {
      name: 'Individual',
      icon: Individual,
      description: `An individual business is a business that is not 
      incorporated. This includes independent 
      contractors, consultants, and freelancers. 
      Sometimes personal businesses are referred to 
      as "solopreneurs."`,
    },
    SOLE_PROPRIETOR: {
      name: 'Sole propreitor',
      icon: SoleProprietor,
      description: `This is enterprise that is owned exclusively by 
      one person and in which there is no legal 
      distinction between the owner and the 
      business entity.`,
    },
    LIMITED_COMPANY: {
      name: 'Limited Company',
      icon: LimitedCompany,
      description: `This is a company that has a legal identity of 
      its own, separate from its owners (shareholders) 
      and its managers (directors). Even where a 
      company has only one individual 
      shareholder/director, the same principles apply.`,
    },
    PARTNERSHIP: {
      name: 'Partnership',
      icon: Partnership,
      description: `This is a business where two or more people 
      share ownership, as well as the responsibility 
      for managing the company and the income or 
      losses the business generates.`,
    },
  }[status];
};
const orderedMerchantType: MerchantType[] = [
  MerchantType.Individual,
  MerchantType.SoleProprietor,
  MerchantType.LimitedCompany,
  MerchantType.Partnership,
];
const TypeOfBusiness = () => {
  const {merchantType, setMerchantType} = useSelfOnboardingContentContext();

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:py-14 md:p-9 sm:p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-5 h-full sm:pt-0 lg:pt-14">
          <h1 className="text-neutral-800 text-xl md:text-[34px] font-semibold">
            What type of business are you setting?
          </h1>
          <p className="text-zinc-700 text-base font-normal leading-tight">
            Select the type of business that your company is registered as.
          </p>
          <div className="flex gap-5 flex-wrap pb-8">
            {orderedMerchantType.map((item) => (
              <div
                key={item}
                onClick={() => setMerchantType?.(item)}
                className={cn(
                  'sm:w-full lg:w-[435px] sm:h-fit lg:h-[225px] flex flex-col gap-5 p-4 cursor-pointer transition-all rounded-2xl border-2 border-transparent shadow',
                  {
                    'border-2 border-smoothRed': merchantType === item,
                  }
                )}>
                <div className="flex justify-between items-center">
                  <img src={cardContent(item).icon} />
                  <Checkbox
                    accentSmoothRed
                    checked={merchantType === item}
                    onChange={() => setMerchantType?.(item)}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h5 className="text-neutral-800 text-lg md:text-xl font-semibold">
                    {cardContent(item).name}
                  </h5>
                  <p className="text-zinc-700 text-sm md:text-base font-normal leading-tight">
                    {cardContent(item).description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer className="flex-5" />
    </div>
  );
};

export default TypeOfBusiness;
