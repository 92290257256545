import {CircularProgress} from '@mui/material';

import Div from '../../../components/common/Div';

const Loading = () => {
  return (
    <Div sx={styles.main}>
      <CircularProgress color="primary" size={40} />
    </Div>
  );
};

const styles = {
  main: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '150px',
  },
};

export default Loading;
