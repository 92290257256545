import {useEffect, useRef, useState} from 'react';

import {googleMapsKey} from './constants';

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const useAutocomplete: () => google.maps.places.AutocompleteService | null =
  () => {
    const [autocomplete, setAutocomplete] =
      useState<google.maps.places.AutocompleteService | null>(null);
    const loaded = useRef(false);

    useEffect(() => {
      if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps') && googleMapsKey) {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places`,
            document.querySelector('head'),
            'google-maps'
          );
        }

        loaded.current = true;
      }
    }, []);

    useEffect(() => {
      if (!autocomplete && window.google) {
        setAutocomplete(new window.google.maps.places.AutocompleteService());
      }
      // we know what we're doing here
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocomplete, window.google]);

    return autocomplete;
  };

export default useAutocomplete;
