import creditCard from '../../assets/images/creditCard.svg';
import mpesaLogo from '../../assets/images/mpesaLogo.svg';
import redCashiaLogo from '../../assets/images/redCashiaLogo.svg';
import {
  TransactionsFieldsFragment,
  TransactionStatus,
} from '../../graphql/generated';
import {isNotAdmin} from '../../utils/apollo/helpers';

export const displayStatus = (status: TransactionStatus): string => {
  return {
    SUCCESS: 'Successful',
    PENDING: 'Pending',
    FAILED: 'Failed',
  }[status];
};

export const statusColor = (status: TransactionStatus): string => {
  return {
    SUCCESS: '#21AA47',
    PENDING: '#FFBA49',
    FAILED: '#FF0000',
  }[status];
};

export const checkTypename = (
  transaction: TransactionsFieldsFragment
): string => {
  const typeName = transaction.__typename && transaction.__typename;
  const value = {
    OrderBatchTransaction: 'You have received an order purchase',
    BillTransaction: 'This is a bill transaction',
    WithdrawTransaction: 'You have transfered funds from your Cashia Wallet.',
    RefundTransaction: 'You have received a refund order parchase',
    TransferTransaction: 'You have transfered funds from your Cashia Wallet.',
    LoadCreditTransaction: 'You have topped-up your Cashia Wallet',
  };
  return typeName ? value[typeName] : 'This is a transaction';
};

export const getMethodNameImage = (
  transaction: TransactionsFieldsFragment,
  id: string
) => {
  switch (transaction?.__typename) {
    case 'OrderBatchTransaction' || 'RefundTransaction':
      return {name: 'Cashia Wallet', logo: redCashiaLogo};
    case 'TransferTransaction':
      return {
        name:
          transaction.issuer.id === id
            ? `@${transaction?.receiver?.user?.name || ''}`
            : `@${transaction?.issuer?.user?.name || ''}`,
        logo: redCashiaLogo,
      };
    case 'WithdrawTransaction' || 'LoadCreditTransaction':
      return transaction.paymentMethod.__typename === 'CreditCardPaymentMethod'
        ? {
            name: `${transaction.paymentMethod.type} ${transaction.paymentMethod.last4Digits}`,
            logo: creditCard,
          }
        : transaction.paymentMethod.__typename === 'PhoneNumberPaymentMethod'
        ? {
            name: 'M-pesa',
            logo: mpesaLogo,
          }
        : transaction.paymentMethod.__typename ===
          'InterswitchBankAccountPaymentMethod'
        ? {
            name: transaction.paymentMethod.interswitchBank?.name,
            logo:
              transaction.paymentMethod.interswitchBank?.logo?.small ||
              creditCard,
          }
        : {
            name: 'Cashia Wallet',
            logo: redCashiaLogo,
          };
    default:
      return {
        name: undefined,
        logo: undefined,
      };
  }
};

export const getPayementInfo = (
  transaction: TransactionsFieldsFragment,
  id: string
) => {
  switch (transaction?.__typename) {
    case 'OrderBatchTransaction' || 'RefundTransaction':
      return {
        type: 'Order Number:',
        info: transaction.orderBatch.referenceNumber,
      };
    case 'TransferTransaction':
      return {
        type: 'Cashia tag',
        info:
          transaction?.issuer?.id === id
            ? isNotAdmin(transaction?.receiver) &&
              `@${transaction.receiver.tag || ''}`
            : isNotAdmin(transaction?.issuer) &&
              `@${transaction.issuer.tag || ''}`,
      };
    case 'WithdrawTransaction' || 'LoadCreditTransaction':
      return transaction.paymentMethod.__typename === 'CreditCardPaymentMethod'
        ? {
            type: transaction.paymentMethod.type,
            info: transaction.paymentMethod.last4Digits,
          }
        : transaction.paymentMethod.__typename === 'PhoneNumberPaymentMethod'
        ? {
            type: 'Mpesa',
            info: transaction.paymentMethod.phone,
          }
        : transaction.paymentMethod.__typename ===
          'InterswitchBankAccountPaymentMethod'
        ? {
            type: transaction.paymentMethod.name,
            info: transaction.paymentMethod.interswitchBank?.name,
          }
        : {
            type: 'To',
            info: 'Cashia Wallet',
          };
  }
};

export const greet = (): string => {
  const curHr = new Date().getHours();
  if (curHr >= 0 && curHr < 12) {
    return 'Good Morning';
  } else if (curHr >= 12 && curHr < 17) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
};
