import {Outlet} from 'react-router-dom';

import Navbar from '../../../components/common/Navbar';

const ReelsLayout = () => {
  return (
    <div className="w-full h-[100svh] no-scrollbar">
      <div className="w-full">
        <Navbar />
      </div>
      <div className="flex h-[calc(100svh-64px)] w-full overflow-hidden no-scrollbar">
        <Outlet />
      </div>
    </div>
  );
};

export default ReelsLayout;
