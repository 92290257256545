import {ApolloError} from '@apollo/client';
import {Autocomplete, AutocompleteRenderInputParams} from '@mui/material';
import {captureException} from '@sentry/react';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {HTMLAttributes, useCallback, useEffect, useMemo, useState} from 'react';
import Geocode from 'react-geocode';

import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete_icon.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location_icon.svg';
import {ReactComponent as MapIcon} from '../../../assets/icons/map_icon.svg';
import TextInput from '../../../components/common/TextInput';
import Spinner from '../../../components/tailwind/Spinner';
import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  useAddOrUpdateAddressesMutation,
  useRemoveAddressesMutation,
} from '../../../graphql/generated';
import '../../../index.css';
import {isMerchant} from '../../../utils/apollo/helpers';
import useAutocomplete from '../../../utils/autocomplete';
import {colours, googleMapsKey} from '../../../utils/constants';
import {translateError} from '../../../utils/graphql';
import {useUserAuth} from '../../../utils/user';
import Footer from '../components/Footer';

interface MatchType {
  offset: number;
  length: number;
}
type GeocodeResponse = {
  results?: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
  }[];
};
type PlaceType = google.maps.places.AutocompletePrediction;

const styles = {
  autoComplete: {marginBottom: '10px'},
  input: {
    border: 'none',
    height: '48px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      backgroundColor: colours.lightenedGrey,
      padding: '0px',
      paddingLeft: '48px',
      height: '48px',
      '&:focus': {
        borderRadius: '10px 10px 0px 0px',
      },
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
    },
  },
};
interface AddressInput {
  location: string;
  coordinates: {
    lat: number;
    long: number;
  };
}
const Location = () => {
  const {merchant} = useUserAuth();
  const {addToast} = useToast();
  const [options, setOptions] = useState<PlaceType[]>([]);
  const [value, setValue] = useState<PlaceType | null>(null);
  const [input, setInput] = useState('');
  const [removingAddressId, setRemovingAddressId] = useState('');
  const autoCompleteService = useAutocomplete();
  const [locationsInput, setLocationsInput] = useState<AddressInput[]>([]);
  const [removeAddress, {error: removeAddressError}] =
    useRemoveAddressesMutation();

  const locations = useMemo(() => merchant?.addresses, [merchant?.addresses]);

  const [AddOrUpdateAddressesMutation, {loading: addOrUpdateAddressesLoading}] =
    useAddOrUpdateAddressesMutation({
      update: (cache, res) => {
        const cached = cache.readQuery<MeQuery>({
          query: MeDocument,
        });
        if (!isMerchant(cached?.me) || !cached?.me) return;
        const addresses = [
          ...(cached.me.addresses || []),
          ...(res.data?.addOrUpdateAddresses || []),
        ];
        cache.writeQuery<MeQuery, MeQueryVariables>({
          query: MeDocument,
          data: {
            me: {
              ...cached?.me,
              addresses: addresses,
            },
          },
        });
      },
    });

  const handleSubmit = useCallback(
    async (newLocations: AddressInput[]) => {
      for (const newLocation of newLocations) {
        const existingLocation = locations?.find(
          (location) =>
            location.location.coordinates.lat === newLocation.coordinates.lat &&
            location.location.coordinates.long === newLocation.coordinates.long
        );
        if (existingLocation) {
          addToast({
            icon: true,
            type: 'warning',
            message: 'This location has already been added.',
            duration: 3000,
          });
          return;
        }
      }
      await AddOrUpdateAddressesMutation({
        variables: {
          input: newLocations,
        },
      });
    },
    [AddOrUpdateAddressesMutation, addToast, locations]
  );

  const onAddressChange = useCallback(
    async (
      _: React.SyntheticEvent<Element, Event>,
      newValue: google.maps.places.AutocompletePrediction | null
    ) => {
      if (!newValue) return;
      setValue(newValue);

      try {
        const resp = await getLatLong(newValue.description);
        const lat = resp?.results?.[0]?.geometry.location.lat;
        const long = resp?.results?.[0]?.geometry.location.lng;
        if (!lat || !long) throw 'No coordinates received';
        const updatedLocationsInput = [
          ...locationsInput,
          {location: newValue.description, coordinates: {lat, long}},
        ];
        setLocationsInput(updatedLocationsInput);
        await handleSubmit(updatedLocationsInput);
        setLocationsInput([]);
        setValue(null);
      } catch (e) {
        if (e instanceof ApolloError) {
          addToast({
            icon: true,
            type: 'error',
            message: new Error(translateError(e)).message,
            duration: 3000,
          });
          return;
        }
        captureException(e);
        addToast({
          icon: true,
          type: 'error',
          message: 'Error ocuured',
          duration: 3000,
        });
      }
    },
    [addToast, handleSubmit, locationsInput]
  );

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: {input: string},
          callback: (results: PlaceType[] | null) => void
        ) => {
          if (!autoCompleteService) return;
          void autoCompleteService.getPlacePredictions(request, callback);
        },
        2000
      ),
    [autoCompleteService]
  );
  const getLatLong = (address: string) =>
    Geocode.fromAddress(address, googleMapsKey) as Promise<GeocodeResponse>;

  const handleDelete = useCallback(
    async (id: string) => {
      setRemovingAddressId(id);
      const result = await removeAddress({
        variables: {
          ids: id,
        },
        update: (cache, res) => {
          const cached = cache.readQuery<MeQuery>({
            query: MeDocument,
          });
          if (
            !res.data?.removeAddresses ||
            !isMerchant(cached?.me) ||
            !cached?.me
          ) {
            return;
          }
          cache.writeQuery<MeQuery, MeQueryVariables>({
            query: MeDocument,
            data: {
              me: {
                ...cached?.me,
                addresses: cached?.me?.addresses?.filter(
                  (item) => item.id !== id
                ),
              },
            },
          });
        },
      });
      setRemovingAddressId('');
      if (result.errors) {
        addToast({
          icon: true,
          type: 'error',
          message: removeAddressError?.message,
          duration: 3000,
        });
      }
    },
    [addToast, removeAddress, removeAddressError?.message]
  );

  useEffect(() => {
    const active = true;
    if (input === '') {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({input: input}, (results: PlaceType[] | null) => {
      if (!active) return;
      let newOptions: PlaceType[] = [];

      if (value) {
        newOptions = [value];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }
      setOptions(newOptions);
    });
  }, [fetch, input, value]);

  const renderInput = useCallback(
    ({inputProps, ...params}: AutocompleteRenderInputParams) => {
      const {valueInput, ...inputPropsWithoutValue} = inputProps as unknown as {
        valueInput: unknown;
      };
      return (
        <div className="flex bg-offWhite rounded-[10px] items-center relative h-12">
          <LocationIcon className="absolute top-[13px] left-5 z-10" />
          <TextInput
            name="addressInput.location"
            {...params}
            label={null}
            variant="outlined"
            data-cy="address"
            inputProps={{
              ...inputPropsWithoutValue,
              id: `data-cy-address-input`,
            }}
            value={input || ''}
            fullWidth
            sx={styles.input}
          />
          {addOrUpdateAddressesLoading && (
            <Spinner className="absolute top-[13px] right-5 z-10 w-5" />
          )}
        </div>
      );
    },
    [addOrUpdateAddressesLoading, input]
  );

  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    option: google.maps.places.AutocompletePrediction
  ) => {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match: MatchType) => [
        match.offset,
        match.offset + match.length,
      ])
    );

    return (
      <li {...props}>
        <div className="flex m-[10px]">
          <MapIcon className="mt-[5px] mr-[10px]" />
        </div>
        <div>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}>
              {part.text}
            </span>
          ))}
          <p className="opacity-70 text-zinc-700 text-[13px] font-normal">
            {option.structured_formatting.secondary_text}
          </p>
        </div>
      </li>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col h-full pt-0 lg:pt-14">
          {locations?.length ? (
            <>
              <div className="flex flex-col gap-2 mb-5">
                <h1 className="text-neutral-800 text-xl font-semibold">
                  Your business locations
                </h1>
                <p className="text-zinc-700 text-base font-normal leading-tight">
                  Where is your business located? You can add a maximum of 20
                  locations.
                </p>
              </div>
              <div className="flex flex-col gap-3">
                {locations?.map((item, i) => (
                  <div
                    key={i}
                    className="w-full flex justify-between items-center bg-white rounded-[10px] p-6 py-5 border border-neutral-800">
                    <div>
                      <p className="text-neutral-800 text-base font-normal truncate">
                        {item.location.formattedAddress.split(',')[0]}
                      </p>
                      <p className="opacity-70 text-zinc-700 text-[13px] font-normal truncate">
                        {item.location.formattedAddress.split(',').slice(1)}
                      </p>
                    </div>
                    {removingAddressId === item.id ? (
                      <Spinner className="w-5" />
                    ) : (
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => handleDelete(item.id)}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="w-full h-px bg-neutral-200 my-6" />
            </>
          ) : (
            <></>
          )}
          <div className="flex flex-col gap-2 pb-8">
            <div className="text-neutral-800 text-xl font-semibold">
              Add {locations?.length ? 'another' : 'your business'} address
            </div>
            <Autocomplete
              getOptionLabel={(option) => option.description}
              options={options}
              filterOptions={(x) => x}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={onAddressChange}
              onInputChange={(_, newInputValue) => setInput(newInputValue)}
              renderInput={renderInput}
              sx={styles.autoComplete}
              renderOption={renderOptions}
            />
          </div>
        </div>
      </div>
      <Footer className="flex-5" disabled={!locations?.length} />
    </div>
  );
};

export default Location;
