import {Avatar, Typography} from '@mui/material';

import Div from '../../../components/common/Div';
import {
  ActorFieldsFragment,
  MessageEdgeFieldsFragment,
} from '../../../graphql/generated';
import {colours} from '../../../utils/constants';
import formatDate from '../../../utils/formatDate';
import {useUserAuth} from '../../../utils/user';

type Props = {
  msg: MessageEdgeFieldsFragment;
};

const Message = ({msg}: Props) => {
  const {merchant} = useUserAuth();

  const memberImg = (member: ActorFieldsFragment): string => {
    switch (member?.__typename) {
      case 'Customer':
        return member.avatar?.small || '';
      case 'Merchant':
        return member.logo?.small || '';
      default:
        return '';
    }
  };

  return (
    <>
      {merchant?.user?.id === msg.node.author.user?.id ? (
        <Div key={msg.node.id} sx={[styles.singleChat, styles.floatRight]}>
          <Div sx={styles.chatBody}>
            <Div sx={styles.commonFlex}>
              <Typography sx={styles.dateText}>
                {formatDate(msg.node.date as Date, {
                  withTime: true,
                  hour12: true,
                  withDate: false,
                  withMonth: false,
                })}
              </Typography>
              <Typography sx={[styles.chatText, styles.myText]}>
                {msg.node.text}
              </Typography>
            </Div>
            <Avatar sx={styles.avatarImg} src={merchant?.logo?.small || ''} />
          </Div>
        </Div>
      ) : (
        <Div key={msg.node.id} sx={[styles.singleChat]}>
          <Div sx={styles.chatBody}>
            <Avatar sx={styles.avatarImg} src={memberImg(msg.node.author)} />
            <Div sx={styles.commonFlex}>
              <Typography sx={styles.dateText}>
                {formatDate(msg.node.date as Date, {
                  withTime: true,
                  hour12: true,
                  withDate: false,
                  withMonth: false,
                })}
              </Typography>
              <Typography sx={styles.chatText}>{msg.node.text}</Typography>
            </Div>
          </Div>
        </Div>
      )}
    </>
  );
};

const styles = {
  floatRight: {
    float: 'right',
    alignItems: 'flex-end',
    paddingTop: '15px',
  },
  commonFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {width: '100%', scrollBehavior: 'smooth'},
  singleChat: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '20px',
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  chatText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    color: colours.black,
    background: colours.naviBlue,
    paddingY: '10px',
    paddingX: '15px',
    borderRadius: '17px',
  },
  chatBody: {
    display: 'flex',
    gap: '10px',
    maxWidth: {md: '50%', xs: '70%'},
  },
  dateText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '8px',
    lineHeight: '8px',
    alignSelf: 'center',
    color: colours.foggy,
    marginBottom: '13px',
  },
  avatarImg: {
    marginTop: '19px',
  },
  myText: {background: colours.lightenedGrey},
};

export default Message;
