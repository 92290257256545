import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Button, Typography} from '@mui/material';

import Div from '../../../components/common/Div';
import {colours} from '../../../utils/constants';

type Props = {
  loadMore: (next: boolean) => Promise<void>;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  count?: number;
};

const CursorPagination = ({
  loadMore,
  hasNextPage,
  hasPrevPage,
  count,
}: Props) => {
  return (
    <Div sx={styles.pagination}>
      <Div sx={styles.dflex}>
        <Button
          sx={styles.btn}
          disabled={!hasPrevPage}
          onClick={() => void loadMore(false)}>
          <KeyboardArrowLeftIcon sx={styles.icon} />
        </Button>
        <Typography sx={styles.count}>{count}</Typography>
        <Button
          sx={styles.btn}
          disabled={!hasNextPage}
          onClick={() => void loadMore(true)}>
          <KeyboardArrowRightIcon sx={styles.icon} />
        </Button>
      </Div>
    </Div>
  );
};

const styles = {
  pagination: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colours.white,
    marginBottom: {md: '10px', xs: '50px'},
    marginTop: {md: '20px', xs: '0px'},
  },
  dflex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
  },
  btn: {
    colour: colours.black,
    paddingY: '1px',
    paddingX: '1px',
    backgroundColor: colours.yellowishWhite,
    minWidth: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {backgroundColor: colours.yellowishWhite},
    '&:disabled': {
      background: colours.lightenedGrey,
      cursor: 'not-allowed',
    },
  },
  icon: {
    padding: '5px',
    margin: '0px',
    color: colours.darkWhite,
  },
  count: {
    border: '1px solid black',
    paddingX: '9px',
    paddingY: '5px',
    minWidth: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: colours.white,
  },
};
export default CursorPagination;
