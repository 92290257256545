import {useCallback, useEffect, useMemo, useState} from 'react';

import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  ProductInput,
  useProductCategoriesQuery,
} from '../../../graphql/generated';
import {useUserAuth} from '../../../utils/user';
import AddProductCategory from '../../category/components/AddProductCategory';
import CategoryList from '../../category/components/CategoryList';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

const Categories = () => {
  const {addToast} = useToast();
  const {merchant} = useUserAuth();
  const {productData, updateLoading, update} =
    useAddOrUpdateProductContentContext();
  const {
    data: productsCategoriesData,
    loading,
    error: productsCategoriesError,
  } = useProductCategoriesQuery({
    variables: {
      merchantId: merchant?.id || '',
    },
    skip: merchant?.id === '',
  });

  const [categoryIds, setCategoryIds] = useState<string[]>();

  const checkIfItExist = useCallback(
    (newCategoryName: string) =>
      productsCategoriesData?.productCategories?.some(
        (item) => item.name.toLowerCase() === newCategoryName.toLowerCase()
      ) || false,
    [productsCategoriesData?.productCategories]
  );

  const productInput = useMemo(() => {
    const input: ProductInput = {
      categoryIds,
    };
    if (productData) {
      input.id = productData.product?.id;
    }
    return input;
  }, [categoryIds, productData]);

  const addOrUpdate = async () => await update?.(productInput);

  const updateCategoryIds = (id: string) => {
    setCategoryIds((prev) =>
      prev
        ? prev.includes(id)
          ? [...(prev || []).filter((item) => item !== id)]
          : [...prev, id]
        : [id]
    );
  };
  useEffect(() => {
    setCategoryIds(productData?.product?.categories.map((cat) => cat.id) || []);
  }, [productData?.product]);

  useEffect(() => {
    if (!productsCategoriesError?.message) return;
    const msg = productsCategoriesError?.message || 'Error occurred';
    addToast({
      icon: true,
      type: 'error',
      message: msg,
      duration: 3000,
    });
  }, [addToast, productsCategoriesError?.message]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-14 lg:pt-8 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar flex flex-col gap-10">
        <div className="flex flex-col gap-5">
          <h3 className="text-neutral-800 text-lg font-semibold leading-tight mb-4">
            Your categories
          </h3>
          <CategoryList
            loading={loading}
            productsCategoriesData={productsCategoriesData}
            merchantId={merchant?.id || ''}
            categoryIds={categoryIds}
            updateCategoryIds={updateCategoryIds}
            checkIfItExist={checkIfItExist}
          />
        </div>
        <AddProductCategory
          checkIfItExist={checkIfItExist}
          merchantId={merchant?.id || ''}
        />
      </div>
      <Footer
        className="flex-5"
        addOrUpdate={addOrUpdate}
        disabled={!categoryIds?.length}
        updateLoading={updateLoading}
      />
    </div>
  );
};

export default Categories;
