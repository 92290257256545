import {useMediaQuery} from '@cashiaApp/web-components';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import backButton from '../../assets/icons/back_arrow_black.svg';
import getHelp from '../../assets/images/get_help.svg';
import Navbar from '../../components/common/Navbar';

type Faqs = {
  question: string;
  content: string[];
  footer: string;
};

const faqs: Faqs[] = [
  {
    question: 'Issue with a Delivery',
    content: [
      '1. Check Order Status: Start by checking your order status using the provided tracking information. This will give you real-time updates on the whereabouts of your package.',
      '2. Delivery Timeframes: Delivery times may vary based on location, courier services, or any unexpected circumstances. Review the estimated delivery timeframe mentioned during checkout.',
      "3. Contact the Carrier: If your package seems delayed or there's a delivery problem, reaching out to the carrier directly can often provide more detailed information. Use the tracking number to communicate with them about your specific situation.",
      '4. Contact Us: Should you require further assistance or if there are any concerns unresolved by the carrier, please contact our customer support team. We are here to help and will do our best to resolve any delivery issues promptly.',
      '5. Address Verification: Ensure that the shipping address provided during checkout is accurate and up-to-date. Sometimes, delivery problems arise due to incorrect or incomplete addresses.',
      '6. Resolving Delivery Concerns: In the rare event of lost or undelivered packages, we have protocols in place to address these situations. Contact us, and we will work diligently to resolve the issue, either by re-sending the order or processing a refund as per our policies.',
    ],
    footer:
      "   Remember, your satisfaction is our priority, and we are committed to ensuring your order reaches you securely and on time. If you have any further questions or need assistance, please don't hesitate to reach out to our dedicated support team.",
  },
  {
    question: 'Rider took too long to arrive',
    content: [],
    footer: '',
  },
  {
    question: 'Damaged delivery',
    content: [],
    footer: '',
  },
  {
    question: 'Rider failed to arrive',
    content: [],
    footer: '',
  },
  {
    question: 'Delivery was cancelled by customer',
    content: [],
    footer: '',
  },
  {
    question: 'Report issue with a rider',
    content: [],
    footer: '',
  },
];
const FaqList = () => {
  return (
    <ul className="mt-[38px]">
      {faqs.map((item, index) => (
        <FaqItem item={item} key={index} />
      ))}
    </ul>
  );
};

const FaqItem = ({item}: {item: Faqs}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-3 border-b border-cashiaGrey mx-3 cursor-pointer">
      <div
        className="flex items-center justify-between gap-5"
        onClick={toggleAccordion}>
        <h4 className="text-sm font-normal lg:font-semibold">
          {item.question}
        </h4>
      </div>

      {isOpen && (
        <div className="mt-4">
          <p className="px-6 text-[16px] text-textColor">
            {item.content.map((list, i) => (
              <li className="text-foggy" key={i}>
                {list}
              </li>
            ))}
          </p>
          <p className="text-foggy py-4">{item.footer}</p>
        </div>
      )}
    </div>
  );
};
const GetHelp = () => {
  const navigate = useNavigate();

  const matchesMobile = useMediaQuery('(min-width: 768px)');

  return (
    <div className="w-full h-full overflow-none">
      {matchesMobile ? (
        <>
          <div className="flex border-b lg:hidden gap-40 items-center">
            <img
              src={backButton}
              className="py-5 pl-5"
              onClick={() => navigate('/Profile')}
            />
            <p className="flex text-lg font-medium pl-[110]">Get help</p>
          </div>
          <div className="hidden w-full lg:flex fixed bg-white overflow-none">
            <Navbar />
          </div>
          <div className="flex flex-col justify-between overflow-y-scroll no-scrollbar">
            <div className="flex flex-col lg:flex-row justify-between items-start px-20 py-16">
              <div className="flex flex-col justify-between gap-4 py-16">
                <h1 className="font-bold text-5xl text-trueBlack">Get Help</h1>
                <p className="text-xs text-darkGrey font-normal">
                  Find assistance and solutions for your queries here
                </p>
              </div>
              <img src={getHelp} className="pt-10 pl-5" />
            </div>

            <div className="ml-16 lg:ml-20 border rounded-2xl w-[86%]">
              <FaqList />
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="flex flex-row">
            <img
              src={backButton}
              className="pt-5 pl-5"
              onClick={() => navigate('/Profile')}
            />
            <p className="flex text-lg font-medium pt-[20] pl-[110]">
              Get help
            </p>
          </div>
          <div className="border-b-[2px] border-greyish pt-[20]" />
          <FaqList />
        </div>
      )}
    </div>
  );
};
export default GetHelp;
