import {Divider, Typography} from '@mui/material';

import {useInboxContext} from './Inbox';
import NotificationSection from './components/NotificationSection';
import Div from '../../components/common/Div';
import {colours} from '../../utils/constants';

const Notification = () => {
  const {chatRoom} = useInboxContext();
  return (
    <Div sx={styles.main}>
      {chatRoom ? (
        <Div sx={styles.titleDiv}>
          <Typography sx={styles.title}>Notifications</Typography>
        </Div>
      ) : (
        <Div sx={styles.titleDiv} />
      )}
      <Divider sx={styles.divide} />
      <NotificationSection />
    </Div>
  );
};

const styles = {
  main: {
    flex: 2,
    overflowY: 'none',
    height: 'calc(100svh - 64px)',
    display: {md: 'flex', xs: 'none'},
    flexDirection: 'column',
  },
  divide: {height: '0.5px', background: colours.greyish},
  titleDiv: {height: '50px', display: 'flex', alignItems: 'center'},
  title: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: colours.black,
    marginLeft: '20px',
  },
};

export default Notification;
