import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import BlackLock from '../../../assets/icons/black-lock.svg';
import BlackTick from '../../../assets/icons/black-tick.svg';
import Logo from '../../../assets/icons/cashia_logo.svg';
import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  MeDocument,
  MeFieldsFragment,
  MeQuery,
  MerchantOnboardingInput,
  MerchantType,
  useOnboardMerchantMutation,
} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import {useUserAuth} from '../../../utils/user';

type SelfOnboardingContent = {
  step: number;
  totalSteps: number;
  updateLoading: boolean;
  merchant?: MeFieldsFragment;
  merchantType?: MerchantType;
  update?: (
    input: MerchantOnboardingInput,
    preventGoToNext?: boolean,
    callBackFn?: () => void
  ) => Promise<void>;
  setMerchantType?: React.Dispatch<
    React.SetStateAction<MerchantType | undefined>
  >;
};

export const links = [
  {title: 'About your business', link: `/self-onboarding`},
  {title: 'Business details', link: `/self-onboarding/business-details`},
  {title: 'Type of business', link: `/self-onboarding/type-of-business`},
  {title: 'Documents', link: `/self-onboarding/documents`},
  {title: 'Location', link: `/self-onboarding/location`},
  {title: 'Type of store', link: `/self-onboarding/type-of-store`},
  {
    title: 'Select operating hours',
    link: `/self-onboarding/select-operating-hours`,
  },
];

const SelfOnboardingContentContext = createContext<SelfOnboardingContent>({
  step: 0,
  totalSteps: links.length,
  updateLoading: false,
});

export const useSelfOnboardingContentContext = () =>
  useContext(SelfOnboardingContentContext);

const SelfOnboardingLayout = () => {
  const {merchant} = useUserAuth();
  const navigate = useNavigate();
  const {addToast} = useToast();
  const [
    onboardMerchantMutation,
    {loading: updateLoading, error: updateError},
  ] = useOnboardMerchantMutation();

  const [step, setStep] = useState(0);
  const [merchantType, setMerchantType] = useState<MerchantType>();
  const {pathname} = useLocation();
  const isActive = useCallback((path: string) => pathname === path, [pathname]);

  const update = useCallback(
    async (
      input: MerchantOnboardingInput,
      preventGoToNext?: boolean,
      callBackFn?: () => void
    ) => {
      if (!merchant?.id) return;
      const res = await onboardMerchantMutation({
        variables: {
          id: merchant?.id,
          input,
        },
        update: (cache, result) => {
          const cached = cache.readQuery<MeQuery>({
            query: MeDocument,
          });
          cache.writeQuery({
            query: MeDocument,
            data: {
              ...cached,
              me: {...result.data?.merchantOnboarding},
            },
          });
        },
      });
      if (res.data?.merchantOnboarding) {
        callBackFn?.();
        if (preventGoToNext) return;
        navigate(links[step + 1]?.link || '');
      }
    },
    [merchant?.id, navigate, onboardMerchantMutation, step]
  );

  useEffect(() => {
    setStep(links.map((menu) => menu.link).indexOf(pathname));
  }, [merchant?.merchantType, pathname]);

  useEffect(() => {
    merchant?.merchantType && setMerchantType(merchant?.merchantType);
  }, [merchant?.merchantType]);

  useEffect(() => {
    if (!updateError?.message) return;
    addToast({
      icon: true,
      type: 'error',
      message: updateError?.message,
      duration: 3000,
    });
  }, [addToast, updateError?.message]);

  return (
    <div className="w-screen h-screen overflow-hidden no-scrollbar">
      <div className="w-full sm:flex justify-center items-center p-4 lg:hidden h-[64px] border-b-[0.3px] border-black">
        <p className="text-neutral-800 text-lg font-medium capitalize">
          {links[step]?.title}
        </p>
      </div>
      <div className="flex sm:h-[calc(100svh-64px)] lg:h-full w-full overflow-hidden bg-slate-500">
        <div className="lg:flex sm:hidden flex-col gap-10 w-96 bg-offWhite p-5 pt-10 duration-300 select-none">
          <img src={Logo} className="w-14 h-14 mb-14" />
          <div className={cn('text-neutral-800 text-2xl font-normal')}>
            Submit your business
          </div>
          <ul className="flex w-full flex-col overflow-y-auto no-scrollbar">
            {links.map((menu, index) => (
              <li
                key={index}
                className={`mt-2 flex items-center gap-x-4 justify-between rounded-md py-2 text-zinc-700 text-base font-normal font-['Metropolis'] leading-tight`}>
                <p className={`${isActive(menu.link) ? 'text-cyan-500' : ''}`}>
                  {menu.title}
                </p>
                {isActive(menu.link) ? (
                  <></>
                ) : step > index ? (
                  <img className="w-5 h-5" src={BlackTick} />
                ) : (
                  <img className="w-5 h-5" src={BlackLock} />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full bg-white">
          <SelfOnboardingContentContext.Provider
            value={{
              step,
              update,
              merchant,
              merchantType,
              updateLoading,
              setMerchantType,
              totalSteps: links.length,
            }}>
            <Outlet />
          </SelfOnboardingContentContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default SelfOnboardingLayout;
