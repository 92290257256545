import {Divider, Typography} from '@mui/material';

import ChatsSection from './components/ChatsSection';
import Div from '../../components/common/Div';
import {colours} from '../../utils/constants';

const Chats = () => {
  return (
    <Div sx={styles.main}>
      <Div sx={styles.titleDiv}>
        <Typography sx={styles.title}>Messages</Typography>
      </Div>
      <Divider sx={styles.divide} />
      <ChatsSection hightLight />
    </Div>
  );
};

const styles = {
  main: {
    flex: 2,
    overflowY: 'none',
    height: 'calc(100svh - 64px)',
    display: {md: 'flex', xs: 'none'},
    flexDirection: 'column',
  },
  titleDiv: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  divide: {height: '0.5px', background: colours.greyish},
  title: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    marginLeft: '20px',
  },
};

export default Chats;
