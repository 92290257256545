import Error from './components/Error';
import Loading from './components/Loading';
import OrderTable from './components/OrderTable';
import useGetOrderBatchesHook from './hooks/useGetOrderBatchesHook';
import Div from '../../components/common/Div';
import {OrderStatus} from '../../graphql/generated';

const Failed = () => {
  const {data, loadMore, count, loading, error} = useGetOrderBatchesHook([
    OrderStatus.Cancelled,
  ]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Div>
      <OrderTable orders={data} failed loadMore={loadMore} count={count} />
    </Div>
  );
};

export default Failed;
