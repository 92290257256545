import {useCallback, useEffect, useState} from 'react';

import {CursorInput, useGetReelsQuery} from '../../../graphql/generated';

function useGetReel(merchantId: string) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const {
    data,
    refetch,
    loading: dataLoading,
    error,
  } = useGetReelsQuery({
    variables: {
      input: {
        cursor: {
          first: 20,
        },
      },
    },
  });
  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after = data?.reels.pageInfo.endCursor;
        cursorLoad.first = 10;
      } else {
        cursorLoad.before = data?.reels.pageInfo.startCursor;
        cursorLoad.last = 10;
      }
      const res = await refetch({
        input: {cursor: cursorLoad, merchantId: merchantId || ''},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      count,
      data?.reels.pageInfo.endCursor,
      data?.reels.pageInfo.startCursor,
      merchantId,
      refetch,
    ]
  );

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);
  return {
    loading,
    error,
    data,
    loadMore,
    count,
  };
}
export default useGetReel;
