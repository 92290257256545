import {toast} from '@cashiaApp/web-components';
import React, {FormEvent, useCallback, useEffect, useState} from 'react';

import PlusIcon from '../../../assets/icons/plus-icon.svg';
import Button from '../../../components/tailwind/Button';
import Spinner from '../../../components/tailwind/Spinner';
import TextInput from '../../../components/tailwind/TextInput';
import {
  ProductCategoriesDocument,
  ProductCategoriesQuery,
  useAddOrUpdateProductCategoryMutation,
} from '../../../graphql/generated';
import {newCategoryNameLimit} from '../../../utils/constants';
import {updateStringStateWithLimit} from '../../../utils/reusableFunctions';

type Props = {
  merchantId: string;
  checkIfItExist: (newCategoryName: string) => boolean;
};
const AddProductCategory = ({merchantId, checkIfItExist}: Props) => {
  const [newCategory, setNewCategory] = useState('');
  const [addOrUpdateProductCategoryMutation, {loading, error}] =
    useAddOrUpdateProductCategoryMutation();

  const onsubmit = useCallback(async () => {
    if (checkIfItExist(newCategory)) return;
    const res = await addOrUpdateProductCategoryMutation({
      variables: {
        input: {
          name: newCategory.toLowerCase(),
        },
      },
      update: (cache, result) => {
        const variables = {
          merchantId,
        };
        const cached = cache.readQuery<ProductCategoriesQuery>({
          query: ProductCategoriesDocument,
          variables,
        });
        cache.writeQuery({
          query: ProductCategoriesDocument,
          variables,
          data: {
            ...cached,
            productCategories: [
              result.data?.addOrUpdateProductCategory,
              ...(cached?.productCategories || []),
            ],
          },
        });
      },
    });
    if (res.data?.addOrUpdateProductCategory) {
      toast.success(`${newCategory} is created`);
      setNewCategory('');
    }
  }, [
    addOrUpdateProductCategoryMutation,
    checkIfItExist,
    merchantId,
    newCategory,
  ]);

  useEffect(() => {
    if (!error?.message) return;
    toast.error(error.message);
  }, [error?.message]);

  const submitValueToCategory = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div>
      <h4 className="text-neutral-800 text-base font-normal leading-tight mb-5 mt-9">
        Create your own new category
      </h4>
      <form
        className="bg-neutral-50 flex flex-col rounded-[10px] border-dashed p-5 border border-neutral-500"
        onSubmit={submitValueToCategory}>
        <TextInput
          value={newCategory}
          endAdornment={
            <span className="text-cashiaBlue text-[10px] lg:text-[13px]">
              {newCategoryNameLimit - (newCategory.length || 0)}
            </span>
          }
          onChange={(e) =>
            updateStringStateWithLimit(
              e.target.value,
              newCategoryNameLimit,
              setNewCategory
            )
          }
        />
        <p className="text-neutral-800 text-[12px] font-normal mt-2 lg:mb-5 mb-2">
          {newCategoryNameLimit} characters max
        </p>
        <div className="w-full h-px bg-stone-300" />
        <Button
          label={
            loading ? (
              <Spinner fillColor="fill-white" width={153} />
            ) : (
              <div className="flex gap-2">
                <img className="h-5 w-5" src={PlusIcon} />
                <span className="text-neutral-50 text-base font-normal">
                  Create category
                </span>
              </div>
            )
          }
          className={`mt-5 self-end ${
            !(!newCategory.length || checkIfItExist(newCategory))
              ? 'bg-smoothRed'
              : 'bg-greyish'
          }`}
          disabled={Boolean(
            !newCategory.length || checkIfItExist(newCategory) || loading
          )}
          onClick={onsubmit}
        />
      </form>
    </div>
  );
};

export default AddProductCategory;
