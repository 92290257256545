import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import Navbar from '../../../components/common/Navbar';
import Button from '../../../components/tailwind/Button';
import {useToast} from '../../../components/tailwind/toast/useToast';
import {useProductCategoriesQuery} from '../../../graphql/generated';
import {useUserAuth} from '../../../utils/user';
import AddProductCategory from '../../category/components/AddProductCategory';
import CategoryList from '../../category/components/CategoryList';

const ManageCategories = () => {
  const navigate = useNavigate();
  const {addToast} = useToast();
  const {merchant} = useUserAuth();
  const {
    data: productsCategoriesData,
    loading,
    error,
  } = useProductCategoriesQuery({
    variables: {
      merchantId: merchant?.id || '',
    },
    skip: merchant?.id === '',
  });

  const checkIfItExist = useCallback(
    (newCategoryName: string) =>
      productsCategoriesData?.productCategories?.some(
        (item) => item.name.toLowerCase() === newCategoryName.toLowerCase()
      ) || false,
    [productsCategoriesData?.productCategories]
  );

  useEffect(() => {
    if (!error?.message) return;
    addToast({
      icon: true,
      type: 'error',
      message: error?.message,
      duration: 3000,
    });
  }, [addToast, error?.message]);

  return (
    <div className="w-full h-full">
      <div className="w-full lg:block hidden">
        <Navbar />
      </div>
      <div className="flex flex-col lg:h-[calc(100svh-64px)] h-full w-full overflow-hidden">
        <div className="lg:py-20 lg:px-24 md:p-9 p-5 py-14 w-full flex-1 overflow-y-scroll flex flex-col gap-10">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <h3 className="text-neutral-800 text-2xl font-semibold leading-[30px]">
                Manage categories
              </h3>
              <Button
                text
                label="Back"
                labelStyle="text-cashiaBlue no-underline"
                onClick={() => navigate('/store')}
              />
            </div>
            <p className="text-zinc-700 text-base font-normal leading-tight mb-4">
              You can edit, add and delete your categories below
            </p>
            <CategoryList
              loading={loading}
              productsCategoriesData={productsCategoriesData}
              merchantId={merchant?.id || ''}
              checkIfItExist={checkIfItExist}
            />
          </div>
          <AddProductCategory
            checkIfItExist={checkIfItExist}
            merchantId={merchant?.id || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageCategories;
