import {useEffect, useMemo, useState} from 'react';

import Bulb from '../../../assets/icons/bulb.svg';
import redNoPhoto from '../../../assets/icons/redNoPhoto.svg';
import ImageModal from '../../../components/tailwind/ImageModal';
import {ProductInput} from '../../../graphql/generated';
import {imageRequirementsRules} from '../../../utils/constants';
import defaultVariant from '../../../utils/defaultVariant';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

type Images = {
  id?: string;
  smallUrl?: string;
  file?: File;
};

const Images = () => {
  const {productData, update, updateLoading} =
    useAddOrUpdateProductContentContext();
  const [coverImages, setCoverImages] = useState<Images | null>();
  const [images, setImages] = useState<Images[]>();

  const getUploadedImgId = (id: string, smallUrl?: string, oldId?: string) =>
    setImages((prev) =>
      prev
        ? oldId
          ? [
              ...(prev || []).map((img) =>
                img.id === oldId ? {id, smallUrl} : img
              ),
            ]
          : [...prev, {id, smallUrl}]
        : [{id, smallUrl}]
    );
  const getUploadedCoverImgId = (id: string, smallUrl?: string) =>
    setCoverImages({id, smallUrl});

  const removeImgId = (imgId?: string) => {
    if (!imgId) return;
    setImages((prev) =>
      prev ? [...(prev || []).filter((img) => img.id !== imgId)] : []
    );
  };

  const productInput = useMemo(() => {
    const sendingOptionalImgIds = images?.map((image) => image.id || '') || [];
    const input: ProductInput = {
      variants: [
        ...(productData?.product?.variants || []).map((variant) =>
          variant.default
            ? {
                id: variant.id,
                images: [coverImages?.id || '', ...sendingOptionalImgIds],
              }
            : {
                id: variant.id,
                images: variant.images?.map((image) => image.id || '') || [],
              }
        ),
      ],
    };
    if (productData) {
      input.id = productData.product?.id;
    }
    return input;
  }, [coverImages?.id, images, productData]);

  const addOrUpdate = async () => await update?.(productInput);

  useEffect(() => {
    setCoverImages(
      productData?.product && {
        id: defaultVariant(productData?.product)?.images?.at(0)?.id || '',
        smallUrl:
          defaultVariant(productData?.product)?.images?.at(0)?.small || '',
      }
    );
    setImages(
      (productData?.product &&
        defaultVariant(productData?.product)
          ?.images?.slice(1)
          .map((img) => ({
            id: img.id || '',
            smallUrl: img.small || '',
          }))) ||
        []
    );
  }, [productData?.product]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 sm:p-5 w-full flex-1 flex sm:flex-col lg:flex-row gap-4 overflow-y-scroll no-scrollbar justify-between">
        <div className="sm:w-full lg:w-[60%]">
          <h2 className="text-neutral-800 text-3xl md:text-4xl font-semibold leading-tight">
            Images
          </h2>
          <p className="text-zinc-700 mt-3 mb-5 text-base font-normal leading-tight">
            Add at least 3 high quality pictures to showcase your <br />
            product. They will be reviewed by our team to make sure <br />
            they meet our quality standards.
          </p>
          <p className="text-neutral-800 text-lg font-normal leading-snug">
            Add a cover picture
          </p>
          <ImageModal
            title="Add a cover picture"
            thumbnailStyle="h-[200px] w-[200px] cursor-pointer"
            noPhotoImg={redNoPhoto}
            imgUrl={coverImages?.smallUrl}
            imgId={coverImages?.id}
            getUploadedImgId={getUploadedCoverImgId}
            removeImgId={() => setCoverImages(null)}
          />
          <div className="sm:py-2 lg:py-8 lg:pb-16 flex flex-col">
            <p className="text-neutral-800 text-lg font-normal leading-snug">
              Add Gallery images (optional)
            </p>
            <p className="text-zinc-700 mt-4 text-base font-normal leading-tight">
              Upload at least 3 or more photos that show the item in <br />
              different states.
            </p>
            <div className="flex gap-5 flex-wrap">
              {images?.map((image, k) => (
                <ImageModal
                  key={k}
                  title="Add Gallery Images"
                  getUploadedImgId={getUploadedImgId}
                  removeImgId={removeImgId}
                  imgUrl={image?.smallUrl}
                  imgId={image?.id}
                />
              ))}
              <ImageModal
                key={crypto.randomUUID()}
                title="Add Gallery Images"
                getUploadedImgId={getUploadedImgId}
                removeImgId={removeImgId}
                imgUrl={undefined}
                imgId={undefined}
              />
            </div>
          </div>
        </div>
        <div className="sm:w-full lg:w-[40%] h-inherit lg:sticky lg:top-0 bg-neutral-100 rounded-[10px] border-2 border-stone-300 p-10 flex flex-col gap-4">
          <img src={Bulb} className="h-10 w-6" />
          <p className="text-neutral-800 text-lg font-normal">
            Image requirements
          </p>
          <ul className="flex flex-col gap-3 overflow-y-scroll no-scrollbar">
            {imageRequirementsRules.map((requirement, index) => (
              <li
                key={index}
                className="text-zinc-700 flex text-sm font-normal leading-relaxed">
                <p className="mr-5">{index + 1}.</p>
                <p>{requirement}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer
        className="flex-5"
        addOrUpdate={addOrUpdate}
        updateLoading={updateLoading}
        disabled={!coverImages?.id}
      />
    </div>
  );
};

export default Images;
