import {styled, TextField} from '@mui/material';

import {colours} from '../../utils/constants';

const TextInput = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '14px 20px',
  },
  '& .MuiInputBase-multiline': {
    padding: '0px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: colours.lightenedGrey,
    '& fieldset': {
      borderColor: colours.greyish,
      borderWidth: '0.5px',
    },
    '&:hover fieldset': {
      borderColor: colours.greyish,
      borderWidth: '0.5px',
    },
    '&.Mui-focused fieldset': {
      borderColor: colours.greyish,
      borderWidth: '0.5px',
    },
  },
});

export default TextInput;
