import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  OverlayView,
  useLoadScript,
} from '@react-google-maps/api';
import React, {useEffect, useRef, useState} from 'react';

import CustomMarker from './components/customMarker';
import DriverDetailsModal from './components/driverModal';
import {mapStyles} from './mapstyles';
import BikeIcon from '../../assets/icons/Bike_icon.svg';
import RestaurantIcon from '../../assets/icons/restaurant_location.svg';
import Spinner from '../../components/tailwind/Spinner';
import {convertToDegrees} from '../../utils/helper/coordinatesCalculation';

interface Coordinates {
  lat: number;
  lng: number;
  distance?: number;
}

const containerStyle = {
  width: '100%',
  height: '93.3354vh',
};

const Tracker = () => {
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const [distance, setDistance] = useState<string | undefined>('0 km');
  const [duration, setDuration] = useState<string | undefined>('0 mins');
  const [progress, setProgress] = useState(0);
  const index = useRef(0);
  const totalDistanceArray = useRef<number[]>([]);
  const [roratedAngle, setRotatedAngle] = useState(100);

  const pathOfMovement = useRef<Coordinates[]>([]);

  const formattedCoordinates = (coordinates: Coordinates | undefined) =>
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    coordinates?.lat.toString() + ', ' + coordinates?.lng.toString();

  const {isLoaded} = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['drawing'],
  });

  const calculateAngle = () => {
    const firstElement = pathOfMovement.current[0];
    const lastElement =
      pathOfMovement.current[pathOfMovement.current.length - 1];
    if (firstElement && lastElement) {
      const diffLat = firstElement.lat - lastElement.lat;
      const diffLng = firstElement.lng - lastElement.lng;

      const anticlockwiseAngleFromEast = convertToDegrees(
        Math.atan2(diffLat, diffLng)
      );
      const clockwiseAngleFromNorth = 75 - anticlockwiseAngleFromEast;
      setRotatedAngle(Math.round(clockwiseAngleFromNorth));
    } else {
      console.error('Either firstElement or lastElement is undefined.');
    }
  };

  //To be removed later when implimenting the backend
  const path: Coordinates[] = [
    {lat: -1.261568, lng: 36.787417},
    {lat: -1.261702, lng: 36.787386},
    {lat: -1.261898, lng: 36.787346},
    {lat: -1.262165, lng: 36.787306},
    {lat: -1.262294, lng: 36.787283},
    {lat: -1.262454, lng: 36.788352},
    {lat: -1.261929, lng: 36.78845},
    {lat: -1.262112, lng: 36.789564},
    {lat: -1.262045, lng: 36.789639},
    {lat: -1.261991, lng: 36.789688},
    {lat: -1.261991, lng: 36.789804},
    {lat: -1.262005, lng: 36.78988},
    {lat: -1.260969, lng: 36.790015},
  ];

  const getDistanceWayPointsEstimatedTime = async (
    startLocationCoordinates: string,
    destinationLocationCoordiantes: string
  ) => {
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();

    await directionsService.route(
      {
        origin: startLocationCoordinates,
        destination: destinationLocationCoordiantes,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result?.routes[0]) {
          if (result.routes[0].legs[0]) {
            setDistance(result.routes[0].legs[0].distance?.text);
            const distanceText =
              result?.routes[0]?.legs[0]?.distance?.text || '';
            let distanceInInteger = 0;
            if (distanceText.includes('km')) {
              distanceInInteger = parseFloat(distanceText.replace(/\D/g, ''));
            } else {
              distanceInInteger =
                parseInt(distanceText.replace(/\D/g, ''), 10) / 1000;
            }

            setDuration(result.routes[0].legs[0].duration?.text);
            setDirections(result);
            const totalDistance = totalDistanceArray.current;
            totalDistance.push(distanceInInteger);
            totalDistanceArray.current = totalDistance;

            const firstElement = totalDistanceArray.current[0] as number;
            const lastElement =
              totalDistanceArray.current[
                totalDistanceArray.current.length - 1
              ] || 0;
            const percentage =
              ((firstElement - lastElement) / firstElement) * 100;
            setProgress(percentage);
            calculateAngle();
          }
        } else {
          console.error('error fetching directions', result, status);
        }
      }
    );
  };

  useEffect(() => {
    if (isLoaded) {
      const interval = setInterval(async () => {
        if (index.current < path.length) {
          const temporaryArray = pathOfMovement.current;
          //TODO:Will fix this when connecting with the backend
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (path[index.current]) temporaryArray?.push(path[index.current]!);

          await getDistanceWayPointsEstimatedTime(
            formattedCoordinates(path[index.current]),
            formattedCoordinates(path[path.length - 1])
          );
          index.current = index.current + 1;
        } else {
          clearInterval(interval);
        }
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  if (isLoaded) {
    const restaurantIcon = {
      url: RestaurantIcon,
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 15),
      scale: 0.8,
    };

    const onLoad = (map: google.maps.Map) => {
      const bounds = new google.maps.LatLngBounds();
      path?.forEach(({lat, lng}) => bounds.extend({lat, lng}));
      map.fitBounds(bounds);
    };

    return (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={16}
          options={{
            streetViewControl: false,
            styles: mapStyles,
          }}
          onLoad={onLoad}>
          <OverlayView
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2,
              y: -height,
            })}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={
              //TODO:Will fix this when connecting with the backend
              path[index.current] !== undefined
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  path[index.current]!
                : {lat: 0, lng: 0}
            }>
            <CustomMarker rotation={roratedAngle}>
              <img
                src={BikeIcon}
                alt="Custom Marker"
                style={{width: '30px', height: '30px'}}
              />
            </CustomMarker>
          </OverlayView>
          <Marker
            position={path[path.length - 1] || {lat: 0, lng: 0}}
            icon={restaurantIcon}
          />
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  strokeColor: '#00A4D4',
                  strokeWeight: 4,
                },
                preserveViewport: true,
                suppressMarkers: true,
              }}
            />
          )}
        </GoogleMap>
        <DriverDetailsModal
          progress={progress}
          distance={distance}
          duration={duration}
        />
      </>
    );
  }
  return (
    <div className="flex items-center justify-center h-screen">
      <Spinner width={149} />
    </div>
  );
};

export default React.memo(Tracker);
