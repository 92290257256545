import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import AddItem from '../../assets/icons/add_item.svg';
import WalletIsActive from '../../assets/icons/bluewallet.svg';
import Wallet from '../../assets/icons/cards.svg';
import OrdersActive from '../../assets/icons/orders_blue.svg';
import Orders from '../../assets/icons/orders_icon.svg';
import StoreActive from '../../assets/icons/store_active.svg';
import Store from '../../assets/icons/store_icon.svg';
import {useUserAuth} from '../../utils/user';

function isRouteActive(currentPath: string, targetPath: string) {
  return currentPath === targetPath;
}
function RightNavItem({
  route,
  activeIcon,
  inactiveIcon,
  label,
}: {
  route: string;
  activeIcon: string | React.ReactNode;
  inactiveIcon: string | React.ReactNode;
  label: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`flex flex-col items-center cursor-pointer ${
        isRouteActive(location.pathname, route) ? 'text-black' : 'text-foggy'
      }`}
      onClick={() => navigate(route)}>
      {typeof activeIcon === 'string' && typeof inactiveIcon === 'string' ? (
        <img
          src={
            isRouteActive(location.pathname, route) ? activeIcon : inactiveIcon
          }
          alt={label}
          className="w-8 h-8"
        />
      ) : (
        activeIcon
      )}
      <span className="text-xs">{label}</span>
    </div>
  );
}

const menuItems = [
  {
    label: 'Store',
    route: '/store',
    activeIcon: StoreActive,
    inactiveIcon: Store,
  },
  {
    label: 'Orders',
    route: '/orders',
    activeIcon: OrdersActive,
    inactiveIcon: Orders,
  },
];

const BottomNavbar = () => {
  const navigate = useNavigate();
  const {merchant} = useUserAuth();
  const [isProfileActive, setIsProfileActive] = useState(
    isRouteActive(location.pathname, '/profile')
  );
  return (
    <div
      className="flex bg-transparent bottom-0 shadow"
      style={{boxShadow: '0px -3.78114px 37.8114px rgba(218, 218, 218, 0.2)'}}>
      <div className="flex h-16 justify-between items-center w-full z-auto">
        <div className="flex flex-1 justify-between items-center bg-white pr-8 md:pr-32 gap-8">
          {menuItems.map((menuItem) => (
            <div
              key={menuItem.route}
              className={`flex flex-col items-center cursor-pointer ${
                isRouteActive(location.pathname, menuItem.route)
                  ? 'text-black'
                  : 'text-foggy'
              }`}
              onClick={() => navigate(menuItem.route)}>
              <img
                src={
                  isRouteActive(location.pathname, menuItem.route)
                    ? menuItem.activeIcon
                    : menuItem.inactiveIcon
                }
                alt={menuItem.label}
              />
              <p className="text-xs">{menuItem.label}</p>
            </div>
          ))}
        </div>
        <div
          className="flex justify-center items-center cursor-pointer mb-10"
          onClick={() => navigate('/store/add-product')}
          style={{minWidth: '32px'}}>
          <img
            src={AddItem}
            alt="Add Item"
            style={{width: '50px', height: '50px'}}
            onClick={() => {
              navigate('/store/add-product');
            }}
          />
        </div>
        <div className="flex flex-1 justify-between items-center bg-transparent pl-8 md:pl-32 gap-4">
          <RightNavItem
            route="/Wallet"
            activeIcon={WalletIsActive}
            inactiveIcon={Wallet}
            label="Wallet"
          />
          <div
            className={`flex flex-col items-center cursor-pointer ${
              isProfileActive
                ? 'text-black border-3 border-cashiaBlue'
                : 'text-foggy'
            }`}
            onClick={() => {
              setIsProfileActive(true);
              navigate('/profile');
            }}>
            <div
              className={`w-8 h-8 ${
                isProfileActive
                  ? 'rounded-full border-[3px] border-cashiaBlue'
                  : ''
              }`}>
              <img
                src={merchant?.logo?.medium || ''}
                alt="Profile"
                className="w-full h-full rounded-full object-cover"
              />
            </div>
            <p className="text-xs">Profile</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BottomNavbar;
