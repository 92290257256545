import CancelIcon from '@mui/icons-material/Cancel';
import {Modal, Typography, Divider} from '@mui/material';

import modalBackground from '../../../assets/images/modalBackground.svg';
import questionMark from '../../../assets/images/questionMark.svg';
import Div from '../../../components/common/Div';
import {OrderBatchFieldsFragment} from '../../../graphql/generated';
import {colours} from '../../../utils/constants';
import formatDate from '../../../utils/formatDate';
import formatMoney from '../../../utils/formatMoney';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  order: OrderBatchFieldsFragment | null | undefined;
};

const DeliveredReceitps = ({open, setOpen, order}: Props) => {
  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{sx: styles.modalBackgroundBg}}>
      <Div sx={styles.modalStyles}>
        <CancelIcon sx={styles.cancelIcon} onClick={() => setOpen(false)} />
        <Div sx={styles.modelInfo}>
          <Typography sx={styles.dateText}>
            {order?.createdAt &&
              formatDate(order?.createdAt as Date, {
                withWeekday: true,
                withMonth: true,
                withYear: true,
                withTime: false,
              })}
          </Typography>
          <Typography sx={styles.userText}>
            Receipt for {order?.buyer.user?.name?.substring(0, 20) || 'unknown'}
          </Typography>
          <Div sx={styles.xyz} />
        </Div>
        <Div sx={styles.des}>
          <Div sx={styles.eachDes}>
            <Typography sx={styles.totalInfo}>TOTAL</Typography>
            <Typography sx={styles.totalInfo}>
              KES {order?.totalCost && formatMoney(order.totalCost, 2)}
            </Typography>
          </Div>
          <Divider />
          <Div sx={styles.scroll}>
            {order?.items.map((item) => (
              <Div sx={styles.eachDes} key={item.variant.id}>
                <Div sx={styles.desWIcon}>
                  <Div sx={styles.qtyIcon}>
                    <Typography sx={styles.originPrice}>
                      {item.count}
                    </Typography>
                  </Div>
                  <Typography sx={styles.originPrice}>
                    {item.variant.product?.title}
                  </Typography>
                </Div>
                <Div>
                  <Typography sx={styles.originPrice}>
                    KES{' '}
                    {Number(
                      item.variant.price && formatMoney(item.variant.price, 2)
                    ) * Number(item.count)}
                  </Typography>
                </Div>
              </Div>
            ))}
          </Div>
          <Divider />
          <Div sx={styles.eachDes}>
            <Div>
              <Typography>Subtotal</Typography>
              <Div sx={styles.desWIcon}>
                <img src={questionMark} alt="questionMark" />
                <Typography sx={styles.service}>Service fee</Typography>
              </Div>
            </Div>
            <Div>
              <Typography sx={styles.subTotal}>
                KES {order?.totalCost && formatMoney(order.totalCost, 2)}
              </Typography>
              <Typography sx={[styles.service, styles.txtBlue]}>
                - KES 0.0
              </Typography>
            </Div>
          </Div>
          <Divider />
          <Div sx={styles.eachDes}>
            <Typography sx={styles.amountInfo}>Amount Received</Typography>
            <Typography sx={[styles.amountInfo, styles.txtAlg]}>
              KES {order?.totalCost && formatMoney(order.totalCost, 2)}
            </Typography>
          </Div>
          <Divider />
          <Div sx={styles.download}>
            <Typography>Download PDF</Typography>
            <Typography>Get help</Typography>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};

const styles = {
  modalStyles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '630px',
    bgcolor: colours.white,
    borderRadius: '15px',
    DivShadow: 24,
    border: 'none',
  },
  modalBackgroundBg: {backgroundColor: colours.modalPurpleBackDropBg},
  scroll: {maxHeight: '160px', overflowY: 'scroll'},
  cancelIcon: {
    position: 'absolute',
    top: 20,
    right: 25,
    color: 'white',
    cursor: 'pointer',
    fontSize: '30px',
  },
  dateText: {
    paddingTop: '50px',
    color: 'white',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '13px',
  },
  userText: {
    color: 'white',
    marginTop: '15px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  modelInfo: {
    backgroundImage: `url(${modalBackground})`,
    maxHeight: '244px',
    p: 4,
  },
  des: {
    paddingX: '30px',
  },
  xyz: {
    color: 'white',
    fontSize: '24px',
    marginBottom: '35px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  eachDes: {
    paddingY: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalInfo: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '24px',
  },
  amountInfo: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '18px',
  },
  desWIcon: {
    display: 'flex',
    gap: '10px',
  },

  qtyIcon: {
    border: '1.5px solid black',
    paddingY: '0px',
    paddingX: '4px',
    borderRadius: '5px',
  },
  originPrice: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
  },
  subTotal: {
    fontStyle: 'normal',
    fontHeight: '600',
    fontSize: '18px',
    lineHeight: '18px',
    textAlign: 'right',
  },
  service: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'right',
    marginTop: '10px',
  },
  download: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    textDecorationLine: 'underline',
    display: 'flex',
    gap: '100px',
    paddingY: '40px',
    cursor: 'pointer',
  },
  txtAlg: {textAlign: 'right'},
  txtBlue: {color: colours.cashiaBlue},
} as const;

export default DeliveredReceitps;
