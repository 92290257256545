/* eslint-disable no-constant-condition */
import {Typography} from '@mui/material';
import {useState} from 'react';

import Div from '../../../components/common/Div';
import CommonTable from '../../../components/common/table';
import {CourierType, WalletActivationStatus} from '../../../graphql/generated';
import useGetCouriers from '../hooks/useGetCouriers';

export type MarkerDetails = {
  id?: string | null;
  name?: string | null;
  address?: string;
  online?: boolean;
  lat?: number;
  lng?: number;
  imgUrl?: string | null;
};

const CouriersTable = () => {
  const [courierType] = useState<CourierType>();
  const [walletStatus] = useState<WalletActivationStatus[]>();

  const {columns, data, setData, setColumns, sortingColumns, loading, error} =
    useGetCouriers(courierType, walletStatus);

  return (
    <>
      {data?.length > 0 ? (
        <Div className="flex h-fit flex-col gap-6 overflow-y-scroll mt-4">
          {error ? (
            <Typography className="rounded-lg bg-red-500 p-6 text-white">
              Error: {error.message}
            </Typography>
          ) : (
            <Div className="flex h-fit w-full flex-col justify-between gap-6">
              <Typography variant="h4" sx={styles.courierText}>
                My Couriers
              </Typography>
              <CommonTable
                prettify
                data={data}
                loading={loading}
                columns={columns}
                setData={setData}
                setColumns={setColumns}
                sortingColumns={sortingColumns}
              />
            </Div>
          )}
        </Div>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = {
  courierText: {
    fontFamily: 'Metropolis',
    fontWeight: '600',
  },
};

export default CouriersTable;
