import {FormEvent, useMemo, useState} from 'react';

import AddIcon from '../../../assets/icons/add-circle.svg';
import CloseIcon from '../../../assets/icons/close_white.svg';
import noPhoto from '../../../assets/icons/noPhoto.svg';
import Button from '../../../components/tailwind/Button';
import Checkbox from '../../../components/tailwind/Checkbox';
import IconButton from '../../../components/tailwind/IconButton';
import Spinner from '../../../components/tailwind/Spinner';
import TextInput from '../../../components/tailwind/TextInput';
import {
  CurrencyCode,
  ProductInput,
  ProductOption,
  ProductVariantFieldsFragment,
} from '../../../graphql/generated';
import defaultVariant from '../../../utils/defaultVariant';
import formatMoney from '../../../utils/formatMoney';
import {cn} from '../../../utils/reusableFunctions';
import EditVariant from '../components/EditVariant';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

const Options = () => {
  const {productData, isUpdating, update, updateLoading} =
    useAddOrUpdateProductContentContext();
  const [openOptions, setOpenOptions] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [optionValues, setOptionValues] = useState<string[]>();
  const [optionName, setOptionName] = useState('');
  const [optionValue, setOptionValue] = useState('');

  const addAllOrRemoveAll = () => {
    if (selectedIds?.length === productData?.product?.variants?.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(
        productData?.product?.variants?.map((item) => item.id ?? '')
      );
    }
  };

  const addOrRemove = (id: string) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds((prev) => prev?.filter((item) => item !== id));
    } else {
      setSelectedIds((prev) => [...(prev || []), id]);
    }
  };

  const toggleEditModal = () => setOpenEditModal(!openEditModal);

  const addValueToOption = () => {
    const alreadyExist = optionValues?.find((item) => item === optionValue);
    if (!optionValue.length) return;
    if (alreadyExist) return;
    setOptionValues((prev) => (prev ? [optionValue, ...prev] : [optionValue]));
    setOptionValue('');
  };
  const removeOptionValue = (option: string) =>
    setOptionValues(optionValues?.filter((value) => value !== option));

  const submitValueToOption = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addValueToOption();
  };

  const optionNameExists = productData?.product?.options?.some(
    (option) => option?.name === optionName
  );

  function addNewOption(
    prevVariants?: ProductVariantFieldsFragment[],
    newOption?: ProductOption
  ) {
    const updatedVariants: ProductVariantFieldsFragment[] = [];
    if (!prevVariants) return updatedVariants;
    if (!newOption) return prevVariants;
    for (let i = 0; i < (newOption?.values || []).length; i++) {
      const option = (newOption?.values || [])[i];
      updatedVariants.push(
        ...prevVariants.map((variant) => ({
          ...(i === 0 ? {...variant} : {}),
          deleted: false,
          option: [
            ...(variant.option || []),
            {
              name: newOption.name,
              value: option,
            },
          ],
        }))
      );
    }

    return updatedVariants;
  }

  const productInput = useMemo(() => {
    if (optionNameExists) return {};
    const newOption: ProductOption = {
      name: optionName,
      values: optionValues || [],
    };
    const productDefaultVariant = defaultVariant(productData?.product);
    const uploadingOptions = (
      productData?.product?.options
        ? [newOption, ...productData.product.options]
        : [newOption]
    ).map((option) => ({
      id: option?.id || '',
      name: option?.name || '',
      values: option?.values as string[],
    }));
    const uploadingVariants = addNewOption(
      productData?.product?.variants || [],
      newOption
    ).map((variant) => ({
      id: variant.id,
      title: productDefaultVariant?.title,
      description: productDefaultVariant?.description,
      price: {
        amountInCents: productDefaultVariant?.price?.amountInCents || 1 * 100,
        currencyCode: variant?.price?.currencyCode || CurrencyCode.Kes,
      },
      images: variant.images?.map((image) => image.id || '') || [],
      option: variant.option?.map((op) => ({
        name: op?.name,
        value: op?.value,
      })),
    }));
    const input: ProductInput = {
      options: uploadingOptions,
      variants: uploadingVariants,
    };
    if (productData) {
      input.id = productData.product?.id;
    }
    return input;
  }, [optionName, optionNameExists, optionValues, productData]);

  const updateStateVariantsAndOptions = () => {
    setOpenOptions(false);
    setOptionName('');
    setOptionValues(undefined);
    setOptionValue('');
  };

  const addOrUpdateVariantsAndOptions = async () =>
    await update?.(productInput, updateStateVariantsAndOptions);

  // useEffect(() => {
  //   setOptions(productData?.product?.options || []);
  //   setVariants(productData?.product?.variants || []);
  // }, [productData?.product]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <h3 className="text-neutral-800 mb-9 text-2xl font-semibold leading-[30px]">
          Options
        </h3>
        <div className="w-full flex flex-col gap-10">
          <div className="w-full flex flex-col gap-0">
            <p className="text-stone-500 text-base font-normal leading-tight">
              Add the different variations of your product from which your
              customers will be able to choose from.
            </p>
            <Button
              label="Add an option +"
              labelStyle="text-neutral-50 text-base font-normal"
              className={`mt-5 ${openOptions ? 'bg-greyish' : 'bg-slate-900'}`}
              onClick={() => setOpenOptions(true)}
            />
          </div>
          {openOptions && (
            <div className="flex flex-col w-full md:w-[58%] gap-2 border border-neutral-200 rounded-lg">
              <div className="flex flex-col p-3 gap-2 w-full ">
                <TextInput
                  value={optionName}
                  autoFocus
                  label="Option Name"
                  labelStyle="text-zinc-700 text-lg font-semibold leading-snug"
                  placeholder="Enter option name"
                  inputStyle="h-6"
                  onChange={(e) => setOptionName(e.target.value.toLowerCase())}
                />
                <div className="flex flex-col w-full gap-1">
                  <p className="text-zinc-700 text-xl font-semibold leading-snug">
                    Option Value
                  </p>
                  <p className="text-neutral-800 text-[13px] font-normal">
                    (Create the range of options that this variant will have
                    e.g. (small, medium & large))
                  </p>
                  <form
                    className="flex w-full gap-3 items-center"
                    onSubmit={submitValueToOption}>
                    <TextInput
                      value={optionValue}
                      placeholder="Enter List of options"
                      containerStyle="w-full"
                      inputStyle="h-6"
                      onChange={(e) =>
                        setOptionValue(e.target.value.toLowerCase())
                      }
                    />
                    <IconButton
                      icon={AddIcon}
                      iconStyle="h-9 w-9"
                      onClick={addValueToOption}
                    />
                  </form>
                  <div className="flex gap-2 flex-wrap">
                    {optionValues?.map((value, i) => (
                      <div
                        key={i}
                        className="flex gap-2 p-2 bg-cashiaBlue rounded-md items-center">
                        <p className="text-neutral-50 text-xs font-medium">
                          {value}
                        </p>
                        <IconButton
                          icon={CloseIcon}
                          iconStyle="h-2 w-2"
                          onClick={() => removeOptionValue(value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex justify-between p-3">
                <Button
                  label="Cancel"
                  text
                  onClick={() => setOpenOptions(false)}
                />
                <Button
                  label={
                    updateLoading ? (
                      <Spinner fillColor="fill-white" width={36.5} />
                    ) : (
                      'Save'
                    )
                  }
                  disabled={
                    !(
                      !!optionName.length &&
                      !!optionValues?.length &&
                      !optionNameExists
                    )
                  }
                  onClick={addOrUpdateVariantsAndOptions}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4">
            {productData?.product?.options?.map((option, i) => (
              <div key={i} className="flex flex-col gap-1">
                <p className="text-zinc-700 text-base font-medium capitalize">
                  {option?.name}:
                </p>
                <div className="flex gap-2 flex-wrap">
                  {[...(option?.values || [])].map((value, k) => (
                    <div
                      key={k}
                      className="flex gap-2 p-2 bg-cashiaBlue rounded-md items-center">
                      <p className="text-neutral-50 text-xs font-medium">
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {productData?.product?.variants?.length ? (
            <div className="flex flex-col">
              <div className="flex justify-between items-center w-full lg:w-[85%]">
                <h4 className="text-zinc-700 text-xl font-semibold leading-snug">
                  Variant List
                </h4>
                <Button
                  label={(selectedIds?.length || 0) > 1 ? 'Bulk Edit' : 'Edit'}
                  disabled={!selectedIds?.length}
                  onClick={toggleEditModal}
                  className="px-2 py-0"
                />
              </div>
              <div className="flex mt-3 flex-col gap-2 w-full lg:w-[85%] rounded-lg border border-neutral-200 px-2.5 py-[15px] lg:px-3 lg:py-4">
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-3 items-center">
                    <Checkbox
                      checked={
                        selectedIds?.length ===
                        productData?.product?.variants?.length
                      }
                      onChange={() => addAllOrRemoveAll()}
                    />
                    <p className="text-neutral-800 text-xs font-semibold font-['Metropolis'] uppercase">
                      Variant
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <p className="text-neutral-800 w-[80px] md:w-[170px] text-xs font-semibold font-['Metropolis'] uppercase">
                      Price
                    </p>
                    <p className="text-neutral-800 w-[80px] md:w-[170px] text-xs font-semibold uppercase">
                      Quantity
                    </p>
                    <p className="text-neutral-800 w-9 text-xs font-semibold uppercase">
                      Image
                    </p>
                  </div>
                </div>
                {productData?.product?.variants?.map((item, i) => (
                  <div
                    key={i}
                    className="flex justify-between items-center w-full">
                    <div className="flex gap-3 items-center max-w-[40%]">
                      <Checkbox
                        checked={Boolean(
                          item?.id && selectedIds?.includes(item.id)
                        )}
                        onChange={() => item?.id && addOrRemove(item.id)}
                      />
                      <p
                        className={cn(
                          'text-neutral-500 text-sm font-medium truncate',
                          {'text-cashiaBlue': item.default}
                        )}>
                        {item.option?.map((op) => op?.value).join(' / ') ||
                          'Default variant'}
                      </p>
                    </div>
                    <div className="flex gap-3">
                      <div className="w-[80px] md:w-[170px] h-[34px] p-2.5 bg-zinc-100 rounded-[7px] justify-start items-center gap-2.5 inline-flex">
                        <p className="text-neutral-400 text-sm font-normal">
                          {item.price && formatMoney(item.price)}
                        </p>
                      </div>
                      <div className="w-[80px] md:w-[170px] h-[34px] p-2.5 bg-zinc-100 rounded-[7px] justify-start items-center gap-2.5 inline-flex">
                        <p className="text-neutral-400 text-sm font-normal">
                          0
                        </p>
                      </div>
                      <img
                        src={
                          (item?.images && item?.images[0]?.small) || noPhoto
                        }
                        className="h-9 w-9 rounded-md object-cover"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-xs text-cashiaBlue mt-2">
                Note: The default variant is the one in blue
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <EditVariant
          openEditModal={openEditModal}
          toggleEditModal={toggleEditModal}
          selectedIds={selectedIds}
          variants={productData?.product?.variants || []}
          // setVariants={setVariants}
          setSelectedIds={setSelectedIds}
        />
      </div>
      <Footer
        className="flex-5"
        next={
          isUpdating
            ? `/store/product/${productData?.product?.id || ''}`
            : undefined
        }
      />
    </div>
  );
};

export default Options;
