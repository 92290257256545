import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@cashiaApp/web-components';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as AccountBalanceWalletIcon} from '../../assets/icons/AccountBalanceWalletIcon.svg';
import {ReactComponent as KeyboardArrowDownIcon} from '../../assets/icons/KeyboardArrowDownIcon.svg';
import {ReactComponent as AddIcon} from '../../assets/icons/add-icon.svg';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import blueCards from '../../assets/icons/blueCards.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close-black.svg';
import CircularProgress from '../../components/tailwind/Spinner';
import {
  InterswitchBankFieldsFragment,
  useAddPaymentMethodMutation,
  useInterswitchBankProvidersQuery,
  PaymentMethodType,
  usePaymentMethodsQuery,
  PaymentMethodsQuery,
  PaymentMethodsDocument,
  useRemovePaymentMethodMutation,
} from '../../graphql/generated';
import useWindowSize from '../inbox/hooks/useWindowSize';

const Banks = () => {
  const {width} = useWindowSize();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [commonErrors, setCommonErrors] = useState(false);
  const [delID, setDelID] = useState('');
  const [selected, setSelected] = useState<InterswitchBankFieldsFragment>();
  const [bankInput, setBankInput] = useState({
    name: '',
    accNum: '',
  });

  const {data, loading, error} = useInterswitchBankProvidersQuery();
  const {
    data: allPaymentMethods,
    loading: paymentLoading,
    error: paymentError,
  } = usePaymentMethodsQuery();
  const [addPaymentMethodMutation, {loading: saveLoading, error: saveError}] =
    useAddPaymentMethodMutation();

  const [
    removePaymentMethodMutation,
    {loading: deleteLoading, error: deleteError},
  ] = useRemovePaymentMethodMutation();

  const saveBankDetails = async () => {
    if (!bankInput.name && !bankInput.accNum && !selected?.id) return;
    if (selected?.id) {
      const res = await addPaymentMethodMutation({
        variables: {
          input: {
            type: PaymentMethodType.InterswitchBank,
            interswitchBankAccount: {
              name: bankInput.name,
              bankAccountNumber: bankInput.accNum,
              interswitchBankId: selected.id,
            },
          },
        },
        update: (cache, result) => {
          const cached = cache.readQuery<PaymentMethodsQuery | null>({
            query: PaymentMethodsDocument,
          });
          if (!cached) return;
          const returnData = result.data?.addPaymentMethod;
          if (returnData) {
            cache.writeQuery<PaymentMethodsQuery | null>({
              query: PaymentMethodsDocument,
              data: {
                ...cached,
                paymentMethods: [...cached.paymentMethods, returnData],
              },
            });
          }
        },
      });
      if (res.data?.addPaymentMethod) {
        setOpen(false);
        setBankInput({
          name: '',
          accNum: '',
        });
        setSelected(undefined);
      }
    }
  };

  const deleteBankAccount = async (id: string) => {
    await removePaymentMethodMutation({
      variables: {
        id: id,
      },
      update: (cache, result) => {
        const cached = cache.readQuery<PaymentMethodsQuery | null>({
          query: PaymentMethodsDocument,
        });
        if (!cached) return;
        const returnData = result.data?.removePaymentMethod;
        if (returnData) {
          cache.writeQuery<PaymentMethodsQuery | null>({
            query: PaymentMethodsDocument,
            data: {
              ...cached,
              paymentMethods: cached.paymentMethods.filter(
                (item) => id !== item?.id
              ),
            },
          });
        }
      },
    });
  };

  const goToWalletHome = () => navigate('/wallet');

  useEffect(() => {
    if (saveError || paymentError || deleteError) setCommonErrors(true);
  }, [saveError, paymentError, deleteError]);

  return (
    <>
      <div className="w-full md:w-full xs:w-screen box-border relative">
        <div className="flex justify-between items-center pt-5 px-5 md:pt-10 md:px-10">
          <ArrowBackIcon
            className="cursor-pointer lg:hidden"
            onClick={goToWalletHome}
          />
          <p className="font-metropolis sm:font-normal lg:font-semibold lg:text-3xl sm:text-lg">
            {width < 900 ? 'Your accounts' : 'My Bank Accounts'}
          </p>
          <img
            src={blueCards}
            className="mb-1 h-10 w-10 cursor-pointer lg:hidden"
            onClick={goToWalletHome}
          />
        </div>

        <div className="lg:hidden sm:flex h-[10px] w-full bg-lightenedGrey shadow-md" />
        <div className="px-5 md:px-10 pt-0 md:pt-4 pb-3">
          {paymentLoading && (
            <div className="flex items-center justify-center h-20">
              <CircularProgress className="size={50}" />
            </div>
          )}
          <div className="flex flex-col mt-5">
            <div className="mt-5">
              {allPaymentMethods?.paymentMethods?.map((item) => {
                if (
                  item?.__typename === 'InterswitchBankAccountPaymentMethod'
                ) {
                  return (
                    <div
                      key={item.id}
                      className="flex flex-col mb-5 md:w-[60%] xs:w-full">
                      <div className="flex justify-between items-center border border-black rounded-lg px-5 py-3">
                        <div className="flex gap-6 items-center">
                          <img
                            src={item?.interswitchBank?.logo?.small || ''}
                            className="h-10 w-10"
                          />
                          <div className="flex flex-col">
                            <p className="font-medium text-base">
                              {item.interswitchBank?.name}
                            </p>
                            <p className="text-sm">{item.bankAccountNumber}</p>
                          </div>
                        </div>
                        <button
                          className="text-sm text-smoothRed"
                          onClick={() => {
                            setDelID(item.id);
                            void deleteBankAccount(item.id);
                          }}>
                          {deleteLoading && delID === item.id ? (
                            <CircularProgress className="size={20}" />
                          ) : (
                            'Delete'
                          )}
                        </button>
                      </div>
                      <div className="p-2 border-b border-solid border-dividerGrey" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <button
            className="lg:w-96 sm:w-full h-14 mt-5 flex justify-between items-center bg-cashiaBlue text-white rounded-md px-4"
            onClick={() => setOpen(true)}>
            <div className="flex gap-2 items-center">
              <AccountBalanceWalletIcon color="inherit" />
              <p className="font-metropolis md:font-semibold md:text-xl xs:text-base font-normal">
                Add a bank account
              </p>
            </div>
            <AddIcon className="md: colours.white, xs: colours.cashiaBlue" />
          </button>
        </div>
        {open && (
          <div className="fixed inset-0 flex items-center justify-center bg-darkGrey bg-opacity-50">
            <div className="bg-white rounded-lg p-8 shadow-md max-w-md w-full">
              <div className="flex justify-between items-center mb-8">
                <div />
                <p className="font-medium text-base text-darkGrey">
                  Set up your bank accounts.
                </p>
                <CloseIcon
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              </div>
              <div className="flex flex-col gap-4">
                <p className="font-semibold text-lg">
                  Let’s add a bank account.
                </p>
                <p className="text-sm text-greyish">
                  To start, let us know where you would like us to send your
                  money.
                </p>
                <div className="flex flex-col gap-2">
                  <p>Select your bank</p>
                  <Popover modal={true}>
                    <PopoverTrigger className="m-0">
                      <div className="flex-col">
                        <div
                          className="flex justify-between items-center cursor-pointer bg-lightGrey p-3.5 rounded-lg box-border"
                          onClick={() => setOpen(true)}>
                          <div className="flex flex-col">
                            <p className="text-sm text-black pr-14">Select</p>
                            <p className="text-sm">
                              {selected?.name || 'Choose a bank'}
                            </p>
                          </div>
                          <PopoverContent className="w-80 bg-white max-h-[320px] md:max-h-[400px] sm:max-h-[300px] xs:max-h-[200px]">
                            <div className="grid gap-4">
                              <div className="p-2 flex justify-between items-center">
                                <p className="font-medium text-lg">
                                  Select bank
                                </p>
                                <CloseIcon
                                  onClick={() => setOpen(false)}
                                  className="cursor-pointer"
                                />
                              </div>
                              <div className="border-b border-solid border-dividerGrey" />
                              <div className="p-5 flex flex-col gap-4 overflow-y-scroll max-h-[220px]  md:max-h-[300px] xs:max-h-[250px]">
                                {data?.interswitchBankProviders.map((item) => (
                                  <div
                                    key={item.id}
                                    className="flex flex-col gap-3">
                                    <div
                                      className="flex gap-3 cursor-pointer items-center"
                                      onClick={() => {
                                        setSelected(item);
                                        setOpen(false);
                                      }}>
                                      <img
                                        src={item.logo?.small || ''}
                                        className="h-10 w-10"
                                      />
                                      <p>{item.name}</p>
                                    </div>
                                    <div className="border-b border-solid border-dividerGrey" />
                                  </div>
                                ))}
                                {loading && (
                                  <CircularProgress className="color=success size={25}" />
                                )}
                                <div className="mb-20" />
                              </div>
                              {error && (
                                <div className="p-5 flex flex-col gap-4">
                                  <p>{error.message}</p>
                                </div>
                              )}
                            </div>
                          </PopoverContent>
                          <KeyboardArrowDownIcon />
                        </div>
                      </div>
                    </PopoverTrigger>
                  </Popover>
                </div>
                <div className="border-b border-solid border-dividerGrey" />
                <div className="flex flex-col gap-2">
                  <p>Whose bank account is it?</p>
                  <input
                    value={bankInput.name}
                    className="bg-lightGrey p-3.5 rounded-lg box-border outline-none"
                    placeholder="name"
                    onChange={(e) =>
                      setBankInput({...bankInput, name: e.target.value})
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <p>What’s the account number?</p>
                  <input
                    value={bankInput.accNum}
                    className="bg-lightGrey p-3.5 rounded-lg box-border"
                    placeholder="account number"
                    type="number"
                    inputMode="numeric"
                    onChange={(e) =>
                      setBankInput({...bankInput, accNum: e.target.value})
                    }
                  />
                </div>
              </div>
              <div className="mt-8 border-t pt-4 flex justify-end">
                <div className="border-b border-solid border-dividerGrey" />
                <button
                  disabled={
                    !(
                      !!bankInput.name.length &&
                      !!bankInput.accNum.length &&
                      !!selected
                    )
                  }
                  onClick={saveBankDetails}
                  className="bg-cashiaBlue text-white px-4 py-2 rounded-md disabled:bg-greyish disabled:cursor-not-allowed">
                  {saveLoading ? (
                    <CircularProgress className="color=inherit size={15}" />
                  ) : (
                    'Send'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {commonErrors && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="bg-darkRed text-white p-4 rounded-md">
            <p>
              {saveError?.message ||
                paymentError?.message ||
                deleteError?.message}
            </p>
            <button
              className="mt-2 bg-cashiaBlue text-white px-4 py-2 rounded-md"
              onClick={() => setCommonErrors(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Banks;
