import {Backdrop, Divider, Tab, Tabs, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';

import {useInboxContext} from './Inbox';
import ChatsSection from './components/ChatsSection';
import NotificationSection from './components/NotificationSection';
import Div from '../../components/common/Div';
import MiniNavbar from '../../components/common/bottomNavbar';
import {colours} from '../../utils/constants';

const SmallScreen = () => {
  const {open} = useInboxContext();
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState('Messages');
  const handleChange = () => {
    if (value === 0) {
      setValue(1);
      setTab('Notifications');
    } else {
      setValue(0);
      setTab('Messages');
    }
  };
  const menu = ['Messages', 'Notifications'];
  return (
    <Backdrop sx={styles.main} open={open}>
      <Div sx={styles.inboxDiv}>
        <Typography sx={styles.inboxTxt}>Inbox</Typography>
      </Div>
      <Divider sx={styles.divide} />
      <Box sx={styles.fullWidth}>
        <Tabs
          value={value}
          sx={styles.navText}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary">
          {menu.map((item, i) => (
            <Tab key={i} label={item} sx={styles.tab} />
          ))}
        </Tabs>
      </Box>
      <Div sx={styles.cont}>
        {tab === 'Messages' ? <ChatsSection /> : <NotificationSection />}
      </Div>
      <div
        className="fixed bottom-0 px-8 bg-white left-0 w-full"
        style={{boxShadow: '-8px -4px 20px -5px rgba(0, 0, 0, 0.25)'}}>
        <MiniNavbar />
      </div>
    </Backdrop>
  );
};

const styles = {
  main: {
    width: '100svw',
    height: '100svh',
    zIndex: 2000,
    background: colours.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cont: {width: '100%', height: '100%'},
  inboxDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: '17px',
    width: '100%',
  },
  tab: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    textTransform: 'capitalize',
  },
  inboxTxt: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '18px',
  },
  divide: {
    background: colours.greyish,
    width: '100%',
  },
  navs: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  fullWidth: {
    width: '100%',
    borderBottom: 1,
    borderColor: 'divider',
    marginY: '10px',
    paddingX: '20px',
  },
  navText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
  },
};

export default SmallScreen;
