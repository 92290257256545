import {ApolloError} from '@apollo/client';
import {Alert, Snackbar} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

export interface Props {
  error?: Error;
  isVisible?: boolean;
  testID?: string;
  message?: string;
  onClose?: () => void;
}

export const typeGuardApolloError = (
  err?: ApolloError | Error
): err is ApolloError => Boolean((err as ApolloError)?.graphQLErrors);

const ErrorComponent = ({
  error,
  isVisible,
  testID,
  message,
  onClose,
}: Props) => {
  const [shown, setShown] = useState(
    isVisible === undefined ? !!error : isVisible
  );

  useEffect(() => {
    setShown(isVisible === undefined ? !!error : isVisible);
  }, [error, isVisible]);

  const errorMessage = useMemo(
    () => message || error?.message,
    [error?.message, message]
  );

  const handleClose = useCallback(() => {
    onClose?.();
    setShown(isVisible || false);
  }, [isVisible, onClose]);

  return (
    <Snackbar
      data-testid={testID}
      open={shown}
      autoHideDuration={6000}
      onClose={handleClose}>
      <Alert severity="error" onClose={handleClose}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorComponent;
