import {ReactNode} from 'react';

import {ReactComponent as LockIcon} from '../../../assets/images/add_display.svg';

const EmptyProductsDisplay = ({icon}: {icon?: ReactNode}) => {
  return (
    <div className="w-full h-[235px] p-[30px] bg-neutral-50 rounded-[10px] border border-neutral-200 flex-col justify-center items-center gap-5 inline-flex">
      {icon ? icon : <LockIcon />}
      <div className="flex-col justify-center items-center gap-2.5 flex">
        <p className="text-center text-neutral-800 text-2xl font-semibold">
          Add your First Product
        </p>
        <p className="text-center text-neutral-400 text-base font-normal font-['Roboto'] leading-tight">
          Add the products you would like to attach
          <br />
          to this post
        </p>
      </div>
    </div>
  );
};

export default EmptyProductsDisplay;
