import React from 'react';
import {useNavigate} from 'react-router-dom';

import ProfileLayout from './Layout';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import Footer from '../../components/common/Footer';
import Switch from '../../components/tailwind/Switch';
import {
  NotificationChannel,
  NotificationSettings,
} from '../../graphql/generated';

type NotificationChannelType = {
  name: string;
  value: boolean;
  onChange: () => void;
};

interface CustomTypographyProps {
  className?: string;
  children: React.ReactNode;
}
const settings: NotificationSettings = {
  account: {
    email: true,
    phoneCall: true,
    pushNotification: true,
    textMessage: true,
  },
  general: {
    email: true,
    phoneCall: true,
    pushNotification: true,
    textMessage: true,
  },
  promotionalMessages: {
    email: true,
    phoneCall: true,
    pushNotification: true,
    textMessage: true,
  },
  reminders: {
    email: true,
    phoneCall: true,
    pushNotification: true,
    textMessage: true,
  },
};

type NotificationSettingsType = {
  title: string;
  description: string;
  identifier: Exclude<keyof NotificationSettings, '__typename'>;
  setting?: NotificationChannel;
  channels: {
    name: string;
    key: Exclude<keyof NotificationChannel, '__typename'>;
  }[];
};

const settingsMap: NotificationSettingsType[] = [
  {
    title: 'Messages',
    identifier: 'general',
    description:
      'Receive messages from customers, users and Cashia support team.',
    channels: [
      {
        name: 'Email',
        key: 'email',
      },
      {
        name: 'Text messages',
        key: 'textMessage',
      },
      {
        name: 'Browser notifications',
        key: 'pushNotification',
      },
    ],
  },
  {
    title: 'Reminders',
    identifier: 'reminders',
    description:
      'Receive product reminders, product review updates, price change notices and other reminders related to your activities on Cashia.',
    channels: [
      {
        name: 'Email',
        key: 'email',
      },
      {
        name: 'Text messages',
        key: 'textMessage',
      },
      {
        name: 'Browser notifications',
        key: 'pushNotification',
      },
    ],
  },
  {
    title: 'Promotional messages',
    identifier: 'promotionalMessages',
    description:
      'Receive promotions, coupons, surveys and products updates from Cashia and our partners.',
    channels: [
      {
        name: 'Email',
        key: 'email',
      },
      {
        name: 'Push notifications',
        key: 'pushNotification',
      },
      {
        name: 'Text messages',
        key: 'textMessage',
      },
    ],
  },
  {
    title: 'Account',
    identifier: 'account',
    description:
      'Receive messages about your account, your orders, legal information, security and privacy changes and updates and support requests. For security, you cannot disable phone calls and text notifications, we may need to contact you by phone or other means if needed.',
    channels: [
      {
        name: 'Email',
        key: 'email',
      },
      {
        name: 'Text messages',
        key: 'textMessage',
      },
      {
        name: 'Phone calls',
        key: 'phoneCall',
      },
    ],
  },
];

const CustomTypography: React.FC<CustomTypographyProps> = ({
  className,
  children,
}) => <p className={`text-24px ${className ?? ''}`}>{children}</p>;

const NotificationChannelView = ({
  name,
  value,
  onChange,
}: NotificationChannelType) => {
  return (
    <div className="flex justify-between items-center border-b border-greyish color-darkGrey h-16">
      <p className="text-darkGrey">{name}</p>
      <Switch checked={value} onChange={onChange} />
    </div>
  );
};

const NotificationSetting = ({
  title,
  description,
  channels,
  setting,
}: Partial<NotificationSettingsType>) => {
  return (
    <div className="py-4">
      <p className="my-2 text-2xl leading-2">{title}</p>
      <p className="my-2 text-darkGrey">{description}</p>
      {channels?.map((channel, index) => {
        return (
          <NotificationChannelView
            key={index}
            name={channel.name}
            value={setting?.[channel.key] || false}
            onChange={() => undefined}
          />
        );
      })}
    </div>
  );
};

const NotificationSettingsPage = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    <a href="/profile" className="text-foggy hover:underline" key="1">
      Profile
    </a>,
    <a href="notifications" className="text-foggy hover:underline" key="2">
      Notifications
    </a>,
  ];

  return (
    <ProfileLayout>
      <div className="z-0 sm:mx-4 lg:mx-2 lg:pt-16">
        <main className="w-full flex-1 flex flex-col p-5 overflow-y-scroll no-scrollbar">
          <div className="sm:flex items-center border-b-[1px] gap-20 lg:hidden">
            <button
              className="flex py-5 items-center"
              onClick={() => navigate('/profile')}>
              <ArrowBackIcon />
            </button>
            <CustomTypography className="mr-20 mt-1  text-lg">
              Notifications
            </CustomTypography>
          </div>
          <div className="sm:hidden lg:flex items-center text-foggy">
            {breadcrumbs.map((breadcrumb, index) => (
              <React.Fragment key={index}>
                {index > 0 && <span className="mx-2">&gt;</span>}
                {breadcrumb}
              </React.Fragment>
            ))}
          </div>
          <p className="sm:hidden lg:flex my-6 lg:text-2xl sm:text-lg leading-2">
            Notifications
          </p>
          {settingsMap.map((item, index) => {
            const setting = settings[item.identifier];
            return (
              <NotificationSetting
                key={`setting-${index}`}
                channels={item.channels}
                description={item.description}
                title={item.title}
                setting={setting}
              />
            );
          })}
          <p className="my-4">
            To manage your push notification settings, use the Cashia App
          </p>
        </main>
        <div className="lg:mx-0 mt-auto mb-16 sm:hidden lg:flex flex-col">
          <Footer />
        </div>
      </div>
    </ProfileLayout>
  );
};

export default NotificationSettingsPage;
