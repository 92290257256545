import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Modal,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Div from '../../../components/common/Div';
import Select from '../../../components/tailwind/Select';
import {
  OrderBatchFieldsFragment,
  OrderFulfillment,
  OrderStatus,
} from '../../../graphql/generated';
import {colours} from '../../../utils/constants';
import formatDate from '../../../utils/formatDate';
import formatMoney from '../../../utils/formatMoney';
import {SelectOption} from '../../../utils/types';
import useWindowSize from '../../inbox/hooks/useWindowSize';

type Props = {
  open: boolean;
  openCancel?: boolean;
  loading?: boolean;
  delivered?: boolean;
  cancelReason?: string;
  setOpenCancel?: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelReason?: React.Dispatch<React.SetStateAction<string>>;
  cancelModal?: () => void;
  order: OrderBatchFieldsFragment | null | undefined;
  setOrder?: React.Dispatch<
    React.SetStateAction<OrderBatchFieldsFragment | null | undefined>
  >;
  changeStatus?: (
    cancel: boolean,
    callBack?: () => void,
    isSmallScreen?: boolean,
    courierId?: string
  ) => Promise<void>;
  selectOptions?: SelectOption[];
};

const OrderModal = ({
  open,
  openCancel,
  cancelModal,
  order,
  loading,
  delivered,
  setOpenCancel,
  cancelReason,
  setOrder,
  setCancelReason,
  changeStatus,
  selectOptions,
}: Props) => {
  const navigate = useNavigate();
  const {width} = useWindowSize();
  const [selectedCourier, setSelectedCourier] = useState<SelectOption | null>(
    null
  );

  const subFunc = () => {
    if (order?.fulfillment === OrderFulfillment.Delivery) {
      setOrder?.({...order, status: OrderStatus.InDelivery});
    } else {
      cancelModal?.();
    }
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={cancelModal}
      BackdropProps={{sx: styles.modalBackgroundBg}}>
      <Div sx={styles.modalSyles}>
        <HighlightOffIcon sx={styles.closeBtn} onClick={cancelModal} />
        {openCancel ? (
          <Div sx={styles.all100}>
            <Div sx={styles.header}>
              <ArrowBackIcon
                sx={[styles.disYes, styles.flMrg]}
                onClick={() => setOpenCancel?.(false)}
              />
              <Typography sx={styles.title}>Cancel order</Typography>
              <Div sx={[styles.disYes, styles.f1]} />
            </Div>
            <Divider />
            <Div sx={styles.cancelCont}>
              <Typography sx={styles.cancelTitle}>
                Please give a reason for this cancellation
              </Typography>
              <Typography sx={styles.cancelDes}>
                NB: Multiple cancellations might hurt your credibility with the
                Cashia community.
              </Typography>
              <Div sx={styles.cancelForm}>
                <OutlinedInput
                  sx={styles.pdTopField}
                  inputProps={{disableUnderline: true}}
                  multiline
                  rows={2}
                  type="text"
                  placeholder="Reason"
                  value={cancelReason}
                  onChange={(e) => setCancelReason?.(e.target.value)}
                />
                <Button
                  sx={styles.submit}
                  disabled={!cancelReason?.length}
                  onClick={() => {
                    void changeStatus?.(true, cancelModal, width > 900);
                  }}>
                  {loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Div>
            </Div>
          </Div>
        ) : (
          <Div sx={styles.orderCont}>
            <Div sx={styles.header}>
              <ArrowBackIcon
                sx={[styles.disYes, styles.flMrg]}
                onClick={cancelModal}
              />
              <Typography sx={styles.title}>Order details</Typography>
              <Div sx={[styles.disYes, styles.f1]} />
            </Div>
            <Divider />
            <Div sx={styles.orderDetails}>
              <Div sx={styles.divCont}>
                {order?.items.map((item) => (
                  <Div sx={styles.section} key={item?.variant?.id}>
                    <Div sx={styles.sectionMin}>
                      <Avatar
                        sx={styles.avtImg}
                        src={item?.variant?.images?.[0]?.small || ''}
                        variant="square"
                      />
                      <Div>
                        <Typography
                          id="modal-modal-description"
                          sx={styles.mdProTitle}>
                          {item.variant.product?.title}
                        </Typography>
                        <Typography sx={[styles.greyedText, styles.pdTopGrey]}>
                          KES{' '}
                          {item?.variant?.price?.amountInCents &&
                            formatMoney(item.variant.price, 2)}
                        </Typography>
                      </Div>
                    </Div>
                    <Typography sx={[styles.mdProTitle, styles.disNone]}>
                      Quantity:
                      <span style={styles.red}>{` ${item.count} items`}</span>
                    </Typography>
                  </Div>
                ))}
              </Div>
              <Divider />
              <Div sx={[styles.section, styles.pdRight]}>
                <Div sx={styles.sectionMin2}>
                  <Div sx={styles.buyerAvt}>
                    <Avatar
                      sx={styles.avt50}
                      src={order?.buyer?.avatar?.small || ''}
                      variant="circular"
                    />
                  </Div>
                  <Div sx={styles.f5}>
                    <Typography sx={[styles.greyedText, styles.pdBot]}>
                      Order placed by
                    </Typography>
                    <Typography sx={[styles.mdProTitle, styles.ln]}>
                      {order?.buyer.user?.name?.substring(0, 25) || 'unknown'}
                    </Typography>
                    <Typography sx={[styles.greyedText, styles.pdTopGrey]}>
                      {order?.buyer?.currentAddress?.location.formattedAddress}
                    </Typography>
                  </Div>
                </Div>
                <Tooltip title="Inbox">
                  <Div sx={[styles.msgBtn, styles.disNone]}>Message</Div>
                </Tooltip>
              </Div>
              <Divider />
              {delivered || (
                <>
                  <Div sx={styles.section}>
                    <Div sx={styles.f3}>
                      <Typography sx={[styles.greyedText, styles.pdBot]}>
                        Time order was placed:
                      </Typography>
                      <Div sx={[styles.sectionMin, styles.gap]}>
                        <AccessTimeIcon />
                        <Typography sx={[styles.mdProTitle, styles.lnFont]}>
                          {order?.createdAt &&
                            formatDate(order?.createdAt as Date, {
                              withWeekday: true,
                              withMonth: true,
                              withYear: true,
                              withTime: true,
                              hour12: true,
                            })}
                        </Typography>
                      </Div>
                    </Div>
                    <Div sx={[styles.fulfillmentCont, styles.disNone]}>
                      <Typography sx={[styles.greyedText, styles.pdBot]}>
                        Fulfillment by:
                      </Typography>
                      <Div sx={[styles.sectionMin, styles.gap]}>
                        {order?.fulfillment === OrderFulfillment.Delivery ? (
                          <DeliveryDiningIcon />
                        ) : (
                          <DirectionsWalkIcon />
                        )}
                        <Typography sx={[styles.mdProTitle, styles.lnFont2]}>
                          {order?.fulfillment.toLocaleLowerCase()}
                        </Typography>
                      </Div>
                    </Div>
                  </Div>
                  <Tooltip title="Inbox">
                    <Div
                      sx={[styles.msgBtn, styles.disYes, styles.mgBtm20]}
                      onClick={() => navigate('/inbox')}>
                      Message
                    </Div>
                  </Tooltip>
                  <Divider />
                  <Div sx={styles.section}>
                    <Div sx={styles.f3}>
                      <Typography sx={[styles.greyedText, styles.pdBot]}>
                        Assign a courier:
                      </Typography>
                      <Div sx={[styles.sectionMin, styles.gap]}>
                        <Select
                          options={selectOptions}
                          placeholder="Select courier"
                          dropdownBottomOffset={140}
                          onChange={setSelectedCourier}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Divider />
                  {order?.status == OrderStatus.New ? (
                    <Div sx={[styles.section, styles.justFlex]}>
                      <Div sx={styles.flex50}>
                        <Tooltip title="Confirm">
                          <Div
                            sx={styles.btnConfirm}
                            onClick={() => {
                              void changeStatus?.(
                                false,
                                undefined,
                                undefined,
                                selectedCourier?.value
                              );
                              cancelModal?.();
                            }}>
                            <CheckCircleIcon sx={styles.actionBtn} />
                            <Typography>Confirm</Typography>
                          </Div>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <Div
                            sx={styles.btnCancel}
                            onClick={() => setOpenCancel?.(true)}>
                            <CancelIcon sx={styles.actionBtn} />
                            <Typography>Cancel</Typography>
                          </Div>
                        </Tooltip>
                      </Div>
                      {loading ? (
                        <Div sx={[styles.flex60, styles.flexCenter]}>
                          <CircularProgress color="primary" />
                        </Div>
                      ) : (
                        <Div sx={styles.flex60}>
                          <Button
                            sx={[
                              styles.f1h50,
                              styles.msgBtnRed,
                              styles.msgBtnFull,
                            ]}
                            onClick={() => {
                              void changeStatus?.(false, subFunc);
                            }}>
                            Confirm Order
                          </Button>
                          <Button
                            sx={[styles.f1h50, styles.msgBtnRed]}
                            onClick={() => setOpenCancel?.(true)}>
                            Cancel Order
                          </Button>
                        </Div>
                      )}
                    </Div>
                  ) : (
                    <Div sx={styles.track}>
                      <Div sx={[styles.scheduled, styles.disYes]}>
                        <Div sx={styles.mbottom10}>
                          <Typography sx={styles.greyedText}>
                            Scheduled for
                          </Typography>
                          <Div sx={styles.disGap}>
                            <CalendarMonthIcon />
                            <Typography sx={[styles.mdProTitle, styles.lnFont]}>
                              -
                            </Typography>
                          </Div>
                        </Div>
                        <Divider />
                      </Div>
                      <Typography sx={[styles.mdProTitle, styles.mgBtm20]}>
                        A rider has been dispatched to pick up the item
                      </Typography>
                      <Div
                        sx={[
                          styles.sectionMinTrackOrder,
                          styles.trackish,
                          styles.disNone,
                        ]}>
                        <LocationOnIcon />
                        <Typography
                          sx={{cursor: 'pointer'}}
                          onClick={() => {
                            if (order?.id) {
                              navigate(`/orders/tracker/${order?.id}`);
                            }
                          }}>
                          Track rider
                        </Typography>
                      </Div>
                      <Div sx={styles.flex60}>
                        <Button sx={styles.msgBtnBlue}>
                          <LocationOnIcon />
                          <Typography
                            sx={{cursor: 'pointer'}}
                            onClick={() => {
                              if (order?.id) {
                                navigate(`/orders/tracker/${order?.id}`);
                              }
                            }}>
                            Track rider
                          </Typography>
                        </Button>
                        <Button sx={[styles.msgBtnRed, styles.msgBtnFullRed]}>
                          Call support
                        </Button>
                      </Div>
                    </Div>
                  )}
                </>
              )}
              {delivered && (
                <Div sx={[styles.deliverOn, styles.disYes]}>
                  <Div sx={styles.mY15}>
                    <Typography sx={styles.greyedText}>
                      Delivered on:
                    </Typography>
                    <Div sx={styles.disGap}>
                      <CalendarMonthIcon />
                      <Typography sx={[styles.mdProTitle, styles.lnFont]}>
                        -
                      </Typography>
                    </Div>
                  </Div>
                  <Divider />
                </Div>
              )}
            </Div>
          </Div>
        )}
      </Div>
    </Modal>
  );
};

const styles = {
  modalSyles: {
    position: 'absolute',
    top: {md: '50%', xs: 0},
    left: {md: '50%', xs: 0},
    border: 'none',
    transform: {md: 'translate(-50%, -50%)', xs: 'translate(0, 0)'},
    width: {md: '600px', xs: '100%'},
    height: {md: 'inherit', xs: '100%'},
    bgcolor: colours.white,
    borderRadius: {md: '10px', xs: '0px'},
    boxShadow: 24,
    p: {md: 4, xs: 2},
    overflow: 'hidden',
  },
  orderDetails: {
    maxHeight: {md: 'inherit', xs: 'calc(100svh - 65px)'},
    overflowY: {md: 'hidden', xs: 'scroll'},
  },
  all100: {width: '100%', height: '100%'},
  mbottom10: {marginBottom: '10px'},
  deliverOn: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  flMrg: {flex: 1, marginLeft: '-15px', cursor: 'pointer'},
  header: {
    display: 'flex',
    justifyContent: {md: 'flex-start', xs: 'space-between'},
    width: '100%',
  },
  orderCont: {width: '100%', marginBottom: {md: '0px', xs: '50px'}},
  cancelCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: {md: '0px', xs: '20px'},
  },
  f1: {flex: 1},
  f3: {flex: 3},
  mY15: {marginY: '15px'},
  scheduled: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  f5: {flex: 5},
  buyerAvt: {flex: 1, marginRight: {md: '0px', xs: '17px'}},
  cancelForm: {
    display: 'flex',
    flexDirection: 'column',
    height: {md: 'inherit', xs: 'calc(100svh - 225px)'},
    justifyContent: 'space-between',
  },
  flexCenter: {justifyContent: 'center', alignItems: 'center'},
  fulfillmentCont: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  f1h50: {flex: 1, height: '50px'},
  title: {
    flex: 5,
    textAlign: {md: 'left', xs: 'center'},
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '20px',
    marginBottom: '20px',
  },
  mgBtm20: {marginBottom: '20px'},
  disNone: {display: {md: 'flex', xs: 'none'}},
  disYes: {display: {md: 'none', xs: 'flex'}},
  avt50: {width: 60, height: 60, marginRight: {md: '20px', xs: '15px'}},
  pdTop: {marginTop: '15px', width: {md: '100%', xs: '94%'}},
  pdTopField: {
    marginTop: '15px',
    width: {md: '100%', xs: '88%'},
    marginBottom: {md: '15px', xs: '0px'},
    border: 'none',
    outline: 'none',
    background: colours.lightenedGrey,
    padding: '9px',
    height: '70px',
    borderRadius: '10px',
  },
  red: {color: colours.red, marginLeft: '5px'},
  flex50: {display: {md: 'flex', xs: 'none'}, gap: '50px'},
  flex60: {
    display: {md: 'none', xs: 'flex'},
    width: '100%',
    gap: '15px',
    marginRight: '25px',
  },
  modalBackgroundBg: {backgroundColor: colours.modalPurpleBackDropBg},
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    color: 'balck',
    cursor: 'pointer',
    display: {md: 'flex', xs: 'none'},
  },
  divCont: {
    maxHeight: {md: '350px', xs: 'inherit'},
    overflowY: {md: 'scroll', xs: 'hidden'},
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
  },
  avtImg: {
    width: {md: 80, xs: 100},
    height: {md: 80, xs: 100},
    borderRadius: '10px',
  },
  disGap: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginY: '10px',
    marginBottom: '15px',
  },
  actionBtn: {
    marginRight: '7px',
    cursor: 'pointer',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: '18px',
  },
  track: {
    display: 'flex',
    flexDirection: {md: 'row', xs: 'column'},
    alignItems: {md: 'center', xs: 'flex-start'},
    justifyContent: 'space-between',
    paddingY: '20px',
  },
  sectionMin: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  sectionMinTrackOrder: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginTop: -3,
  },
  sectionMin2: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {md: 'row', xs: 'row-reverse'},
    width: '100%',
  },
  mdProTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
  },
  greyedText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '13px',
    color: colours.foggy,
  },
  msgBtn: {
    border: `2px solid ${colours.cashiaBlue}`,
    borderRadius: '4px',
    color: colours.cashiaBlue,
    paddingX: '15px',
    paddingY: '10px',
    cursor: 'pointer',
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
  msgBtnRed: {
    border: `2px solid ${colours.smoothRed}`,
    borderRadius: '10px',
    color: colours.smoothRed,
    paddingX: '15px',
    paddingY: '10px',
    cursor: 'pointer',
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
  msgBtnBlue: {
    border: `2px solid ${colours.cashiaBlue}`,
    color: colours.cashiaBlue,
    paddingX: '15px',
    paddingY: '10px',
    cursor: 'pointer',
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    height: '50px',
    borderRadius: '10px',
  },
  msgBtnFull: {
    background: colours.cashiaBlue,
    color: colours.absoluteWhite,
    border: 'none',
    '&:hover': {
      background: colours.cashiaBlue,
      color: colours.absoluteWhite,
    },
  },
  msgBtnFullRed: {
    background: colours.smoothRed,
    flex: 1,
    height: '50px',
    color: colours.absoluteWhite,
    marginRight: '25px',
    border: 'none',
    '&:hover': {
      background: colours.smoothRed,
      color: colours.absoluteWhite,
    },
  },
  btnConfirm: {
    display: 'flex',
    color: colours.grenish,
    cursor: 'pointer',
  },
  btnCancel: {
    display: 'flex',
    color: colours.smoothRed,
    cursor: 'pointer',
  },
  cancelTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '30px',
    color: colours.black,
    paddingTop: '15px',
    marginTop: {md: '0px', xs: '30px'},
    width: {md: 'inherit', xs: '92%'},
  },
  cancelDes: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: colours.darkGrey,
    paddingTop: '15px',
    paddingBottom: '10px',
    width: {md: 'inherit', xs: '92%'},
  },
  submit: {
    backgroundColor: colours.cashiaBlue,
    color: colours.white,
    marginTop: '15px',
    padding: '15px',
    borderRadius: '10px',
    marginRight: {md: '0px', xs: '30px'},
    marginBottom: {md: '0px', xs: '20px'},
    width: {md: '180px', xs: '92%'},
    alignSelf: {md: 'flex-end', xs: 'flex-start'},
    '&:hover': {backgroundColor: colours.cashiaBlue, color: colours.white},
    '&:disabled': {backgroundColor: colours.greyish, cursor: 'not-allowed'},
  },
  pdTopGrey: {paddingTop: '5px'},
  pdRight: {paddingRight: {md: '60px', xs: '0px'}},
  pdBot: {paddingBottom: '13px'},
  ln: {lineHeight: '20px'},
  gap: {gap: '8px'},
  lnFont: {
    lineHeight: '18px',
    fontSize: '14px',
  },
  lnFont2: {
    lineHeight: '18px',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  justFlex: {
    justifyContent: {md: 'flex-end', xs: 'flex-start'},
  },
  trackish: {
    gap: '8px',
    color: colours.cashiaBlue,
  },
} as const;

export default OrderModal;
