import React, {useMemo, useState} from 'react';

import Button from '../../../components/tailwind/Button';
import Checkbox from '../../../components/tailwind/Checkbox';
import Spinner from '../../../components/tailwind/Spinner';
import {
  MerchantOnboardingInput,
  OperatingTimeInput,
  TimeInterval,
} from '../../../graphql/generated';
import {days} from '../../../utils/constants';
import {
  cn,
  convertTo12HourFormat,
  getOperatingHoursTimeGroups,
  removeOrAddElementInArray,
} from '../../../utils/reusableFunctions';
import Footer from '../components/Footer';
import {useSelfOnboardingContentContext} from '../layout';

type NewOperatingHours = {
  closingHours: string;
  openingHours: string;
  daysIndex: number[];
  openDaysDropdown: boolean;
};

type OperatingTime = {
  [key: string]: TimeInterval;
};

const defaultNewOperatingHours = {
  closingHours: '',
  openingHours: '',
  daysIndex: [],
  openDaysDropdown: false,
};

const SelectOperatingHours = () => {
  const {merchant, update, updateLoading} = useSelfOnboardingContentContext();
  const [newOperatingHours, setnewOperatingHours] = useState<NewOperatingHours>(
    defaultNewOperatingHours
  );

  const merchantOnboardingInput = useMemo(() => {
    const operatingTime: OperatingTime = {};
    newOperatingHours.daysIndex.forEach((dayIndex) => {
      operatingTime[days[dayIndex]?.key as keyof OperatingTimeInput] = {
        from: newOperatingHours.openingHours,
        to: newOperatingHours.closingHours,
      };
    });
    const input: MerchantOnboardingInput = {
      operatingTime,
    };
    return input;
  }, [newOperatingHours]);

  const resetNewOperatingHours = () =>
    setnewOperatingHours(defaultNewOperatingHours);

  const updateMerchant = async () =>
    await update?.(merchantOnboardingInput, true, resetNewOperatingHours);

  const cards = useMemo(
    () => getOperatingHoursTimeGroups(merchant?.operatingTime || {}),
    [merchant?.operatingTime]
  );

  const sortNumbersAscending = (numbers: number[]): number[] => {
    return numbers.slice().sort((a, b) => a - b);
  };

  const onDaysChange = (day: number) =>
    setnewOperatingHours((prev) => ({
      ...prev,
      daysIndex: sortNumbersAscending(
        removeOrAddElementInArray([...prev.daysIndex], day)
      ),
    }));

  const shortDays = (list: number[]): string => {
    return list.map((item) => days[item]?.display).join(', ');
  };

  const checkValidity = !(
    !newOperatingHours.closingHours ||
    !newOperatingHours.openingHours ||
    !newOperatingHours.daysIndex.length
  );

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 sm:p-5 w-full flex-1 flex flex-col gap-7 overflow-y-scroll no-scrollbar">
        <h1 className="text-neutral-800 text-xl md:text-xl md:text-[34px] font-semibold">
          Selected time
        </h1>
        <div className="flex gap-7 flex-wrap">
          {cards?.map((card, i) => (
            <div
              key={i}
              className={cn(
                'sm:w-full md:w-[350px] px-5 pb-5 pt-3 flex flex-col gap-5 bg-neutral-50 rounded shadow-md border border-zinc-300',
                {'animate-pulse': updateLoading}
              )}>
              <div className="flex gap-5">
                {card.days.map((day) => (
                  <p
                    key={day.display}
                    className="text-cyan-500 text-[13px] font-normal font-['Metropolis']">
                    {day.display}
                  </p>
                ))}
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col gap-2">
                  <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                    Opening hours
                  </p>
                  <p className="text-neutral-800 text-2xl font-normal font-['Metropolis']">
                    {convertTo12HourFormat(card.openingHours || '')}
                  </p>
                </div>
                <div className="w-0.5 h-full bg-zinc-200 rounded-[10px]" />
                <div className="flex flex-col gap-2">
                  <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                    Closing hours
                  </p>
                  <p className="text-neutral-800 text-2xl font-normal font-['Metropolis']">
                    {convertTo12HourFormat(card.closingHours || '')}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <h2 className="text-neutral-500 md:text-neutral-800 text-bas md:text-xl font-semibold">
            Add the hours of delivery for your store
          </h2>
          <div className="w-full flex gap-4 md:gap-0 flex-wrap bg-zinc-100 rounded-[10px] shadow border border-stone-300 mt-5 py-[10px] px-5">
            <label htmlFor="opHours" className="flex-1 pr-5 cursor-pointer">
              <p className="text-neutral-800 text-[13px] font-normal">
                Opening hours
              </p>
              <input
                type="time"
                id="opHours"
                value={newOperatingHours.openingHours}
                onFocus={(e) => e.target.showPicker()}
                className="w-full bg-transparent focus:outline-none py-1 cursor-pointer"
                onChange={(e) =>
                  setnewOperatingHours((prev) => ({
                    ...prev,
                    openingHours: e.target.value,
                  }))
                }
              />
            </label>
            <label
              htmlFor="clHours"
              className="flex-1 border-l border-stone-300 px-5 cursor-pointer">
              <p className="text-neutral-800 text-[13px] font-normal">
                Closing hours
              </p>
              <input
                type="time"
                id="clHours"
                value={newOperatingHours.closingHours}
                onFocus={(e) => e.target.showPicker()}
                className="w-full bg-zinc-100 focus:outline-none py-1 cursor-pointer"
                onChange={(e) =>
                  setnewOperatingHours((prev) => ({
                    ...prev,
                    closingHours: e.target.value,
                  }))
                }
              />
            </label>
            <div
              className="flex-1 border-x border-stone-300 px-5 cursor-pointer select-none"
              onClick={() =>
                setnewOperatingHours((prev) => ({
                  ...prev,
                  openDaysDropdown: !prev.openDaysDropdown,
                }))
              }>
              <p className="text-neutral-800 text-[13px] font-normal">Days</p>
              <p className="text-neutral-500 text-base font-normal py-1">
                {newOperatingHours.daysIndex.length
                  ? shortDays(newOperatingHours.daysIndex)
                  : 'Select days'}
              </p>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <Button
                label={
                  updateLoading ? (
                    <Spinner fillColor="fill-white" width={36.5} />
                  ) : (
                    <div className="text-center text-neutral-50 text-base font-normal">
                      Add +
                    </div>
                  )
                }
                fullWidth
                className={`w-[80%] py-3 h-full rounded-[10px] ${
                  checkValidity ? 'bg-cashiaBlue' : 'bg-greyish'
                }`}
                disabled={!checkValidity}
                onClick={() => checkValidity && void updateMerchant()}
              />
            </div>
          </div>
          {newOperatingHours.openDaysDropdown && (
            <div className="w-full bg-neutral-50 rounded-[10px] shadow mt-5">
              {days.map((day, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center p-5 border-b border-neutral-200 cursor-pointer transition duration-200 select-none hover:bg-slate-100"
                  onClick={() => onDaysChange(i)}>
                  <p className="text-neutral-800 text-[14px] md:text-base font-normal leading-tight">
                    {day.title}
                  </p>
                  <Checkbox
                    accentSmoothRed
                    checked={newOperatingHours.daysIndex.includes(i)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer className="flex-5" next="/thanks" disabled={!cards?.length} />
    </div>
  );
};

export default SelectOperatingHours;
