import {ReelsProductFieldsFragment} from '../../../graphql/generated';
import formatMoney from '../../../utils/formatMoney';
import {cn} from '../../../utils/reusableFunctions';

type Props = {
  products?: ReelsProductFieldsFragment[];
  onClickAction?: (product: ReelsProductFieldsFragment) => void;
  remove?: boolean;
  omitActions?: boolean;
};

const titles = ['Product', 'Price (kes)', 'Items in stock'];

const defaultVariant = (
  product: ReelsProductFieldsFragment | undefined | null
) => product?.variants?.find((v) => v.default);

const ProductsDisplay = ({
  products,
  onClickAction,
  remove,
  omitActions,
}: Props) => {
  return (
    <div className="w-full rounded-[10px] border border-neutral-200 flex-col flex">
      <div className="w-full h-20 p-[30px] bg-neutral-100 justify-start items-center flex">
        {titles.map((name) => (
          <p
            key={name}
            className="flex-1 text-neutral-800 text-lg font-semibold uppercase leading-tight truncate">
            {name}
          </p>
        ))}
        {!omitActions && <div className="flex-2 w-[135px] h-[38px]" />}
      </div>
      {products?.map((product) => (
        <div
          key={product.id}
          className="w-full h-[68px] px-[30px] py-[15px] border-b border-neutral-200 justify-start items-center flex">
          <div className="flex flex-1 gap-3 items-center">
            <img
              className="w-[58.48px] h-[35px] rounded object-cover"
              src={defaultVariant(product)?.images?.[0]?.small || ''}
            />
            <p className="text-neutral-800 text-base font-medium leading-tight truncate">
              {product.title || '---'}
            </p>
          </div>
          <p className="flex-1 text-neutral-800 text-base font-medium leading-tight truncate">
            KES {formatMoney(defaultVariant(product)?.price || 0, 2)}
          </p>
          <p className="flex-1 text-neutral-800 text-base font-medium leading-tight truncate">
            In stock
          </p>
          {!omitActions && (
            <button
              className={cn(
                'flex-2 w-[135px] h-[38px] px-2.5 py-[11px] bg-cashiaBlue rounded-[10px] border border-cashiaBlue justify-center items-center gap-2.5 flex',
                {'bg-red-600 border-red-600': remove}
              )}
              onClick={() => onClickAction?.(product)}>
              <p className="text-center text-neutral-50 text-sm font-medium truncate">
                {remove ? 'Remove item' : 'Add to Display'}
              </p>
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductsDisplay;
