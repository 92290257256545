const TermsAndConditions = [
  {
    id: 'tab_item_0',
    title: '',
    content: [
      'This is a legally binding agreement (the “Agreement”) between Kashia Services Limited (hereinafter referred to as “Cashia”) , its Distributors and the Merchant Client (each one referred to hereinafter as the “Party” and together the “Parties”), setting out the relationship between the Parties. Please read carefully before signing and accepting the terms and conditions of this Agreement. Registration, activation or use of the Cashia Merchant Wallet is construed as you having accepted the Cashia Merchant Agreement as well as all policies of Kashia Services Limited, Cyber Security Data Protection Policies and Cash terms and conditions (hereinafter “Complementary Documents”), as those may be found on the Cashia Website (www.Cashia.com), and as those may be amended from time to time. In order to use any of the Cashia services, a Cashia account must be opened in your name with Kashia Services Limited.',
      'By accepting the terms and conditions of this Agreement, you declare that you are the authorized representative of the Merchant and that with your signature (or acceptance if online), the Merchant acknowledges that the terms and conditions and Complementary Documents were read and understood (or have sought legal advice on them) and agrees to be bound by them. As acceptance of the terms and conditions and Complementary Documents will also be considered any use of one or more of the services described below. If the Merchant does not agree with the terms and conditions of this Agreement or of the Complementary documents, you should not make use of any of the services.',
    ],
  },
  {
    id: 'tab_item_1',
    title: 'Introduction',
    content: [
      'This Merchant Agreement along with the Complementary Documents (together the “Agreement”) form the relationship between the Merchant (“Client”, “You”, “Your”) and its agents and/or authorized representatives, and Cashia and its Distributors. Making use of any of the Cashia services constitutes acceptance of the terms and conditions of the Agreement, and for Cashia to establish an account for you. You further agree that the Agreement (and any update thereof) will also cover your use of the account to add services to your service plan, to modify or to cancel your service plan. Even in the absence of any notification to Kashia Services Limited, the Agreement is also applicable to any other person (legal or natural) you grant permission to use your account.',
      'You confirm that the person (or persons) designated by you as your authorized representative(s) has/have been granted full authority to act on your behalf in relation to the Cashia services (and as this may be modified from time to time), and that any such actions taken by him/her/them including but not limited to purchase of additional services, modification of the service plan, termination, or transfer of the service plan, would be considered binding and final.',
      'Kashia Services Limited’s headquarters are located in Nairobi, Kenya  jurisdiction of main operations and where the performance of the services should be deemed to take place. Unless through an authorized agent, acceptance of this Agreement by any method available to you would be considered as if to have taken place at Kashia Services Limited’s premises in Kenya.',
      'All paragraphs below apply to any (and all) of the services that you purchase under this Agreement.',
      'In this Agreement, except where the context otherwise requires, the terms shall have the meaning given to them in the Definitions section.',
      'Any term used in this Agreement and not otherwise interpreted, shall have the meaning attributed thereto in the Law and/or any Directive.',
      'Headings of the paragraphs shall be used solely for ease of reference and shall not affect the contents and interpretation of the Agreement.',
      'Unless the context otherwise requires, reference to persons shall also include legal persons, the singular shall include the plural and vice-versa and either gender shall include the other.',
      'Reference to any agreement (including without limitation, this Agreement) or to any other document, shall be deemed to include references to them as these may from time to time be amended, expanded or replaced and to all agreements and documents which are declared to be supplementary to them or are attached thereto.',
      'The Client wants to make use of the Services provided by Kashia Services Limited, having completed for this purpose all the relevant account opening steps as described in this Agreement. Cashia may require you to have an Cashia Account to use the Services (including, without limitation, to send or receive payments).',
      'This Agreement incorporates references to the Cyber Security Data Protection Policies and Cash terms and conditions (the “Policies”) that include terms and conditions relevant to the use of the Service. These references make the Policies an integral part of the Agreement. The Policies are “Complementary Documents” and neither these documents in their entirety or any references to their terms and conditions in this Agreement should not be construed as “Framework Contracts” for the purpose of the National Payment Systems Regulations 2011.',
      'This Agreement, together with other legal terms and legally required disclosures relating to your use of the Service will be provided to you, at all times on the Cashia website. This information may also be sent to you or appear in places on the Cashia website or otherwise where relevant to your use of the Services. Any changes/updates to this Agreement and the Policies are published in the Cashia website and the date of their effective implementation will be as stated in each specific update. Certain regulatory conditions apply.',
      'By registering for the Services, you must read, agree with and accept all of the terms and conditions contained in this Agreement. This Agreement is provided to you and concluded in English. Cashia recommend that you store electronically by downloading or print a copy of the Agreement (including the Policies) for your records. Irrespective of the aforementioned, you agree that any use by you of the Services shall constitute your acceptance of the Agreement.',
      'In this Agreement, except where the context otherwise requires, the terms shall have the meaning given to them in the Definitions section.',
      'Any term used in this Agreement and not otherwise interpreted, shall have the meaning attributed thereto in the Law and/or any Directive.',
      'Headings of the paragraphs shall be used solely for ease of reference and shall not affect the contents and interpretation of the Agreement.',
      'Unless the context otherwise requires, reference to persons shall also include legal persons, the singular shall include the plural and vice-versa and either gender shall include the other.',
      'Reference to any agreement (including without limitation, this Agreement) or to any other document, shall be deemed to include references to them as these may from time to time be amended, expanded or replaced and to all agreements and documents which are declared to be supplementary to them or are attached thereto.',
    ],
  },
  {
    id: 'tab_item_2',
    title: 'Definitions',
    content: [
      '“Account” or “Cashia Account” or “Cashia Merchant eWallet” means a Business web-based e-money payments account opened and maintained by Cashia in Your name and which is used for the execution of all transactions with tis respective debiting or crediting.',
      '“Agreement” means this agreement including all subsequent amendments and any additional documentation that accompanies it and to which Clients should adhere to, including Privacy Policy, Refund Policy, Acceptable Use Policy',
      '“Balance” means any e-money that you have in your Cashia Account.',
      '“Working or Work Days” means a day (other than a Saturday, Sunday or public/bank holiday) on which supporting banks in Kenya or other jurisdictions where the Services are provided are open for business (other than for the sole purpose of 24-hour electronic banking).',
      '“Calendar year” means 1 January to 31 December inclusive in any year.',
      '“Chargeback” means a demand by a credit-card issuer for restitution of the loss on a fraudulent or disputed transaction by the Merchant. Details on the Refund Policy may be found at online at www.Cashia.com',
      '“Claim” means a challenge to a payment that a sender of a payment files directly with Kashia Services Limited.',
      '“Corporate Account” or “Business Account” means a legal entity account.',
      '“Cross Border” means the ability to offer the Cashia services as those are provided by Cashia across the borders of their home state.',
      '“Customer Service” is the customer support which can be accessed online by sending an email at support@Cashia.com ',
      '“Dispute” means a dispute filed directly with Kashia Services Limited.',
      '“Electronic Money” or “E-money” means electronically, including magnetically, stored monetary value as represented by a claim on Kashia Services Limited, which is issued on receipt of funds for the purpose of making payment transactions and which is accepted by a natural or legal person other than the issuer of electronic money. The terms “E-money”, “money” and “funds” are used interchangeably in this Agreement “Fees” means any Fees, tariffs and charges that may be charged by Cashia to You and which are agreed between you and Cashia in writing.',
      '“Funding Source” means the payment method used to fund a Topping Up or a Withdrawal transaction as indicated in the Agreement.',
      '“Information” means any confidential and/or personally identifiable information or other information related to an Account or Merchant, including but not limited to the following: name, email address, VAT, post/shipping address, phone number, financial information or information related to Know-Your-Business procedures.',
      '“Payment Service User” (“PSU”) means any verified, registered individual who holds an active Cashia eWallet on his/her name with Kashia Services Limited, may use the Cashia services and transact with Merchant.',
      '“Merchant Processing Delay” means a delay between the time Payment Service User authorizes and Cashia execute a payment and You process your payment.',
      '“Payment Order” means a valid instruction by the Merchant to Cashia requesting the execution of a Transaction.',
      '“Payment Review” means the process described in section 5 and 6 of this Agreement.',
      '“Cashia website(s)” means any URL, such as www.Cashiawallet.eu, that Cashia use to provide the Services to you.',
      '“Policy” or “Policies” means any Policy or other agreement between you and Cashia that you entered into on the Cashia website(s), or in connection with your use of the Services',
      '“Policy Update” means changes in the Cashia Policies for which you will be notified and may be made available to you in writing or through the Cashia website(s).',
      '“Restricted Activities” means those activities described in section 11 of this Agreement.',
      '“Services” means all products, services, content, features, technologies or functions offered by Cashia and all related sites, applications, and services.',
      '“Third Party eWallet” means a Web-based electronic money (e-money) account opened and maintained by Cashia in the name of the third party, including a PSU.',
      '“Transaction” means the transfer of e-money from Your eWallet to a Third Party eWallet or from a Third Party eWallet to Your eWallet.',
      '“Topping Up transaction” means the transaction that aims to the issuance of electronic money from Cashia with the payment to Cashia of equal nominal value amount through approved Funding Sources and the subsequent crediting of the said amount to the Cashia Account. For any Topping Up Transaction to take place, all Funding Sources (credit cards, debit cards, bank accounts) have to be verified as having you the sole beneficiary.',
      '“Withdrawal transaction” or “Redemption transaction” means any transaction initiated and/or executed by the User with the aim to redeem part or the whole Balance available in the Cashia Account through the receipt of equal nominal value amount of money and the respective decrease of the Balance. For any Withdrawal Transaction to take place, all Funding Sources (credit cards, debit cards, bank accounts) have to be verified as having you the sole beneficiary.',
      '“Receiving Payment Transaction”: means any transaction executed through the payments account of Cashia under which the Cashia Account of the User as beneficiary of the payment, is credited with electronic money of equal monetary value with the amount of the corresponding payment order by the payer to the User for any lawful cause (with the supporting documentation), settled as to the transaction’s fees.',
      '“Payment Transaction”: means any transaction executed through the payments account of Cashia under which the User, as payer, pays to third party/payee, irrespective of underlying cause, the requested amount, while equally debiting the Balance of his/her Cashia Account.',
      '“Unauthorized Transaction” and “Unauthorized Account Access” has the meaning assigned to them in section 14 of this Agreement.',
      '“Territory” or “Territories” means the countries where the Services are offered.',
      '“Client”, “Merchant”, “you” or “your” means you and any other person or entity entering into this Agreement with Cashia or using the Service on your behalf as retailer with physical or online shop, who is selling goods and/or services and is using the services to receive payment through Cashia eWallet, in whose name the Cashia Merchant eWallet is registered. Natural persons must be above 18 years old to use the Services.',
      '“Verified” means that the Merchant has completed Kashia Services Limited’s and its Distributors’ verification process (KYB) by which confirmation of the Merchant identity has been established.',
      '“Verified Bank Account” means the active bank account held in the name of the User in a recognized credit institution, and of which the User has been identified as sole legal beneficiary.',
      '“Verified Card” means the active prepaid, credit and/or debit card, issued by recognized financial entity in cooperation with cards schemes (indicatively VISA and Mastercard) of which the User has been identified as sole legal beneficiary.',
      '“Kashia Services Limited” means Cashia  a company which is a licensed E.M.I. with license number ……… under the supervision of Central Bank of Kenya, with registered head office at 4 Gallant Mall, sixth floor, Nairobi, Kenya and approved to provide electronic money services under the freedom of provision of services within the EEA countries under the Electronic Money Directives 110/2009 and 2366/2015.',
    ],
  },
  {
    id: 'tab_item_3',
    title: 'Provision of Services',
    content: [
      'Cashia is a brand under which the Services are offered.',
      'Kashia Services Limited’s main business is the provision of services closely related to the issuance, distribution and redemption of E-money. A description of the main characteristics of the Services may be found within this Agreement as well as via the Cashia website. In order to use the Services, a Cashia account must be opened in Your name with Kashia Services Limited.',
      'Cashia enable you to make payments to and accept payments from third parties. It also allows you to maintain balances which can neither be deemed as deposits nor investment service under the definition of the Law and are therefore not afforded any protection under the deposit protection scheme or the investor compensation fund scheme that is provided for in Kenya. The Client balances are prohibited from earning any interest as they are not deemed as deposits.',
      'Cashia have no control over the products or services that are paid for with the Service, as it only acts as an independent intermediary. The lack of control denotes that Cashia cannot confirm the legality and do not assume the responsibility and potential liability stemming from the legality of the products or services that are paid for with its Service.',
      'Further, Cashia cannot guarantee the identity of the user nor ensure that the transaction between the transacting parties will be successfully completed. There are therefore inherent risks that come with the inability to confirm identity especially when dealing with people who act under false pretenses. Such inability could lead to the use of the Service by persons under the age of 18 (underage persons or minors), despite Kashia Services Limited’s and its Distributors’ requirement that an eligible user be at least 18 years of age. It could lead to potential dealings with minors that are risky.',
      'Protecting your privacy is very important to Cashia and its Distributors. Please review the Cashia Privacy Policy to understand how Cashia aim to protect your privacy and how Cashia intent to use your information.',
      'Cashia is a registered tradename and for which Cashia have granted rights for use for the provision of the Services. The Cashia brand(s), Cashia website(s) URL(s), logos relating to the name, products and services described/used in/on electronic and hard copy material are either copyrighted, or are registered trademarks, or are trademarks owned and/or lawfully licensed to Kashia Services Limited, or its licensors or its Distributors. In addition, all page headers, custom graphics, button icons, and scripts are copyrighted service marks, trademarks belonging to Cashia or its licensors or its Distributors. Copying, modification, alteration of any kind, amendment or use of any kind is strictly prohibited unless Cashia and its licensors consent to such action.',
      'Cashia may appoint one or more Agents or Distributors. While the Agents and Distributors may distribute the eWallet on Kashia Services Limited’s behalf, they do not act as Kashia Services Limited’s Agent/Distributor in the issuing of e-money or the carrying out of any Transaction.',
      'Posting of Content: When providing Cashia with content or posting content (in each case for publication, whether on- or off-line) using the Services, you grant Cashia a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, right to exercise any and all copyright, publicity, trademarks, database rights and intellectual property rights you have in the content, in any media known now or in the future. Further, to the fullest extent permitted under applicable law, you waive your moral rights and promise not to assert such rights against Cashia or assignees. You represent and warrant that none of the following infringe any intellectual property right:',
      'your provision of content to Cashia and its Distributors;',
      'your posting of content using the Service; and Kashia Services Limited’s use of such content (including any works derived from it) in connection with the Services.',
      'Official Language of Agreement: The official language of communication will be the same as the language used to communicate this Agreement to you. Cashia may also elect to use an alternative language to the language used to communicate this contract to effectively communicate locally with You. Use of such alternative language for localization purposes should not be deemed as official for contractual purposes. For contract purposes the official language is Greek and English and where any discrepancy exists in the translated contracts the English version will supersede the other.',
      'Notices: You agree that the methods of providing information to you include:',
      'The Cashia website (including the posting of information accessible to you after your account login);',
      'Email to the email address you provided in your Account (to receive such email communication you need to ensure that you are online and that you email account is accessible);',
      'By postal mail to the street address listed in your Account;',
      'Such notice shall be considered to be received by you within 24 hours of the time it is posted to the Cashia website(s) or emailed to you. If the notice is sent by mail, Cashia will consider it to have been received by you three Business Days after it is sent. These time frames exclude notifications in relation to amendments to the Agreement. You may request a copy of any legally required disclosures (including this Agreement) from Cashia and Cashia will provide this to you in a form which allows you to store and reproduce the information and you may terminate your consent to receive required disclosures through electronic communications by contacting Cashia as described below. Cashia reserves the right to close your Account if you withdraw your consent to receive electronic communications.',
      'Notices to Cashia made in connection with this Agreement must be sent either:',
      'By postal mail to the Kashia Services Limited’s head office number 4 Gallant Mall, sixth floor, Nairobi, Kenya.',
      'By email to Client Support at support@Cashia.com where one of the Kashia Services Limited’s representatives will remit it to the Department handling matters such as yours, and where a responsible person will return with a response within 2 Business Days from receipt of your email, depending on the request.',
      'Transaction History and Balance:',
      'Your Transaction History and Balance details can be accessed by logging into your Account and selecting the Transactions tab/button. Cashia will refrain from sending you periodic statements by either physical or electronic mail. With this you acknowledge and agree to review your transactions through your Account and the relevant transaction history section. If for any reason (such as restricted access) it is impossible to access your transaction history through your account, you may contact one of Cashia representatives to assist you on the matter either by email at support@Cashia.com.',
      'The User accepts unconditionally and understands that solely the electronic money amount corresponding to the Account Balance is possible to be used for any Transactions requiring debiting of the User and/or redemption.',
      'Any Payment order by the User through his/her Account for the execution of which the remaining Balance is not sufficient, shall not be executed and shall be held as Pending Transaction.',
      'Transferring your account to a third party is strictly prohibited. No person other that You shall have any rights under this Agreement. Your eWallet is personal to You and You may not assign or otherwise transfer any rights under this Agreement to any third party, without Kashia Services Limited’s and its Distributors’ prior consent in writing. Cashia have and reserve the right to transfer or assign any right or obligation under this Agreement (or the entirety of this Agreement) at any time without requiring your consent. This right does not prevent you from closing your account with the Cashia and terminating this agreement as stipulated in the termination section below.',
      'Amendments of the Agreement: Except if otherwise agreed upon between the Merchant and Kashia Services Limited, this Agreement may be amended at any time by Cashia alone and may include changes in the terms and conditions as required by regulatory changes or as needed due to changes in the Service (additions or deletions). Any amendment will be communicated to the Client via the Cashia website(s) or by the designated email or by the Cashia profile of the user. The time given for changes to enter into effect is two months, except in cases where the amendments are required by the Law and have to take effect immediately. Also, any amendments necessary due to the enrichment of the Service and its delivery, or in cases where your rights and obligations are not adversely affected shall be effective immediately. Cashia will not provide the Client with any notice of such immediate changes.',
      'The fact that you may close your account free of charge according to the terms of this Agreement or other specific terms agreed upon in writing between you and Cashia and its Distributors, does not preclude you from potential liabilities that may have incurred prior to the closing of the account and for which you may be responsible for. Refer to the relevant section on account closing for additional information.',
      'This Agreement will take effect on the date You registered your eWallet and will terminate in accordance with Section 9 of the Agreement.',
      'If any provision of this Agreement is deemed unenforceable or illegal, the remaining Clauses will continue in full force and effect.',
    ],
  },
  {
    id: 'tab_item_4',
    title: 'Accounts',
    content: [
      'Eligible users of the Cashia services are:',
      'Residents of any country where the Service is available;',
      'Individuals who are of the age of 18 and older;',
      'Persons (individuals or legal entities) who can legally enter into a contract.',
      'Joined accounts or nominee accounts are prohibited and in opening an Cashia account the Client represents and warrants to Cashia that he/she is the sole beneficiary of the account and that all actions taken in relation to the account are taken by the Client alone, unless the Client is a legal entity and the person(s) opening and operating the account, does so under the direction of the company that employs him/her/them. Unless the person is acting for the company that employs him/her/them, the new account must only be in the Client’s own name. This Agreement applies to Users who are residents in the Territory.',
      'Cashia offers two account types namely Personal and Corporate (or Merchant). The primary use of a Corporate account is for its own operational purposes and should not be used for personal or family purposes.',
      'To open a Corporate Account with Kashia Services Limited, the Merchant has to provide all necessary information and documents required by Cashia Compliance Department, by answering the Cashia Corporate Opening Account Questionnaire and procuring the necessary documentation requested therein. After this process has been successfully completed, Merchant has a verified status, without limits to its transactions.',
      'In relation to the email address that You have designated and registered with:',
      ' You guarantee that you are the beneficiary and lawful user of this email address,',
      'You are obliged to take all necessary measures to avoid unauthorized access and use of this email address and you recognize that any communication through the use of this email address is considered as deriving, addressed to and received by You.',
      'Cashia may, from time to time, make available to you other methods or procedures for you to become of verified status. Such methods will be provided on the Cashia website or sent to you by email. By indicating you as verified, Cashia merely confirm your new status which neither represents, undertakes or guarantees that your verified status will result to completing a Transaction. Cashia may at any time require further information from the Merchant or supplementary documents, in order to comply with its regulatory obligations.',
      'Cashia apply Strong Customer Authentication in the following: cases:',
      'when the User has access to his/her Account in direct online connection,',
      'whenever the User initiates an electronic payment transaction,',
      'whenever the User executes any transaction that may be assessed as high risk for payment fraud or other delinquencies.',
      'Cashia implement all necessary and appropriate security measures in order to protect the accuracy and secrecy of the personalized security credentials of the Users.',
    ],
  },
  {
    id: 'tab_item_5',
    title: 'Issuance of E-Money',
    content: [
      'Cashia issues electronic money after a relevant request from the User. The issuance takes place in execution of a Topping Up Transaction.',
      'Any User can execute Topping Up Transactions and debiting his/her Cashia Account with electronic money of equivalent value with the monetary value deposited to Cashia for this purpose.',
      'The deposit of the equivalent monetary value for the Topping Up Transaction can take place:',
      'through the deposit of money from the User’s Verified Bank Account to the Clients Bank Account of Cashia following a process that is systemically indicated to the User,',
      ' through the debiting of the Verified Card, following a process that is systemically indicated to the User,',
      'with the deposit of cash in any of the Kashia Services Limited’s Mobile Money.',
    ],
  },
  {
    id: 'tab_item_6',
    title: 'Payment and Receiving Payment Transactions',
    content: [
      'For Merchant Clients, Cashia offer apart from Topping Up Transactions and Withdrawal Transactions, services for the execution of Payment Transactions and Receiving Payment Transactions. These services include a) the execution of Receiving Payment Transactions when the User receives monetary amounts deposited by third parties/payers for any cause within their business activities, b) the execution of Payment Transactions when the Merchant pays a third party a specific amount by debiting the Merchant’s Cashia Balance and c) all the necessary steps for the acceptance, clearance and settlement of these payments.',
      'Receiving Payment Transactions can take place as follows:',
      'Through Cashia Account of the payer to Cashia Account of the Merchant (User to Merchant transaction or Merchant to Merchant Transaction),',
      'Through verified payment instruments (such as VISA, Mastercard etc)',
      'Through verified bank account transfers (internet banking, phone banking etc)',
      'For any Transaction through payment instruments and bank transfers, the providers may charge fees to the User, according to their policies.',
    ],
  },
  {
    id: 'tab_item_7',
    title: 'E-Money Remittance',
    content: [
      'Subject to the terms of this Agreement, you agree that Cashia will execute your instructed Payment Order by debiting your Account and crediting the Account of the person you are remitting the E-money to at Cashia on the same day.',
      'The basic currency in which Payment Orders will be accepted will be the Kshs, and any other currency agreed in specific in writing between You and Kashia Services Limited. Cashia does not currently offer currency conversion services.',
      'Transactions will only be executed when there is available balance in your Cashia Account. To ensure that there is available balance, you need to review your account balance and if necessary, top up your account by drawing funds from one of your connected Funding Sources. Once the funds are received, then Cashia will issue e-money and keep it as an e-money balance in your Account, or transfer the e-money to the recipient, in each case according to your instructions and subject to the terms and conditions of this Agreement.',
      'You may select the Funding Source of your preference each time you top up your account balance, under the condition that you are the beneficiary of this Funding Source.',
      'Cashia may limit the Funding Sources that will be available to you when funding your account. The reason Cashia may set such limitation is to manage the potential risk that may arise from the payment transaction. Any such limitation will be communicated to you before the payment transaction is executed, at which time you will be asked to provide an alternative funding source (for example use of another e-wallet as opposed to a credit/debit card). Although Cashia may allow you to proceed with the Funding Source that presented the risk initially, such action will be taken with your consent and may lead to a limitation of your rights to dispute resolution (you may be unable to contest the transaction due to funding source restrictions). All available Funding Sources will be accessible to view through your account profile.',
      'When using a credit or debit card to top up (“Card Top Up”) your balance you are requesting a transfer from your credit/debit card issuer account to your Cashia Account. For this transaction, you agree that:',
      'You need to instruct your issuing bank through Kashia Services Limited’s acquiring agent using a tokenization process to make a transfer to the Kashia Services Limited’s bank clients account. You will be contacting your credit/debit card issuer indirectly through Kashia Services Limited’s acquiring partner in the manner and method prescribed (by that provider) and instructing them to make a payment to the Kashia Services Limited’s bank client account. The acquiring partner will use a form of tokenization of the credit/debit card information using cryptographic technology to ensure that your personal and card data is secure. Cashia will not store any of your credit/debit card details nor will it ask you to disclose it to. Tokens are reusable and can be used for making and receiving payments until their expiration date (expiry date), which is linked to the issuer’s credit/debit card expiration date.',
      'By receiving a payment from a PSU, you are able to process and complete the transaction.',
      'A recipient is not required to accept e-money sent to him/her despite being available. Cashia cannot control such action and you agree that you will not hold Cashia liable for any damages resulting from a recipient’s decision not to accept a payment made through the Cashia service. To this end Cashia will either:',
      'Return any declined or refunded payment to your Cashia Account; or',
      ' Return any payment that remained unclaimed by the recipient to your account from the date you instructed Cashia to make the payment.',
      'Third party-initiated payments are not permitted.',
      'The currencies the Client can transact in are currently the KSH. Other currencies may be added to the current list and will be communicated to you through the Cashia website or by email. Cashia has the right to add or remove currencies from the list of available currencies at its own discretion.',
      'There is no currency conversion option at this time.',
    ],
  },
  {
    id: 'tab_item_8',
    title: 'Receiving Money (Receiving Payment Transactions)',
    content: [
      'Anyone can remit funds to your Cashia Account irrespective of having an Cashia Account themselves or not.',
      'A periodic receipt limit may be imposed on your Account either by requirement of the anti-money laundering laws and regulations, or at Kashia Services Limited’s reasonable discretion (for example, without limitation, to limit fraud or credit risk). In no event will such limits exceed the maximum allowable limits set by the applicable anti-money laundering legislation. Any such limits set can be viewed in your account details.',
      'Cashia is entitled to review all payment Transactions and will do so in cases it deems to be of higher than normal risk. Such reviews will occur when Cashia has reasonable suspicion that the payment Transaction may involve a Restricted or Prohibited activity, or any other reason that may be determined by Cashia from time to time.',
      'Should the Transaction be deemed as problematic, Cashia will reverse the payment and place the e-money back to the sender’s balance. Cashia will provide notices to you by email (the email you have designated when registering for the Cashia Account). A payment is subject to review for the sole purpose of minimizing Kashia Services Limited’s risk as well as its Clients’ transaction dealings. Cashia does not intent with this process to review the involved parties’ business relations, their reputation and should not be construed as demeaning or discriminant in any way.',
      'The receipt of e-money is only concluded after the Client accepts it, at which point Cashia clears the balance in the account and makes it available for other payment or withdrawal transactions. Notifications from Cashia that you are the recipient of e-money do not constitute a confirmation that the balance has been cleared and has become available. You acknowledge and agree that a payment transaction is completed and received by you upon acceptance even if it becomes subject to a Reversal for reasons stated in this Agreement or its updates/amendments as it may be updated/amended from time to time.',
      'Invalidation of a transaction by means of reversal or cancelation, or suspension of a transaction does not release you from any liability towards Cashia and you will remain liable for any amount due to Cashia plus any Fees that relate to the payments.',
      'A recipient can receive funds in any currency allowed by Cashia irrespective of the currency maintained in the account at the time of the receipt for as long as the payment is accepted. In such instances, Cashia will credit your balance with the amount in the currency it was sent. Cashia does not offer currency conversion service at this time, and you acknowledge and agree that where you hold balances in multiple currencies, at time of withdrawal, your receiving funding source may cause you to convert the currency balances to the reference currency of the funding source’s account. This may lead to additional charges not relating to Cashia and the Service and for which Cashia is not responsible for. Contact your funding source directly to inquire about such potential charges and their currency conversion policy.',
      'Cashia is not responsible to determine any applicable taxes relating to your transaction, nor is it responsible to collect, report and remit any taxes arising from any transaction. You agree that it is your sole responsibility to determine the applicable taxes relating to a transaction and further to collect, report and remit tax balances arising from any transaction to the relevant tax authority.',
    ],
  },
  {
    id: 'tab_item_9',
    title: 'Account Balances and Transaction Information',
    content: [
      'Any Client can check the balance of their Account and their Transaction history information by accessing their online account at any time. The process of doing so is by logging into your account by entering your login credentials (username and password). You will then be requested to enter the email code you receive on your email for further authentication and to obtain access to your account and your account details. To access your transaction history, you will need to select the “Transactions” tab/button. By selecting the “Transactions” option, you will be able to access and review the entire transaction history that was processed by the Kashia Services Limited’s system irrespective of where the transaction was initiated (for example PSU, merchant, Funding Source, or the Cashia site). The transaction history will include detail of the service, the transaction number, currency, the transaction value and the transaction status.',
      'A balance will always be shown even if the amount is zero. Positive balances will not be paid any interest since the balance is in e-money and the account is not considered a deposit account making it illegal to earn interest.',
      'You agree that Cashia may settle any outstanding balances owed to it by using amounts from the balance you maintain in your Cashia Account. Cashia will try to settle any amounts due to it by a Client in Kshs. In the rare event that the Client will owe Cashia an amount and will not have a balance in the Kshs account, Cashia may use a variety of methods to settle the balance. You agree that such potential methods may include:',
      'Deduction from e-money received into your account;',
      'Deduction from the amount requested for withdrawal;',
      'Use of amount in a different currency under your account. Should this method be used, you agree to be liable for any conversion costs.',
      'Any balances that you owe to Cashia for more than 15 Working Days will automatically be deducted from any available balance in any currency you may hold in your account. Cashia has the right to convert that amount into Kshs with the cost of such conversion be borne by you.',
      'To the jurisdictions allowing it, as security for any potential balances that a Client may owe to Kashia Services Limited, the Client agrees to grant Cashia with a first right or legal claim (a “lien”) on balances and proceeds in his account.',
      'The currencies Cashia allows you to transact in have no peg to any other currency and their value fluctuates daily. Maintaining balances in more than one currency can be risky and may result in potential loss (or gain) that will be realized on the day you decide to withdraw your balance in a currency other than the currency in your account. Maintaining multiple currency balances for speculation (forex trading) is prohibited by Kashia Services Limited. You agree not to take such action and you accept the risk nonetheless of any potential loss stemming from maintaining multiple currency balances.',
    ],
  },
  {
    id: 'tab_item_10',
    title: 'Withdrawing/Redeeming E-money',
    content: [
      'A withdrawal is effectively a request to redeem e-money from your Cashia Account in your account and convert them into cash money. Clients can instruct Cashia to withdraw all or part of their account balance in the currency the balance is maintained for as long as the Funding Source can accept payments denominated in the currency of your account. A withdrawal can only be made to an existing Funding Source that has been confirmed as your own, after having provided proof that the Funding Source is verifiably Yours. Indicatively, under the condition that the User has completed the necessary verification process, can request the redemption of part or the whole of the Cashia Account Balance',
      'by transferring the requested electronic money amount to the Verified Bank Account,',
      'by crediting the requesting electronic money amount to the Verified Card.',
      'As a result of a successful execution of a Withdrawal Transaction, the Cashia Account Balance shall be reduced equally to the amount that has been withdrawn plus the incurred expenses and Fees.',
      'Cashia is entitled to review all withdrawal transactions and will do so in cases it deems to be of higher than normal risk. Such reviews will occur when Cashia has reasonable suspicion that the withdrawal transaction may involve a Restricted or Prohibited activity, or any other reason that may be determined by Cashia from time to time. When a withdrawal is reviewed, Cashia will hold the redemption and deem your withdrawal/redemption instruction as a future date. Once Cashia determines that there is no longer a risk in proceeding with the redemption, it will do so within the time specified in this Agreement. You agree that your withdrawal/redemption instruction for a restricted transaction becomes effective one work day after the restriction is lifted.',
    ],
  },
  {
    id: 'tab_item_11',
    title: 'Term and Termination of the Agreement (Closing of Your Account)',
    content: [
      'A withdrawal is effectively a request to redeem e-money from your Cashia Account in your account and convert them into cash money. Clients can instruct Cashia to withdraw all or part of their account balance in the currency the balance is maintained for as long as the Funding Source can accept payments denominated in the currency of your account. A withdrawal can only be made to an existing Funding Source that has been confirmed as your own, after having provided proof that the Funding Source is verifiably Yours. Indicatively, under the condition that the User has completed the necessary verification process, can request the redemption of part or the whole of the Cashia Account Balance',
      'by transferring the requested electronic money amount to the Verified Bank Account,',
      'by crediting the requesting electronic money amount to the Verified Card.',
      'As a result of a successful execution of a Withdrawal Transaction, the Cashia Account Balance shall be reduced equally to the amount that has been withdrawn plus the incurred expenses and Fees.',
      'Cashia is entitled to review all withdrawal transactions and will do so in cases it deems to be of higher than normal risk. Such reviews will occur when Cashia has reasonable suspicion that the withdrawal transaction may involve a Restricted or Prohibited activity, or any other reason that may be determined by Cashia from time to time. When a withdrawal is reviewed, Cashia will hold the redemption and deem your withdrawal/redemption instruction as a future date. Once Cashia determines that there is no longer a risk in proceeding with the redemption, it will do so within the time specified in this Agreement. You agree that your withdrawal/redemption instruction for a restricted transaction becomes effective one work day after the restriction is lifted.',
    ],
  },
  {
    id: 'tab_item_12',
    title: 'Restricted (or Prohibited) Activities',
    content: [
      'Cashia has listed a number of activities that are restricted or prohibited as they relate to the use of the Services, the terms of this Agreement, the applicable laws, regulations, directives and decrees that may be issued from time to time by Kashia Services Limited, the jurisdiction it is authorized, the jurisdictions of its cross-border activities. You agree that engaging in any of the below activities, may cause the Cashia services quality to suffer, and that you may restrict the level of safe access that you or any other client may enjoy. Such activities include: ',
      'Violating any law, regulation, directive, statute or contract in relation to the Service received, including without limitation, money laundering laws and regulations, consumer and personal data protection, the Electronic Money Institution Law and its directives;',
      'Violating the terms and conditions of this Agreement, including without limitation the Acceptable Use policy, the Privacy Policy, the Refund Policy, the opening and use of multiple accounts, and operating an account under false pretences;',
      'Acting in a manner that is offensive, harassing or demeaning to other Clients, to Kashia Services Limited, its Distributors and third parties;',
      'Providing inaccurate, disingenuous, or entirely false (deliberately) information without justifiable cause;',
      'Sending and receiving what may be deemed as unauthorized funds or funds from fraudulent transactions;',
      'Not being cooperative with Cashia when requested to provide additional information that will allow Cashia to better familiarize itself with the nature of your business and continuing operations;',
      'Refusing to provide confirmation of your identity and verification of your details when requested upon, in order for Cashia to perform an investigation when needed;',
      'Using tools and mechanisms to conceal your online identity and location (use of proxy and other anonymizing technics).',
      'Controlling an Account with close links to another account. The close link in this case exists when the accounts have a common person exercising control and giving instructions, and/or the beneficial ownership of the account as indicated by the common funding source is linked. Shared attributes are an indication that close links between two or more accounts exist;',
      'Causing and maintaining an account with a balance owed to Cashia (negative balance);',
      'Engaging in activities (in accordance with information in the Kashia Services Limited’s possession) that may present Cashia with increased risk of fraud or credit exposure that is beyond the acceptable limits set by Kashia Services Limited.',
      'Engaging in activities that lead to Cashia handling a disproportionate number of claims settled in favor of the claimant Client;',
      'Using the Cashia service from a jurisdiction that Kashia Services Limited’s cross border authorization does not cover and which is not included in the list of cross border jurisdictions as those are indicated on the Cashia Website;',
      'Sending unsolicited email in exchange for a payment, or sending unsolicited emails and other communication to Kashia Services Limited’s and its Distributors’ clients;',
      'Intentionally or unintentionally facilitate any malicious computer programming routines that may cause damage, harmfully interfere with, clandestinely capture or steal any system, data or information;',
      'Intentionally or unintentionally using any automatic device process, or manual process to monitor or copy the Cashia website without Kashia Services Limited’s and its Distributors’ prior written permission;',
      'Using any device and/or software that intentionally or unintentionally interferes with the proper operation of our platform and the Cashia website;',
      'Your actions causing Kashia Services Limited’s and its Distributors’ internet service providers (ISPs), payment processors, or other suppliers to seize offering their services to Kashia Services Limited;',
      'Revealing your Account login details to anyone for any reason. None, other than you, is authorized to use this account and disclosing to another party your login details is a violation of the terms of this Agreement. Cashia is not responsible for any losses you may incur, without limitation, as a result of your account being used by a third party;',
      'Acting in a manner that has a negative interference with the provision of the Cashia service in accordance with the terms of this Agreement;',
      'Using the service in a manner that could present a risk of non-compliance with the Kashia Services Limited’s anti-money laundering, counter terrorist financing and other relevant regulatory obligations. One such use and potential offense for not complying with our obligation is your lack of cooperation in providing identity confirmation details and the inability to verify your identity potentially exposing Cashia to the risk of any regulatory fines by Regulator or other authorities as a result of processing your transactions);',
      'Using the Cashia services in a manner that may result in complaints, disputes, claims, reversals, chargebacks, fees, fines, penalties and other liability to Cashia and its Distributors, a Client, or a third party; or',
      'Using the service in a manner that deviates from what is prescribed in this Agreement.',
      'In order to ensure the safety of your account and of the Cashia service provided to you, you agree to do the following:',
      'Refrain from engaging in any restricted Activity;',
      'Keep your login details (username and password) along with your Funding Source details safe and do not disclose to or allow anyone to access these details in any way. Only use the login details for the use of the Cashia service;',
      'Try to conceal your password in order to make it hard for others to understand;',
      'Try to avoid using as a password information that can be known to a number of people like your birthday, ID number, telephone number, or a sequence of letters and numbers that can easily be guessed;',
      'Try not to allow any prying eyes from seeing you entering your login details;',
      'Do not use any functionality, either device or software specific, that allows you to store (“remember”) your login details as they may be compromised;',
      'Ensure that you do not remain logged in your account after you have finished accessing the service and if you are using a browser it is recommended that you also close the window/tab that you used to access your account. This way you can ensure that other unauthorized persons cannot access your account especially when using an unsecured public access hotspot (“Free WiFi” locations);',
      'Refrain from using any functionality that saves or stores your password or PIN on your access device;',
      'Comply with all reasonable instructions Cashia may issue from time to time in relation to this subject matter;',
      'Keep your personal details up to date in order to be able to confirm that you are the authorized person for the account. This cannot be done if the information (email, address, telephone number) Cashia have on record do not match the information that you try to reach Cashia from.',
    ],
  },
  {
    id: 'tab_item_13',
    title: 'Merchant Liability',
    content: [
      'Notwithstanding to any provisions that are more specific and agreed between the Merchant and Cashia in writing, Clients can be liable for the following issues (or other issues that may be communicated to you by Cashia from time to time).',
      'Using the Cashia services in a manner that results in complaints, disputes, claims, reversals, chargebacks, fees, fines, penalties and other liability to Cashia and its Distributors, a Client, or a third party, will make you liable for the outcome. In such a case, you agree to reimburse Cashia and its Distributors, a Client, or a third party for any and all such liability, and for Cashia to settle any amounts owed as a result of the liability from your available balance. Should your balance be insufficient to cover the amount, you agree that Cashia may use any account proceeds from a transaction, and in the event that such amounts do not cover the liability, allow the Cashia to recover the amount through other legal means available.',
      'In case of disputed amounts resulting from purchaser claim, reversal on a payment you received, or chargeback, Cashia will block the use of enough funds in your account to cover the full amount of the dispute. The block will not affect the use of your account if you have a residual balance in excess of the blocked disputed amount. The block is restricted to the disputed balance amount. Should the dispute be resolved on your favor, Cashia will restore the full access to your account and remove the block from the formerly disputed amount. To the contrary, if the dispute is resolved in favor of the opposing party, the blocked amount will remove the required amount from your account in settlement of the dispute resolution decision.',
    ],
  },
  {
    id: 'tab_item_14',
    title: 'Kashia Services Limited’s And Its Distributors’ Legal Rights',
    content: [
      'Cashia have the legal right (and in certain cases obligation) to take actions necessary to remedy a condition caused by Clients’ actions. Cashia will take action to protect its own interests and those of its Clients, associates and other third parties that may be affected by suspected restricted or prohibited activities. Such remedial actions may include but not be limited to the following:',
      'Cashia may suspend, block partially or completely any transaction, limit the use of, cancel the access to or close your account entirely. Such action will limit or freeze:',
      'access to your balance funds, your ability to draw funds from or remit funds to your funding sources;',
      'sending money;',
      'receiving money;',
      'withdrawing/redeeming money;',
      'making amendments to your account details; and/or',
      'access to your account history.',
      'Kashia Services Limited’s practice is to offer advanced warning of any such action, but you agree that it is not required to do so, and given reasonable justification (such as preventing to alert any party of the suspicion of money laundering also known as “tipping off”, or if it poses a risk to Kashia Services Limited’s infrastructure security);',
      'Cashia may reverse a payment transaction on the grounds of reasonable suspicion that it violates, or on proof that it violates Kashia Services Limited’s restricted activities rules and/or the Cashia policy of acceptable use of the system/service.',
      'Cashia may refuse any payment transaction for any reason without limitation. Provided it is not prohibited by any applicable law, Cashia will be required to provide information as to the reason it took action, what caused it to take action, and any remedial action the affected party may take to resolve the refusal issue if possible;',
      'Cashia may refuse to provide any of its services at any point in time;',
      'The law requires that any information relating to the identity of the Client be kept updated at all times. Cashia may require you to either update your information if it deems that they may be outdated or missing. It may further require you to provide information needed to verify your identity even without you requesting to lift any sending, receiving or withdrawing limits imposed on your account;',
      'For the purposes of dealing with Restricted activities, Cashia may disclose information relating to such actions to third parties but always keeping in line with the terms of the Privacy Policy in the Cyber Security Policy',
      'Cashia may block a required balance of your funds, for a reasonable period of time which in any way not exceed 36 months (unless otherwise required by law, decree or court/regulatory authority decision) as may be deemed necessary to protect against the risk of liability.',
      'Cashia has the right to take legal action against you in the courts of its legal jurisdiction.',
      'Cashia may limit or block access to your account, if there is suspicion of unauthorized access by a third person and such action will be without prior notice. Once the action is taken, Cashia will notify you of the limitation or blocking and the reason for it. Once the issue is resolved and if appropriate, Cashia will allow you to request reinstatement of your access rights.',
      'Where required by legal circumstances including any breach of the terms and conditions of this agreement, Cashia has the right to close a Client’s account without prior notice and at any time. Cashia also has the right to close an account and terminate the Agreement, even if the aforementioned conditions are not the cause, and without reason by giving a 2-month notice to the account holder. Should this occur, the Cashia may provide you (but not obligated to) with a reason for electing to close your account and will allow you to withdraw any funds that are not blocked for any other reason.',
      'Suspending a payment transaction: You agree that Cashia may suspend a payment transaction, which based on available information at the time, can be reasonably deemed as above acceptable risk levels. Should Cashia take such action, you will be notified.',
      'Should the threat of excess risk be eliminated, Cashia will lift the suspension allowing the payment to be concluded. If the suspension is for purposes other than acceptable risk levels or transaction risk, but rather relates to action taken as per section 10.a.i above, Cashia may continue the suspension until the disputed matter is resolved.',
      'Cashia has the right to close the account and terminate the agreement if the Client objects to the suspension. The Client also has the right to close the account if he/she objects to the suspension. You agree that Cashia may reasonably request that you provide additional information that will either reduce or eliminate the risk that is causing the suspension. If you refuse to provide such information, Cashia has the right to close your account and terminate the agreement. In case of account closure, Cashia may block an amount equal to the amount relating to the payment transaction under scrutiny, in order to cover for any potential obligations resulting from the transaction.',
      'In order to be compliant with its anti–money laundering obligations, Cashia may require you to provide additional information that relate to your identity and potentially financial status. Such information is substantially more than what a Client is asked to provide when opening an account based on a framework contract. You agree that you will comply with such requests and provide Cashia with the details either by email, fax or any other means or form as may be required. Such details may include identification documents to confirm identity and place of residence, as well as (if needed) provide Cashia at your own expense with information on your sources of income, and if you are a legal entity with details of your operations, and audited financial statement (or if not possible management accounts).',
    ],
  },
  {
    id: 'tab_item_15',
    title: 'Errors and Unauthorized Transactions',
    content: [
      'Cashia recognizes that unauthorized or erroneous transactions may occur and stresses the importance of monitoring your Account on a regular basis by accessing the transaction history segment and reviewing the transactions listed. Should you suspect or believe that there was unauthorized activity in your account and that potentially one of the below instances occurred, immediately inform Cashia to take all necessary remedial actions. The list may not be exhaustive:',
      'Your account has been accessed by another person with no authorization for such access;',
      'Your account was used for a transaction you did not authorize;',
      'Your password has been compromised;',
      'You are also expected to immediately inform Cashia of any erroneous transaction that may have taken place relating to your account, in order for Cashia to investigate the cause of the error and take remedial action as necessary. For as long as Cashia is content with the justifications provided in relation to the unauthorized use, Cashia will not hold you liable for the unauthorized transaction. It will however, exercise its legal rights and hold you fully liable when Cashia deems that you tried to defraud by acting in deliberate fashion in granting access to a third person by willingly providing or “unintentionally” exposing your login information to that person. Cashia will also hold you responsible and liable when it evidently realizes that either with gross negligence, or intent, you fail to comply with the obligations stemming out of this Agreement and its terms and conditions.',
      'The most immediate way to contact Cashia is by completing and submitting the online form found on the Cashia website, or alternatively contacting one of their members by sending an email to support@Cashia.com with the subject heading “Erroneous transaction” or “Unauthorized Transaction”. Cashia requires you to also submit a written record of the incident providing Cashia with any information you may have in your possession so that it would make the investigation process faster and the remedial action quicker. Written records can be in the form of an email addressed to the same address referenced in this section and with the same subject heading options. You are expected to reasonably assist Cashia and you agree that certain information you provide may be provided to a third party relevant to the case in hand always in accordance with Cashia Privacy Policy.',
      'Cashia will review the reports submitted and revert with the investigation results within 15 working days from the day the notice is initially provided by the Client. Depending on the complexity of the investigation, Cashia may take up to an additional 3 months to further investigate the report before coming to a conclusion. Cashia will try to remedy any error caused by its actions almost immediately to enable its Client unobstructed use of the account.',
      'After the investigation is concluded and the results of the investigation are ready, they will be communicated to you in writing by generating a ticket in the system and sending it to you, and will include the result of the investigation and an explanation of the findings that led to the final result. In the event that Cashia provisionally treated a transaction as erroneous and credited your account with the relevant balance, and the investigation resulted in “no error”, Cashia will have the right to debit your account for the same amount as if the transaction was never reported as erroneous. The Clients who reported an error can request copies of the material that was used for the investigation.',
      'The liability of any Unauthorized Transactions that evidence does not suggest that there has been any fraudulent, deliberate or gross negligent action or behavior by the Client, will fall on Kashia Services Limited. In such cases the Client will receive full reimbursement of the amount relevant to the reported unauthorized transaction(s) from the Client’s account. Clients will be entitled to such reimbursement only if they report such unauthorized transaction incidents to Cashia within one calendar year from the date the first unauthorized transaction incident was executed. Where the reported incident demands further investigation, Cashia will follow the process described in section 13.3.5 above.',
      'The Clients are entitled to a refund of the full amount of any authorized payment transaction whether the transaction was initiated through the system, through a merchant’s website or other third party, provided that any conditions that make the Client eligible for such a refund are met.',
      'Cashia will rectify all errors that may originate from its system. Errors are normally discovered during the fund reconciliation process. For instances where your account was erroneously credited with less money than you were entitled, Cashia will credit the difference in your account. If the opposite occurs and your account is credited with more money than it should, Cashia will debit your account for the difference. Cashia will be liable to refund any transaction that has resulted in either not being of correct amount or not being executed in time and for any losses or damages directly resulting from this failure unless:',
      'You did not have sufficient funds in your account to conclude the transaction and the cause of this insufficiency was not Kashia Services Limited;',
      'You tried to execute the transactions at a time that it was known to users that the system was not properly functioning, either due to maintenance or unresolved technical issues; or',
      'Because of circumstances beyond Kashia Services Limited’s control (such as acts of God or other technical issues such as online connectivity) the transaction could not be properly executed despite Kashia Services Limited’s best endeavors to the contrary.',
      'Cashia will not be liable for any such incomplete/failed transactions unless a Client reports such incomplete/failed transaction to Cashia within one calendar year from the date he/she became aware of the incomplete/failed transaction and reported it to Kashia Services Limited.',
    ],
  },
  {
    id: 'tab_item_16',
    title: 'Merchant Responsibilities',
    content: [
      'It is the Merchant’s sole responsibility to:',
      'Frequently check the status of the Account maintained with Cashia and to ensure by reviewing with similar frequency the Account’s transaction history in order to ascertain if any unauthorized activity has taken place. When the Merchant suspects that unauthorized activity occurred, the suspicion should be promptly communicated to Kashia Services Limited.',
      'Keep the login details secure and strictly confidential. No unauthorized person should be in the possession of such information and the Merchant must and will immediately inform Cashia of the suspected unauthorized possession or use of its login details. Although Cashia may implement its own user password updating requirements, the Merchant is expected to implement even stricter requirements (but not less strict than those required by Cashia and its Distributors) depending on the specific risk it may face.',
      'Develop and maintain (including hosting arrangements of) the Merchant website ensuring its accessibility on the internet. Through the Merchant website, the Merchant should ensure that any placed orders for products and/or services it sells will be properly fulfilled. The Merchant must also ensure unobstructed transmission of any transactional information to the Kashia Services Limited’s and its Distributors’ servers and that the Merchant’s system (software and hardware) is not tainting the transmitted information in any way.',
      'Ensure that the software used is updated to the latest version and that any required security updates and patches are in place.',
      'Provide all necessary information for anti-money laundering/ counter terrorism prevention procedures (KYB) and ensure that this information and all the Merchant registration information requested at the Cashia Merchant website as well as the available payment information is always up to date.',
      'Ensure that there is at least one active commercial banking relationship with a credit institution. Cashia will have no bearing on this relationship and any terms and conditions set out by such a relationship will have no bearing on Cashia and will be the sole responsibility of the Merchant and the credit institution(s).',
      'Ensure that collection, storage and transmission of customer data is performed using security policies that are in line with business best practices and which protect the privacy of client data as required by the applicable personal data protection legal framework. For using Cashia Service, Cashia may review the Merchant data handling process and if deemed necessary, request that the Merchant undertake to bolster its process, within reasonable commercial limits, to further safeguard the customer data and the overall security of the Cashia service.',
      'Ensure that any information procured through the use of the Cashia service should only be used in conjunction with the service and in such a way that it is acceptable in accordance with the Agreement and the policies. The Merchant should not permit the use of such information by any other third party other than in conjunction with the service.',
      'Use the Cashia service in accordance with the terms and conditions stipulated in the Agreement, the Policies, and User Guides and/or Manuals that Cashia may publish from time to time.',
      'Ensure that the Merchant is up to date with any amendments to the terms and conditions of the Agreement and with any changes to the Cashia services from time to time.',
      'Take all necessary measures to identify and verify the identity of his/her customers (payers) using payment instruments requiring their user’s identification. Merchant Client is solely responsible for the implementation of all identification and verification procedures against payers.',
      'Display enhanced due diligence towards the holders of payments instruments regarding their proper use and charging of fees.',
      'Not to collect, retain or process in any way or disclose to third parties in any way, identification and verification data related to payment instruments and their holders, or to relevant transactions, whether those are incorporated in tangible or intangible means, neither can the Merchant use these data for any other purpose than for the execution of the transaction.',
      'To keep all necessary supporting to the transaction documents for at least 13 months from the day of execution.',
      'To comply with PCI DSS process, as this may be amended, and available in the websites of www.visa.com, www.mastercard.com and www.pcisecuritystandards.org.',
      'Merchant must not for the duration of this Agreement:',
      'Accept payment instruments, previously declared as stolen or lost',
      'Execute any Transaction without the authorization of the holder',
      'Breach any of the terms of this Agreement, or any other agreement with Kashia    Services Limited and its Distributors',
      'Facilitate or cause an interception of payment instrument or transaction data,',
      'Act in a way that can be construed as Money laundering activity, according to legislation,',
      'Provide false information to Cashia ',
      'Cause exceeding number of chargebacks/disputes of Transactions.',
      'Merchant may use material provided by Cashia under the Merchant Agreement without the prior consent of Cashia for the sole purpose of being identified as a merchant accepting payments through the Service. It should be noted that Cashia may at its own discretion at any time and for any reason revoke the automatic permission or limit the use of the material provided. As a Merchant, you are prohibited from modifying or altering any of the material provided to you in any way, or use them in manner that may be considered as an endorsement or sponsorship or, modify or change these HTML logos in any way, use them in a manner that has a negative resonance to Cashia or the Service. All right, title and interest in and to the Cashia website and any content thereon is the exclusive property of Cashia including its licensors.',
      'As a Merchant client, you agree not to surcharge for the use of the services unless the applicable laws allow you to do so. Should you charge a client a surcharge fee, Cashia will be no party to such charge and you agree to inform the purchaser directly of the charge clearly stating that you as the seller and not Cashia is charging the surcharge. It is not Kashia Services Limited’s and its Distributors’ responsibility to inform and where you fail to inform the purchaser of the surcharge details, Cashia will assume no responsibility and will have no liability towards any purchaser as a result of the omission. You further acknowledge that failure to disclose such surcharge to the purchaser when permitted, may constitute a criminal and/or civic offense.',
      'Merchant clients may grant, remove and manage permissions for certain third parties on their behalf by accessing their account and managing the API permissions (when this is technically available). Such permission should not be deemed as a relief of your responsibilities and obligations under this Agreement but rather their extension to the authorized third party. By granting permission to a third party to act on their behalf, Merchants acknowledge that Cashia may disclose to this third party, Merchant account specific information. You acknowledge that if you grant permission for a third party to take actions on your behalf, Cashia may disclose certain information about your Cashia Account to this third party. You further acknowledge and agree that you will not hold Cashia responsible for, and will indemnify Cashia from, any liability arising from the actions or inactions of this third party in connection with the permissions you granted.',
    ],
  },
  {
    id: 'tab_item_17',
    title: 'Cashia Responsibilities',
    content: [
      'Cashia agree (always subject to the terms and conditions of the Agreement) and is responsible to:',
      'Provide the services for which the Merchant subscribes to and pays for to the Merchant;',
      'Provide the Merchant with (or provide access to through the Cashia platform system) standardized transaction reports as well as accounting reporting tools that the Merchant can use in preparation of its accounting records.',
      'Cashia do not agree and it will not be bound by any representations made by employees and representatives of third parties or agents that the Merchant has used to subscribe for all or any of the Cashia services, nor will it be bound by any information posted on publicly available media including but not limited to its website and other electronic or hard copy brochures and leaflets.',
      'Cashia have the right and the Merchant agrees that it may at any time:',
      'Amend the terms and conditions of the Agreement including any and all of its Schedules, as well as the Policies;',
      'Change all or part of the services it offers to the Merchant under the Agreement.',
      'Amendments or service changes of any kind as indicated in subsections 15.3.1 and 15.3.2. above, will be enforced and be binding upon the provision of written or electronic notification to the Merchant, or 20 Working days from the day that such amendments and changes are posted on the Cashia website. The method of delivery of the aforementioned amendments and or changes to the services will be chosen by Cashia at their sole discretion. Although Cashia will endeavor to inform the Merchant of the aforesaid in the delivery methods prescribed, the Merchant agrees to remain updated of any such amendments to the terms and conditions and any changes to the services, by periodically reviewing the various delivery methods for any such notifications. No one other than the executive management team with the Board of Directors’ approval is authorized to amend the terms and conditions or make changes to any or all the Cashia services. Any other employees, agents, representatives or other third parties are not authorized to do so and should therefore not be considered as a reliable source of information for such amendments and or changes.',
      'The Merchant can choose to terminate the Agreement at any time by notifying Cashia in the manner described in the Termination section of this Agreement, if it objects to the amendments in the terms and conditions and/or the changes in the Cashia services. The Merchant agrees that upon termination of the Agreement any paid fees will not be refunded and Cashia agrees that no additional fees will be charged. For clarification purposes, any fees that are still due to Cashia and owed by the Merchant will remain due and the Merchant should settle them even if the Agreement has been terminated.',
      'Continued use of the services by the Merchant will be construed as acceptance of the amendments and/or changes, and the Merchant agrees to be bound by them. By continuing to use the Cashia services after any revision to this Agreement or change in Services, you agree to abide by and be bound by any such revisions or changes.',
      'Security of transactions is paramount to Cashia which have employed and will sustain adequate security systems to ensure secure transmission of Merchant’s Transactions over the internet. The security measures taken consist of encryption and firewall systems as well as intrusion prevention mechanisms. The security measures taken consist of encryption and firewall systems as well as intrusion prevention mechanisms. For encryption Cashia will use Secure Sockets Layer (SSL) which is considered the standard, while for the firewall system it will use a combination of hardware and software that will result in all external traffic terminating at a demilitarized firewalled zone. Intrusion prevention mechanisms will include a combination of NGFW, GIPS and ATM technologies. Cashia do not guarantee the security of the services or transaction data, and Cashia will not be responsible in the event of any infiltration of its security systems, provided that Cashia have used commercially reasonable efforts to prevent any such infiltration. Merchant further acknowledges and agrees that Merchant, and not Cashia and its Distributors, is responsible for the security of transaction data, or information or any other information stored on Merchant’s servers, and that Cashia are not responsible for any other party’s servers (other than subcontractors of Cashia solely to the extent Cashia are liable for its own actions hereunder).',
      'The Merchants will be entitled to Kashia Services Limited’s and its Distributors’ support services that are specific to the services they selected during the enrollment process and to any subsequent service package selected. The Merchant shall seek support by sending an email describing the problem or difficulty they face at support@Cashia.com.',
    ],
  },
  {
    id: 'tab_item_18',
    title: 'Privacy Policy',
    content: [
      'The full Cashia Privacy Policy Statement is within the Cyber Security Policy can be found on the Cashia website at www.Cashia.com. This Privacy Policy dictates the rights and obligations of each Party with regards to the possession and processing of personal information. Cashia may at its sole discretion and at any time make amendments to the Privacy Policy statement. The revised Privacy Policy will be posted on the Website and for a period of 2 months will include the version preceding the changes for ease of reference. The Merchant agrees to periodically review the Privacy Policy statement and be made aware of any changes to it. Use of any of the Cashia services after such modifications will be construed as acceptance of the revised policy terms. The Merchant acknowledges that it has the right to terminate the agreement, if all or any of the amendments are deemed to be not acceptable.',
      'The User, as personal data subject, according to the provisions of the Data Protection Act and the applicable personal data legislation hereby grants explicit consent to Cashia for the collection and processing of their personal data. The User understand and agrees that the processing of the personal data takes place for the purposes of this Agreement and for the compliance of Cashia with any regulatory requirements. Any denial to grant consent to Cashia in relation to the necessary collection, processing or data transfer may result in Kashia Services Limited’s inability to provide the Services or the unilateral suspension of the provision of the Services, the freezing of the balance by Cashia and/or the unilateral termination of this Agreement by Kashia Services Limited.',
      'In the course of providing the services to the Merchant, Cashia will process certain customer and transaction information (the “Data). The Merchant acknowledges that and agrees to Kashia Services Limited’s and its Distributors’ processing of these Data and further agrees to provide only the Data necessary for Cashia to provide the services. Cashia agree to use the Data in identifiable form only for the purposes of offering the services and for the processing of transactions.',
      'Cashia shall have the rights to:',
      'Use the Data in a manner required to offer the services contemplated in this Agreement without limitation the provision of such Data to third parties who relate to the provision of the services,',
      'To maintain the Data for a period of up to five years, or as the relevant EU Laws and Directives may require. Such Data may be used for internal record keeping, internal reporting, and support purposes, and To make such Data available either to honor a court issued injunction or the Law, or to be used in Kashia Services Limited’s and its Distributors’ defense in a legal dispute or for.',
      'Cashia as well as any third-party payments service provider involved (i.e. collaborating credit institutions, Visa, Mastercard, etc) have the right to request information from the data archives and data basis of Cashia which are lawfully held, in relation to the credibility and trustworthiness of the User. Provided that the applicable legislation allows authorized third parties access to transaction data, the User hereby grants his/her unconditional consent for this access.',
      'The Merchant is responsible to notify the third-party persons of the fact that their personal data will be provided to Cashia as required by the service provided to the Merchant and represent and warrants that it will have received their consent for the provision to and the processing of (as necessary) by Cashia prior to doing so. The Merchant further warrants that the notification to the third-party persons includes the reason for collecting and remitting such personal data, who are the intended data recipients, which parts of the personal data is compulsory and which are optional, and should the need arise, by what method can the persons whose personal data is being held and controlled can correct them (in case of change or error). It is the Merchant’s sole responsibility for any repercussion stemming from his/her failure to notify, or to receive the consent of the third party, for the maintenance and control of his/her personal data and for the provision of any inaccurate, incomplete or outdated information.',
    ],
  },
  {
    id: 'tab_item_19',
    title: 'Fees and Currency Conversion',
    content: [
      'The Merchant agrees to pay the Fees as consideration for the applicable Cashia services. The payable Fees are as stipulated in the Schedule of Fees which is an integral part of this Agreement and signed between the Merchant and Kashia Services Limited. Fees may be changed from time to time, at the sole discretion of Cashia and the revised fee schedule will apply to any new Agreement, or upon renewal of the Agreement which will be subject to the Terms & Conditions applicable at the time. The Merchant has the right to not renew the Agreement if it objects to any revision on Fees.',
      'All Fees become due and payable upon the provision of the Cashia Services to the Merchant. Cashia has the right to receive all due and payable amounts towards it by directly debiting and subsequently reducing the Balance of the Account of the User, without any specific notification. With the acceptance of the terms of this Agreement, the User explicitly and unconditionally mandates Cashia for the receipt of these amount with the equivalent debiting of the User Cashia Account. Any mounts that have become due and payable and have not been paid off due to insufficient Balance of the User, shall be withheld by Cashia in priority against any other debit, once and to the extent the Balance is sufficient.',
      'Any Fees that are due upon entering into this Agreement should be settled immediately in any of the forms accepted by Cashia and stated in the Schedule of Fees. The Merchant agrees that any fees that are due at the time the service is delivered, will be settled either immediately by Cashia withholding the respective fee amount from its Client’s account, or aggregated by the Merchant’s approved authorized agent(s) and collected on a weekly or monthly basis as will be determined in the Schedule of Fees appended to this Agreement.',
      'All Fees are not refundable unless otherwise provided for in this Agreement. Fees can be payable in Kshs. Currencies for fee payments other than the Kshs may be added or removed in the future in which case the Merchant can be informed in writing. The Merchant agrees to pay all applicable sales, value added (VAT) and any other taxes relevant to Cashia services or to any payments made to Cashia where such relevant taxes apply. No deductions or withholding should be made to the aforesaid payments unless otherwise required by law. In such a case, the fee sum that the withholding party must pay, will be adjusted to include any such deduction or withholding, ensuring that the resulting amount Cashia receives is equal to what the amount would have been, should it not have been for such a deduction or withholding being required. Unpaid balances that are overdue (where overdue means that their “pay by” date has lapsed and any additional remedial period has also lapsed), will accrue a late charge interest of 2% per month or the maximum allowed under the law, where the lesser of the two will apply. In the event that Cashia cannot collect the fees due, it shall have the right to terminate the Agreement in accordance with the terms.',
      'All fees owed by the Merchant to third parties are not subject to this Agreement and they are his/her sole responsibility.',
      'Cashia does not currently offer currency conversion services. Should it do so in the future this segment will be updated accordingly.',
      'Users recognize and accept unconditionally that any depiction of the unpaid amounts towards Cashia in the accounting reconciliations of Cashia and/or the updated accounting reconciliations of the transactions, transfers and of the Balance of the Account which may be issued by Cashia produce full proof of the amount not paid from the User to Cashia for any court, and in reverse, and are undisputed, capable of supporting as full proof any relevant claim, fulfilling all possible requirements for the issuance of a payment order as well as for any compliance obligation Cashia may have as holder of an electronic payments license.',
    ],
  },
  {
    id: 'tab_item_20',
    title: 'Representations and Warranties',
    content: [
      'Cashia represent and warrant that:',
      'They possesses the necessary corporate authority to enter into and carry out the terms of this Agreement;',
      'Their executive officers, board of directors and shareholders have taken all the necessary corporate actions for the performance of Kashia Services Limited’s and its Distributors’ obligations stemming from this Agreement.',
      'Except as expressly set forth above and to the extent permitted by applicable law, Kashia Services Limited, their authorized representatives, and their licensors, as applicable, make no warranty of any kind, express, implied or statutory, regarding the services offered or the software provided;',
      'Kashia Services Limited, their authorized representatives, and their licensors expressly disclaim, to the maximum extent permitted by law, all conditions and warranties including but not limited to the implied warranty of non-infringement, except for any condition or warranty whose exclusion would contravene any law and regulation (including the Kshspean Commission Directive on Unfair Commercial Practices of 2005), or cause any part of this paragraph to be void (hereon after referred to as “non-excepted conditions”)',
      'Kashia Services Limited’s and its Distributors’ liability towards the Merchant as a result of a breach of any non-excepted condition is limited at its own option to the following:',
      'Refund of the price paid for the good or service that caused the breach.',
      'Replace or repair the good or repeat the provision of the service that caused the breach unless such liability cannot be limited under jurisdictional laws and regulations.',
      'Kashia Services Limited, their associates, and their licensors neither represent nor warrant that the provision of services to the Merchant will be without, delay, interruption, compromise to its security systems related to its services, or error free (neither that all errors will be rectified), and the Merchant acknowledges and agrees that Cashia have not made any such representations or warranties to it.',
      'The Merchant represents and warrants that:',
      'He/She or as a legal entity possesses the necessary corporate or other authority to enter into and carry out the terms of this Agreement;',
      'If a legal entity, Its executive officers, board of directors and shareholders have taken all the necessary corporate actions for the performance of Merchant’s obligations stemming from this Agreement;',
      'If it is an entity of any legal form or partnership, then the legal entity or the partnership is in good standing in its jurisdiction of incorporation or registration;',
      'He/She or as a legal entity its authorized representatives have read and understood the Agreement in its entirety and had the opportunity to appoint or has appointed a counsel of his/her/its own choosing;',
      'This Agreement constitutes a legally binding contract, it is valid and can be enforced against it under its terms and conditions;',
      ' He/She or as a legal entity has not received representations of any kind to entice it to enter into this Agreement.',
      'The Merchant further represents and warrants that it shall comply with all applicable laws and regulations in relation to the use of the Cashia services, in dealing with the Payment Service Users, and the use, provision and disclosure of Data.',
    ],
  },
  {
    id: 'tab_item_21',
    title: 'Indemnification',
    content: [
      'Except if something more specific has been agreed upon between Cashia and the Merchant in writing, Cashia shall indemnify, defend and hold harmless the Merchant and its employees, representatives, agents, affiliates, directors, officers, distributors and franchisees from and against any and all claims, suits, actions, liabilities or other proceedings brought against it that are based on a breach of Kashia Services Limited’s and its Distributors’ representations or warranties hereunder or any claim that Kashia Services Limited’s and its Distributors’ service and systems infringe the Intellectual Property Rights of any third party. Cashia shall pay any and all costs, damages, and expenses.',
      'Except if something more specific has been agreed upon between Cashia and the Merchant in writing, the Merchant shall indemnify, defend and hold harmless Cashia and their employees, representatives, agents, affiliates, directors, officers, distributors and franchisees from and against any and all claims, suits, actions, liabilities or other proceedings brought against them that are based on a breach of the Merchant’s representations or warranties hereunder or any claim that the Merchant infringes the Intellectual Property Rights of any third party. The Merchant shall pay any and all costs, damages, and expenses, including, without limitation, reasonable attorneys’ fees and costs awarded against or otherwise incurred by the Merchant in connection with or arising from any such claim, suit, action, or proceeding.',
      'Either Party shall promptly notify the other Party (the “indemnifying Party”) in writing of any potential claim and cooperate in all reasonable respects, at the indemnifying Party’s cost and expense, with the investigation, trial and defense of such claim and any appeal arising therefrom. The indemnifying party shall not compromise any claim or enter into any settlement, or consent to the entry of any judgment without the written consent of the indemnified party. The indemnified party may in addition retain separate counsel of its own choosing at its own cost.',
      'Limitations on Liability',
      'Except if something more specific has been agreed upon between Cashia and the Merchant in writing, the Merchant acknowledges that Cashia are not responsible for the results of any credit inquiry, the functionality of Internet Service Providers’ (ISP) or Financial Institutions’ (FI) websites, or the accessibility and functionality of the Internet, or for any damages or costs the Merchant may incur or suffer as a result of any instructions given, actions taken or omissions made by it, its financial processor(s) and FI or any ISP.',
      'Except if something more specific has been agreed upon between Cashia and the Merchant in writing, EXCEPT FOR THE MERCHANT’S OBLIGATIONS UNDER SECTION 16 OR A BREACH OF SECTION 19, IN NO EVENT WILL EITHER PARTY’S AGGREGATE LIABILITY UNDER THIS AGREEMENT EXCEED THE FEES PAID BY THE MERCHANT TO KASHIA SERVICES LIMITED UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH LIABILITY. IN NO EVENT WILL KASHIA SERVICES LIMITED OR THEIR LICENSORS HAVE ANY LIABILITY (INCLUDING LIABILITY FOR NEGLIGENCE) TOWARDS THE MERCHANT OR ANY OTHER PARTY FOR ANY FOREGONE PROFITS, MISSED OPPORTUNITY, COSTS RELATING TO THE PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR SPECIAL DAMAGES ARISING OUT OF THIS AGREEMENT, UNDER ANY CAUSE OF ACTION OR THEORY OF LIABILITY (INCLUDING NEGLIGENCE), AND WHETHER OR NOT KASHIA SERVICES LIMITED HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. Notwithstanding the above, the limitations set forth above shall be enforceable to the maximum extent allowed by applicable law.',
    ],
  },
  {
    id: 'tab_item_22',
    title: 'Confidentiality',
    content: [
      'Each party shall use the Confidential Information solely in the performance of its obligations under this Agreement, treat it as confidential, and not disclose it, except as expressly permitted by this Agreement and to its authorized employees, officers, directors, legal counsel and accountants (provided that they are bound by a duty of confidentiality no less restrictive than the duty imposed by this Section). Without limiting the foregoing, the receiving party shall treat the other party’s Confidential Information with at least the same degree of care it uses to prevent the disclosure of its own Confidential Information, but in no event less than reasonable care. The receiving party shall promptly notify the disclosing party of any actual or suspected misuse or unauthorized disclosure of the Confidential Information. Upon expiration or termination of this Agreement, each party shall destroy all electronic copies, and return all tangible copies, of any Confidential Information of the other party unless otherwise required by any applicable laws and regulations.',
      'Confidential Information shall not include information that the receiving party can prove: (a) was generally available to the public at the time it received the information from the disclosing party or later becomes generally available to the public through no fault of the receiving party, (b) was known to it, without restriction, at the time of disclosure by the disclosing party, (c) is disclosed with the prior written approval of the disclosing party, (d) was independently developed by it without any use of the Confidential Information of the disclosing party, (e) becomes known to it, without restriction, from a source other than the disclosing party without a duty of confidentiality to the disclosing party, or (f) is disclosed in response to an order or requirement of a court, administrative agency, or other governmental body or pursuant to the rules of any applicable securities market or exchange; provided, however, that (i) the receiving party must provide prompt advance notice of the proposed disclosure to the disclosing party, and (ii) any Confidential Information so disclosed shall otherwise remain subject to the provisions of this Section. All source code and the terms of this Agreement will be considered Confidential Information.',
      'Each party acknowledges that breach of this Section by it would result in irreparable harm to the other party, for which money damages would be an insufficient remedy, and therefore that the other party will be entitled to seek injunctive relief to enforce the provisions of this Section.',
    ],
  },
  {
    id: 'tab_item_23',
    title:
      'Intellectual Property Rights (IPR), Software Use and Licensing Rights',
    content: [
      'Cashia, their system vendor and where applicable their Licensors, grant you a limited, non-exclusive, non-transferable license for the use of any software application(s) you access through, or have downloaded to your personal computer, mobile device, or platform, including an Application Programming Interface (API) or a Developer’s toolkit where applicable. In accordance with this Agreement, this software should be for your personal use only. Transferring your usage rights, leasing or renting of this software is strictly prohibited and any such action is cause for termination of this Agreement and potentially other Cashia action. You further agree not to alter, reproduce, adapt, distribute, display, publish, reverse engineer, translate, disassemble, decompile or otherwise attempt to create any source code which is derived from the software. With this you acknowledge that all rights to this software belong exclusively to Cashia and its Distributors, or to a third party provider depending on the contractual arrangements between Cashia and the third party provider.',
      'The rights to any software application you may use through Cashia belonging to a third party is subject to license and usage terms you agreed to with the third party provider. For as long as Cashia does not own any rights to the software application provided by the third party, it will have no control over it and you acknowledge that it will bear no responsibility over any issues that may arise from their download and/or use, including but not limited to potential threat from malicious software.',
      'Unless otherwise set forth in this Agreement, Cashia or their licensors are the owners of all rights, interests and titles of:',
      'Logos, trade names, registered and unregistered trademarks, custom designs;',
      'Proprietary software core code, patentable applications;',
      'Trade secrets;',
      'Registered and unregistered copyrights',
      ' Any and all other intellectual property, proprietary rights or other rights related to intangible property which are used, or developed in connection with any of the Cashia services and the Merchant agrees not to claim ownership of or interest in any such IPR.',
      'The Merchant acknowledges that it has not obtained any rights expressed or implied other than the rights granted to it under the terms of this Agreement, and that no title of ownership has been transferred to it under this Agreement. The Merchant further acknowledges that any work it performs in enhancing or modifying, expanding, compressing, compiling or recasting in any way pre existing versions of work provided to it shall be owned by Cashia and its Distributors, and all existing and future copyright and other right, title and interest shall automatically bestow to Cashia and its Distributors.',
      'Except to the extent permitted by applicable law, the Merchant shall not undertake any action with the intent to ascertain and/or obtain for any reason or purpose the Kashia Services Limited’s and its Distributors’ systems source code, processes or data. The Merchant has not been granted the right and shall not sell or resell, lease or sublet, sublicense, lend or otherwise transfer the Services or components thereof.',
    ],
  },
  {
    id: 'tab_item_24',
    title: 'Dispute Resolution',
    content: [
      'Cashia understands that there may be a dispute between It and a User in relation to the services provided. The aim is to try and resolve the dispute in an efficient and effective manner that will keep its Clients satisfied with their overall experience with Cashia and its Distributors. It further understands that some disputes may be hard to resolve without the contribution of an independent party and in certain cases without using a dispute resolution mechanism.',
      'As a first step you can report a dispute between you and Cashia by contacting the Kashia Services Limited’s and its Distributors’ Client Administration department by email to support@Cashia.com or by submitting the online form and a client service representative will issue a ticket in reference to the dispute matter. Cashia will then review the dispute claim and try to address it as quickly as possible. The client service representative will then communicate to you the dispute resolution mechanism and the proposed remedy to resolve it.',
      'Any User has the right to file a complaint with the certified local Consumer protection authorities',
    ],
  },
  {
    id: 'tab_item_25',
    title: 'Miscellaneous Terms',
    content: [
      'Neither party will be liable to the other party for any loss or damage resulting from any delay or failure to perform all or any part of this Agreement, except for failure to pay monies due, if such delay or failure is caused, in whole or in part, by circumstances beyond its control and not as a result of its own negligence. Such circumstances include, without limitation, acts of God, strikes, lockouts, riots, acts of war, acts of violence, acts of terror, earthquakes, floods, fire and explosions.',
      'Failure by either party to enforce any provision of this Agreement will not be deemed a waiver of future enforcement of that or any other provision. Any waiver, amendment, supplementation or other modification or supplementation of any provision of this Agreement will be effective only if in writing and signed by both parties. If for any reason a court of competent jurisdiction finds any provision or portion of this Agreement to be unenforceable, that provision of this Agreement will be enforced to the maximum extent permissible so as to effect the intent of the parties, and the remainder of this Agreement will continue unmodified except as necessary to avoid unfairness.',
      'This Agreement will be binding upon and inure to the benefit of the parties to this Agreement and their respective successors and permitted assigns; provided, however, that the Merchant may not assign any of its rights, obligations, or privileges under this Agreement without the prior written consent of Cashia and its Distributors. Notwithstanding the foregoing, however, either party may assign this Agreement in connection with an asset sale, merger, acquisition, corporate reorganization or the like. Any attempted assignment in violation of this Section shall be void.',
      'Cashia provides the Services to a Client as specifically stated in this Agreement without any warranty. Cashia does not guarantee uninterrupted or secure access to any part of its Service, nor does it take any responsibility for any delay or failure in delivering the services under this Agreement. Cashia may undertake to perform maintenance and/or repair work on its systems that may restrict a Client’s access to the account. Cashia expect that such scheduled or unscheduled maintenance works, may extend for a certain period that will limit/restrict access to the system (including its website).',
      'Cashia cannot ensure that the parties in a transaction will complete the transaction. Cashia also does not have any control over the products or services that are paid for with the Cashia Service.',
      ' To the best of its knowledge, Cashia will spare no effort to ensure the accuracy of the information it provides to the Clients. They cannot however, guarantee the accuracy of the information, and Clients are warned/encouraged not to place great reliance on such information for their decision-making process. A Client is responsible for understanding and complying with any laws, rules and regulations of his /her specific jurisdiction that may be applicable to him/her in connection with the use of the Services.',
      'Clients agree to indemnify and hold Cashia and its Distributors, their employees, affiliates, associates and any persons or who are authorized to act on their behalf harmless from any claim or demand (including legal fees) made or incurred by any third party as a result of their or their employees’, affiliates, associates, or agents’ breach of this Agreement, breach of any applicable law and/or use of the Services.',
      'This Agreement (including any Schedule) in its entirety constitute the basis of the relationship between Cashia and its Clients. Sections 11, 19, 13, 15, as well as any other terms which by their nature should survive, will survive the termination of this Agreement. If any provision of this agreement shall be held to be invalid or unenforceable, the same shall be deemed to be deleted to the extent necessary to cure such invalidity or unenforceability and all other provisions of this agreement shall remain in full force and effect.',
      'Except if something else is agreed upon in writing between the Merchant and Cashia and its Distributors, this Agreement is governed by, and construed in accordance with a) the law of the country where the services are provided and b) exceptionally, Cypriot law, for any dispute arising between Cashia and the Merchant related to Cashia which could be: obligations of Cashia to carry out the communications to the pertinent bank authorities, conflicts regarding the security of the e-money funds, anti-money laundering framework etc. The Courts of the place where the service is provided will have jurisdiction in relation to any claim, dispute or difference concerning this Agreement and any matter arising from it. Each party irrevocably waives any right it may have to object to any action being brought in those courts, to claim that the action has been brought in an inappropriate forum, or to claim that those courts do not have jurisdiction.',
      'The Merchant acknowledges and agrees that it shall not import, export, or re-export directly or indirectly, any product or service, or to use any of the Cashia services in a manner that violates the laws, regulations and/or decrees, declared sanctions of any applicable jurisdiction.',
      'Except as otherwise expressly stated in this Agreement, any notice sent or delivered by any Party to this Agreement shall be sent by next day courier, facsimile, electronic mail or by hand delivery to the address of shown below and shall be marked for the attention of the person specified below:',
      'Address: 6TH floor, Galant Mall, Parklands Road, Nairobi, Kenya',
      'E-mail: support@Cashia.com ',
      'To the attention of: Legal Department',
      'Any such notice shall be deemed to have been received, in the case of notices sent by next day courier 2 Business Days following the day of posting and in the case of notices sent by hand delivery, facsimile or electronic mail on the day that such notice is sent.',
      'The Merchant authorizes Cashia to provide it with information regarding the services including but not limited to service modifications, technical and performance issues, security information, and other service related matters, and agrees to receive such information via commercial emails, telephone calls and other means of communication. The Merchant also has the option to receive (to “opt-in” automatically by entering into this Agreement) information which include but are not limited to new products and services, or alternatively to “opt-out” which will prohibit Cashia from sending such communication. By opting out the Merchant is not forgoing its right to opt-in at any time by notifying Cashia that he wishes so.',
      'This Agreement will not be construed as creating an agency, partnership, joint venture or any other form of legal association between the parties and each party is an independent contractor.',
    ],
  },
  {
    id: 'tab_item_26',
    title: 'Consent ',
    content: [
      "By clicking/tapping the 'I agree' button, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions set forth above.",
      'In addition:',
      'By accessing or using our service, you agree to be bound by these terms and conditions. If you do not agree to all the terms and conditions of this agreement, then you may not access the service. By accepting these terms and conditions, you also acknowledge that you have read and understood them, and that you agree to comply with all applicable laws and regulations regarding your use of the service.',
      'If you are accepting these terms and conditions on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that entity to these terms and conditions, in which case the terms you and your in this agreement shall refer to such entity.',
    ],
  },
];
export default TermsAndConditions;
