import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import ProfileLayout from './Layout';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import Footer from '../../components/common/Footer';
import Button from '../../components/tailwind/Button';
import Checkbox from '../../components/tailwind/Checkbox';
import {
  OperatingTimeFieldsFragment,
  useUpdateMerchantMutation,
} from '../../graphql/generated';
import {days} from '../../utils/constants';
import {cn, convertTo12HourFormat} from '../../utils/reusableFunctions';
import {DayOfWeek} from '../../utils/types';
import {useUserAuth} from '../../utils/user';

type DayType = {
  title: string;
  display: string;
  key: string;
};
type OperatingDayTime = {
  dayKey?: string;
  startTime?: string;
  endTime?: string;
};
type CardItems = {
  openingHours?: string;
  closingHours?: string;
  days: Array<DayType>;
};
type Duration = {
  from?: string;
  to?: string;
};
type OperatingTime = {
  [key: string]: Duration;
};
export const genCardItems = (
  operatingTimes?: OperatingTimeFieldsFragment
): CardItems[] | undefined => {
  const obj: Record<string, DayOfWeek[]> = {};
  if (!operatingTimes) return;
  Object.entries(operatingTimes).forEach(([key, value]) => {
    if (value === 'OperatingTime' || key === '__typename') return;
    value?.map((item) => {
      if (item?.from && item?.to && item?.from) {
        obj[item?.from + item?.to] = obj[item?.from + item?.to]?.concat([
          key as DayOfWeek,
        ]) || [key as DayOfWeek];
      }
    });
  });
  if (!obj) return;
  return Object.entries(obj).map(([key, value]) => ({
    days: value.map((entry) => days.filter((day) => day.key === entry)).flat(),
    openingHours: key.slice(0, 5),
    closingHours: key.slice(5),
  }));
};

interface CustomTypographyProps {
  className?: string;
  children: React.ReactNode;
}

const CustomTypography: React.FC<CustomTypographyProps> = ({
  className,
  children,
}) => <p className={`text-24px ${className ?? ''}`}>{children}</p>;

const ProfileOperatingHours = () => {
  const [updateMerchant] = useUpdateMerchantMutation();
  const [, setFocus] = useState('');

  const {merchant} = useUserAuth();
  const merchantId = useMemo(() => merchant?.id, [merchant]);

  const navigate = useNavigate();

  const [showDays, setShowDays] = useState(false);
  const [operatingDayHours, setOperatingDayHours] = useState<OperatingDayTime>({
    dayKey: '',
    startTime: '07:00',
    endTime: '20:00',
  });
  const [selectedDays, setSelectedDays] = useState<DayType[]>([]);
  const [cards, setCards] = useState<CardItems[]>([]);

  const operatingHours = useMemo(
    () => merchant?.operatingTime,
    [merchant?.operatingTime]
  );

  useEffect(() => {
    if (!operatingHours) return;
    setCards(genCardItems(operatingHours) || []);
  }, [operatingHours]);

  const menuRef = useRef<HTMLDivElement>(null);

  const submitUpdate = useCallback(
    async (times: CardItems[]) => {
      const operatingTime: OperatingTime = {};

      times.forEach((item) => {
        item.days.forEach((day) => {
          operatingTime[day.key] = {
            from: item.openingHours,
            to: item.closingHours,
          };
        });
      });

      await updateMerchant({
        variables: {
          id: merchantId || '',
          input: {
            operatingTime,
          },
        },
      });
    },
    [updateMerchant, merchantId]
  );

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (!menuRef.current?.contains(e.target as Node)) {
        setShowDays(false);
      }
    });
  }, []);

  const hasDays = useMemo(() => !!selectedDays.length, [selectedDays]);

  const onSelectDayChange = useCallback(
    (item: DayType) => {
      const existingIndex = selectedDays.findIndex(
        (day) => day.title === item.title
      );

      if (existingIndex > -1) {
        setSelectedDays((old) => {
          return [
            ...old.slice(0, existingIndex),
            ...old.slice(existingIndex + 1),
          ];
        });
      } else {
        setSelectedDays((old) => [...old, item]);
      }
    },
    [selectedDays]
  );

  const isChecked = useCallback(
    (item: DayType) => {
      const exists = selectedDays.findIndex((day) => day.title === item.title);
      return exists > -1;
    },
    [selectedDays]
  );

  const addDayTime = useCallback(() => {
    setFocus('');
    if (!hasDays) {
      return;
    } /* there is default for opening and closing hours*/
    const newCards = [
      ...cards,
      {
        openingHours: operatingDayHours.startTime,
        closingHours: operatingDayHours.endTime,
        days: selectedDays,
      },
    ];
    setCards(newCards);
    void submitUpdate(newCards);
  }, [
    hasDays,
    cards,
    operatingDayHours.startTime,
    operatingDayHours.endTime,
    selectedDays,
    submitUpdate,
  ]);

  const breadcrumbs = [
    <a href="/profile" className="text-foggy hover:underline" key="1">
      Profile
    </a>,
    <a href="operating_hours" className="text-foggy hover:underline" key="2">
      Operating hours
    </a>,
  ];

  return (
    <ProfileLayout>
      <div className="flex flex-col w-full min-h-screen">
        <main className="sm:px-12 lg:pt-14 lg:p-9 w-full flex-1 flex flex-col gap-7 overflow-y-scroll no-scrollbar pb-16">
          <div className="sm:flex items-center gap-28 border-b-[1px] lg:hidden">
            <button
              className="flex py-5 items-center"
              onClick={() => navigate('/profile')}>
              <ArrowBackIcon />
            </button>
            <CustomTypography className="mr-20 mt-1 text-lg">
              Operating hours
            </CustomTypography>
          </div>
          <div className="sm:hidden lg:flex items-center text-foggy">
            {breadcrumbs.map((breadcrumb, index) => (
              <React.Fragment key={index}>
                {index > 0 && <span className="mx-2">&gt;</span>}
                {breadcrumb}
              </React.Fragment>
            ))}
          </div>
          <h1 className="text-neutral-800 sm:text-xl lg:text-[34px] font-semibold">
            Operating hours
          </h1>
          <div className="flex gap-7 flex-wrap">
            {cards?.map((card, i) => (
              <div
                key={i}
                className={cn(
                  'sm:w-full lg:w-[350px] px-5 pb-5 pt-3 flex flex-col gap-5 bg-neutral-50 rounded shadow-md border border-zinc-300'
                )}>
                <div className="flex gap-5">
                  {card.days.map((day) => (
                    <p
                      key={day.display}
                      className="text-cyan-500 text-[13px] font-normal font-['Metropolis']">
                      {day.display}
                    </p>
                  ))}
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col gap-2">
                    <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                      Opening hours
                    </p>
                    <p className="text-neutral-800 text-2xl font-normal font-['Metropolis']">
                      {convertTo12HourFormat(card.openingHours || '')}
                    </p>
                  </div>
                  <div className="w-0.5 h-full bg-zinc-200 rounded-[10px]" />
                  <div className="flex flex-col gap-2">
                    <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                      Closing hours
                    </p>
                    <p className="text-neutral-800 text-2xl font-normal font-['Metropolis']">
                      {convertTo12HourFormat(card.closingHours || '')}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <h2 className="sm:text-neutral-500 lg:text-neutral-800 sm:text-base lg:text-xl font-semibold">
              Add new hours
            </h2>
            <div className="w-full flex lg:gap-4 sm:gap-0 flex-wrap bg-zinc-100 rounded-[10px] shadow border border-stone-300 mt-5 py-[10px] px-5">
              <label htmlFor="opHours" className="flex-1 pr-5 cursor-pointer">
                <p className="text-neutral-800 text-[13px] font-normal">
                  Opening hours
                </p>
                <input
                  type="time"
                  id="opHours"
                  value={operatingDayHours.startTime}
                  className="w-full bg-transparent focus:outline-none py-1 cursor-pointer"
                  onFocus={(e) => e.target.showPicker()}
                  onChange={(e) => {
                    setOperatingDayHours((prevState) => ({
                      ...prevState,
                      startTime: e.target.value,
                    }));
                  }}
                />
              </label>
              <label
                htmlFor="clHours"
                className="flex-1 border-l border-stone-300 px-5 cursor-pointer">
                <p className="text-neutral-800 text-[13px] font-normal">
                  Closing hours
                </p>
                <input
                  type="time"
                  id="clHours"
                  value={operatingDayHours.endTime}
                  onFocus={(e) => e.target.showPicker()}
                  className="w-full bg-zinc-100 focus:outline-none py-1 cursor-pointer"
                  onChange={(e) => {
                    setOperatingDayHours((prevState) => ({
                      ...prevState,
                      endTime: e.target.value,
                    }));
                  }}
                />
              </label>
              <div
                className="flex-1 border-x border-stone-300 px-5 cursor-pointer select-none"
                onClick={() => {
                  setShowDays(!showDays);
                  setFocus('days');
                }}>
                <p className="text-neutral-800 text-[13px] font-normal">Days</p>
                <p className="text-neutral-500 text-base font-normal py-1">
                  {hasDays
                    ? selectedDays.reduce(
                        (init, curr) => `${init}  ${curr.display}`,
                        ''
                      )
                    : 'Select Days'}
                </p>
              </div>
              <div className="flex-1 flex justify-center items-center">
                <Button
                  label={
                    <div className="text-center text-neutral-50 text-base font-normal">
                      Add +
                    </div>
                  }
                  fullWidth
                  className={`w-[80%] py-3 h-full rounded-[10px] ${
                    hasDays ? 'bg-cashiaBlue' : 'bg-greyish'
                  }`}
                  disabled={!hasDays}
                  onClick={addDayTime}
                />
              </div>
            </div>
            {showDays && (
              <div
                className="w-full bg-neutral-50 rounded-[10px] shadow mt-5"
                ref={menuRef}>
                {days.map((day, i) => (
                  <div
                    key={i}
                    className="flex justify-between items-center p-5 border-b border-neutral-200 cursor-pointer transition duration-200 select-none hover:bg-slate-100"
                    onClick={() => onSelectDayChange(day)}>
                    <p className="text-neutral-800 sm:text-[14px] lg:text-base font-normal leading-tight">
                      {day.title}
                    </p>
                    <Checkbox accentSmoothRed checked={isChecked(day)} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </main>
        <div className="sm:hidden sm:mx-20 lg:mx-0 mb-20 lg:flex flex-col">
          <Footer />
        </div>
      </div>{' '}
    </ProfileLayout>
  );
};

export default ProfileOperatingHours;
