import {toast} from '@cashiaApp/web-components';
import React, {FormEvent, useCallback, useEffect, useState} from 'react';

import closeIcon from '../../../assets/icons/close-circle.svg';
import DeleteIcon from '../../../assets/images/DeleteIcon.svg';
import EditIcon from '../../../assets/images/EditIcon.svg';
import Button from '../../../components/tailwind/Button';
import Checkbox from '../../../components/tailwind/Checkbox';
import Modal from '../../../components/tailwind/Modal';
import Spinner from '../../../components/tailwind/Spinner';
import TextInput from '../../../components/tailwind/TextInput';
import {
  ProductCategoriesDocument,
  ProductCategoriesQuery,
  ProductCategoryFieldsFragment,
  useAddOrUpdateProductCategoryMutation,
  useRemoveProductCategoryMutation,
} from '../../../graphql/generated';
import {newCategoryNameLimit} from '../../../utils/constants';
import {updateStringStateWithLimit} from '../../../utils/reusableFunctions';

type Props = {
  loading: boolean;
  productsCategoriesData: ProductCategoriesQuery | undefined;
  merchantId: string;
  categoryIds?: string[];
  updateCategoryIds?: (id: string) => void;
  checkIfItExist: (newCategoryName: string) => boolean;
};
const CategoryList = ({
  productsCategoriesData,
  loading,
  merchantId,
  categoryIds,
  updateCategoryIds,
  checkIfItExist,
}: Props) => {
  const [
    addOrUpdateProductCategoryMutation,
    {
      loading: productsCategoriesMutationLoading,
      error: productsCategoriesMutationError,
    },
  ] = useAddOrUpdateProductCategoryMutation();
  const [
    removeProductCategoryMutation,
    {loading: removeLoading, error: removeMutationError},
  ] = useRemoveProductCategoryMutation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [updatingCategoryName, setUpdatingCategoryName] = useState('');
  const [selectedCategory, setSelectedCategory] =
    useState<ProductCategoryFieldsFragment | null>();
  const [updatingCategory, setUpdatingCategory] =
    useState<ProductCategoryFieldsFragment | null>();

  const toggleDeleteModal = useCallback(
    (selected?: ProductCategoryFieldsFragment) => {
      if (selected) setSelectedCategory(selected);
      else setSelectedCategory(undefined);
      setOpenDeleteModal(!openDeleteModal);
    },
    [openDeleteModal]
  );
  const toggleUpdateCategory = useCallback(
    (selected?: ProductCategoryFieldsFragment) => {
      if (selected) {
        setUpdatingCategory(selected);
        setUpdatingCategoryName(selected.name);
      } else {
        setUpdatingCategory(null);
        setUpdatingCategoryName('');
      }
    },
    []
  );

  const updateProductCategory = useCallback(async () => {
    if (checkIfItExist(updatingCategoryName)) return;
    const res = await addOrUpdateProductCategoryMutation({
      variables: {
        input: {
          id: updatingCategory?.id,
          name: updatingCategoryName.toLowerCase(),
        },
      },
      update: (cache, result) => {
        const variables = {
          merchantId,
        };
        const cached = cache.readQuery<ProductCategoriesQuery>({
          query: ProductCategoriesDocument,
          variables,
        });
        cache.writeQuery({
          query: ProductCategoriesDocument,
          variables,
          data: {
            ...cached,
            productCategories: [
              ...(cached?.productCategories || []).map((item) =>
                item.id === result.data?.addOrUpdateProductCategory.id
                  ? {
                      ...item,
                      name: result.data.addOrUpdateProductCategory.name,
                    }
                  : item
              ),
            ],
          },
        });
      },
    });
    if (res.data?.addOrUpdateProductCategory) toggleUpdateCategory();
  }, [
    addOrUpdateProductCategoryMutation,
    checkIfItExist,
    merchantId,
    toggleUpdateCategory,
    updatingCategory?.id,
    updatingCategoryName,
  ]);

  const deleteProductCategory = useCallback(async () => {
    const res = await removeProductCategoryMutation({
      variables: {
        id: selectedCategory?.id || '',
      },
      update: (cache, result) => {
        const variables = {
          merchantId,
        };
        const cached = cache.readQuery<ProductCategoriesQuery>({
          query: ProductCategoriesDocument,
          variables,
        });
        if (!result.data?.removeProductCategory) return;
        cache.writeQuery({
          query: ProductCategoriesDocument,
          variables,
          data: {
            ...cached,
            productCategories: [...(cached?.productCategories || [])].filter(
              (item) => item.id !== selectedCategory?.id
            ),
          },
        });
      },
    });
    if (res.data?.removeProductCategory) {
      toast.error(`'${selectedCategory?.name || 'Category'}' is deleted`);
      toggleDeleteModal();
    }
  }, [
    merchantId,
    removeProductCategoryMutation,
    selectedCategory?.id,
    selectedCategory?.name,
    toggleDeleteModal,
  ]);

  useEffect(() => {
    if (
      !(
        productsCategoriesMutationError?.message || removeMutationError?.message
      )
    ) {
      return;
    }
    const msg =
      productsCategoriesMutationError?.message ||
      removeMutationError?.message ||
      'Error occurred';
    toast.error(msg);
  }, [productsCategoriesMutationError?.message, removeMutationError?.message]);
  const submitNameToCategory = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <>
      {loading && <Spinner height={40} />}
      {productsCategoriesData?.productCategories?.map((cat) => (
        <div
          key={cat.id}
          className="flex justify-between items-center lg:pr-8 sm:pr-0 border-b-[0.5px] border-zinc-400 pb-5">
          {updatingCategory?.id === cat.id ? (
            <form
              className="flex flex-col md:flex-row gap-4 md:gap-7 items-center w-full"
              onSubmit={submitNameToCategory}>
              <TextInput
                value={updatingCategoryName}
                autoFocus
                containerStyle="w-full"
                endAdornment={
                  <span className="text-cashiaBlue text-[10px] lg:text-[13px]">
                    {newCategoryNameLimit - (updatingCategoryName.length || 0)}
                  </span>
                }
                onChange={(e) =>
                  updateStringStateWithLimit(
                    e.target.value,
                    newCategoryNameLimit,
                    setUpdatingCategoryName
                  )
                }
              />
              <div className="flex items-center gap-1 md:gap-5">
                <Button
                  label={
                    productsCategoriesMutationLoading ? (
                      <Spinner fillColor="fill-white" width={45} />
                    ) : (
                      'Save'
                    )
                  }
                  className={`${
                    !(
                      !updatingCategoryName.length ||
                      checkIfItExist(updatingCategoryName)
                    )
                      ? 'bg-smoothRed'
                      : 'bg-greyish'
                  } w-[76px]`}
                  labelStyle="text-neutral-50 text-base font-normal text-center"
                  disabled={checkIfItExist(updatingCategoryName)}
                  onClick={updateProductCategory}
                />
                <Button
                  label="Cancel"
                  className="bg-lightGrey"
                  labelStyle="text-black text-base font-normal"
                  onClick={toggleUpdateCategory}
                />
              </div>
            </form>
          ) : (
            <div className="flex justify-between w-full">
              <p className="text-neutral-800 lg:w-full w-[50%] whitespace-normal truncate text-base font-normal capitalize">
                {cat.name}
              </p>
              <div className="flex lg:gap-24 gap-10 items-center">
                {categoryIds && updateCategoryIds && (
                  <Checkbox
                    checked={categoryIds?.includes(cat.id)}
                    onChange={() => updateCategoryIds?.(cat.id)}
                  />
                )}
                <div className="flex gap-10 items-center">
                  <img
                    className="h-6 w-6 cursor-pointer"
                    src={DeleteIcon}
                    onClick={() => toggleDeleteModal(cat)}
                  />
                  <img
                    className="h-6 w-6 cursor-pointer"
                    src={EditIcon}
                    onClick={() => toggleUpdateCategory(cat)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <Modal isVisible={openDeleteModal} onClose={toggleDeleteModal}>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center p-7 pb-4">
            <h4 className="text-xl font-semibold leading-5">Delete category</h4>
            <img
              src={closeIcon}
              className="cursor-pointer"
              onClick={() => toggleDeleteModal()}
            />
          </div>
          <hr />
          <div className="flex flex-col p-7">
            <p>
              There are products listed under the “
              <span className="text-cashiaBlue capitalize">
                {selectedCategory?.name}
              </span>
              “ category.
            </p>
            <p>Are you sure you want to delete it?</p>
            <div className="mt-5 flex gap-5 mb-4">
              <Button
                label={
                  removeLoading ? (
                    <Spinner fillColor="fill-white" width={50} />
                  ) : (
                    'Delete'
                  )
                }
                className="bg-cashiaBlue"
                labelStyle="text-neutral-50 text-base font-normal px-5 md:px-7"
                disabled={removeLoading}
                onClick={deleteProductCategory}
              />
              <Button
                label="Cancel"
                className="bg-lightGrey"
                labelStyle="text-black text-base font-normal px-5 md:px-7"
                onClick={() => toggleDeleteModal()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CategoryList;
