import {Box, Divider, LinearProgress, Typography} from '@mui/material';
import React, {PropsWithChildren, memo} from 'react';

import DistanceIcon from '../../../assets/icons/distance_icon.svg';
import DriverImage from '../../../assets/icons/driver_image.svg';
import EstimatedTime from '../../../assets/icons/estimated_arrival.svg';

type DriverDetailsModalProps = {
  duration?: string;
  distance?: string;
  progress: number;
};

const DriverDetailsModal: React.FC<
  PropsWithChildren<DriverDetailsModalProps>
> = ({distance, duration, progress}) => {
  const styles = {
    mainss: {
      zIndex: 1,
      position: 'absolute',
      width: {xs: '250px', sm: '325px'},
      borderRadius: '10px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      top: 200,
      left: 10,
      backgroundColor: 'white',
    },
    upperBox: {
      padding: '30px',
      backgroundColor: '#EEEEEE',
    },
    estimatedTime: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    estimatedTimeText: {
      marginLeft: '10px',
      color: '#494949',
    },
    linearProgress: {
      color: '#DC1F5C',
      marginY: '20px',

      backgroundColor: '#D0CECD',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#DC1F5C',
      },
    },
    lowerBox: {
      display: 'flex',
      padding: '30px',
    },
    driverText: {
      fontSize: '16px',
      color: '#212121',
      fontWeight: 400,
      lineHeight: '18px',
    },
    driverTransportText: {
      fontSize: '13px',
      color: '#757575',
      fontWeight: 400,
      lineHeight: '18px',
    },
    supportText: {color: '#757575', fontSize: '16px', lineHeight: '18px'},
  };
  return (
    <Box sx={styles.mainss}>
      <Box style={styles.upperBox}>
        <Box sx={styles.estimatedTime}>
          <Box sx={{display: 'flex'}}>
            <img src={EstimatedTime} alt="estimatedTime" />
            <Typography sx={styles.estimatedTimeText}>
              Estimated Arrival
            </Typography>
          </Box>
          {duration && <Typography>{duration}</Typography>}
        </Box>
        <Divider sx={{marginY: '20px'}} />
        <Box sx={styles.estimatedTime}>
          <Box sx={{display: 'flex'}}>
            <img src={DistanceIcon} alt="distanceIcon" />
            <Typography sx={styles.estimatedTimeText}>Distance</Typography>
          </Box>
          {distance && <Typography>{distance}</Typography>}
        </Box>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={styles.linearProgress}
        />
      </Box>
      <Box>
        <Box sx={styles.lowerBox}>
          <img src={DriverImage} alt="driver" />
          <Box sx={{marginLeft: '10px', marginTop: '5px'}}>
            <Typography sx={styles.driverText}>Jonathan Rotich</Typography>
            <Typography sx={styles.driverTransportText}>
              Boda (KCFD-345A)
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.lowerBox}>
          <Typography sx={styles.supportText}>
            Facing an issue?
            <span style={{color: '#DC1F5C'}}> Chat support</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(DriverDetailsModal);
