import Div from '../../../components/common/Div';
import {useInboxContext} from '../Inbox';

const NotificationSection = () => {
  const {chatRoom} = useInboxContext();
  return (
    <>
      {chatRoom ? (
        <Div sx={styles.content}>
          <p>No new notifications</p>
        </Div>
      ) : (
        <Div sx={styles.content} />
      )}
    </>
  );
};

const styles = {
  content: {
    overflowY: 'scroll',
    marginLeft: '20px',
    width: '100%',
  },
};

export default NotificationSection;
