import {MerchantResolvers} from '../../../graphql/generated';

const merchant: MerchantResolvers = {
  website: () => 'http://example.com',
  phone: () => ({
    countryCode: '254',
    number: '123456789',
  }),
};

export default merchant;
