import {useNavigate} from 'react-router-dom';

import Button from '../../../components/tailwind/Button';
import Spinner from '../../../components/tailwind/Spinner';
import {cn} from '../../../utils/reusableFunctions';
import {useSelfOnboardingContentContext, links} from '../layout';

type Props = {
  className?: string;
  next?: string;
  disabled?: boolean;
  updateLoading?: boolean;
  update?: () => Promise<void>;
};
const Footer = ({
  className,
  next,
  update,
  disabled = false,
  updateLoading = false,
}: Props) => {
  const {step, totalSteps} = useSelfOnboardingContentContext();
  const navigate = useNavigate();
  const progressBarWidth = ((step + 1) * 100) / totalSteps;

  return (
    <div className="w-full h-[70px]">
      <div
        className="h-0.5 bg-gradient-to-r from-rose-600 via-pink-600 to-yellow-500"
        style={{width: `${String(progressBarWidth)}%`}}
      />
      <div
        className={cn(
          'px-6 flex justify-between w-full items-center py-4 bg-neutral-100',
          className
        )}>
        <Button
          label="Back"
          labelStyle="text-base font-normal text-black"
          className="border border-slate-900 bg-transparent rounded-xl"
          onClick={() => navigate(links[step - 1]?.link || '/welcome')}
        />
        <p className="text-neutral-500 text-[13px] font-normal">
          {totalSteps - (step + 1)
            ? `${totalSteps - (step + 1)} items left`
            : 'Final step'}
        </p>
        <Button
          label={
            updateLoading ? (
              <Spinner fillColor="fill-white" width={36.5} />
            ) : (
              'Next'
            )
          }
          className="rounded-xl"
          disabled={disabled}
          labelStyle="text-neutral-50 text-base font-normal"
          onClick={() => {
            if (disabled) return;
            update
              ? void update()
              : navigate(next || links[step + 1]?.link || '');
          }}
        />
      </div>
    </div>
  );
};

export default Footer;
