import {PropsWithChildren} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {useAuth} from '../utils/auth';

const RequireAuthLayout = () => {
  return (
    <RequireAuth>
      <div className="h-screen w-screen overflow-hidden">
        <Outlet />
      </div>
    </RequireAuth>
  );
};

const RequireAuth = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }
  return <>{children}</>;
};

export default RequireAuthLayout;
