import {Divider, Tab, Tabs, Typography} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';

import CouriersWidget from './components/CouriersWidget';
import Div from '../../components/common/Div';
import MiniNavbar from '../../components/common/bottomNavbar';
import {colours} from '../../utils/constants';
import useWindowSize from '../inbox/hooks/useWindowSize';

type NavItemsType = {
  name: string;
  to: string;
}[];

const navItems: NavItemsType = [
  {
    name: 'Requested',
    to: '/orders',
  },
  {
    name: 'Delivered',
    to: '/orders/delivered',
  },
  {
    name: 'Failed',
    to: '/orders/failed',
  },
];

const Orders = () => {
  const location = useLocation();
  const {width} = useWindowSize();
  const currIndex = useMemo(() => {
    return navItems.findIndex((x) => x.to === location.pathname);
  }, [location.pathname]);
  const navigate = useNavigate();
  const isSmallScreen = width > 900;
  const [isCourierSection, setIsCourierSection] = useState(false);

  useEffect(() => {
    const inCourierSection = location.pathname
      .toLowerCase()
      .endsWith('my-couriers');

    setIsCourierSection(inCourierSection);
  }, [location.pathname]);

  return (
    <div className="flex flex-col h-[calc(100svh-64px)] w-screen overflow-y-scroll no-scrollbar">
      <Div sx={styles.main}>
        {!isCourierSection && (
          <Fragment>
            <Typography sx={styles.orderText}>Orders</Typography>
            <CouriersWidget />
            <Div sx={styles.fullWidth}>
              <Tabs
                value={currIndex}
                sx={styles.navText}
                textColor={isSmallScreen ? 'primary' : 'inherit'}
                TabIndicatorProps={{
                  style: isSmallScreen ? styles.tabPrimary : styles.tabSmall,
                }}>
                {navItems.map((item, i) => (
                  <Tab
                    key={i}
                    sx={styles.tab}
                    label={item.name}
                    onClick={() => navigate(item.to)}
                  />
                ))}
              </Tabs>
              <Divider />
            </Div>
          </Fragment>
        )}
        <Outlet />
      </Div>
      <div
        className="sm:grid lg:hidden fixed bottom-0 px-8 bg-white w-full"
        style={{boxShadow: '-8px -4px 20px -5px rgba(0, 0, 0, 0.25)'}}>
        <MiniNavbar />
      </div>
    </div>
  );
};

const styles = {
  navs: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  mini: {
    position: 'fixed',
    bottom: 20,
    left: 0,
    display: {md: 'none', xs: 'flex'},
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colours.white,
    width: '100%',
  },
  fullWidth: {width: '100svw', marginTop: {md: '0px', xs: '15px'}},
  orderText: {
    display: {md: 'flex', xs: 'none'},
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '34px',
    letterSpacing: '-0.03em',
    marginY: '35px',
  },
  courierText: {
    display: 'flex',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    letterSpacing: '-0.03em',
  },
  navText: {
    padding: '0px',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
    '& .MuiIn': {
      borderRadius: '1px',
    },
  },
  tabPrimary: {
    backgroundColor: colours.cashiaBlue,
  },
  tabSmall: {
    backgroundColor: colours.black,
  },
  main: {
    paddingX: {md: '35px', xs: '15px'},
    overflowX: 'hidden',
  },
  tab: {
    padding: '0px',
    marginRight: {md: '35px', xs: '15px'},
    minWidth: '80px',
    textAlign: 'left',
    textTransform: 'capitalize',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: {md: '16px', xs: '14px'},
    lineHeight: '20px',
  },
};

export default Orders;
