import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ProfileLayout from './Layout';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close-black.svg';
import NoImg from '../../assets/images/no_photo.svg';
import Footer from '../../components/common/Footer';
import GQLError from '../../components/common/GQLError';
import LoadingModal from '../../components/common/LoadingModal';
import Modal from '../../components/tailwind/Modal';
import {
  useProductsQuery,
  useAddOrUpdateProductMutation,
  ProductsQuery,
  ProductsDocument,
  ProductWithVariantsFieldsFragment,
} from '../../graphql/generated';
import defaultVariant from '../../utils/defaultVariant';
import {useUserAuth} from '../../utils/user';

interface CustomButtonProps {
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  className,
}) => (
  <button
    onClick={onClick}
    className={`text-white w-20 h-8 bg-smoothRed text-13px leading-0 rounded-md flex justify-center items-center ${
      className ?? ''
    }`}>
    {children}
  </button>
);

interface CustomTypographyProps {
  className?: string;
  children: React.ReactNode;
}

const CustomTypography: React.FC<CustomTypographyProps> = ({
  className,
  children,
}) => <p className={`text-24px ${className ?? ''}`}>{children}</p>;

const ArchivedProducts = () => {
  const navigate = useNavigate();
  const {merchant} = useUserAuth();

  const {data: allProductsData} = useProductsQuery({
    variables: {
      merchantId: merchant?.id || '',
    },
  });

  const [selectedProduct, setSelectedProduct] =
    useState<ProductWithVariantsFieldsFragment | null>(null);

  const handleOpenModal = (productId: ProductWithVariantsFieldsFragment) => {
    if (window.innerWidth <= 768) {
      setSelectedProduct(productId);
    }
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const [updateProduct, {loading, error}] = useAddOrUpdateProductMutation();

  const archivedProducts = useMemo(
    () =>
      allProductsData?.merchant?.products.items?.filter(
        (item) => !item?.active
      ),
    [allProductsData?.merchant?.products.items]
  );

  const handleRestore = useCallback(
    async (id?: string | null) => {
      if (!id) return;
      await updateProduct({
        variables: {
          input: {
            id,
            active: true,
          },
        },
        update: (cache, res) => {
          const cached = cache.readQuery<ProductsQuery>({
            query: ProductsDocument,
          });
          if (
            !cached ||
            !cached?.merchant?.products ||
            !res.data?.addOrUpdateProduct
          ) {
            return;
          }
          const updatedProductIndex = cached?.merchant.products.items.findIndex(
            (item) => item?.id === id
          );
          const existingProducts = cached?.merchant.products.items;
          cache.writeQuery<ProductsQuery>({
            query: ProductsDocument,
            data: {
              merchant: {
                ...cached.merchant,
                products: {
                  ...cached?.merchant.products,
                  items: [
                    ...existingProducts.slice(0, updatedProductIndex),
                    res.data.addOrUpdateProduct,
                    ...existingProducts.slice(updatedProductIndex + 1),
                  ],
                },
              },
            },
          });
        },
      });
    },
    [updateProduct]
  );

  const breadcrumbs = [
    <a className="text-foggy hover:underline" key="1" href="/profile">
      Profile
    </a>,
    <span className="text-foggy mx-2" key="">
      {'>'}
    </span>,
    <a className="text-foggy hover:underline" key="2" href="archived">
      Archived
    </a>,
  ];

  return (
    <ProfileLayout>
      <div className="flex flex-col min-h-screen ">
        <div className="sm:mx-4 lg:mx-4 lg:mt-12 flex flex-col min-h-full">
          <div className="sm:flex items-center gap-28 border-b-[1px] lg:hidden">
            <button
              className="flex py-3 items-center"
              onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </button>
            <CustomTypography className="mr-20 mt-1 text-lg">
              Archived
            </CustomTypography>
          </div>
          <div className="sm:hidden lg:flex mt-2">{breadcrumbs}</div>
          <CustomTypography className="sm:flex lg:hidden font-normal text-cashia-grey text-base mt-4">
            {archivedProducts?.length || 0} archived items{' '}
          </CustomTypography>
          <CustomTypography className="text-3xl font-semibold mt-4 sm:hidden lg:flex">
            Archived
          </CustomTypography>
          <div className="flex flex-wrap gap-8 mb-6">
            {archivedProducts?.length ? (
              archivedProducts?.map((product) => {
                const variant = product && defaultVariant(product);
                return (
                  <div
                    key={product?.id}
                    onClick={() => product && handleOpenModal(product)}
                    className="flex flex-col pb-full mt-8">
                    <div className="sm:h-[165px] lg:h-[200px] sm:w-[165px] lg:w-[200px] overflow-hidden rounded">
                      {variant?.images?.[0]?.large ? (
                        <img
                          src={variant?.images?.[0]?.large}
                          alt={product?.title || ''}
                          loading="lazy"
                          width="100%"
                          height="100%"
                          className="object-cover w-full h-full"
                          onError={(e) => {
                            if (e.currentTarget.src !== NoImg) {
                              e.currentTarget.src = NoImg;
                            }
                          }}
                        />
                      ) : (
                        <img src={NoImg} width="100%" height="100%" />
                      )}
                    </div>
                    <div className="sm:hidden lg:flex justify-between items-center mt-4">
                      <CustomTypography className="w-10 text-lg font-medium">
                        {product?.title}
                      </CustomTypography>
                      <CustomButton
                        onClick={() => handleRestore(product?.id)}
                        className="mt-1">
                        Restore
                      </CustomButton>
                    </div>
                    <Modal
                      isVisible={!!selectedProduct?.id}
                      onClose={handleCloseModal}>
                      <div className="p-4 flex justify-between items-center">
                        <div
                          onClick={() => {
                            void handleRestore(selectedProduct?.id);
                            handleCloseModal();
                          }}>
                          Restore: {selectedProduct?.title}
                        </div>

                        <div className="p-4" onClick={() => handleCloseModal()}>
                          <CloseIcon />
                        </div>
                      </div>
                    </Modal>
                  </div>
                );
              })
            ) : (
              <div>
                <CustomTypography className="">
                  There are no archived products
                </CustomTypography>
              </div>
            )}
          </div>
          <LoadingModal open={loading} />
          {error && <GQLError isVisible={!!error} error={error} />}
        </div>
        <div className="sm:hidden sm:mx-20 lg:mx-8 mt-auto mb-16 lg:flex flex-col min-h-full">
          <Footer />
        </div>
      </div>
    </ProfileLayout>
  );
};

export default ArchivedProducts;
