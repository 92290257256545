import {clsx, type ClassValue} from 'clsx';
import {twMerge} from 'tailwind-merge';

import ApproveIcon from '../../assets/icons/approve.svg';
import PendingIcon from '../../assets/icons/timer.svg';
import {
  OperatingTimeFieldsFragment,
  WalletActivationStatus,
} from '../../graphql/generated';
import {days} from '../constants';
import {OperatingHoursCardItems, DayOfWeek} from '../types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const updateStringStateWithLimit = (
  value: string,
  valueLimit: number,
  setterFunction: (value: string) => void
) => {
  if (value.length >= valueLimit) {
    setterFunction(value.substring(0, valueLimit));
  } else setterFunction(value);
};

export const removeOrAddElementInArray = <T>(array: T[], element: T): T[] => {
  return array.includes(element)
    ? [...array].filter((item) => item !== element)
    : [...array, element];
};

export const getOperatingHoursTimeGroups = (
  operatingTimes?: OperatingTimeFieldsFragment
): OperatingHoursCardItems[] | undefined => {
  const obj: Record<string, DayOfWeek[]> = {};
  if (!operatingTimes) return;
  Object.entries(operatingTimes).forEach(([key, value]) => {
    if (value === 'OperatingTime' || key === '__typename') return;
    value?.map((item) => {
      if (item?.from && item?.to && item?.from) {
        obj[item?.from + item?.to] = obj[item?.from + item?.to]?.concat([
          key as DayOfWeek,
        ]) || [key as DayOfWeek];
      }
    });
  });
  if (!obj) return;
  return Object.entries(obj).map(([key, value]) => ({
    days: value.map((entry) => days.filter((day) => day.key === entry)).flat(),
    openingHours: key.slice(0, 5),
    closingHours: key.slice(5),
  }));
};

export const convertTo12HourFormat = (time: string): string => {
  const [hours = 0, minutes = 0] = time.split(':').map(Number);
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  const twelveHourFormat = (hours % 12 || 12).toString();
  return `${twelveHourFormat.padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${meridiem}`;
};

export function formatFileSize(fileSizeInBytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  let fileSize = fileSizeInBytes;
  while (fileSize >= 1024 && index < units.length - 1) {
    fileSize /= 1024;
    index++;
  }
  return `${fileSize.toFixed(0)} ${units[index] as string}`;
}

export const readableWalletStatus = (status?: WalletActivationStatus) => {
  if (!status) return [];
  return {
    PENDING: ['Pending', PendingIcon],
    IN_PROGRESS: ['In Progress', PendingIcon],
    VERIFIED: ['Verified', ApproveIcon],
  }[status];
};

export const createHashtag = (name: string) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/&/g, 'and')
    .replace(/\s*\/\s*/g, '-')
    .replace(/\/+/g, '-')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
};
