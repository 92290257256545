import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import TextArea from '../../../components/tailwind/TextArea';
import TextInput from '../../../components/tailwind/TextInput';
import {
  ProductInput,
  ProductWithVariantsFieldsFragment,
} from '../../../graphql/generated';
import defaultVariant from '../../../utils/defaultVariant';
import {updateStringStateWithLimit} from '../../../utils/reusableFunctions';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

const ProductTitle = () => {
  const {productData, addProductToContext, isUpdating, update, updateLoading} =
    useAddOrUpdateProductContentContext();
  const {id} = useParams();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const limits = {
    title: 40,
    description: 150,
  };

  const productInput = useMemo(() => {
    const input: ProductInput = {
      title,
      variants: [
        {
          title,
          description,
          default: true,
        },
      ],
    };
    if (productData && Object.keys(productData.product || {}).length) {
      input.id = productData.product?.id;
      input.categoryIds = [
        ...(productData.product?.categories || []).map((c) => c.id),
      ];
      input.variants = [
        ...(productData?.product?.variants || []).map((variant) =>
          variant.default
            ? {
                id: variant?.id,
                images: variant?.images?.map((image) => image.id || ''),
                title,
                description,
              }
            : {
                id: variant.id,
                images: variant.images?.map((image) => image.id || '') || [],
              }
        ),
      ];
    }

    return input;
  }, [description, productData, title]);

  const saveToContext = (
    updatedProduct?: ProductWithVariantsFieldsFragment
  ) => {
    void addProductToContext?.(
      updatedProduct?.id || '',
      isUpdating
        ? `/store/product/${productData?.product?.id || ''}`
        : undefined
    );
  };

  const addOrUpdate = async () => await update?.(productInput, saveToContext);

  useEffect(() => {
    setTitle(productData?.product?.title || '');
    setDescription(
      productData?.product
        ? defaultVariant(productData?.product)?.description || ''
        : ''
    );
  }, [productData?.product, id]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar flex flex-col gap-10">
        <h2 className="text-neutral-800 text-3xl md:text-4xl font-semibold font-['Metropolis'] leading-tight">
          Product details
        </h2>
        <TextInput
          value={title}
          label="Give your product a title"
          labelStyle="text-neutral-800 text-xl font-normal"
          endAdornment={
            <span className="text-cashiaBlue">
              {limits.title - (title?.length || 0)}
            </span>
          }
          onChange={(e) =>
            updateStringStateWithLimit(e.target.value, limits.title, setTitle)
          }
        />
        <TextArea
          value={description}
          label="Describe your product (optional)"
          labelStyle="text-neutral-800 text-xl font-normal"
          onChange={(e) =>
            updateStringStateWithLimit(
              e.target.value,
              limits.description,
              setDescription
            )
          }
          helperText={
            description.length ? (
              <span className="pt-[10px] text-[13px] font-medium text-darkGrey">{`${description.length}/${limits.description}`}</span>
            ) : (
              <span className="text-red-700 pt-[10px] text-[13px] font-normal leading-[15px]">
                {limits.description} characters needed
              </span>
            )
          }
        />
      </div>
      <Footer
        className="flex-5"
        addOrUpdate={addOrUpdate}
        disabled={!title.length}
        updateLoading={updateLoading}
      />
    </div>
  );
};

export default ProductTitle;
