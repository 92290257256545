import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import SocialLogin from './SocialLogin';
import {ReactComponent as LogoSvg} from '../../assets/logos/cashia_logo.svg';
import Button from '../../components/tailwind/Button';
import Spinner from '../../components/tailwind/Spinner';
import TextInput from '../../components/tailwind/TextInput';
import {useAuth} from '../../utils/auth';

const Login = () => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const {makeLoginWithCode} = useAuth();

  const login = async () => {
    if (!phone) return;
    setLoading(true);
    await makeLoginWithCode('12345', phone);
    setLoading(false);
  };

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="h-[90%] py-10 px-9 md:py-14 md:px-24">
        <div className="flex justify-between items-center">
          <LogoSvg />
          <div className="flex gap-7 items-center">
            <p className="hidden md:block text-zinc-700 text-base font-normal">
              New to Cashia?
            </p>
            <button
              className="w-[150px] md:w-[207px] h-12 rounded-[10px] border-2 border-neutral-800 text-center text-neutral-800 text-sm md:text-base font-normal"
              onClick={() => navigate('/register')}>
              Create an account
            </button>
          </div>
        </div>
        <div className="h-full w-full flex justify-center items-center">
          <div className="h-[60%] md:h-[65%] sm:w-full md:w-[45%] lg:w-[30%] flex flex-col">
            <h1 className="text-center text-neutral-800 text-[50px] font-bold mb-6">
              Log in
            </h1>
            <SocialLogin />
            {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
              <div className="flex gap-3 items-center w-full mt-3">
                <TextInput
                  id="phone"
                  value={phone}
                  placeholder="Only in staging"
                  inputStyle="placeholder-gray-400"
                  containerStyle="w-full"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Button
                  label={
                    loading ? (
                      <Spinner fillColor="fill-white" width={57} />
                    ) : (
                      'Login'
                    )
                  }
                  id="loginBtn"
                  disabled={!phone}
                  className="h-full"
                  labelStyle="text-neutral-50 text-base font-normal"
                  onClick={login}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[10%] px-9 md:px-24 flex justify-between items-center border-t border-neutral-200">
        <p className="text-zinc-700 text-sm md:text-base font-normal">
          @{new Date().getFullYear()} Cashia inc.
        </p>
        <p
          className="text-right text-cyan-500 text-sm md:text-base font-medium"
          onClick={() => navigate('/terms')}>
          Terms of Use
        </p>
      </div>
    </div>
  );
};

export default Login;
