import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  DocumentStatus,
  DocumentType,
  MeDocument,
  MeQuery,
  MerchantDocumentFieldsFragment,
  MerchantType,
  MerchantTypeInput,
  useChangeMerchantDocumentStatusMutation,
} from '../../../graphql/generated';
import {isMerchant} from '../../../utils/apollo/helpers';
import FileUpload from '../components/FileUpload';
import Footer from '../components/Footer';
import {links, useSelfOnboardingContentContext} from '../layout';

export type DocumentKeys =
  | 'kraPin'
  | 'registrationCertificate'
  | 'incorporationCertificate'
  | 'directors'
  | 'cr12'
  | 'partnershipDeed';

type MerchantTypeDec = {
  [MerchantType.Individual]?: Partial<
    Record<DocumentKeys, MerchantDocumentFieldsFragment[]>
  >;
  [MerchantType.SoleProprietor]?: Partial<
    Record<DocumentKeys, MerchantDocumentFieldsFragment[]>
  >;
  [MerchantType.LimitedCompany]?: Partial<
    Record<DocumentKeys, MerchantDocumentFieldsFragment[]>
  >;
  [MerchantType.Partnership]?: Partial<
    Record<DocumentKeys, MerchantDocumentFieldsFragment[]>
  >;
};

const docsContent = {
  kraPin: {
    title: 'Upload your KRA PIN',
    description: `A PIN is a Personal Identification Number used while doing business with the Kenya Revenue Authority, other 
Government agencies and service providers. `,
  },
  registrationCertificate: {
    title: 'Upload Business Registration or Business Permit',
    description: `These documents grant you the right to start and run a your business and is issued by the Government of Kenya. 
It is a type of permit indicating that your business has the government's approval to operate.`,
  },
  incorporationCertificate: {
    title: 'Upload the Certificate of Incorporation',
    description: `A certificate of incorporation is a legal document proving the legal formation of a company in Kenya.
The certificate is required for the smooth operation of any business by a company in Kenya.`,
  },
  directors: {
    title: 'Upload the Identification Documents of directors',
    description: 'These are legal documents of the owners of the company.',
  },
  cr12: {
    title: 'Upload your CR-12 certificate',
    description: `A CR12 is an official confirmation by the Registrar of Companies in Kenya as to whom the directors of a 
company are. It also confirms that the company’s records exist at the Company Registry.`,
  },
  partnershipDeed: {
    title: 'Upload the Partnership Deed/Affidavit',
    description: `This is a written legal document that contains an agreement made between two individuals who have 
the intention of doing business with each other and share profits and losses.`,
  },
};

const Documents = () => {
  const {merchantType, merchant, updateLoading, update, step} =
    useSelfOnboardingContentContext();
  const navigate = useNavigate();
  if (!merchantType) navigate('/self-onboarding/type-of-business');
  const [expectedDocs, setExpectedDocs] = useState<MerchantTypeDec>();
  const [selectedDeletedProductId, setselectedDeletedProductId] = useState('');
  const [docsAvailability, setDocsAvailability] = useState(false);
  const [changeMerchantDocumentStatusMutation, {loading: changeLoading}] =
    useChangeMerchantDocumentStatusMutation();

  const getExpectedDocuments = useCallback(
    (docType: DocumentType) =>
      merchant?.merchantDocuments?.filter(
        (doc) => doc.documentType === docType
      ),
    [merchant?.merchantDocuments]
  );
  const getExpectedDocumentMediaId = useCallback(
    (docType: DocumentType) =>
      merchant?.merchantDocuments?.find((doc) => doc.documentType === docType)
        ?.mediaId,
    [merchant?.merchantDocuments]
  );

  const deleteDocument = async (documentId: string) => {
    setselectedDeletedProductId(documentId);
    await changeMerchantDocumentStatusMutation({
      variables: {
        input: {
          documentId,
          status: DocumentStatus.Deleted,
        },
      },
      update: (cache, result) => {
        const cached = cache.readQuery<MeQuery>({
          query: MeDocument,
        });
        if (!isMerchant(cached?.me) || !cached?.me) return;
        cache.writeQuery({
          query: MeDocument,
          data: {
            ...cached,
            me: {
              ...cached?.me,
              merchantDocuments: [
                ...(cached?.me.merchantDocuments || []),
              ].filter(
                (doc) => doc.id !== result.data?.changeMerchantDocumentStatus.id
              ),
            },
          },
        });
      },
    });
    setselectedDeletedProductId('');
  };

  const merchantTypeInputSelector = (status: MerchantType) => {
    return {
      INDIVIDUAL: 'individual',
      SOLE_PROPRIETOR: 'soleProprietor',
      LIMITED_COMPANY: 'limitedCompany',
      PARTNERSHIP: 'partnership',
    }[status];
  };

  const updateMerchantType = async () => {
    if (merchantType === merchant?.merchantType) {
      navigate(links[step + 1]?.link || '');
      return;
    }
    if (!merchantType) return;
    const requiredDocs = (state: MerchantType) => {
      switch (state) {
        case MerchantType.Individual: {
          return {
            kraPinId: getExpectedDocumentMediaId(DocumentType.KraPin),
          };
        }
        case MerchantType.SoleProprietor: {
          return {
            kraPinId: getExpectedDocumentMediaId(DocumentType.KraPin),
            registrationCertificateId: getExpectedDocumentMediaId(
              DocumentType.RegistrationCertificate
            ),
          };
        }
        case MerchantType.LimitedCompany: {
          return {
            kraPinId: getExpectedDocumentMediaId(DocumentType.KraPin),
            incorporationCertificate: getExpectedDocumentMediaId(
              DocumentType.IncorporationCertificate
            ),
            directorsIds: [getExpectedDocumentMediaId(DocumentType.Directors)],
            cr12Id: getExpectedDocumentMediaId(DocumentType.Cr12),
          };
        }
        case MerchantType.Partnership: {
          return {
            kraPinId: getExpectedDocumentMediaId(DocumentType.KraPin),
            registrationCertificateId: getExpectedDocumentMediaId(
              DocumentType.RegistrationCertificate
            ),
            directorsIds: [getExpectedDocumentMediaId(DocumentType.Directors)],
            partnershipDeedId: getExpectedDocumentMediaId(
              DocumentType.PartnershipDeed
            ),
          };
        }
      }
    };
    const newMerchantType: MerchantTypeInput = {
      [merchantTypeInputSelector(merchantType)]: requiredDocs(merchantType),
    };
    await update?.({
      merchantType: newMerchantType,
    });
  };

  const documentTypeExists = useCallback(
    (values: DocumentType[]): boolean =>
      values.every((value) =>
        merchant?.merchantDocuments
          ?.filter((doc) => doc.status !== DocumentStatus.Deleted)
          .some((item) => item.documentType === value)
      ) || false,
    [merchant?.merchantDocuments]
  );

  useEffect(() => {
    const info = (state: MerchantType) => {
      switch (state) {
        case MerchantType.Individual: {
          setDocsAvailability(documentTypeExists([DocumentType.KraPin]));
          return {
            kraPin: getExpectedDocuments(DocumentType.KraPin),
          };
        }
        case MerchantType.SoleProprietor: {
          setDocsAvailability(
            documentTypeExists([
              DocumentType.KraPin,
              DocumentType.RegistrationCertificate,
            ])
          );
          return {
            kraPin: getExpectedDocuments(DocumentType.KraPin),
            registrationCertificate: getExpectedDocuments(
              DocumentType.RegistrationCertificate
            ),
          };
        }
        case MerchantType.LimitedCompany: {
          setDocsAvailability(
            documentTypeExists([
              DocumentType.KraPin,
              DocumentType.IncorporationCertificate,
              DocumentType.Directors,
              DocumentType.Cr12,
            ])
          );
          return {
            kraPin: getExpectedDocuments(DocumentType.KraPin),
            incorporationCertificate: getExpectedDocuments(
              DocumentType.IncorporationCertificate
            ),
            directors: getExpectedDocuments(DocumentType.Directors),
            cr12: getExpectedDocuments(DocumentType.Cr12),
          };
        }
        case MerchantType.Partnership: {
          setDocsAvailability(
            documentTypeExists([
              DocumentType.KraPin,
              DocumentType.RegistrationCertificate,
              DocumentType.Directors,
              DocumentType.PartnershipDeed,
            ])
          );
          return {
            kraPin: getExpectedDocuments(DocumentType.KraPin),
            registrationCertificate: getExpectedDocuments(
              DocumentType.RegistrationCertificate
            ),
            directors: getExpectedDocuments(DocumentType.Directors),
            partnershipDeed: getExpectedDocuments(DocumentType.PartnershipDeed),
          };
        }
        default: {
          return {};
        }
      }
    };
    merchantType &&
      setExpectedDocs({[merchantType]: merchantType && info(merchantType)});
  }, [documentTypeExists, getExpectedDocuments, merchantType]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:py-14 md:p-9 sm:p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-7 h-full sm:pt-0 lg:pt-14">
          <h1 className="text-neutral-800 text-[25px] md:text-[34px] font-semibold font-['Metropolis']">
            Upload required documents
          </h1>
          <div className="flex flex-col gap-7 last:pb-10">
            {expectedDocs &&
              Object.keys(expectedDocs[merchantType as MerchantType] || {}).map(
                (doc, i) => (
                  <div key={i} className="flex flex-col gap-3">
                    <h3 className="text-neutral-800 text-[18px] md:text-xl font-semibold font-['Metropolis']">
                      {docsContent[doc as DocumentKeys].title}
                    </h3>
                    <p className="text-zinc-700 text-base font-normal leading-tight">
                      {docsContent[doc as DocumentKeys].description}
                    </p>
                    <div className="flex gap-5 flex-wrap">
                      {merchantType &&
                        (
                          expectedDocs[merchantType]?.[doc as DocumentKeys] ||
                          []
                        ).map((uploadedPdf) =>
                          uploadedPdf.status !== DocumentStatus.Deleted ? (
                            <FileUpload
                              docType={doc as DocumentKeys}
                              key={uploadedPdf.id}
                              uploadedPdf={uploadedPdf}
                              deleteLoading={
                                uploadedPdf.id === selectedDeletedProductId &&
                                changeLoading
                              }
                              deleteDocument={deleteDocument}
                            />
                          ) : (
                            <></>
                          )
                        )}
                      <FileUpload docType={doc as DocumentKeys} />
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <Footer
        className="flex-5"
        disabled={!docsAvailability}
        update={updateMerchantType}
        updateLoading={updateLoading}
      />
    </div>
  );
};

export default Documents;
