import {cn} from '../../utils/reusableFunctions';

type Props = {
  leftIcon?: string | JSX.Element;
  label: string | JSX.Element;
  rightIcon?: string | JSX.Element;
  onClick?: () => void;
  text?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  labelStyle?: string;
  id?: string;
};

const Button = ({
  onClick,
  label,
  leftIcon,
  rightIcon,
  text = false,
  disabled = false,
  fullWidth = false,
  className,
  labelStyle,
  ...other
}: Props) => {
  return (
    <button
      className={cn(
        'select-none flex h-[38px] w-fit cursor-pointer flex-row items-center justify-between rounded-md px-5 text-white bg-black',
        {
          'bg-transparent': text,
          'cursor-not-allowed bg-greyish': disabled && !text,
        },
        className
      )}
      onClick={() => disabled || onClick?.()}
      {...other}>
      {typeof leftIcon === 'string' ? (
        <img src={leftIcon} className="mr-2" />
      ) : (
        leftIcon
      )}
      <p
        className={cn(
          'text-center',
          {
            'text-black underline': text,
            'cursor-not-allowed text-foggy': disabled,
            'flex w-full items-center justify-center': fullWidth,
          },
          labelStyle
        )}>
        {label}
      </p>
      {typeof rightIcon === 'string' ? <img src={rightIcon} /> : rightIcon}
    </button>
  );
};

export default Button;
