import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {Button, Divider, Modal, Typography} from '@mui/material';
import {format} from 'date-fns';
import React from 'react';

import {checkTypename, getMethodNameImage, getPayementInfo} from './helper';
import greenTransactionBg from '../../assets/greenTransactionBg.svg';
import orangeTransactionBg from '../../assets/orangeTransactionBg.svg';
import redTransactionBg from '../../assets/redTransactionBg.svg';
import Div from '../../components/common/Div';
import {
  TransactionsFieldsFragment,
  TransactionStatus,
} from '../../graphql/generated';
import {colours, localStorageAccessTokenKey} from '../../utils/constants';
import formatDate from '../../utils/formatDate';
import formatMoney from '../../utils/formatMoney';
import {useUserAuth} from '../../utils/user';
import useWindowSize from '../inbox/hooks/useWindowSize';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  receipt?: TransactionsFieldsFragment | null;
}

const ReceipModal = ({open, setOpen, receipt}: Props) => {
  const {merchant} = useUserAuth();
  const {width} = useWindowSize();

  const transactionBg = (status: TransactionStatus) => {
    return `url(${
      {
        SUCCESS: greenTransactionBg,
        PENDING: orangeTransactionBg,
        FAILED: redTransactionBg,
      }[status]
    })`;
  };

  const getStatusTitle = (status: TransactionStatus) => {
    return {
      SUCCESS: 'Transfer succesful',
      PENDING: 'Transfer pending',
      FAILED: 'Transfer unsuccesful',
    }[status];
  };

  const modalBtnBg = (status: TransactionStatus) => {
    return {
      SUCCESS: colours.green,
      PENDING: colours.lightYellow,
      FAILED: colours.smoothRed,
    }[status];
  };

  const modalStatusIcon = (status: TransactionStatus) => {
    return {
      SUCCESS: <DoneIcon color="inherit" />,
      PENDING: <HourglassTopIcon color="inherit" />,
      FAILED: <CancelIcon color="inherit" />,
    }[status];
  };

  async function downloadPDF() {
    const accessToken = localStorage.getItem(localStorageAccessTokenKey);

    if (!receipt?.id) {
      console.error('Missing receipt ID ');
      return;
    }
    if (!accessToken) {
      console.error('Missing access token');
      return;
    }
    const url = `https://api.stg.cashia.com/pdf/transaction/${receipt?.id}?token=${accessToken}`;

    const transactionDate = new Date();

    const formattedDate = format(transactionDate, 'yyyyMMddHHmmss');

    const fileName = `Cashia_receipt_${formattedDate}.pdf`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  }

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={styles.mainModal}
      BackdropProps={{
        sx:
          width > 900
            ? styles.modalBg
            : {
                ...styles.smBg,
                backgroundImage:
                  receipt?.status && transactionBg(receipt.status),
              },
      }}>
      <Div sx={styles.modalStyles}>
        <CancelIcon
          sx={[styles.cancelIcon, styles.disNone]}
          onClick={() => setOpen(false)}
        />
        <Div
          sx={[
            {
              ...styles.modelInfo,
              backgroundImage: receipt?.status && transactionBg(receipt.status),
            },
            styles.disNoneCustom,
          ]}>
          <Typography sx={styles.date}>
            {receipt?.date &&
              formatDate(receipt.date as Date, {
                withWeekday: true,
                withShortMonth: true,
                withTime: true,
                withYear: true,
                hour12: true,
              })}
          </Typography>
          <Typography id="modal-modal-title" sx={styles.transTitle}>
            {receipt?.status && getStatusTitle(receipt.status)}
          </Typography>
          <Typography sx={styles.checkTypename}>
            {receipt && checkTypename(receipt)}
          </Typography>
        </Div>
        <Div sx={styles.des} id="modal-modal-description">
          <Div sx={[styles.transferInfoWrp, styles.disYes]}>
            <Div sx={styles.transferInfo}>
              <Div
                sx={{
                  ...styles.tick,
                  backgroundColor:
                    receipt?.status && modalBtnBg(receipt.status),
                }}>
                {receipt?.status && modalStatusIcon(receipt?.status)}
              </Div>
              <Typography sx={styles.trnTxt}>
                {receipt?.status && getStatusTitle(receipt.status)}
              </Typography>
            </Div>
            <Divider />
          </Div>
          <Div sx={styles.eachDes}>
            <Typography sx={styles.totalInfoRight}>Amount</Typography>
            <Typography sx={styles.totalInfo}>
              {receipt?.amount.currencyCode}{' '}
              {receipt?.amount && formatMoney(receipt.amount, 2)}
            </Typography>
          </Div>
          <Divider />
          <Div sx={[styles.eachDes, styles.disYes]}>
            <Typography sx={styles.rightText}>Date:</Typography>
            <Typography sx={styles.leftText}>
              {receipt?.date &&
                formatDate(receipt.date as Date, {
                  withShortMonth: true,
                  withTime: false,
                  withYear: true,
                })}
            </Typography>
          </Div>
          <Divider sx={styles.disYes} />
          <Div sx={[styles.eachDes, styles.disYes]}>
            <Typography sx={styles.rightText}>Time:</Typography>
            <Typography sx={styles.leftText}>
              {receipt?.date &&
                formatDate(receipt.date as Date, {
                  withWeekday: false,
                  withDate: false,
                  withTime: true,
                  hour12: true,
                })}
            </Typography>
          </Div>
          <Divider sx={styles.disYes} />
          <Div sx={styles.eachDes}>
            <Typography sx={styles.rightText}>Method:</Typography>
            <Typography sx={styles.leftText}>
              {receipt &&
                getMethodNameImage(receipt, merchant?.id ? merchant.id : '')
                  ?.name}
            </Typography>
          </Div>
          <Divider />
          <Div sx={styles.eachDes}>
            <Typography sx={styles.rightText}>
              {receipt &&
                getPayementInfo(receipt, merchant?.id ? merchant.id : '')?.type}
            </Typography>
            <Typography sx={styles.leftText}>
              {receipt &&
                getPayementInfo(
                  receipt,
                  merchant?.id ? merchant.id : ''
                )?.info?.toString()}
            </Typography>
          </Div>
          <Divider />
          <Div sx={styles.eachDes}>
            <Typography sx={styles.rightText}>Transaction ID:</Typography>
            <Typography sx={styles.leftText}>
              {receipt?.referenceNumber}
            </Typography>
          </Div>
          <Divider />
          {TransactionStatus.Success && (
            <div
              className="font-normal underline cursor-pointer mt-[40px]"
              onClick={downloadPDF}>
              Download PDF
            </div>
          )}

          <Div sx={[styles.download, styles.disNone]} />
          <Div sx={[styles.eachDes, styles.disYes]}>
            <Button
              sx={{
                ...styles.infoBtn,
                backgroundColor: receipt?.status && modalBtnBg(receipt.status),
                '&:hover': {
                  background: receipt?.status && modalBtnBg(receipt.status),
                },
              }}
              onClick={() => setOpen(false)}>
              {receipt?.status === TransactionStatus.Failed
                ? 'Try again'
                : 'Done'}
            </Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};

const styles = {
  receipt: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '120px',
    width: '100%',
    paddingY: '15px',
  },
  transferInfo: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    paddingTop: '6px',
  },
  trnTxt: {
    fontweight: '400',
    fontSize: '16px',
    lineHeight: '16px',
  },
  transferInfoWrp: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingY: {md: '30px', xs: '13px'},
  },
  tick: {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colours.absoluteWhite,
  },
  modalBg: {backgroundColor: colours.modalPurpleBackDropBg},
  disYes: {display: {md: 'none', xs: 'flex'}},
  disNone: {display: {md: 'flex', xs: 'none'}},
  disNoneCustom: {
    display: {md: 'flex', xs: 'none'},
    flexDirection: 'column',
  },
  smBg: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  date: {
    paddingTop: '50px',
    color: 'white',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '13px',
  },
  infoBtn: {
    width: '100%',
    height: '44px',
    background: colours.grenish,
    borderRadius: '10px',
    color: colours.white,
    paddingX: '10px',
    '&:hover': {background: colours.grenish, color: colours.white},
  },
  transTitle: {
    color: 'white',
    marginTop: '25px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  checkTypename: {
    color: 'white',
    fontSize: '16px',
    marginTop: '25px',
    fontWeight: 400,
    lineHeight: '16px',
  },

  method: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '34px',
    color: colours.black,
  },

  modalStyles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {md: '630px', xs: '85%'},
    bgcolor: colours.white,
    borderRadius: '16px',
    DivShadow: 24,
    border: 'none',
  },
  mainModal: {
    border: 'none',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  cancelIcon: {
    position: 'absolute',
    top: 20,
    right: 25,
    color: 'white',
    cursor: 'pointer',
    fontSize: '30px',
  },

  modelInfo: {
    maxHeight: '244px',
    p: 4,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
  },
  des: {
    paddingX: '30px',
  },
  eachDes: {
    paddingY: {md: '30px', xs: '23px'},
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalInfo: {
    fontStyle: 'normal',
    fontWeight: {md: 600, xs: 400},
    fontSize: {md: '24px', xs: '16px'},
    lineHeight: {md: '24px', xs: '16px'},
    color: colours.black,
  },
  totalInfoRight: {
    fontStyle: 'normal',
    fontWeight: {md: 600, xs: 400},
    fontSize: {md: '24px', xs: '13px'},
    lineHeight: {md: '24px', xs: '13px'},
    color: {md: colours.black, xs: colours.darkGrey},
  },
  leftText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: {md: 600, xs: 400},
    fontSize: {md: '20px', xs: '16px'},
    lineHeight: '20px',
    color: colours.black,
  },
  rightText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: {md: '16px', xs: '13px'},
    lineHeight: {md: '16px', xs: '13px'},
    color: {md: colours.foggy, xs: colours.darkGrey},
  },
  download: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    textDecorationLine: 'underline',
    color: colours.black,
    display: 'flex',
    gap: '100px',
    paddingY: '40px',
    cursor: 'pointer',
  },
} as const;

export default ReceipModal;
