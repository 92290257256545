import {useEffect, useMemo, useState} from 'react';

import TextInput from '../../../components/tailwind/TextInput';
import {CurrencyCode, ProductInput} from '../../../graphql/generated';
import defaultVariant from '../../../utils/defaultVariant';
import formatMoney from '../../../utils/formatMoney';
import Footer from '../components/Footer';
import {useAddOrUpdateProductContentContext} from '../layout';

const Price = () => {
  const {productData, updateLoading, update} =
    useAddOrUpdateProductContentContext();
  const [basePrice, setBasePrice] = useState<number | null>();

  const productInput = useMemo(() => {
    const input: ProductInput = {
      variants: [
        ...(productData?.product?.variants || []).map((variant) =>
          variant.default
            ? {
                id: variant?.id,
                images: variant?.images?.map((image) => image.id || ''),
                price: {
                  amountInCents: (basePrice || 0) * 100,
                  currencyCode:
                    variant?.price?.currencyCode || CurrencyCode.Kes,
                },
              }
            : {
                id: variant.id,
                images: variant.images?.map((image) => image.id || '') || [],
              }
        ),
      ],
    };
    if (productData) {
      input.id = productData.product?.id;
    }
    return input;
  }, [basePrice, productData]);

  const addOrUpdate = async () => await update?.(productInput);

  useEffect(() => {
    const formatedMoney = formatMoney(
      (productData?.product && defaultVariant(productData?.product)?.price) || 0
    );
    setBasePrice(formatedMoney ? Number(formatedMoney) : null);
  }, [productData?.product]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <h3 className="text-neutral-800 mb-9 text-2xl font-semibold leading-[30px]">
          Price your product
        </h3>
        <div className="w-full flex flex-col gap-10">
          <TextInput
            value={basePrice || ''}
            type="number"
            label="Add a base price"
            labelStyle="text-neutral-800 text-base font-normal leading-tight"
            description="Keep in mind to add a competitive price"
            descriptionStyle="text-zinc-500 text-base font-normal leading-tight text-sm"
            onChange={(e) => setBasePrice(Number(e.target.value))}
          />
        </div>
      </div>
      <Footer
        className="flex-5"
        addOrUpdate={addOrUpdate}
        updateLoading={updateLoading}
        disabled={(basePrice && isNaN(basePrice)) === 0 ? true : false}
      />
    </div>
  );
};

export default Price;
