import {useCallback, useEffect, useState} from 'react';

import closeIcon from '../../../assets/icons/close-circle.svg';
import noPhoto from '../../../assets/icons/noPhoto.svg';
import trashIcon from '../../../assets/icons/trashIcon.svg';
import Button from '../../../components/tailwind/Button';
import Modal from '../../../components/tailwind/Modal';
import Spinner from '../../../components/tailwind/Spinner';
import Switch from '../../../components/tailwind/Switch';
import TextInput from '../../../components/tailwind/TextInput';
import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  CurrencyCode,
  ProductInput,
  ProductVariantFieldsFragment,
  ProductVariantInput,
  useUploadMediaMutation,
} from '../../../graphql/generated';
import defaultVariant from '../../../utils/defaultVariant';
import formatMoney from '../../../utils/formatMoney';
import {useAddOrUpdateProductContentContext} from '../layout';

type Props = {
  openEditModal: boolean;
  toggleEditModal: () => void;
  variants?: ProductVariantFieldsFragment[];
  selectedIds?: string[];
  setSelectedIds?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};

type Img = {
  url?: string;
  file?: File;
};

const EditVariant = ({
  openEditModal,
  toggleEditModal,
  selectedIds,
  variants,
  setSelectedIds,
}: Props) => {
  const {addToast} = useToast();
  const {productData, update} = useAddOrUpdateProductContentContext();
  const [price, setPrice] = useState<number | null>();
  const [image, setImage] = useState<Img | null>();
  const [loading, setLoading] = useState(false);
  const [newDefaultVariantId, setNewDefaultVariantId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadMedia, {error}] = useUploadMediaMutation();

  const handleImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;
    setImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setErrorMessage('');
  }, []);

  const save = useCallback(async () => {
    if (!image?.file) return [];
    const res = await uploadMedia({variables: {file: image.file}});
    return [res.data?.uploadMedia?.id || ''];
  }, [image?.file, uploadMedia]);

  const close = useCallback(() => {
    toggleEditModal();
    setNewDefaultVariantId('');
    setErrorMessage('');
  }, [toggleEditModal]);

  const updateDefaultVariant = useCallback(
    (variantsInput: ProductVariantInput[]) => {
      if (!newDefaultVariantId) return variantsInput;
      return variantsInput.map((item) => ({
        ...item,
        default: item.id === newDefaultVariantId,
      }));
    },
    [newDefaultVariantId]
  );

  const productInput = useCallback(
    (imgIds: string[]) => {
      const editedVariants: ProductVariantFieldsFragment[] = [];
      const remainngVariants: ProductVariantFieldsFragment[] = [];

      variants?.forEach((item) => {
        selectedIds?.includes(item.id || '')
          ? editedVariants.push({
              ...item,
              price: {
                amountInCents: price
                  ? (price || 1) * 100
                  : item.price?.amountInCents || 1 * 100,
                currencyCode: item?.price?.currencyCode || CurrencyCode.Kes,
              },
            })
          : remainngVariants.push(item);
      });
      const updatingVariants = [...editedVariants].map((variant) => ({
        id: variant.id,
        price: {
          amountInCents: variant.price?.amountInCents || 1,
          currencyCode: variant?.price?.currencyCode || CurrencyCode.Kes,
        },
        images: [
          ...imgIds,
          ...(variant.images?.map((img) => img.id || '') || []),
        ],
      }));
      const restVariants = [...remainngVariants].map((variant) => ({
        id: variant.id,
        images: variant.images?.map((img) => img.id || '') || [],
      }));
      const input: ProductInput = {
        variants: updateDefaultVariant([...updatingVariants, ...restVariants]),
      };
      if (productData) {
        input.id = productData.product?.id;
      }
      return input;
    },
    [price, productData, selectedIds, updateDefaultVariant, variants]
  );

  const sideEffect = () => {
    setSelectedIds?.([]);
    setLoading(false);
  };

  const updateVariants = async () => {
    setErrorMessage('');
    if (!image?.url) {
      setErrorMessage(
        'Please select an image for the variant to perform update'
      );
      return;
    }
    setLoading(true);
    const imgIds = await save();
    const input = productInput(imgIds);
    await update?.(input, sideEffect);
    close();
  };

  useEffect(() => {
    if ((selectedIds?.length || 0) > 1) {
      setPrice(null);
      setImage(null);
    } else {
      const variant = variants?.find((v) => v.id === selectedIds?.[0]);
      const formatedMoney = formatMoney(variant?.price || 0);
      setPrice(formatedMoney ? Number(formatedMoney) : null);
      setImage({
        url: variant?.images?.[0]?.small || '',
        file: undefined,
      });
    }
  }, [selectedIds, selectedIds?.length, variants, openEditModal]);

  useEffect(() => {
    if (!error) return;
    setLoading(false);
    addToast({
      icon: false,
      type: 'error',
      message: (
        <p className="text-xs normal-case">
          Failed to upload{' '}
          <span className="text-blue-200">{`"${
            image?.file?.name || ''
          }"`}</span>
        </p>
      ),
      duration: 4000,
    });
  }, [addToast, error, image?.file?.name]);

  return (
    <Modal isVisible={openEditModal} onClose={close}>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between items-center p-7 pb-4">
          <h4 className="text-xl font-semibold leading-5">
            Edit Variant{(selectedIds?.length || 0) > 1 && 's'}
          </h4>
          <img src={closeIcon} className="cursor-pointer" onClick={close} />
        </div>
        <hr />
        <div className="flex flex-col p-7 pt-4 w-full">
          <div className="flex flex-col md:flex-row md:justify-between md:gap-5 md:items-center w-full">
            <div className="flex flex-col w-full">
              <TextInput
                value={price || ''}
                autoFocus
                label="Price:"
                labelStyle="text-zinc-700 text-lg font-medium leading-snug"
                placeholder={`Enter price for variant${
                  (selectedIds?.length || 0) > 1 ? 's' : ''
                }`}
                inputStyle="h-6"
                onChange={(e) => setPrice(Number(e.target.value))}
              />
              <p className="text-sm font-medium text-red-500">{errorMessage}</p>
            </div>
            <div className="flex gap-6 md:gap-4 flex-row md:flex-col-reverse mt-4 md:mt-0">
              <div className="flex flex-row justify-center md:flex-col gap-3 items-center rounded-lg">
                <div className="relative">
                  {image?.url && (
                    <img
                      src={trashIcon}
                      className="absolute top-2 right-2 rounded cursor-pointer bg-white"
                      onClick={() => {
                        setImage(null);
                      }}
                    />
                  )}
                  <input
                    type="file"
                    hidden
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImage}
                    id="img"
                  />
                  <img
                    src={image?.url ? image.url : noPhoto}
                    className="h-[100px] w-[100px] md:h-[130px] md:w-[200px] rounded-lg object-cover"
                  />
                </div>
                {image?.url ? (
                  <></>
                ) : (
                  <label
                    htmlFor="img"
                    className="text-xs font-semibold p-2 flex justify-center items-center cursor-pointer border-2 border-black/70 w-full rounded-md">
                    Upload from device
                  </label>
                  // <button className="text=[10px]"> Upload a file from your computer</button>
                )}
              </div>
              {(selectedIds?.length || 0) > 1 ||
              defaultVariant(productData?.product)?.id === selectedIds?.[0] ? (
                <></>
              ) : (
                <div className="flex gap-3 bg-slate-4 items-center">
                  <p className="text-zinc-700 text-base font-medium leading-snug">
                    Default:
                  </p>
                  <Switch
                    checked={Boolean(newDefaultVariantId.length)}
                    onChange={() =>
                      !newDefaultVariantId.length
                        ? setNewDefaultVariantId(selectedIds?.[0] || '')
                        : setNewDefaultVariantId('')
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 flex gap-5 mb-4">
            <Button
              label={
                loading ? <Spinner fillColor="fill-white" width={37} /> : 'Save'
              }
              className="bg-cashiaBlue"
              labelStyle="text-neutral-50 text-base font-normal px-5 md:px-7"
              disabled={(price && isNaN(price)) === 0}
              onClick={updateVariants}
            />
            <Button
              label="Cancel"
              className="bg-lightGrey"
              labelStyle="text-black text-base font-normal px-5 md:px-7"
              onClick={close}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditVariant;
