import React from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as Facebook} from '../../assets/icons/facebook.svg';
import {ReactComponent as Instagram} from '../../assets/icons/instagram.svg';
import {ReactComponent as Linkedin} from '../../assets/icons/linkedin.svg';
import {ReactComponent as Twitter} from '../../assets/icons/twitter.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex justify-between w-[90%] fixed bottom-0 mt-auto border-t-[1px] h-[120px] md:h-[88px] bg-white">
      <div className="flex justify-between items-center gap-[50px] mt-[16px] md:mt-0">
        <p>@{currentYear} Cashia inc.</p>
        <Link to="/terms">
          <p className="text-black">Terms of Use</p>
        </Link>
        <Link to="">
          <p className="text-black">Give us feedback</p>
        </Link>
      </div>
      <div className="flex justify-between items-center gap-[30px] mt-[16px] md:mt-0">
        <a
          href="https://www.facebook.com/CashiaKe"
          target="_blank"
          rel="noopener noreferrer">
          <Facebook height={28} width={28} />
        </a>
        <a
          href="https://www.instagram.com/cashiakenya"
          target="_blank"
          rel="noopener noreferrer">
          <Instagram height={28} width={28} />
        </a>
        <a
          href="https://x.com/CashiaKenya"
          target="_blank"
          rel="noopener noreferrer">
          <Twitter height={28} width={28} />
        </a>
        <a
          href="https://ke.linkedin.com/company/cashia"
          target="_blank"
          rel="noopener noreferrer">
          <Linkedin height={28} width={28} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
