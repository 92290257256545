import ErrorIcon from '@mui/icons-material/Error';
import {Typography} from '@mui/material';

import Div from '../../../components/common/Div';
import {colours} from '../../../utils/constants';

const Error = () => {
  return (
    <Div sx={styles.err}>
      <Div sx={styles.inErr}>
        <ErrorIcon fontSize="large" />
        <Typography sx={styles.errtext}>An Error Occured.</Typography>
      </Div>
    </Div>
  );
};

const styles = {
  err: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colours.lightRed,
    borderRadius: '10px',
    width: '100%',
    paddingY: '40px',
    marginTop: '17px',
  },
  inErr: {
    width: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: colours.smoothRed,
  },
  errtext: {
    textAlign: 'center',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '13px',
  },
};

export default Error;
