import {useCallback, useEffect, useState} from 'react';

import Button from './Button';
import Modal from './Modal';
import Spinner from './Spinner';
import {useToast} from './toast/useToast';
import closeIcon from '../../assets/icons/close-circle.svg';
import {ReactComponent as ImageDelete} from '../../assets/icons/imagedelete_icon.svg';
import noPhoto from '../../assets/icons/noPhoto.svg';
import {useUploadMediaMutation} from '../../graphql/generated';
import {cn} from '../../utils/reusableFunctions';

type Img = {
  url?: string;
  file?: File;
};

type Props = {
  imgUrl?: string;
  imgId?: string;
  getUploadedImgId?: (
    newId: string,
    createdUrl?: string,
    oldId?: string
  ) => void;
  removeImgId?: (imgId?: string) => void;
  helperText?: string;
  title?: string;
  description?: string;
  isOpen?: boolean;
  omitFrontDeleteIcon?: boolean;
  onToggle?: (value: boolean) => void;
  thumbnailStyle?: string;
  imageStyle?: string;
  noPhotoImg?: string;
  thumbnailImageStyle?: string;
};

const ImageModal = ({
  imgUrl,
  imgId,
  getUploadedImgId,
  removeImgId,
  title,
  description,
  isOpen,
  onToggle,
  thumbnailStyle,
  imageStyle,
  helperText,
  noPhotoImg = noPhoto,
  omitFrontDeleteIcon,
  thumbnailImageStyle,
}: Props) => {
  const {addToast} = useToast();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState<Img | undefined>({
    url: imgUrl,
    file: undefined,
  });

  useEffect(() => {
    if (!isOpen) return;
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!imgUrl) return;
    setImage({
      url: imgUrl,
      file: undefined,
    });
  }, [imgUrl, isOpen]);

  const [uploadMedia, {loading, error}] = useUploadMediaMutation();

  const handleImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;

    const file = e.target.files[0];
    const fileType = file.type;

    if (
      fileType !== 'image/png' &&
      fileType !== 'image/jpeg' &&
      fileType !== 'image/jpg'
    ) {
      setErrorMessage('Please select a PNG, JPG, or JPEG file.');
      e.target.value = '';
      setImage(undefined);
    } else {
      setImage({
        url: URL.createObjectURL(file),
        file: file,
      });
      setErrorMessage('');
      e.target.value = '';
    }
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const toggle = useCallback(() => {
    setOpen(!open);
    onToggle?.(!open);
  }, [onToggle, open]);

  const save = useCallback(async () => {
    if (!image?.file) return;
    const res = await uploadMedia({variables: {file: image.file}});
    getUploadedImgId?.(res.data?.uploadMedia?.id || '', image.url, imgId);
    toggle();
  }, [getUploadedImgId, image?.file, image?.url, imgId, toggle, uploadMedia]);

  useEffect(() => {
    if (!error || !errorMessage) return;
    addToast({
      icon: false,
      type: 'error',
      message: (
        <p className="text-sm text-white normal-case">
          {errorMessage || 'Failed to upload'}
          <span className="text-blue-300">
            &quot;{image?.file?.name?.toLowerCase()}&quot;
          </span>
        </p>
      ),
      duration: 3000,
    });
  }, [addToast, error, errorMessage, image?.file?.name]);

  return (
    <>
      <div
        className={cn(
          'mt-3 h-[150px] w-[150px] cursor-pointer relative rounded',
          thumbnailStyle
        )}
        onClick={toggle}>
        <img
          src={image?.url ? image.url : noPhotoImg}
          className={cn('h-full w-full object-cover', thumbnailImageStyle)}
        />
        {!image?.url && (
          <p
            onClick={handleClickOpen}
            className="flex flex-col items-center text-neutral-800 text-sm cursor-pointer font-normal leading-tight underline mt-1">
            {helperText}
          </p>
        )}

        {image?.url && removeImgId && (
          <ImageDelete
            className={cn('absolute top-1 right-1', {
              'top-0 right-0 h-10 w-10': !thumbnailStyle,
              hidden: omitFrontDeleteIcon,
            })}
            onClick={(e) => {
              if (!omitFrontDeleteIcon) {
                e.stopPropagation();
                setImage(undefined);
                removeImgId?.(imgId || '');
              }
            }}
          />
        )}
      </div>
      <Modal isVisible={open} onClose={toggle}>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center p-7 pb-4">
            <h4 className="text-xl font-semibold leading-5">{title}</h4>
            <img src={closeIcon} className="cursor-pointer" onClick={toggle} />
          </div>
          <hr />
          <div
            className={cn('flex sm:flex-col items-center justify-center p-7', {
              'flex-col md:flex-row': description,
            })}>
            <div className="relative h-[200px] w-[200px] rounded-lg">
              {image?.url && removeImgId && (
                <ImageDelete
                  className={cn(
                    'absolute top-2 right-2 rounded cursor-pointer',
                    imageStyle
                  )}
                  onClick={() => {
                    setImage(undefined);
                    removeImgId?.(imgId || '');
                  }}
                />
              )}
              <input
                type="file"
                hidden
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleImage}
                id="img"
              />
              <img
                src={image?.url ? image.url : noPhotoImg}
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
            <div
              className={cn('flex w-full flex-col gap-5', {
                'basis-[57%] ml-7': description,
              })}>
              <p className="sm:hidden md:block text-base font-normal leading-5 text-darkGrey">
                {description}
              </p>
              <Button
                label={
                  loading ? (
                    <Spinner />
                  ) : image?.url ? (
                    'Save'
                  ) : (
                    <label
                      htmlFor="img"
                      className="w-full cursor-pointer text-black">
                      Upload from device
                    </label>
                  )
                }
                fullWidth
                className="w-full mt-4 md:mt-1 border-2 border-black bg-transparent disabled:cursor-not-allowed disabled:border-greyish disabled:bg-greyish"
                labelStyle="text-black"
                onClick={save}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageModal;
