import {Fragment} from 'react';

import CouriersTable from './components/CouriersTable';
import Div from '../../components/common/Div';

const MyCouriers = () => {
  const breadcrumbs = [
    <a href="/orders" className="text-foggy hover:underline" key="1">
      Orders
    </a>,
    <a href="my-couriers" className="text-foggy hover:underline" key="2">
      My Couriers
    </a>,
  ];

  return (
    <Div sx={styles.main}>
      <div className="hidden md:flex items-center text-foggy">
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={index}>
            {index > 0 && <span className="mx-2">&gt;</span>}
            {breadcrumb}
          </Fragment>
        ))}
      </div>
      <CouriersTable />
    </Div>
  );
};

const styles = {
  main: {
    paddingY: {md: '35px', xs: '15px'},
  },
};

export default MyCouriers;
