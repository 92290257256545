import {useNavigate, useParams} from 'react-router-dom';

import {ReactComponent as UploadReelIcon} from '../../../assets/images/upload_reels.svg';
import Spinner from '../../../components/tailwind/Spinner';
import TextInput from '../../../components/tailwind/TextInput';
import {cn} from '../../../utils/reusableFunctions';
import Footer from '../components/Footer';
import {
  captionLimit,
  useUploadOrEditReelsContentContext,
} from '../layout/UploadOrEditReelsLayout';

const UploadOrEditReel = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {
    fileInfo,
    loading,
    updateReel,
    createReel,
    isUpdating,
    handleVideo,
    updateThumbnail,
    handleChangeOnCaption,
  } = useUploadOrEditReelsContentContext();

  const saveReelInfo = () => {
    if (!fileInfo?.caption?.length) return;
    if (fileInfo.uploadedReelMedia?.id && !fileInfo.createdReelMedia?.name) {
      void createReel?.();
    } else {
      if (fileInfo.createdReelMedia?.name === fileInfo.caption) {
        navigate(
          isUpdating
            ? `/reels/edit/${id || ''}/tag-products`
            : `/reels/upload/tag-products`
        );
      } else {
        void updateReel?.(
          {
            name: fileInfo.caption,
          },
          isUpdating
            ? `/reels/edit/${id || ''}/tag-products`
            : `/reels/upload/tag-products`
        );
      }
    }
  };

  return (
    <div className="w-full h-[calc(100svh-64px)] flex flex-col pt-10 overflow-hidden no-scrollbar">
      <div className="flex flex-col h-full gap-10 pl-24 pb-10 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col">
          <h1 className="text-neutral-800 text-[34px] font-semibold">
            {isUpdating ? 'Edit Reel' : 'Upload a Video'}
          </h1>
          <p className="text-neutral-500 text-base font-medium">
            {isUpdating
              ? 'Edit the video of your products to your account'
              : 'Post a video of your products to your account'}
          </p>
        </div>
        <div className="flex h-full w-full gap-14">
          <div className="flex justify-center items-center w-[400px] h-full rounded-[10px] border-2 border-dashed border-neutral-200">
            <div className="flex flex-col h-full gap-7 justify-center items-center">
              {loading ? (
                <>
                  <Spinner
                    fillColor="fill-cashiaBlue"
                    className="w-[100px] h-[60px]"
                  />
                  <div className="text-center text-neutral-400 text-base font-medium truncate">
                    Uploading <br />
                    <p className="p-[20px] truncate">{fileInfo?.file?.name}</p>
                  </div>
                </>
              ) : fileInfo?.selectedThumbnail ? (
                <img
                  src={fileInfo?.selectedThumbnail}
                  className="w-[200px] h-[85%] object-cover rounded-[15px] border-4 border-neutral-800"
                />
              ) : (
                <label
                  htmlFor="video"
                  className="flex flex-col h-full gap-7 justify-center items-center cursor-pointer">
                  <UploadReelIcon className="w-13 h-13 self-center" />
                  <p className="text-center text-black text-xl font-semibold">
                    Select Video to Upload
                  </p>
                  <p className="text-center text-neutral-500 text-base font-semibold">
                    Or drag and drop a file
                  </p>
                  <div className="text-center text-neutral-400 text-sm font-medium">
                    MP4 or WebM
                    <br />
                    720*1280 resolution or higher
                    <br />
                    Up to 30 minutes
                    <br />
                    Less than 2 GB
                  </div>
                  <input
                    type="file"
                    hidden
                    accept="video/*"
                    onChange={handleVideo}
                    id="video"
                  />
                  <label
                    htmlFor="video"
                    className="h-[38px] px-[15px] w-full cursor-pointer text-neutral-50 text-sm font-medium pt-2.5 pb-[11px] bg-cashiaBlue rounded-lg justify-center items-center gap-[3px] inline-flex">
                    Select File
                  </label>
                </label>
              )}
            </div>
          </div>
          <div className="flex w-[calc(100svw-400px)]">
            <div className="w-[85%] flex flex-col gap-12">
              <div className="flex flex-col gap-1">
                <div className="flex">
                  <p className="w-full text-neutral-800 text-xl font-semibold">
                    Caption
                  </p>
                  <p className="text-neutral-500 text-[15px] font-medium">
                    {fileInfo?.caption?.length || 0}/{captionLimit}
                  </p>
                </div>
                <TextInput
                  containerStyle="h-12"
                  value={fileInfo?.caption || ''}
                  onChange={handleChangeOnCaption}
                />
              </div>
              <div className="flex w-full flex-col gap-1">
                <p className="text-neutral-800 text-xl font-semibold">Cover</p>
                <div className="w-full h-[142px] p-3.5 bg-zinc-100 rounded-[10px] border border-stone-300 justify-start items-center gap-3 flex flex-wrap overflow-x-scroll no-scrollbar">
                  {fileInfo?.thumbnails?.length ? (
                    fileInfo?.thumbnails?.map((imgUrl, i) => (
                      <img
                        key={i}
                        src={imgUrl}
                        onClick={() => updateThumbnail?.(imgUrl)}
                        className={cn(
                          'w-[68px] h-[114px] object-cover cursor-pointer',
                          {
                            'opacity-50': imgUrl !== fileInfo.selectedThumbnail,
                          }
                        )}
                      />
                    ))
                  ) : (
                    <div className="w-[68px] h-[114px] bg-stone-300" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        back={isUpdating ? `/reels/analytic/${id || ''}` : `/reels`}
        saveReelInfo={saveReelInfo}
        disabled={
          !(
            fileInfo?.caption &&
            (isUpdating || fileInfo?.uploadedReelMedia?.id)
          )
        }
      />
    </div>
  );
};

export default UploadOrEditReel;
