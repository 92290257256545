import {useCallback, useEffect, useMemo, useState} from 'react';
import AppleSignin from 'react-apple-signin-auth';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {Location, useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as AppleSvg} from '../../assets/logos/apple_logo.svg';
import {ReactComponent as GoogleSvg} from '../../assets/logos/google_logo.svg';
import Spinner from '../../components/tailwind/Spinner';
import {AuthProvider} from '../../graphql/generated';
import {useAuth} from '../../utils/auth';
import {googleClientId} from '../../utils/constants';

type EnvEnvironment = 'development' | 'production' | 'staging';

export type AppleAuthorizationType = {
  code?: string;
  id_token?: string;
  state?: string;
};

export type AppleLoginResponse = {
  authorization: AppleAuthorizationType;
};

interface AppleButtonProps {
  onClick: () => void;
}

export type LocationState = {
  from: {pathname: string};
};

type SocialLoginTrigger = {
  type?: 'google' | 'apple';
  loading?: boolean;
};

const SocialLogin = () => {
  const {signupWithProvider} = useAuth();
  const navigate = useNavigate();
  const location: Location = useLocation();
  const [socialLoginTrigger, setSocialLoginTrigger] =
    useState<SocialLoginTrigger>();
  const {isAuthenticated} = useAuth();
  const locationState = useMemo(
    () => location.state as LocationState,
    [location.state]
  );
  const from = useMemo(
    () => locationState?.from?.pathname || '/',
    [locationState?.from?.pathname]
  );

  const goToHome = useCallback(
    () => navigate(from, {replace: true}),
    [from, navigate]
  );
  useEffect(() => {
    if (isAuthenticated) {
      goToHome();
    }
  }, [from, goToHome, isAuthenticated, navigate]);

  const handleGoogleSuccess = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      void (async () => {
        const googleResponse = response as GoogleLoginResponse;
        if (!googleResponse.tokenId) return;
        await signupWithProvider(googleResponse.tokenId, AuthProvider.Google);
      })();
      setSocialLoginTrigger({type: 'google', loading: false});
    },
    [signupWithProvider]
  );
  const handleAppleSuccess = useCallback(
    (response: AppleLoginResponse) => {
      void (async () => {
        if (!response.authorization.id_token) return;
        await signupWithProvider(
          response.authorization.id_token,
          AuthProvider.Apple
        );
      })();
      setSocialLoginTrigger({type: 'apple', loading: false});
    },
    [signupWithProvider]
  );
  const handleFailure = useCallback(() => {
    setSocialLoginTrigger((prev) => ({...prev, loading: false}));
  }, []);

  const appleRedirectURL = (appEnv: EnvEnvironment) => {
    const value = {
      development: `http://localhost:3000${location.pathname}`,
      staging: `https://main.merchant.stg.cashia.com${location.pathname}`,
      production: `https://merchant.cashia.com${location.pathname}`,
    };
    return value[appEnv];
  };

  return (
    <div className="flex flex-col gap-5">
      {googleClientId && (
        <GoogleLogin
          render={(renderProps) => (
            <button
              className="w-full flex justify-between items-center gap-2 py-3 px-5 rounded-[10px] bg-transparent border-2 border-neutral-800 cursor-pointer transition duration-200 select-none hover:bg-slate-50"
              onClick={() => {
                setSocialLoginTrigger({type: 'google', loading: true});
                renderProps.onClick();
              }}
              disabled={renderProps.disabled}>
              {socialLoginTrigger?.type === 'google' &&
              socialLoginTrigger.loading ? (
                <Spinner />
              ) : (
                <>
                  <GoogleSvg />
                  <p>Continue with Google</p>
                  <div className="h-4 w-1" />
                </>
              )}
            </button>
          )}
          clientId={googleClientId}
          onSuccess={handleGoogleSuccess}
          onFailure={handleFailure}
          cookiePolicy="single_host_origin"
        />
      )}
      <AppleSignin
        render={(renderProps: AppleButtonProps) => (
          <button
            className="w-full flex justify-between items-center gap-2 py-3 px-5 rounded-[10px] bg-transparent border-2 border-neutral-800 cursor-pointer transition duration-200 select-none hover:bg-slate-50"
            onClick={() => {
              setSocialLoginTrigger({type: 'apple', loading: true});
              renderProps.onClick();
            }}>
            {socialLoginTrigger?.type === 'apple' &&
            socialLoginTrigger.loading ? (
              <Spinner />
            ) : (
              <>
                <AppleSvg />
                <p>Continue with Apple</p>
                <div className="h-4 w-1" />
              </>
            )}
          </button>
        )}
        authOptions={{
          clientId: 'com.cashia.web',
          scope: 'email name',
          redirectURI: appleRedirectURL(
            process.env.REACT_APP_ENVIRONMENT as EnvEnvironment
          ),
          state: 'state',
          nonce: 'nonce',
          usePopup: true,
        }}
        onSuccess={handleAppleSuccess}
        onError={handleFailure}
        cookiePolicy="single_host_origin"
      />
    </div>
  );
};

export default SocialLogin;
