import {
  Button,
  BottomSheet,
  BottomSheetClose,
  BottomSheetContent,
  BottomSheetFooter,
  BottomSheetHeader,
  BottomSheetTrigger,
  useMediaQuery,
} from '@cashiaApp/web-components';
import React, {useState, useEffect, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import NoPhoto from '../../assets/icons/no-photo.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/search_icon.svg';
import Modal from '../../components/tailwind/Modal';
import TextInput from '../../components/tailwind/TextInput';
import {Product, useOwnProductsSearchLazyQuery} from '../../graphql/generated';
import defaultVariant from '../../pages/store/components/defaultVariantSearch';
import useDebounce from '../../utils/helper/useDebounce';
import '../../index.css';
import Spinner from '../tailwind/Spinner';

const SearchResult = () => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [clickedProduct, setClickedProduct] = useState<Product | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const placeholder = location.pathname.includes('orders')
    ? 'Search orders...'
    : 'Search store...';
  const [showModal, setShowModal] = useState(false);

  const isLargeScreen = useMediaQuery('(min-width: 1024px)');

  const [triggerProductLoad, {data: productsData, loading: productsLoading}] =
    useOwnProductsSearchLazyQuery();

  const searchProducts = useCallback(async () => {
    await triggerProductLoad({
      variables: {
        searchQuery: {
          text: debouncedSearch,
        },
      },
    });
  }, [debouncedSearch, triggerProductLoad]);

  const addToSearchHistory = useCallback(
    (query: string) => {
      if (query.trim() === '') return;
      const completeQuery = query.trim();
      if (!searchHistory.includes(completeQuery)) {
        const updatedHistory = [completeQuery, ...searchHistory];
        localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
      }
    },
    [searchHistory]
  );

  const clearSearchHistory = () => {
    setSearchHistory([]);
    setClickedProduct(null);
    localStorage.removeItem('searchHistory');
  };

  useEffect(() => {
    const storedHistory = localStorage.getItem('searchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory) as string[]);
    }
  }, []);

  useEffect(() => {
    if (debouncedSearch) void searchProducts();
  }, [debouncedSearch, searchProducts]);

  const renderSearchContent = () => (
    <div className="flex flex-col w-full">
      <TextInput
        autoFocus
        containerStyle="flex justify-center items-center"
        inputStyle="h-full w-[280px] md:w-[700px] lg:w-[480px] px-4 border-hidden"
        placeholder={placeholder}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        startAdornment={<SearchIcon />}
      />
      {search.length === 0 && !clickedProduct && searchHistory.length === 0 ? (
        <div className="flex items-center justify-center pt-6 pb-20">
          No recent searches
        </div>
      ) : search.length === 0 && !clickedProduct ? (
        <div>
          {searchHistory.length > 0 && (
            <>
              <div className="flex px-8 justify-between pt-6 items-center">
                <p>Recent</p>
                <li>
                  <button onClick={clearSearchHistory}>
                    <p className="text-cashiaBlue py-4">Clear all</p>
                  </button>
                </li>
              </div>
              <ul>
                {searchHistory.map((query) => (
                  <div
                    key={query}
                    className="flex px-8 justify-between hover:bg-white text-cashiaBlue items-center"
                    onClick={() => {
                      setSearch(query);
                      setShowModal(true);
                    }}>
                    {query}
                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                ))}
              </ul>
            </>
          )}
        </div>
      ) : (
        <div className="flex justify-between flex-col">
          <div className="flex px-4 z-10 py-8 pb-2.5 border-b-[1px] border-greyish">
            <div className="flex md:w-[253px] flex-1 flex-nowrap">
              <p className="font-semibold">PRODUCT</p>
            </div>
            <div className="flex md:w-[170px] flex-1">
              <p className="font-semibold">NAME</p>
            </div>
          </div>
          <div className="flex flex-col max-h-[500px] justify-between overflow-y-scroll">
            {productsLoading ? (
              <div className="flex flex-1 justify-center">
                <Spinner />
              </div>
            ) : productsData?.ownProductsSearch?.products.length ? (
              productsData.ownProductsSearch.products.map((product) => {
                if (!product || !product.active || !product.title) return;
                const {images} = defaultVariant(product) ?? {};
                return (
                  <div
                    className="flex gap-20 md:gap-4 min-h-[55px] px-8 border-b-[1px] border-greyish"
                    onClick={() => {
                      if (product && product.id) {
                        navigate(`/store/product/${product.id || ''}`);
                        addToSearchHistory(
                          (product?.title || '').toLowerCase()
                        );
                      }
                    }}
                    key={product.id}>
                    <div className="flex items-center md:w-[253px]">
                      <img
                        src={images?.[0]?.medium || NoPhoto}
                        className="w-[52.5px] h-[35px] mr-5 rounded border"
                      />
                    </div>
                    <p className="flex md:w-[152px] items-center flex-1 flex-wrap">
                      {product?.title}
                    </p>
                  </div>
                );
              })
            ) : (
              <p className="flex justify-center items-center text-center h-[40vh]">
                No products available for {search}. You can add the product
                using the `Add item +` button.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full sm:px-1 lg:px-7 xl:px-20">
      <div
        className="flex items-center w-full px-4 py-2 bg-lightenedGrey rounded-lg"
        onClick={() => setShowModal(true)}>
        <input
          type="text"
          placeholder={placeholder}
          className="flex-grow bg-transparent border-none outline-none"
          readOnly
        />
        <SearchIcon
          className="ml-2 cursor-pointer"
          onClick={() => setShowModal(true)}
        />
      </div>

      {isLargeScreen ? (
        <Modal
          className="flex items-center justify-center max-h-[80%] py-8"
          isVisible={showModal}
          onClose={() => {
            setShowModal(false);
          }}>
          {renderSearchContent()}
        </Modal>
      ) : (
        <BottomSheet open={showModal} onOpenChange={setShowModal}>
          <BottomSheetTrigger asChild>
            <div />
          </BottomSheetTrigger>
          <BottomSheetContent>
            <BottomSheetHeader className="text-left" />
            {renderSearchContent()}
            <BottomSheetFooter className="pt-2">
              <BottomSheetClose asChild>
                <Button variant="outline">Close</Button>
              </BottomSheetClose>
            </BottomSheetFooter>
          </BottomSheetContent>
        </BottomSheet>
      )}
    </div>
  );
};

export default SearchResult;
