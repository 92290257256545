import {
  ChangeEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  CreateReelFieldsFragment,
  GetReelsDocument,
  GetReelsQuery,
  MediaFieldsFragment,
  ProductWithVariantsFieldsFragment,
  ReelFieldsFragment,
  UpdateReelInput,
  useCreateReelMutation,
  useGetReelLazyQuery,
  useUpdateReelMutation,
  useUploadReelMutation,
} from '../../../graphql/generated';
import {updateStringStateWithLimit} from '../../../utils/reusableFunctions';
import {useUserAuth} from '../../../utils/user';
import {VideoToFrames, VideoToFramesMethod} from '../utils/VideoToFrames';

type FileInfo = {
  file?: File;
  caption?: string;
  thumbnails?: string[];
  selectedThumbnail?: string;
  product?: ProductWithVariantsFieldsFragment;
  uploadedReelMedia?: MediaFieldsFragment;
  createdReelMedia?: ReelFieldsFragment | CreateReelFieldsFragment;
};

type UploadOrEditReelsContent = {
  step: number;
  loading?: boolean;
  totalSteps: number;
  fileInfo?: FileInfo;
  createReel?: () => void;
  updateReelLoading?: boolean;
  isUpdating?: boolean;
  updateThumbnail?: (imgUrl: string) => void;
  updateReel?: (input?: UpdateReelInput, to?: string) => void;
  handleChangeOnCaption?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleVideo?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

type Props = {
  isUpdating?: boolean;
};

export const captionLimit = 150;

const UploadOrEditReelsContentContext = createContext<UploadOrEditReelsContent>(
  {
    step: 1,
    totalSteps: 2,
  }
);

export const useUploadOrEditReelsContentContext = () =>
  useContext(UploadOrEditReelsContentContext);

const UploadOrEditReelsLayout = ({isUpdating}: Props) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {id} = useParams();
  const {addToast} = useToast();
  const {merchant} = useUserAuth();
  const [fileInfo, setFileInfo] = useState<FileInfo>();
  const [loading, setloading] = useState(false);
  const [updateReelLoading, setupdateReelLoading] = useState(false);
  const [step, setstep] = useState(0);
  const [uploadReelMutation] = useUploadReelMutation();
  const [createReelMutation] = useCreateReelMutation();
  const [updateReelMutation] = useUpdateReelMutation();

  const uploadReelsLinks = useMemo(
    () => ['/reels/upload', '/reels/upload/tag-products'],
    []
  );
  const editReelsLinks = useMemo(
    () => [`/reels/edit/${id || ''}`, `/reels/edit/${id || ''}/tag-products`],
    [id]
  );

  const [getReel] = useGetReelLazyQuery({
    variables: {id: id || ''},
  });

  const updateThumbnail = (imgUrl: string) =>
    setFileInfo((prev) => ({
      ...prev,
      selectedThumbnail: imgUrl,
    }));

  const handleChangeOnCaption = (event: ChangeEvent<HTMLInputElement>) => {
    updateStringStateWithLimit(event.target.value, captionLimit, (caption) =>
      setFileInfo((prev) => ({...prev, caption}))
    );
  };

  const handleVideo = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      setFileInfo({file, caption: fileInfo?.caption});
      setloading(true);
      uploadReelMutation({
        variables: {file},
      })
        .then(async (res) => {
          const frames = await VideoToFrames.getFrames(
            URL.createObjectURL(file),
            9,
            VideoToFramesMethod.totalFrames
          );
          setFileInfo((prev) => ({
            ...prev,
            thumbnails: frames,
            selectedThumbnail: frames[0],
            uploadedReelMedia: res.data?.uploadReel,
          }));
          setloading(false);
        })
        .catch((error: Error) => {
          setFileInfo(undefined);
          setloading(false);
          addToast({
            icon: false,
            type: 'error',
            message: error.message,
            duration: 5000,
          });
        });
    },
    [addToast, fileInfo?.caption, uploadReelMutation]
  );

  const createReel = useCallback(() => {
    if (
      !(fileInfo?.uploadedReelMedia?.id && merchant?.id && fileInfo.caption)
    ) {
      return;
    }
    setupdateReelLoading(true);
    createReelMutation({
      variables: {
        input: {
          name: fileInfo.caption,
          merchantId: merchant?.id,
          mediaId: fileInfo.uploadedReelMedia.id,
        },
      },
      update: (cache, result) => {
        const variables = {
          input: {
            cursor: {
              first: 20,
            },
          },
        };
        const cached = cache.readQuery<GetReelsQuery>({
          query: GetReelsDocument,
          variables,
        });
        cache.writeQuery({
          query: GetReelsDocument,
          variables,
          data: {
            ...cached,
            reels: {
              ...cached?.reels,
              edges: [
                {
                  __typename: 'ReelEdge',
                  cursor: '',
                  node: {
                    ...result?.data?.createReel,
                  },
                },
                ...(cached?.reels.edges || []),
              ],
              pageInfo: {...cached?.reels.pageInfo},
            },
          },
        });
      },
    })
      .then((res) => {
        setFileInfo((prev) => ({
          ...prev,
          createdReelMedia: res.data?.createReel,
        }));
        setupdateReelLoading(false);
        navigate(`/reels/upload/tag-products`);
      })
      .catch((error: Error) => {
        setupdateReelLoading(false);
        addToast({
          icon: false,
          type: 'error',
          message: error.message,
          duration: 5000,
        });
      });
  }, [
    addToast,
    createReelMutation,
    fileInfo?.caption,
    fileInfo?.uploadedReelMedia?.id,
    merchant?.id,
    navigate,
  ]);

  const updateReel = useCallback(
    (input?: UpdateReelInput, to?: string) => {
      if (!(fileInfo?.createdReelMedia?.id && fileInfo.caption)) {
        return;
      }
      setupdateReelLoading(true);
      updateReelMutation({
        variables: {
          id: fileInfo?.createdReelMedia?.id,
          input: input
            ? input
            : {
                name: fileInfo.caption,
              },
        },
        update: (cache, result) => {
          const variables = {
            input: {
              cursor: {
                first: 20,
              },
            },
          };
          const cached = cache.readQuery<GetReelsQuery>({
            query: GetReelsDocument,
            variables,
          });
          cache.writeQuery({
            query: GetReelsDocument,
            variables,
            data: {
              ...cached,
              reels: {
                ...cached?.reels,
                edges: [
                  ...(cached?.reels.edges || []).map((item) =>
                    item.node.id === fileInfo?.createdReelMedia?.id
                      ? {
                          ...item,
                          node: {
                            ...result?.data?.updateReel,
                          },
                        }
                      : item
                  ),
                ],
                pageInfo: {...cached?.reels.pageInfo},
              },
            },
          });
        },
      })
        .then((res) => {
          setFileInfo((prev) => ({
            ...prev,
            createdReelMedia: res.data?.updateReel,
          }));
          setupdateReelLoading(false);
          navigate(to || '');
        })
        .catch((error: Error) => {
          setupdateReelLoading(false);
          addToast({
            icon: false,
            type: 'error',
            message: error.message,
            duration: 5000,
          });
        });
    },
    [
      addToast,
      fileInfo?.caption,
      fileInfo?.createdReelMedia?.id,
      navigate,
      updateReelMutation,
    ]
  );

  const initialFetch = useCallback(async () => {
    if (!isUpdating) return;
    const res = await getReel({
      variables: {id: id || ''},
    });
    setFileInfo({
      caption: res.data?.reel.name,
      createdReelMedia: res.data?.reel,
      selectedThumbnail: res.data?.reel.thumbnail.large || '',
      thumbnails: [res.data?.reel.thumbnail.large || ''],
    });
  }, [getReel, id, isUpdating]);

  useEffect(() => {
    setstep(
      isUpdating
        ? editReelsLinks.indexOf(pathname) + 1
        : uploadReelsLinks.indexOf(pathname) + 1
    );
  }, [editReelsLinks, isUpdating, pathname, uploadReelsLinks]);

  useEffect(() => {
    if (isUpdating) sessionStorage.removeItem('newProductId');
    void initialFetch();
  }, [initialFetch, isUpdating]);

  useEffect(() => {
    if (!fileInfo?.createdReelMedia?.id && !isUpdating) {
      navigate('/reels/upload');
    }
  }, [fileInfo?.createdReelMedia?.id, isUpdating, navigate]);

  return (
    <UploadOrEditReelsContentContext.Provider
      value={{
        step,
        loading,
        fileInfo,
        createReel,
        updateReel,
        isUpdating,
        handleVideo,
        updateThumbnail,
        updateReelLoading,
        handleChangeOnCaption,
        totalSteps: uploadReelsLinks.length,
      }}>
      <Outlet />
    </UploadOrEditReelsContentContext.Provider>
  );
};

export default UploadOrEditReelsLayout;
