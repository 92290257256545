import {useNavigate} from 'react-router-dom';

import Button from '../../../components/tailwind/Button';
import Spinner from '../../../components/tailwind/Spinner';
import {useUploadOrEditReelsContentContext} from '../layout/UploadOrEditReelsLayout';

type Props = {
  next?: string;
  back?: string;
  disabled?: boolean;
  saveReelInfo?: () => void;
};
const Footer = ({back, next, saveReelInfo, disabled}: Props) => {
  const navigate = useNavigate();
  const {step, totalSteps, updateReelLoading} =
    useUploadOrEditReelsContentContext();
  const progressBarWidth = (step * 100) / totalSteps;

  return (
    <div className="w-full h-[70px]">
      <div
        className="h-0.5 bg-gradient-to-r from-rose-600 via-pink-600 to-yellow-500"
        style={{width: `${String(progressBarWidth)}%`}}
      />
      <div className="px-6 flex justify-between w-full items-center py-4 bg-neutral-100">
        <Button
          onClick={() => navigate(back || '')}
          label="Back"
          labelStyle="text-base font-normal text-black"
          className="border border-slate-900 bg-transparent rounded-xl"
        />
        <p className="text-neutral-500 text-[13px] font-normal">
          {step}/{totalSteps}
        </p>
        <Button
          className="rounded-xl"
          disabled={disabled}
          labelStyle="text-neutral-50 text-base font-normal"
          onClick={() => {
            if (!disabled) {
              next && navigate(next);
              saveReelInfo?.();
            }
          }}
          label={
            updateReelLoading ? (
              <Spinner fillColor="fill-white" width={36.5} />
            ) : (
              'Next'
            )
          }
        />
      </div>
    </div>
  );
};

export default Footer;
