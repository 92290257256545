import {useMediaQuery} from '@cashiaApp/web-components';
import {CircularProgress} from '@mui/material';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import ProfileLayout from './Layout';
import {ReactComponent as Archived} from '../../assets/icons/archive_icon.svg';
import {ReactComponent as ChevGrey} from '../../assets/icons/chevronGrey_icon.svg';
import {ReactComponent as Chevron} from '../../assets/icons/chevron_red.svg';
import {ReactComponent as Clock} from '../../assets/icons/clock_icon.svg';
import {ReactComponent as Feedback} from '../../assets/icons/feedback_icon.svg';
import {ReactComponent as File} from '../../assets/icons/file_icon.svg';
import {ReactComponent as Help} from '../../assets/icons/help.svg';
import {ReactComponent as Location} from '../../assets/icons/location_icon.svg';
import {ReactComponent as Logout} from '../../assets/icons/log_out.svg';
import {ReactComponent as Notifications} from '../../assets/icons/notification_icon.svg';
import {ReactComponent as Receipts} from '../../assets/icons/receipt_icon.svg';
import {ReactComponent as Transactions} from '../../assets/icons/transactions_icon.svg';
import Footer from '../../components/common/Footer';
import MiniNavbar from '../../components/common/bottomNavbar';
import Button from '../../components/tailwind/Button';
import {useGetWalletScreenInfoQuery} from '../../graphql/generated';
import {useAuth} from '../../utils/auth';
import formatMoney from '../../utils/formatMoney';
import {useUserAuth} from '../../utils/user';

interface ProfileCardType {
  icon: JSX.Element;
  title: string;
  text: string;
  route: string;
}

const ProfileCard = ({icon, title, text, route}: ProfileCardType) => {
  const navigate = useNavigate();
  const matchesMobile = useMediaQuery('(max-width: 768px)');
  const goToAddress = useCallback(() => navigate(route), [navigate, route]);
  return (
    <div
      onClick={goToAddress}
      className="flex lg:flex-1 lg:gap-0 sm:gap-5  border sm:items-center sm:justify-around lg:items-start lg:justify-start lg:flex-col sm:flex-row rounded-lg cursor-pointer drop-shadow-2xl shadow-lg p-4">
      <div className="w-4 h-5 ">{icon}</div>
      <p className="whitespace-pre-line w-full text-left sm:font-base left-0 lg:font-bold lg:text-primary sm:text-black lg:text-cashiaBlue mb-2 mt-2">
        {title}
      </p>
      {matchesMobile ? (
        <div className="">
          <ChevGrey />
        </div>
      ) : (
        <div className="flex">{text}</div>
      )}
    </div>
  );
};

const cardOne = [
  {
    icon: <Archived />,
    title: 'Archived',
    text: 'View and edit all products stored in your archives',
    route: 'archived',
  },
  {
    icon: <Transactions />,
    title: 'Transactions',
    text: 'View and manage all your transactions in and out of Cashia',
    route: '/wallet/track-usage',
  },
  {
    icon: <Receipts />,
    title: 'Receipts',
    text: 'Manage your receipts and keep upto date with your wallet activity',
    route: '/wallet/receipts',
  },
];
const cardTwo = [
  {
    icon: <Clock />,
    title: 'Operating hours',
    text: 'Manage your operating hours, work at your own convinience',
    route: 'operating_hours',
  },
  {
    icon: <Location />,
    title: 'Locations',
    text: 'Manage all your business locations. You can have multiple locations',
    route: 'locations',
  },
  {
    icon: <Notifications />,
    title: 'Notifications',
    text: 'Choose notification preferences and how you want to be contacted',
    route: 'notifications',
  },
];

const Profile = () => {
  const {merchant} = useUserAuth();
  const {data: balance, loading: balanceLoading} =
    useGetWalletScreenInfoQuery();
  const navigate = useNavigate();
  const goToEdit = useCallback(() => navigate('edit'), [navigate]);
  const matchesMobile = useMediaQuery('(min-width: 1020px)');
  const {logout} = useAuth();

  return (
    <ProfileLayout>
      <div className="flex flex-col sm:mx-6 lg:mx-4 min-h-screen pb-16">
        <main className="flex-grow pb-16">
          <div className="flex justify-between lg:mx-4 items-center mt-8 mb-4">
            <div className="text-3xl font-medium leading-8">Profile</div>
            <button
              onClick={goToEdit}
              className="border rounded lg:border-primary px-4 py-2 sm:border-cashiaBlue text-cashiaBlue">
              View Profile
            </button>
          </div>
          <div className="lg:mx-4 text-lg font-normal mb-16 leading-5">
            {merchant?.name}
          </div>
          <div className="flex lg:mx-4 sm:mx-0 flex-col gap-6">
            <div
              onClick={() => navigate('')}
              className="sm:flex lg:hidden text-foggy">
              CASHIA WALLET
            </div>
            <div className="sm:flex justify-between lg:hidden border rounded cursor-pointer shadow-lg p-4">
              <div className="flex flex-col justify-between gap-4">
                <div className="text-base text-foggy mr-12">
                  Available Balance
                </div>
                <div className="font-medium text-2xl text-black mr-12">
                  {balanceLoading ? (
                    <CircularProgress size="30" color="inherit" />
                  ) : (
                    balance?.me &&
                    balance?.me.__typename === 'Merchant' &&
                    `${balance?.me?.wallet?.credit?.currencyCode || ''} 
                  ${
                    balance?.me?.wallet?.credit?.currencyCode
                      ? formatMoney(balance?.me?.wallet?.credit, 2)
                      : '---'
                  }`
                  )}
                </div>
              </div>
              <Button
                label="Go to wallet"
                labelStyle="text-xs sm:text-sm"
                className="bg-cashiaBlue"
                onClick={() => navigate('/wallet')}
              />
            </div>
            <div className="sm:flex lg:hidden text-foggy">ACCOUNT SETTINGS</div>
            <div className="flex lg:flex-row sm:flex-col gap-4">
              {cardOne.map((card, index) => (
                <ProfileCard
                  key={index}
                  icon={card.icon}
                  title={card.title}
                  text={card.text}
                  route={card.route}
                />
              ))}
            </div>
            <div className="flex lg:flex-row sm:flex-col gap-4 lg:mb-20">
              {cardTwo.map((card, index) => (
                <ProfileCard
                  key={index}
                  icon={card.icon}
                  title={card.title}
                  text={card.text}
                  route={card.route}
                />
              ))}
            </div>
            <div className="sm:flex lg:hidden text-foggy">SUPPORT</div>
            <div className="sm:flex lg:hidden flex-col rounded border shadow-lg justify-center">
              <div
                onClick={() => navigate('/get-help')}
                className="sm:flex justify-between lg:hidden border cursor-pointer p-4">
                <Help />
                <div className="font-base text-base mr-28">Get help</div>
                <div>
                  <ChevGrey />
                </div>
              </div>
              <div
                onClick={() => navigate('/feedback')}
                className="sm:flex justify-between lg:hidden border cursor-pointer p-4">
                <Feedback />
                <div className="font-base text-base mr-12">
                  Give us feedback
                </div>
                <div>
                  <ChevGrey />
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate('')}
              className="sm:flex lg:hidden text-foggy">
              LEGAL
            </div>
            <div
              onClick={() => navigate('')}
              className="sm:flex justify-between lg:hidden border rounded cursor-pointer shadow-lg p-4">
              <File />
              <div className="font-semibold text-base mr-12">
                Terms of service
              </div>
              <div>
                <ChevGrey />
              </div>
            </div>
            <div className="sm:flex lg:hidden text-foggy">MORE</div>
            <div
              onClick={logout}
              className="sm:flex justify-between lg:hidden border rounded cursor-pointer shadow-md p-4 mb-20">
              <Logout />
              <div className="font-semibold text-base text-red mr-28">
                Log Out
              </div>
              <div>
                <Chevron />
              </div>
            </div>
          </div>
        </main>
        {matchesMobile ? (
          <Footer />
        ) : (
          <div
            className="fixed bottom-0 px-8 bg-white left-0 w-full"
            style={{boxShadow: '-8px -4px 20px -5px rgba(0, 0, 0, 0.25)'}}>
            <MiniNavbar />
          </div>
        )}
      </div>
    </ProfileLayout>
  );
};

export default Profile;
