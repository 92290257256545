import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import backButton from '../../assets/icons/back_arrow_black.svg';
import Modal from '../../components/tailwind/Modal';

type Props = {
  onToggle?: (value: boolean) => void;
};
const DeliveryIssues = ({onToggle}: Props) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const toggle = useCallback(() => {
    setOpen(!open);
    onToggle?.(!open);
  }, [onToggle, open]);
  return (
    <div>
      <div className="flex flex-row">
        <img
          src={backButton}
          className="pt-5 pl-5"
          onClick={() => navigate('/GetHelp')}
        />
        <p className="flex text-lg font-medium pt-[20] pl-[110]">
          Delivery Issues
        </p>
      </div>
      <div className="border-b-[2px] border-greyish pt-[20]" />
      <p className="pt-[25] pl-[20] pr-[20]">
        Due to current developments related to COVID-19, some of our deliveries
        may experience delays of one to two hours. We are working to respond
        swiflty to all incoming orders and requests, but we are prioritizing
        safety and urgent emergency deliveries.
      </p>
      <p className="pt-[25] pl-[20] pr-[20]">
        The safety of our merchants, riders, drivers and customers is our always
        our main priority. We are acvtively monitoring the COVID-19 situation
        and want to thank you in advance for helping keep our community safe.
      </p>
      <p className="pt-[25] pl-[20] pr-[20]">
        We apologize for any inconvinience caused and we appreciate your
        patience.
      </p>
      <p className="pt-[25] pl-[20] pr-[20]">
        Please visit our website for more information about Cashia’s COVID-19
        response.
      </p>
      <p className="pt-[25] pl-[20] pr-[20] text-CashiaBlue">
        Our COVID-19 Resources, Guideliness and updates
      </p>
      <div className="pl-[20] pt-[90]">
        <button
          className="rounded-md text-smoothRed w-[350] h-[50] outline outline-smoothRed"
          onClick={toggle}>
          Contact support
        </button>
      </div>
      <Modal
        isVisible={open}
        onClose={toggle}
        className="w-screen h-screen bg-purplebg opacity-70">
        <div className="flex flex-row">
          <div className="flex flex-col space-between gap-8 items-center pt-[635] pl-[20]">
            <button className="cursor-pointer w-[350] h-[50] rounded-md outliine-white bg-white font-semibold">
              +254123456789
            </button>
            <button
              className="cursor-pointer w-[350] h-[50] text-white font-semibold rounded-md outline-smoothRed bg-smoothRed"
              onClick={toggle}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeliveryIssues;
