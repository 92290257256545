import clsx from 'clsx';
import {
  createContext,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useState,
} from 'react';

import {Toast} from './Toast';
import {ToastContextType, ToastPositionType, ToastProps} from './type';
import {positionClasses} from './utils';

const ToastContext = createContext<ToastContextType>({
  addToast: () => undefined,
  removeToast: () => undefined,
  position: 'bottomLeft',
});

export const useToast = () => useContext(ToastContext);

export const ToastProvider: FC<{children: ReactNode}> = ({children}) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [position, setPosition] = useState<ToastPositionType>('bottomLeft');

  const addToast = useCallback(
    (toast: Omit<ToastProps, 'id'>) => {
      if (toast.position && toast.position !== position) {
        setPosition(toast.position);
      }
      setToasts((prev) => [...prev, {...toast, id: Math.random() * 10000}]);
    },
    [position]
  );

  const removeToast = useCallback(
    (toastId: number, ref: RefObject<HTMLDivElement>) => {
      ref?.current?.classList.add('animate-toastOut');
      ref?.current?.addEventListener('animationend', () => {
        setToasts((prev) => prev.filter((toast) => toast.id !== toastId));
      });
    },
    []
  );

  return (
    <ToastContext.Provider value={{addToast, removeToast, position}}>
      {children}
      <div
        className={clsx(
          positionClasses[position],
          'fixed w-screen max-w-xs z-50'
        )}>
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
