import {ChangeEvent, DragEvent, useEffect, useMemo, useState} from 'react';

import {ReactComponent as DeleteIcon} from '../../../assets/icons/deleteIcon.svg';
import {ReactComponent as DocIcon} from '../../../assets/icons/docIcon.svg';
import {ReactComponent as TickIcon} from '../../../assets/icons/tickIcon.svg';
import {ReactComponent as UploadIcon} from '../../../assets/icons/uploadIcon.svg';
import Spinner from '../../../components/tailwind/Spinner';
import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  DocumentType,
  MeDocument,
  MeQuery,
  MerchantDocumentFieldsFragment,
  useCreateMerchantDocumentMutation,
  useUploadMediaMutation,
} from '../../../graphql/generated';
import {isMerchant} from '../../../utils/apollo/helpers';
import {cn, formatFileSize} from '../../../utils/reusableFunctions';
import {useSelfOnboardingContentContext} from '../layout';
import {DocumentKeys} from '../pages/Documents';

type Props = {
  docType: DocumentKeys;
  deleteLoading?: boolean;
  uploadedPdf?: MerchantDocumentFieldsFragment;
  deleteDocument?: (documentId: string) => Promise<void>;
};

const FileUpload = ({
  docType,
  uploadedPdf,
  deleteDocument,
  deleteLoading,
}: Props) => {
  const {merchantType: currentMerchantType} = useSelfOnboardingContentContext();
  const {addToast} = useToast();
  const inputId = useMemo(() => `pdf-uploader-${crypto.randomUUID()}`, []);
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [uploadMedia, {error}] = useUploadMediaMutation();
  const [createMerchantDocumentMutation] = useCreateMerchantDocumentMutation();

  const documentType = (doc?: DocumentKeys) => {
    if (!doc) return DocumentType.KraPin;
    return {
      kraPin: DocumentType.KraPin,
      registrationCertificate: DocumentType.RegistrationCertificate,
      incorporationCertificate: DocumentType.IncorporationCertificate,
      directors: DocumentType.Directors,
      cr12: DocumentType.Cr12,
      partnershipDeed: DocumentType.PartnershipDeed,
    }[doc];
  };

  const upLoadPdfDocument = async (upLoadingFile?: File) => {
    if (!currentMerchantType) return;
    setLoading(true);
    setFile(upLoadingFile);
    if (upLoadingFile?.type !== 'application/pdf') {
      addToast({
        icon: true,
        type: 'warning',
        message: (
          <p className="text-sm text-white normal-case">
            Only <span className="text-blue-300">&quot;PDF&quot; </span>
            files are accepted
          </p>
        ),
        duration: 3000,
      });
      setLoading(false);
      return;
    }
    const res = await uploadMedia({
      variables: {
        file: upLoadingFile,
      },
    });
    if (res.data?.uploadMedia?.id && docType) {
      await createMerchantDocumentMutation({
        variables: {
          input: {
            mediaId: res.data?.uploadMedia.id,
            documentType: documentType(docType),
          },
        },
        update: (cache, result) => {
          const cached = cache.readQuery<MeQuery>({
            query: MeDocument,
          });
          if (!isMerchant(cached?.me) || !cached?.me) return;
          cache.writeQuery({
            query: MeDocument,
            data: {
              ...cached,
              me: {
                ...cached?.me,
                merchantDocuments: [
                  ...(cached?.me.merchantDocuments || []),
                  result.data?.createMerchantDocument,
                ],
              },
            },
          });
        },
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    if (uploadedPdf) return;
    event.preventDefault();
    void upLoadPdfDocument(event.dataTransfer.files[0]);
  };
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (uploadedPdf) return;
    event.preventDefault();
    void upLoadPdfDocument(event.target.files?.[0]);
  };

  useEffect(() => {
    if (!error) return;
    addToast({
      icon: false,
      type: 'error',
      message: (
        <p className="text-sm text-white normal-case">
          Failed to upload{' '}
          <span className="text-blue-300">
            &quot;{file?.name?.toLowerCase()}&quot;
          </span>
        </p>
      ),
      duration: 3000,
    });
  }, [addToast, error, file?.name]);

  return (
    <div
      className={cn(
        'sm:w-full md:w-[47%] h-[138px] bg-slate-50 rounded-2xl border-2 border-dashed border-cyan-500',
        {'border-solid border border-green-600': uploadedPdf}
      )}
      onDrop={handleDrop}
      onDragOver={(event) => {
        if (uploadedPdf) return;
        event.preventDefault();
      }}>
      {uploadedPdf ? (
        <div className="flex flex-col p-5 justify-between h-full">
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center w-[80%]">
              <DocIcon />
              <p className="text-neutral-800 text-base font-medium leading-tight truncate">
                {uploadedPdf.fileName}
              </p>
            </div>
            <p className="text-neutral-500 text-right text-[13px] font-normal w-[20%]">
              {uploadedPdf.size && formatFileSize(uploadedPdf.size || 0)}
            </p>
          </div>
          <div className="w-full h-px bg-zinc-200" />
          <div className="flex justify-between">
            <div className="w-[92px] h-6 pl-2 pr-1 py-1 bg-green-100 rounded-xl justify-center items-center gap-[5px] inline-flex">
              <div className="text-center text-green-600 text-[13px] font-medium leading-none">
                Uploaded
              </div>
              <TickIcon />
            </div>
            <div className="w-[30px]">
              {deleteLoading ? (
                <Spinner />
              ) : (
                <DeleteIcon
                  onClick={() => void deleteDocument?.(uploadedPdf.id || '')}
                  className="cursor-pointer"
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center select-none items-center h-full gap-1">
          {loading ? (
            <>
              <Spinner
                fillColor="fill-cashiaBlue"
                className="h-[40px] w-full"
              />
              <p className="text-sm">uploading...</p>
            </>
          ) : (
            <>
              <UploadIcon />
              <p className="text-neutral-800 text-base font-medium">
                Drag and drop files, or{' '}
                <label
                  htmlFor={inputId}
                  className="text-cyan-500 cursor-pointer">
                  Browse
                </label>
              </p>
              <p className="text-neutral-500 text-[13px] font-normal">
                Supports PDF files
              </p>
              <input
                type="file"
                hidden
                id={inputId}
                onChange={handleChange}
                accept="application/pdf"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
