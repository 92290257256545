import {toast, useMediaQuery} from '@cashiaApp/web-components';
import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

import MobileViewStore from './MobileViewStore';
import useGetProducts from './hooks/useGetProducts';
import Archive from '../../assets/icons/archive.svg';
import CheckedGreen from '../../assets/icons/checked-icon-green.svg';
import closeIcon from '../../assets/icons/close-circle.svg';
import DeleteIcon from '../../assets/icons/delete-red.svg';
import ExclamationRed from '../../assets/icons/exclamation-red.svg';
import NoPhoto from '../../assets/icons/no-photo.svg';
import Settings from '../../assets/icons/settings.svg';
import empty from '../../assets/images/emptyStore.svg';
import Navbar from '../../components/common/Navbar';
import Button from '../../components/tailwind/Button';
import Modal from '../../components/tailwind/Modal';
import Spinner from '../../components/tailwind/Spinner';
import {
  useRemoveProductMutation,
  ProductsQuery,
  ProductsDocument,
  useAddOrUpdateProductMutation,
  useGetMerchantProductCategoriesQuery,
} from '../../graphql/generated';
import defaultVariant from '../../utils/defaultVariant';
import formatMoney from '../../utils/formatMoney';
import {useUserAuth} from '../../utils/user';
import CursorPagination from '../orders/components/CursorPagination';
import '../../index.css';

interface Route {
  title: string;
  linkname: string;
  id?: string;
}

interface Product {
  id?: string | null;
  active?: boolean | null;
}

const EmptyStore = ({navigate}: {navigate: NavigateFunction}) => (
  <div className="flex flex-col h-[calc(100svh-64px)] w-full overflow-hidden">
    <div className="h-[40%] flex flex-col justify-center items-center w-full">
      <div className="text-black text-[45px] font-semibold">
        Your store is empty.
      </div>
      <button
        className="text-center w-[206px] h-12 bg-cashiaBlue rounded-[30px] text-neutral-50 text-base font-normal"
        onClick={() => navigate('add-product')}>
        Add your first item +
      </button>
    </div>
    <div className="h-[60%] w-full">
      <img src={empty} className="w-full h-full object-contain" />
    </div>
  </div>
);

const Store = () => {
  const {merchant} = useUserAuth();
  const [categoryId, setCategoryId] = useState<string | undefined>('');
  const {
    loadMore,
    count,
    data: cursorProducts,
    loading: productsLoading,
    error: productsError,
    refetch,
  } = useGetProducts(merchant?.id || '', categoryId ? [categoryId] : undefined);

  const navigate = useNavigate();
  const [activeRoute, setActiveRoute] = useState('all');
  const [productId, setproductId] = useState<string>('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const {data: productsCategoriesData, loading: categoriesLoading} =
    useGetMerchantProductCategoriesQuery({
      variables: {
        id: merchant?.id || '',
      },
      skip: merchant?.id === '',
      fetchPolicy: 'network-only',
    });

  const isActive = useCallback(
    (name: string) => activeRoute === name,
    [activeRoute]
  );
  const productCategories = useMemo(
    () => productsCategoriesData?.merchant?.productCategories,
    [productsCategoriesData]
  );

  const [
    addOrUpdateProductMutation,
    {loading: updateProductLoading, error: updateProductError},
  ] = useAddOrUpdateProductMutation();
  const [removeProductMutation, {loading: deleteLoading, error: deleteError}] =
    useRemoveProductMutation();

  const products = useMemo(
    () => cursorProducts?.productsWithCursorPagination.edges,
    [cursorProducts]
  );
  const variables = {
    merchantId: merchant?.id || '',
  };

  useEffect(() => {
    const fetchProducts = async () => {
      if (categoryId !== undefined) {
        await refetch();
      }
    };
    fetchProducts().catch(console.error);
  }, [categoryId, refetch]);

  const handleDeleteProduct = async () => {
    setDeleteModalOpen(false);
    const response = await removeProductMutation({
      variables: {id: productId},
      update: (cache, result) => {
        const cached = cache.readQuery<ProductsQuery>({
          query: ProductsDocument,
          variables,
        });
        if (
          !cached ||
          !cached?.merchant?.products.items ||
          !result?.data?.removeProduct
        ) {
          return;
        }
        const arr = cached.merchant.products.items.filter((item) => {
          return item?.id !== productId;
        });
        cache.writeQuery<ProductsQuery>({
          query: ProductsDocument,
          variables,
          data: {
            ...cached,
            merchant: {
              products: {
                items: arr,
                total: arr.length,
              },
            },
          },
        });
        toast.success('Product has been deteled successfully');
      },
    });
    if (response.data?.removeProduct) {
      setDeleteModalOpen(false);
      try {
        await refetch();
      } catch (refetchError) {
        console.error('Error refetching products:', refetchError);
      }
    } else {
      toast.error(error);
    }
  };

  const handleArchiveProduct = async (currentlyActive: boolean) => {
    const newActiveState = currentlyActive === null ? true : !currentlyActive;

    try {
      const res = await addOrUpdateProductMutation({
        variables: {
          input: {
            id: productId,
            active: newActiveState,
          },
        },
      });
      setArchiveModalOpen(false);
      if (res.data?.addOrUpdateProduct?.active === newActiveState) {
        toast.success(
          `Product has been ${
            newActiveState ? 'unarchived' : 'archived'
          } successfully`
        );
        await refetch();
      } else {
        toast.error('Failed to update product status');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      toast.error('An error occurred while updating the product');
    }
  };

  const handleClick = async (product: Product) => {
    if (product.id && product.active !== undefined && product.active !== null) {
      try {
        await handleArchiveProduct(product.active);
      } catch (error) {
        console.error('Error archiving/unarchiving product:', error);
      }
    } else {
      console.error('Invalid product state', product);
    }
  };

  const error = useMemo(
    () =>
      deleteError?.message ||
      productsError?.message ||
      updateProductError?.message,
    [deleteError?.message, productsError?.message, updateProductError?.message]
  );
  const RouteItem = ({title, linkname, id}: Route) => (
    <div
      onClick={() => {
        setCategoryId(id);
        setActiveRoute(linkname);
      }}
      className={`flex items-center cursor-pointer px-6 h-14 border-b border-white ${
        isActive(linkname) ? 'bg-cashiaBlue' : 'bg-transparent'
      } ${isActive(linkname) ? '' : 'hover:bg-greyish'}`}>
      <p
        className={`${
          isActive(linkname) ? 'text-white' : 'text-black'
        } font-metropolis text-base`}>
        {title}
      </p>
    </div>
  );

  const list = productCategories?.map((item) => (
    <RouteItem
      key={item?.id}
      title={item?.name}
      linkname={item?.name.toLowerCase()}
      id={item.id}
    />
  ));

  const matchesMobile = useMediaQuery('(min-width: 1024px)');

  if (productsLoading || categoriesLoading) {
    return (
      <div className="w-screen h-screen">
        <Navbar />
        <div className="flex justify-center items-center h-[calc(100svh-64px)] overflow-hidden">
          <Spinner fillColor="fill-cashiaBlue" className="h-[40px] w-full" />
        </div>
      </div>
    );
  }

  if (!matchesMobile) {
    return (
      <div className="w-screen h-screen">
        <Navbar />
        <MobileViewStore />
      </div>
    );
  }

  const hasProducts = products && products.length > 0;
  const isEmptyStore = !categoryId && !hasProducts;

  const getModalTitle = () => {
    if (deleteModalOpen) {
      return 'Delete Product';
    } else if (archiveModalOpen) {
      const productEdge = products?.find((p) => p.node.id === productId);
      if (!productEdge) return '';
      const product = productEdge.node;
      const isArchived = !product.active;
      return isArchived ? 'Unarchive Product' : 'Archive Product';
    }
    return '';
  };
  const getNbStatement = () => {
    if (!archiveModalOpen) return '';

    const productEdge = products?.find((p) => p.node.id === productId);
    if (!productEdge) return '';
    const product = productEdge.node;
    const isArchived = !product.active;

    return isArchived
      ? 'Un-archived products will appear on our Cashia App store'
      : 'Archived products will not appear on our Cashia App store';
  };

  return (
    <div className="w-screen h-screen">
      <Navbar />
      {isEmptyStore ? (
        <EmptyStore navigate={navigate} />
      ) : (
        <div className="flex flex-row h-[calc(100svh-64px)] w-full flex-1">
          <div className="flex flex-col h-[calc(100svh-64px)] overflow-y-scroll no-scrollbar w-1/4 bg-offWhite">
            <h4 className="sm:h-[10%] xl:h-20 p-4 font-semibold text-xl">
              Categories
            </h4>
            <div className="h-[80%] xl:h-[84%] overflow-y-scroll no-scrollbar">
              <RouteItem title="All" linkname="all" />
              {list}
            </div>
            <div
              className="flex flex-grow p-6 w-full bg-offWhite shadow-md cursor-pointer"
              style={{boxShadow: '-8px -4px 20px -5px rgba(0, 0, 0, 0.25)'}}
              onClick={() => navigate('/store/manage-categories')}>
              <div className="mr-4">
                <img src={Settings} alt="Settings Icon" />
              </div>
              <p>Manage categories</p>
            </div>
          </div>

          <div className="flex-column px-8 py-10 justify-between flex-1 overflow-y-scroll no-scrollbar flex-col w-3/4">
            <div className="flex justify-between pb-4 border-b border-lightGrey">
              <div className="w-1/4">
                <p className="font-semibold">PRODUCT</p>
              </div>
              <div className="w-1/6">
                <p className="font-semibold">PRICE (KES)</p>
              </div>
              <div className="w-1/6">
                <p className="font-semibold">STATUS</p>
              </div>
              <div className="w-1/6">
                <p className="font-semibold">MANAGE</p>
              </div>
              <div className="w-1/6" />
            </div>

            <div className="flex flex-col justify-between max-h-[72svh] overflow-y-scroll no-scrollbar">
              {productsLoading ? (
                <div className="flex justify-center mt-4">
                  <Spinner
                    fillColor="fill-cashiaBlue"
                    className="h-[40px] w-full"
                  />
                </div>
              ) : hasProducts ? (
                products.map((product) => {
                  const {price, images} = defaultVariant(product.node) ?? {};
                  const isArchived = !product.node.active;
                  return (
                    <div
                      className={`flex items-center justify-around pb-6 pt-4 min-h-20 border-b border-lightGrey ${
                        isArchived ? 'text-lightGrey' : ''
                      }`}
                      key={product.node.id}>
                      <div
                        className="w-1/4 flex items-center"
                        onClick={() =>
                          navigate(`/store/product/${product.node.id || ''}`)
                        }>
                        <img
                          src={images?.[0]?.small || NoPhoto}
                          className="w-10 h-10 mr-4 rounded border object-cover"
                          alt="Product Image"
                        />
                        <div className="cursor-pointer">
                          <p>{product?.node.title}</p>
                        </div>
                      </div>
                      <div className="w-1/6">
                        <p>
                          {price?.currencyCode}
                          {price && formatMoney(price, 2)}
                        </p>
                      </div>
                      <div className="flex w-1/6">
                        {product.node.active ? (
                          <>
                            <div className="mr-2">
                              <img src={CheckedGreen} alt="Checked Icon" />
                            </div>
                            <p>In Stock</p>
                          </>
                        ) : (
                          <>
                            <div className="mr-2">
                              <img
                                src={ExclamationRed}
                                alt="Exclamation Icon"
                              />
                            </div>
                            <p>Out of Stock</p>
                          </>
                        )}
                      </div>
                      <div
                        className="flex w-1/6 cursor-pointer"
                        onClick={() => {
                          product.node.id && setproductId(product.node.id);
                          setArchiveModalOpen(true);
                        }}>
                        {productId === product.node.id &&
                        updateProductLoading ? (
                          <Spinner
                            fillColor="fill-cashiaBlue"
                            className="h-[40px] w-full"
                          />
                        ) : (
                          <>
                            <div className="mr-2">
                              <img src={Archive} alt="Archive Icon" />
                            </div>
                            <div className="text-trueBlack">
                              {product.node.active
                                ? 'Archive item'
                                : 'Unarchive item'}
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className="pl-20 w-1/6 cursor-pointer"
                        onClick={() => {
                          product.node.id && setproductId(product.node.id);
                          setDeleteModalOpen(true);
                        }}>
                        {deleteLoading && product.node.id === productId ? (
                          <Spinner
                            fillColor="fill-lazyRed"
                            className="h-[40px] w-full"
                          />
                        ) : (
                          <img src={DeleteIcon} alt="Delete Icon" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center py-8 items-center">
                  No products available in this category. You can add a product
                  using the `Add item +` button
                </div>
              )}
              <div className="flex absolute bottom-0 lg:right-[35%] items-center justify-center">
                {cursorProducts?.productsWithCursorPagination.pageInfo
                  .hasNextPage ||
                cursorProducts?.productsWithCursorPagination.pageInfo
                  .hasPrevPage ? (
                  <CursorPagination
                    loadMore={loadMore}
                    hasNextPage={
                      cursorProducts?.productsWithCursorPagination.pageInfo
                        .hasNextPage
                    }
                    hasPrevPage={
                      cursorProducts?.productsWithCursorPagination.pageInfo
                        .hasPrevPage
                    }
                    count={count}
                  />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        isVisible={deleteModalOpen || archiveModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setArchiveModalOpen(false);
        }}>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center p-7 pb-4">
            <h4 className="text-xl font-semibold leading-5">
              {getModalTitle()}
            </h4>
            <img
              src={closeIcon}
              className="cursor-pointer"
              onClick={() => {
                setDeleteModalOpen(false);
                setArchiveModalOpen(false);
              }}
            />
          </div>
          <hr />
          {deleteModalOpen ? (
            <div className="p-7">
              <div>
                <p>Are you sure you want to delete this product?</p>
                <div className="flex justify-between mt-4">
                  <div className="mt-5 flex gap-5 mb-4">
                    <Button
                      label="Delete"
                      className="bg-cashiaBlue"
                      labelStyle="text-neutral-50 text-base font-normal px-5 md:px-7"
                      onClick={handleDeleteProduct}
                    />
                    <Button
                      label="Cancel"
                      className="bg-lightGrey"
                      labelStyle="text-black text-base font-normal px-5 md:px-7"
                      onClick={() => setDeleteModalOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            archiveModalOpen && (
              <div>
                {(() => {
                  const productEdge = products?.find(
                    (p) => p.node.id === productId
                  );
                  if (!productEdge) return null;
                  const product = productEdge.node;
                  const isArchived = !product.active;
                  return (
                    <div className="p-7">
                      <p>
                        Are you sure you want to{' '}
                        {isArchived ? 'unarchive' : 'archive'} this product?
                      </p>
                      <p className="text-foggy">
                        NB:
                        {getNbStatement()}
                      </p>
                      <div className="mt-5 flex gap-5 mb-4">
                        <Button
                          label={isArchived ? 'unarchive' : 'archive'}
                          className="bg-cashiaBlue"
                          labelStyle="text-neutral-50 text-base font-normal px-5 md:px-7"
                          onClick={() => handleClick(product)}
                        />
                        <Button
                          label="Cancel"
                          className="bg-lightGrey"
                          labelStyle="text-black text-base font-normal px-5 md:px-7"
                          onClick={() => setArchiveModalOpen(false)}
                        />
                      </div>
                    </div>
                  );
                })()}
              </div>
            )
          )}
        </div>
      </Modal>
    </div>
  );
};
export default Store;
