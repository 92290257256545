import {useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import BackIcon from '../../../assets/icons/back_arrow_white.svg';
import BatteryIcon from '../../../assets/icons/battery-icon.svg';
import LogoSvg from '../../../assets/icons/cashia_logo.svg';
import CloseIcon from '../../../assets/icons/close_white.svg';
import NetworkIcon from '../../../assets/icons/network-icon.svg';
import noPhoto from '../../../assets/icons/noPhoto.svg';
import StarSVG from '../../../assets/icons/starRed.svg';
import VerifiedSVG from '../../../assets/icons/verified_merchant.svg';
import WifiIcon from '../../../assets/icons/wifi-icon.svg';
import BikeLineImg from '../../../assets/images/bike_line.svg';
import LikeShareIcons from '../../../assets/images/like_share_icons.svg';
import Rating from '../../../assets/images/rating.svg';
import LoadingModal from '../../../components/common/LoadingModal';
import {ApprovalState, useProductQuery} from '../../../graphql/generated';
import defaultVariant from '../../../utils/defaultVariant';
import formatDate from '../../../utils/formatDate';
import formatMoney from '../../../utils/formatMoney';
import {useUserAuth} from '../../../utils/user';

const ProductPreview = () => {
  const {merchant: productMerchant} = useUserAuth();
  const navigate = useNavigate();
  const {productId} = useParams();

  const {data, loading, error} = useProductQuery({
    variables: {
      id: productId || '',
    },
    skip: !productId,
  });

  const product = useMemo(() => data?.product, [data]);
  const defaultProduct = useMemo(
    () => product && defaultVariant(product),
    [product]
  );
  const defaultImages = useMemo(
    () => defaultProduct?.images?.map((item) => item?.large || noPhoto) || [''],
    [defaultProduct]
  );

  if (loading) {
    return <LoadingModal open={loading} />;
  }

  if (error) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="bg-red-200 p-3 rounded-xl">
          <p>Error!</p>
          <p>{error?.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full flex flex-col relative justify-center items-center">
      <div className="relative mx-auto md:border-[#1F2020] md:dark:border-[#1F2020] bg-[#1F2020] border-none md:border-[14px] rounded-none md:rounded-[2.5rem] w-[100%] z-10 lg:w-[26%] md:w-[57%] h-[100%] md:h-[85%] lg:h-[95%] flex justify-center items-center shadow-xl">
        <div className="w-full h-full md:w-[94%] md:h-[95%]">
          <div className="w-full h-[8%] md:h-[10%] lg:h-[11%] rounded-none md:rounded-t-2xl bg-gradient-to-r from-rose-600 to-violet-900 py-3 px-4 text-white flex flex-col justify-center md:justify-between">
            <div className="hidden md:flex justify-between items-center">
              <p className="text-center text-neutral-50 text-[15px] font-semibold font-['SF Pro Text']">
                {formatDate(new Date(), {withDate: false, hour12: true})}
              </p>
              <div className="flex gap-2">
                <img src={NetworkIcon} />
                <img src={WifiIcon} />
                <img src={BatteryIcon} />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <img
                className="w-4 h-4"
                src={BackIcon}
                onClick={() => navigate(`/store/product/${productId || ''}`)}
              />
              <p className="text-center text-neutral-50 text-lg font-semibold tracking-tight truncate">
                {(product?.title?.length || 0) > 25
                  ? (product?.title?.slice(0, 25) || '') + '...'
                  : product?.title}
              </p>
              <img
                src={CloseIcon}
                className="w-3 h-3"
                onClick={() => navigate(`/store/product/${productId || ''}`)}
              />
            </div>
          </div>
          <div className="h-[92%] md:h-[90%] lg:h-[89%] w-full">
            <div className="h-[90%] md:h-[88%] w-full overflow-y-scroll no-scrollbar bg-white">
              <div className="w-full h-[345px] lg:h-[325px] relative">
                <div className="w-full h-full no-scrollbar flex overflow-x-scroll snap-x snap-mandatory">
                  {defaultImages.map((image, i) => (
                    <img
                      key={i}
                      className="object-cover w-full h-[345px] lg:h-[325px] snap-center snap-always"
                      src={image || noPhoto}
                    />
                  ))}
                </div>
                <img src={LikeShareIcons} className="absolute top-4 right-4" />
              </div>
              <div className="w-full">
                <div className="w-full p-4">
                  <p className="text-neutral-800 text-xl font-semibold truncate">
                    {product?.title}
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="text-rose-600 text-base font-normal">
                      {`Ksh ${
                        defaultProduct?.price
                          ? formatMoney(defaultProduct.price, 2)
                          : ''
                      }`}
                    </p>
                    <img src={Rating} />
                  </div>
                </div>
                <div className="w-full h-0.5 bg-zinc-200" />
                <div className="w-full p-4 flex flex-col gap-3">
                  {(product?.variants?.length || 0) > 1 && (
                    <p className="text-cyan-500 text-base font-normal">
                      Customize your item {'>'}
                    </p>
                  )}
                  <p className="text-neutral-800 text-base font-normal leading-normal">
                    {defaultProduct?.description}
                  </p>
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <p className="text-neutral-800 text-lg font-normal truncate">
                          {productMerchant?.name}
                        </p>
                        <p className="text-neutral-500 text-[13px] font-normal">
                          Joined{' '}
                          {productMerchant?.createdAt &&
                            formatDate(productMerchant?.createdAt as Date, {
                              withMonth: true,
                              withYear: true,
                              withDate: false,
                              withTime: false,
                            })}
                        </p>
                      </div>
                      <img
                        src={productMerchant?.logo?.small || noPhoto}
                        className="h-12 w-12 rounded-full"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-2 items-center">
                        <img src={StarSVG} />
                        <p className="text-neutral-800 text-base font-normal leading-normal">
                          {productMerchant?.reviews?.length} reviews
                        </p>
                      </div>
                      {productMerchant?.approvalState ===
                        ApprovalState.Approved && (
                        <div className="flex gap-2 items-center">
                          <img src={VerifiedSVG} />
                          <p className="text-neutral-800 text-base font-normal leading-normal">
                            Shop Verified
                          </p>
                        </div>
                      )}
                    </div>
                    <p className="text-zinc-700 text-base font-normal leading-normal">
                      {productMerchant?.description}
                    </p>
                    <div className="flex justify-center items-center text-neutral-800 text-base font-normal w-full border border-black p-2 rounded-lg">
                      Contact seller
                    </div>
                    <div className="flex flex-col gap-2 mt-2">
                      <div className="flex flex-col gap-0">
                        <p className="text-neutral-800 text-lg font-normal">
                          Location
                        </p>
                        <p className="text-zinc-500 text-sm font-normal">
                          {
                            productMerchant?.addresses?.[0]?.location
                              ?.formattedAddress
                          }
                        </p>
                      </div>
                      <div className="flex justify-center items-center text-cashiaBlue text-base font-normal w-full border border-cashiaBlue p-2 rounded-lg">
                        Go to map
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[10%] md:h-[12%] px-3 flex flex-col justify-evenly items-center bg-white rounded-none md:rounded-b-2xl">
              <div className="flex w-full justify-between items-center">
                <div className="w-[97px] h-7 justify-center items-center gap-[13px] inline-flex">
                  <div className="w-7 h-7 rounded border border-stone-300 flex justify-center items-center text-stone-300">
                    -
                  </div>
                  <div className="text-center text-zinc-700 text-sm font-normal">
                    01
                  </div>
                  <div className="w-7 h-7 bg-zinc-700 rounded flex justify-center items-center text-white">
                    +
                  </div>
                </div>
                <div className="w-[45%] py-2 bg-rose-600 rounded-[10px] text-center flex justify-center items-center text-neutral-50 text-base font-normal">
                  Add to cart
                </div>
              </div>
              <div className="w-[135px] h-[5px] rounded-xl bg-black" />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <img className="fixed bottom-20 left-0" src={BikeLineImg} />
      <img className="fixed top-14 left-10 h-14 w-14" src={LogoSvg} />
      <div
        className="fixed top-14 right-10 w-[120px] flex h-12 justify-center items-center bg-cashiaBlue text-neutral-50 text-base font-normal rounded-lg cursor-pointer"
        onClick={() => navigate(`/store/product/${productId || ''}`)}>
        Exit
      </div>
    </div>
  );
};

export default ProductPreview;
