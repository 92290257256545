import {toast} from '@cashiaApp/web-components';
import {useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {ReactComponent as ArrowBackIcon} from '../../../assets/icons/back_arrow_black.svg';
import LogoSvg from '../../../assets/icons/cashia_logo.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close-black.svg';
import {ReactComponent as More} from '../../../assets/icons/more_icon.svg';
import noPhoto from '../../../assets/icons/noPhoto.svg';
import LoadingModal from '../../../components/common/LoadingModal';
import Button from '../../../components/tailwind/Button';
import Modal from '../../../components/tailwind/Modal';
import Spinner from '../../../components/tailwind/Spinner';
import {
  ProductsDocument,
  ProductsQuery,
  useAddOrUpdateProductMutation,
  useProductQuery,
  useRemoveProductMutation,
} from '../../../graphql/generated';
import defaultVariant from '../../../utils/defaultVariant';
import formatMoney from '../../../utils/formatMoney';
import merchant from '../../../utils/mocks/resolvers/merchant';
import {cn} from '../../../utils/reusableFunctions';

const Product = () => {
  const {productId} = useParams();
  const navigate = useNavigate();

  const id: string = productId || '';

  const {data, loading, error} = useProductQuery({
    variables: {
      id: productId || '',
    },
  });
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

  const variables = {
    merchantId: merchant?.id || '',
  };

  const [addOrUpdateProductMutation, {loading: updateProductLoading}] =
    useAddOrUpdateProductMutation();
  const [removeProductMutation, {loading: deleteLoading}] =
    useRemoveProductMutation();

  const handleDeleteProduct = async () => {
    const response = await removeProductMutation({
      variables: {id: id},
      update: (cache, result) => {
        const cached = cache.readQuery<ProductsQuery>({
          query: ProductsDocument,
          variables,
        });
        if (
          !cached ||
          !cached?.merchant?.products.items ||
          !result?.data?.removeProduct
        ) {
          return;
        }
        const arr = cached.merchant.products.items.filter((item) => {
          return item?.id !== productId;
        });
        cache.writeQuery<ProductsQuery>({
          query: ProductsDocument,
          variables,
          data: {
            ...cached,
            merchant: {
              products: {
                items: arr,
                total: arr.length,
              },
            },
          },
        });
      },
    });
    if (response.data?.removeProduct) {
      toast.error('Product has been deleted successfully');
    } else {
      toast.error('Failed to delete product');
    }
  };
  const handleArchiveProduct = async () => {
    const newActiveState = !data?.product?.active;

    try {
      const res = await addOrUpdateProductMutation({
        variables: {
          input: {
            id: productId,
            active: newActiveState,
          },
        },
      });
      if (res.data?.addOrUpdateProduct?.active === newActiveState) {
        toast.success(
          `Product has been ${
            newActiveState ? 'unarchived' : 'archived'
          } successfully`
        );
      } else {
        toast.error('Failed to update product status');
      }
    } catch (e) {
      toast.error('Failed to update product status');
    }
  };

  const handleOpenModal = (selectedProductId: string) => {
    if (window.innerWidth <= 768) {
      setSelectedProduct(selectedProductId);
    }
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };
  const defaultProductVariant = useMemo(
    () => defaultVariant(data?.product),
    [data?.product]
  );
  if (loading) {
    return <LoadingModal open={loading} />;
  }

  if (error) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="bg-red-200 p-3 rounded-xl">
          <p>Error!</p>
          <p>{error?.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full px-5 pt-4 lg:px-[100px] lg:pt-[50px] flex flex-col gap-7 overflow-hidden">
      <div className="hidden lg:flex justify-between items-center">
        <img
          className="cursor-pointer w-14 h-14"
          src={LogoSvg}
          onClick={() => navigate('/store')}
        />
        <Button
          label="Preview Product"
          className="bg-cashiaBlue lg:px-10 lg:py-4"
          onClick={() => navigate(`/store/product/${productId || ''}/preview`)}
        />
      </div>
      <div className="sm:flex lg:hidden justify-between w-full items-center border-b-2 gap-4">
        <button onClick={() => navigate('/store')}>
          <ArrowBackIcon />
        </button>
        <h1 className="text-black mx-12 text-lg font-normal leading-9 w-full capitalize">
          {data?.product?.title}
        </h1>
        <button onClick={() => handleOpenModal(data?.product?.title || '')}>
          <More />
        </button>
        <Modal isVisible={!!selectedProduct} onClose={handleCloseModal}>
          <div className="p-4 flex justify-between items-end">
            <div />
            <div onClick={() => handleCloseModal()} className="px-4">
              <CloseIcon />
            </div>
          </div>
          <div className="px-8 flex flex-col justify-between gap-4">
            {deleteLoading && productId === data?.product?.id ? (
              <Spinner className="h-[40px] w-full" />
            ) : (
              <div
                onClick={() => {
                  void handleDeleteProduct();
                  navigate('/store');
                }}>
                Delete item
              </div>
            )}
            <div className="border" />
            {productId === data?.product?.id && updateProductLoading ? (
              <Spinner className="h-[40px] w-full" />
            ) : (
              <div className="mb-4" onClick={handleArchiveProduct}>
                {data?.product?.active ? 'Archive item' : 'Unarchive item'}
              </div>
            )}
          </div>
        </Modal>
      </div>

      <div className="flex flex-col gap-10 lg:pt-14 pb-10 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <h1 className="text-neutral-800 text-2xl font-semibold leading-[30px]">
              Product details
            </h1>
            <div className="flex lg:hidden justify-between items-center">
              <div
                className="text-cashiaBlue underline lg:px-10 lg:py-4"
                onClick={() =>
                  navigate(`/store/product/${productId || ''}/preview`)
                }>
                Preview
              </div>
            </div>
          </div>
          <p className="text-zinc-700 text-base font-normal leading-tight">
            Customers can see this info before a purchase
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Title
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(`/store/update-product/${productId || ''}`)
                }
              />
            </div>
            <p className="text-zinc-700 text-base font-normal leading-tight w-full lg:w-[80%] capitalize">
              {data?.product?.title}
            </p>
          </div>
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Description
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(`/store/update-product/${productId || ''}`)
                }
              />
            </div>
            <p className="text-zinc-700 text-base font-normal leading-tight  w-full lg:w-[80%]">
              {defaultProductVariant?.description || 'Add Description'}
            </p>
          </div>
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Category
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(
                    `/store/update-product/${productId || ''}/select-category`
                  )
                }
              />
            </div>
            <ol className="text-zinc-700 text-base font-normal leading-tight capitalize flex flex-col gap-1">
              {data?.product?.categories.length ? (
                data?.product?.categories.map((category) => (
                  <li key={category.id} className="flex gap-2 items-center">
                    <div className="w-[5px] h-[5px] bg-cyan-500 rounded-full" />
                    <span>{category.name}</span>
                  </li>
                ))
              ) : (
                <p>Add Category</p>
              )}
            </ol>
          </div>
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Variants in stock
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(`/store/update-product/${productId || ''}/options`)
                }
              />
            </div>
            <div className="flex gap-4 flex-wrap">
              {data?.product?.variants?.map((variant) => (
                <div
                  key={variant.id}
                  className="flex gap-4 w-full lg:w-[343px] h-[120px] p-[10px] rounded-[5px] border border-stone-300 bg-neutral-50">
                  <img
                    className="w-[100px] h-[100px] rounded-[5px] object-cover"
                    src={
                      (variant?.images && variant?.images[0]?.small) || noPhoto
                    }
                  />
                  <div className="flex flex-col justify-center">
                    <p className="text-neutral-800 text-base font-normal truncate">
                      {data?.product?.title}
                    </p>
                    <p
                      className={cn(
                        'text-rose-600 text-[13px] font-normal truncate',
                        {'text-cashiaBlue': variant.default}
                      )}>
                      Ksh {variant.price && formatMoney(variant.price)}
                    </p>
                    <p className="text-neutral-800 text-base font-normal truncate">
                      {variant.option?.map((op) => op?.value).join(' / ') ||
                        'Default variant'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Price
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(`/store/update-product/${productId || ''}/price`)
                }
              />
            </div>
            <div className="flex flex-col gap-4">
              <div>
                <p className="text-zinc-700 text-base font-normal leading-tight w-[80%]">
                  Base price:{' '}
                  <span className="text-cashiaBlue">
                    KES{' '}
                    {defaultProductVariant?.price &&
                      formatMoney(defaultProductVariant.price)}
                  </span>
                </p>
                <p className="text-xs text-cashiaBlue">
                  Note: Base price is derived from default product variant.
                </p>
              </div>
              <p className="text-zinc-700 text-[15px] font-normal font-['Metropolis'] leading-tight">
                Cashia will deduct a 3% service fee from your payout. This helps
                us mantain the platform and provide services such payment
                processing, <br />
                insuring your goods from damages while in transit and offering
                24/7 support.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Shipping
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(`/store/update-product/${productId || ''}/shipping`)
                }
              />
            </div>
            <p className="text-zinc-700 text-base font-normal leading-tight capitalize">
              {data?.product?.fulfillment?.toLocaleLowerCase()}
            </p>
          </div>
          <div className="flex flex-col gap-2 border-b border-neutral-300 pb-6">
            <div className="flex justify-between items-center">
              <h4 className="text-neutral-800 text-lg font-semibold leading-snug">
                Pictures
              </h4>
              <Button
                text
                label="Edit"
                labelStyle="text-cashiaBlue text-base font-normal leading-tight"
                onClick={() =>
                  navigate(`/store/update-product/${productId || ''}/images`)
                }
              />
            </div>
            <div className="w-full h-[200px] bg-white flex flex-nowrap gap-3 overflow-x-scroll no-scrollbar">
              {defaultProductVariant?.images?.length
                ? defaultProductVariant?.images?.map((image) => (
                    <img
                      key={image.id}
                      className="w-[200px] h-[200px] rounded object-cover"
                      src={image.medium || ''}
                    />
                  ))
                : 'Add Pictures'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
