import React from 'react';
import {useNavigate} from 'react-router-dom';

import report from '../../assets/icons/SadFace.svg';
import backButton from '../../assets/icons/back_arrow_black.svg';

const Feedback = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-row">
        <img
          src={backButton}
          className="pt-5 pl-3"
          onClick={() => navigate('/Profile')}
        />
        <p className="flex text-lg font-medium pt-[20] pl-[90]">
          Give us feedback
        </p>
      </div>
      <div className="border-b-[2px] border-greyish pt-[15]" />
      <h1 className="pt-[15] text-xl pl-[20] font-medium">How are we doing</h1>
      <p className="text-cashia-grey pt-[20] pl-[20] pr-[20]">
        We are always working to improve the user experience of Cashia, so we
        would love to hear what’s working and what’s not so that we can do
        better.
      </p>
      <p className="text-cashia-grey pt-[20] pl-[20] pr-[20]">
        <span className="text-black font-medium">
          This isn’t the mode to contact the support team though.
        </span>
        <span> </span>We can’t respond to feedback or bugs you report here. If
        you have a question or need help resolving an issue, you will find help
        in our
        <span className="text-CashiaBlue"> Help center</span>, or you can
        <span className="text-CashiaBlue"> chat with support</span>.
      </p>
      <p className="text-black font-medium pt-[30] pl-[20]">
        What would you like to do?
      </p>
      <div className="flex flex-row pl-[20] pt-[15]">
        <button className="flex rounded-md w-[350] h-[40] text-left pl-[15] pt-[5] font-normal bg-lightGrey">
          Report a bug
          <img src={report} className="flex items-right pt-[5] pl-[200]" />
        </button>
      </div>
    </div>
  );
};

export default Feedback;
