import {
  ActorFieldsFragment,
  Admin,
  Courier,
  Customer,
  Merchant,
  UserFieldsFragment,
} from '../../graphql/generated';

export const isMerchant = (
  fragment?: ActorFieldsFragment | null
): fragment is Merchant => fragment?.__typename === 'Merchant';

export const isAdmin = (fragment?: ActorFieldsFragment): fragment is Admin =>
  fragment?.__typename === 'Admin';

export const isNotAdmin = (
  fragment?: ActorFieldsFragment | null
): fragment is Merchant | Customer | Courier =>
  fragment?.__typename !== 'Admin';

export const isUser = (fragment?: UserFieldsFragment | null) =>
  fragment?.__typename === 'User';
