import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '@cashiaApp/web-components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import useGetProducts from './hooks/useGetProducts';
import CategoryIcon from '../../assets/icons/category-icon.svg';
import MenuIcon from '../../assets/icons/menu_icon.svg';
import NoPhoto from '../../assets/icons/no-photo.svg';
import MiniNavbar from '../../components/common/bottomNavbar';
import Spinner from '../../components/tailwind/Spinner';
import {useGetMerchantProductCategoriesQuery} from '../../graphql/generated';
import defaultVariant from '../../utils/defaultVariant';
import formatMoney from '../../utils/formatMoney';
import {useUserAuth} from '../../utils/user';
import CursorPagination from '../orders/components/CursorPagination';
interface Route {
  title: string;
  linkname: string;
  id?: string;
}
const StoreMobile = () => {
  const {merchant} = useUserAuth();
  const [categoryId, setCategoryId] = useState<string | undefined>('');
  const [activeRoute, setActiveRoute] = useState('all');
  const navigate = useNavigate();

  const {
    loadMore,
    count,
    data: cursorProducts,
    loading: productsLoading,
    refetch,
  } = useGetProducts(merchant?.id || '', categoryId ? [categoryId] : undefined);

  const {data: productsCategoriesData} = useGetMerchantProductCategoriesQuery({
    variables: {
      id: merchant?.id || '',
    },
  });

  const products = useMemo(
    () => cursorProducts?.productsWithCursorPagination.edges,
    [cursorProducts]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      if (categoryId !== undefined) {
        await refetch();
      }
    };
    fetchProducts().catch(console.error);
  }, [categoryId, refetch]);

  const isActive = useCallback(
    (name: string) => activeRoute === name,
    [activeRoute]
  );
  const productCategories = useMemo(
    () => productsCategoriesData?.merchant?.productCategories,
    [productsCategoriesData]
  );
  const RouteItem = ({title, linkname, id}: Route) => (
    <div
      onClick={() => {
        setCategoryId(id);
        setActiveRoute(linkname);
      }}
      className={`cursor-pointer whitespace-nowrap ${
        isActive(linkname) ? 'border-b border-black' : ''
      }`}>
      <p
        className={`${
          isActive(linkname) ? 'text-black' : 'text-foggy'
        } font-base`}>
        {title}
      </p>
    </div>
  );

  const DrawerItem = ({title, linkname, id}: Route) => (
    <div
      onClick={() => {
        setCategoryId(id);
        setActiveRoute(linkname);
      }}
      className="flex py-2 cursor-pointer px-6">
      <p className="font-semibold text-black pt-8">{title}</p>
    </div>
  );

  return (
    <>
      <div className="flex flex-col max-h-screen w-screen">
        <Sheet>
          <SheetTrigger>
            <div className="fixed rounded-full w-[58px] h-[58px] bg-transparent bottom-32 right-6 z-50">
              <div className="flex hover:shadow-none">
                <img src={MenuIcon} alt="Menu Icon" />
              </div>
            </div>
          </SheetTrigger>
          <SheetContent
            side="bottom"
            className="flex overflow-y-scroll justify-between w-full h-full flex-col">
            <div className="overflow-y-scroll">
              <SheetHeader>
                <DrawerItem title="All" linkname="all" />
                <div className="border-b border-lightGrey" />
              </SheetHeader>
              {productCategories?.map((item) => (
                <div className="flex flex-col gap-8 py-px" key={item.id}>
                  <DrawerItem
                    title={item.name}
                    linkname={item.name.toLowerCase()}
                    id={item.id}
                  />
                  <div className="border-b border-lightGrey" />
                </div>
              ))}
            </div>
            <div
              className="flex items-center bottom-0 z-10 py-4 justify-center pb-8"
              style={{boxShadow: '-8px -4px 20px -5px rgba(0, 0, 0, 0.25)'}}>
              <button
                className="flex items-center gap-20 border border-cashiaBlue rounded-lg h-12 w-11/12 px-4 py-2 cursor-pointer"
                onClick={() => navigate('/store/manage-categories')}>
                <div>
                  <img src={CategoryIcon} alt="Category Icon" />
                </div>
                <p className="text-cashiaBlue text-base font-normal">
                  Manage categories
                </p>
              </button>
            </div>
          </SheetContent>
        </Sheet>
        <div className="flex z-10 gap-4 px-4 py-4 text-center justify-between items-center w-full h-14 border-b overflow-x-scroll no-scrollbar">
          <RouteItem title="All" linkname="all" />
          {productCategories?.map((item) => (
            <RouteItem
              key={item.id}
              title={item.name}
              linkname={item.name.toLowerCase()}
              id={item.id}
            />
          ))}
        </div>
        <div className="flex z-0 overflow-y-scroll flex-wrap justify-between p-4">
          {productsLoading ? (
            <div className="flex justify-center items-center">
              <Spinner
                fillColor="fill-cashiaBlue"
                className="h-[40px] w-full"
              />
            </div>
          ) : products?.length ? (
            products.map((product) => {
              const {price, images} = defaultVariant(product.node) ?? {};
              const isArchived = !product.node.active;
              return (
                <div
                  key={product.node.id}
                  onClick={() =>
                    navigate(`/store/product/${product?.node.id || ''}`)
                  }
                  className={`flex w-[48%] md:w-60 shadow-md ${
                    isArchived ? 'text-lightGrey' : ''
                  }`}>
                  <div className="w-full h-full">
                    <img
                      src={images?.[0]?.medium || NoPhoto}
                      alt="Product"
                      className="w-full h-40 object-cover bg-slate-400 rounded"
                    />
                    <div className="p-2">
                      <div
                        onClick={() =>
                          navigate(`/store/product/${product.node.id || ''}`)
                        }>
                        <p>{product?.node.title}</p>
                      </div>
                      <p className="text-sm text-gray-500">
                        {price?.currencyCode} {price && formatMoney(price, 2)}
                      </p>
                      <div>
                        {product?.node.active ? (
                          <p className="text-green-500">In Stock</p>
                        ) : (
                          <p className="text-red-500">Out of Stock</p>
                        )}
                      </div>
                      {product?.node.active ? (
                        <p
                          onClick={() =>
                            navigate(`/store/product/${product?.node.id || ''}`)
                          }
                          className="underline text-sm cursor-pointer">
                          View Details
                        </p>
                      ) : (
                        <p className="text-sm">Update Item</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full text-center">
              No products available. You can add a product using the `Add item
              +` button
            </div>
          )}
          <div className="w-full flex items-center mb-28 pt-4 justify-center">
            {cursorProducts?.productsWithCursorPagination.pageInfo
              .hasNextPage ||
            cursorProducts?.productsWithCursorPagination.pageInfo
              .hasPrevPage ? (
              <CursorPagination
                loadMore={loadMore}
                hasNextPage={
                  cursorProducts?.productsWithCursorPagination.pageInfo
                    .hasNextPage
                }
                hasPrevPage={
                  cursorProducts?.productsWithCursorPagination.pageInfo
                    .hasPrevPage
                }
                count={count}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      <div
        className="fixed bottom-0 px-8 bg-white left-0 w-full"
        style={{boxShadow: '-8px -4px 20px -5px rgba(0, 0, 0, 0.25)'}}>
        <MiniNavbar />
      </div>
    </>
  );
};
export default StoreMobile;
