import {Alert, AlertColor, Snackbar, Typography} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';

import Error from './components/Error';
import Loading from './components/Loading';
import OrderModal from './components/OrderModal';
import OrderTable from './components/OrderTable';
import useGetCouriers from './hooks/useGetCouriers';
import useGetOrderBatchesHook from './hooks/useGetOrderBatchesHook';
import Div from '../../components/common/Div';
import {
  OrderStatus,
  OrderBatchFieldsFragment,
  WalletActivationStatus,
} from '../../graphql/generated';
import {colours} from '../../utils/constants';
import {SelectOption} from '../../utils/types';
import {TableDataType} from '../../utils/types/table.types';

const Requested = () => {
  const {
    data,
    loadMore,
    count,
    error,
    loading,
    cgoError,
    changeStatus,
    cgoLoading,
  } = useGetOrderBatchesHook([OrderStatus.New, OrderStatus.InDelivery]);
  const [open, setOpen] = useState(false);
  const [handleSnackBar, setHandleSnackBar] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [severity, setSeverity] = useState<AlertColor>();
  const [order, setOrder] = useState<OrderBatchFieldsFragment | null>();

  const {data: courierData} = useGetCouriers(undefined, [
    WalletActivationStatus.Verified,
  ]);

  const selectOptions: SelectOption[] | undefined = useMemo(() => {
    return courierData?.map((item: TableDataType) => ({
      label: item?.name?.[0] as string,
      value: item?.id as string,
    }));
  }, [courierData]);

  const changeOrderStatus = useCallback(
    async (
      cancel: boolean,
      callBack?: () => void,
      isSmallScreen?: boolean,
      courierId?: string
    ) => {
      if (!order) return;
      const snackFeedback = (change: OrderStatus) =>
        feedback(change === OrderStatus.Completed ? 'success' : 'warning');
      await changeStatus(
        cancel,
        order,
        cancelReason,
        callBack,
        isSmallScreen,
        snackFeedback,
        courierId
      );
    },
    [cancelReason, changeStatus, order]
  );

  const cancelModal = () => {
    setOpen(false);
    setOpenCancel(false);
    setCancelReason('');
  };

  const openCancelModal = (selectedOrder: OrderBatchFieldsFragment) => {
    setOrder(selectedOrder);
    setOpen(true);
    setOpenCancel(true);
  };

  const selectOrder = (selectedOrder: OrderBatchFieldsFragment) => {
    setOrder(selectedOrder);
    setOpen(true);
  };

  const feedback = (text: AlertColor) => {
    setHandleSnackBar(true);
    setSeverity(text);
  };

  const pickColor = (type: AlertColor) => {
    return {
      error: styles.error,
      success: styles.success,
      info: styles.info,
      warning: styles.warning,
    }[type];
  };

  const closeSnackBar = () => setHandleSnackBar(false);

  useEffect(() => {
    if (cgoError) feedback('error');
  }, [cgoError]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Div>
      <OrderTable
        orders={data}
        requested
        selectOrder={selectOrder}
        openCancelModal={openCancelModal}
        loadMore={loadMore}
        count={count}
        orderID={order?.id}
        orderLoading={cgoLoading}
      />
      <OrderModal
        open={open}
        openCancel={openCancel}
        cancelModal={cancelModal}
        setOpenCancel={setOpenCancel}
        order={order}
        changeStatus={changeOrderStatus}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        loading={cgoLoading}
        setOrder={setOrder}
        selectOptions={selectOptions}
      />
      <Snackbar
        open={handleSnackBar}
        autoHideDuration={3000}
        onClose={closeSnackBar}>
        <Alert
          onClose={closeSnackBar}
          severity={severity}
          variant="filled"
          sx={[styles.errDis, pickColor(severity || 'success')]}>
          <Typography sx={styles.txt}>
            {severity === 'error'
              ? cgoError?.message
              : `Order ${order?.referenceNumber || ''} is successfuly ${
                  severity === 'success' ? 'completed' : 'canceled'
                }`}
          </Typography>
        </Alert>
      </Snackbar>
    </Div>
  );
};
const styles = {
  errDis: {
    width: '100%',
    zIndex: {
      md: '1400',
      xs: '100000',
    },
    color: colours.absoluteWhite,
    borderRadius: '10px',
    justifyContent: 'center',
  },
  success: {backgroundColor: colours.grenish},
  warning: {backgroundColor: colours.orangeish},
  error: {
    backgroundColor: colours.lightPink,
    color: '#5F2120',
    '& .MuiAlert-icon': {
      color: 'red',
    },
  },
  info: {backgroundColor: colours.cashiaBlue},
  txt: {
    alignSelf: 'center',
    justifySelf: 'center',
    fontSize: {
      md: 'inherit',
      xs: '12px',
    },
  },
};

export default Requested;
