import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useToast} from '../../../components/tailwind/toast/useToast';
import {
  ReelsProductFieldsFragment,
  useProductsWithCursorPaginationQuery,
} from '../../../graphql/generated';
import {useUserAuth} from '../../../utils/user';
import EmptyProductsDisplay from '../components/EmptyProductsDisplay';
import Footer from '../components/Footer';
import ProductsDisplay from '../components/ProductsDisplay';
import {useUploadOrEditReelsContentContext} from '../layout/UploadOrEditReelsLayout';

const TagProducts = () => {
  const {merchant} = useUserAuth();
  const {id} = useParams();
  const {updateReel, fileInfo, isUpdating} =
    useUploadOrEditReelsContentContext();
  const {addToast} = useToast();
  const [fetchedProducts, setfetchedProducts] =
    useState<ReelsProductFieldsFragment[]>();
  const [selectedProducts, setselectedProducts] =
    useState<ReelsProductFieldsFragment[]>();
  const {data} = useProductsWithCursorPaginationQuery({
    variables: {
      input: {
        cursor: {
          first: 50,
        },
        merchantId: merchant?.id || '',
      },
    },
  });

  const addToSelectedProducts = (product: ReelsProductFieldsFragment) => {
    if (selectedProducts?.length === 5) {
      addToast({
        icon: true,
        type: 'warning',
        message: <p className="text-sm">Reached selection limit</p>,
        duration: 3000,
      });
      return;
    }
    if (selectedProducts?.find((item) => item.id === product.id)) {
      addToast({
        icon: true,
        type: 'success',
        message: <p className="text-sm">{product.title} is already selected</p>,
        duration: 3000,
      });
      return;
    }
    setfetchedProducts((prev) =>
      prev?.filter((item) => item.id !== product.id)
    );
    setselectedProducts((prev) => (prev ? [...prev, product] : [product]));
  };
  const removeFromSelectedProducts = (product: ReelsProductFieldsFragment) => {
    setselectedProducts((prev) =>
      prev?.filter((item) => item.id !== product.id)
    );
    if (fetchedProducts?.find((item) => item.id === product.id)) return;
    setfetchedProducts((prev) => (prev ? [product, ...prev] : [product]));
  };

  const saveReelInfo = () => {
    if (!selectedProducts?.length) return;
    void updateReel?.(
      {
        productIds: selectedProducts?.map((item) => item.id || ''),
      },
      isUpdating ? `/reels/analytic/${id || ''}` : `/reels`
    );
  };

  useEffect(() => {
    setfetchedProducts(
      data?.productsWithCursorPagination.edges.map((item) => item.node) || []
    );
    setselectedProducts(fileInfo?.createdReelMedia?.products);
  }, [
    data?.productsWithCursorPagination.edges,
    fileInfo?.createdReelMedia?.products,
  ]);

  return (
    <div className="w-full h-[calc(100svh-64px)] flex flex-col overflow-hidden no-scrollbar">
      <div className="flex flex-col h-full gap-12 px-24 py-10 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col w-full gap-7">
          <div className="flex flex-col gap-0">
            <h2 className="text-neutral-800 text-[34px] font-semibold">
              Select Display Products
            </h2>
            <p className="text-neutral-500 text-base font-medium">
              Choose upto 5 items from your inventory to share on this post
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-neutral-800 text-2xl font-medium">
              Display Products
            </p>
            {selectedProducts?.length ? (
              <ProductsDisplay
                remove
                products={selectedProducts}
                onClickAction={removeFromSelectedProducts}
              />
            ) : (
              <EmptyProductsDisplay />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-neutral-800 text-2xl font-medium">
            Available Products
          </p>
          <ProductsDisplay
            products={fetchedProducts}
            onClickAction={addToSelectedProducts}
          />
        </div>
      </div>
      <Footer
        back={isUpdating ? `/reels/edit/${id || ''}` : '/reels/upload'}
        saveReelInfo={saveReelInfo}
        disabled={!selectedProducts?.length}
      />
    </div>
  );
};

export default TagProducts;
