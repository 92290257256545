import {useCallback, useEffect, useState} from 'react';

import {
  CourierType,
  CursorInput,
  WalletActivationStatus,
  useCouriersWithCursorPaginationQuery,
} from '../../../graphql/generated';
import {readableWalletStatus} from '../../../utils/reusableFunctions';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

function useGetCouriers(
  courierType?: CourierType,
  walletStatus?: WalletActivationStatus[]
) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [activeEntries, setActiveEntries] = useState<number>(0);

  const {
    data: allCouriers,
    loading: dataLoading,
    error,
    refetch,
  } = useCouriersWithCursorPaginationQuery({
    variables: {
      input: {
        cursor: {
          first: 20,
        },
        courierType,
        walletStatus,
      },
    },
  });

  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after =
          allCouriers?.couriersWithCursorPagination.pageInfo.endCursor;
        cursorLoad.first = 20;
      } else {
        cursorLoad.before =
          allCouriers?.couriersWithCursorPagination.pageInfo.startCursor;
        cursorLoad.last = 20;
      }
      const res = await refetch({
        input: {cursor: cursorLoad, courierType, walletStatus},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      allCouriers?.couriersWithCursorPagination.pageInfo.endCursor,
      allCouriers?.couriersWithCursorPagination.pageInfo.startCursor,
      count,
      courierType,
      refetch,
      walletStatus,
    ]
  );

  const [data, setData] = useState<TableDataType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'name',
    'phone_number',
    'license_plate',
    'status',
  ]);
  const parentColumns: TableColumnType[] = [
    'name',
    'phone_number',
    'license_plate',
    'status',
  ];
  const sortingColumns: TableColumnType[] = ['name', 'status'];

  function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  const formatEnumContent = useCallback((str: string | undefined) => {
    if (!str) return '-';
    if (str.includes('_')) return capitalize(str.split('_').join(' '));
    return capitalize(str);
  }, []);

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    if (!allCouriers?.couriersWithCursorPagination?.edges) return;
    setData(
      allCouriers?.couriersWithCursorPagination?.edges.map((courier) => ({
        id: courier.node.id || '',
        name: [
          courier.node.user?.name || '-',
          courier.node.avatar?.small || '',
        ],
        phone_number: `+${courier.node.user?.phone?.countryCode || '-'}${
          courier.node.user?.phone?.number || '-'
        }`,
        license_plate: courier.node.licensePlate || '-',
        status:
          (courier.node.wallet?.activation?.status &&
            readableWalletStatus(courier.node.wallet.activation.status)) ||
          '',
      })) || []
    );
  }, [allCouriers, formatEnumContent]);

  useEffect(() => {
    setCount(1);
  }, [courierType, walletStatus]);

  useEffect(() => {
    setTotalEntries(
      allCouriers?.couriersWithCursorPagination?.pageInfo.entries || 0
    );
    setActiveEntries(
      allCouriers?.couriersWithCursorPagination?.edges.map(
        (courier) => courier.node.online
      ).length || 0
    );
  }, [allCouriers]);

  return {
    data,
    setData,
    allCouriers,
    totalEntries,
    activeEntries,
    count,
    loadMore,
    loading,
    error,
    columns,
    setColumns,
    parentColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
    formatEnumContent,
  };
}

export default useGetCouriers;
