import {toast} from '@cashiaApp/web-components';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';

import ArrowForwardSharpIcon from '../../assets/icons/forward_arrow_white.svg';
import CashTagBackground from '../../assets/images/cashtag_background.svg';
import {ReactComponent as LogoSvg} from '../../assets/logos/cashia_logo.svg';
import LoadingModal from '../../components/common/LoadingModal';
import TextInput from '../../components/tailwind/TextInput';
import {
  useRequestWalletActivationForMerchantMutation,
  useFindUserLazyQuery,
} from '../../graphql/generated';
import useDebounce from '../../utils/helper/useDebounce';
import {cn} from '../../utils/reusableFunctions';
import {useUserAuth} from '../../utils/user';

const validationSchema = yup.object().shape({
  cashTagValue: yup
    .string()
    .required('This Ca$htag field is required')
    .min(4, 'Ca$htag should be at least 4 characters long.')
    .max(15, 'Ca$htag should not exceed 15 characters.')
    .matches(/^[a-z]/, 'Ca$htag must start with a lowercase letter.'),
});
const Cashtag = () => {
  const navigate = useNavigate();
  const [cashTagValue, setCashTagValue] = useState('');
  const [validationError, setValidationError] = useState<string>('');
  const [isValid, setIsvalid] = useState(false);
  const [cashtagLength, setCashtagLength] = useState<number>(15);
  const debouncedCashtag = useDebounce(cashTagValue, 500);
  const {user} = useUserAuth();
  const [findUser, {data}] = useFindUserLazyQuery();
  const [validationErrors, setValidationErrors] = useState<
    | {
        cashTagValue: string[];
      }
    | undefined
  >({
    cashTagValue: [],
  });

  const validateInput = (tag: string) => {
    validationSchema
      .validate({cashTagValue: tag}, {abortEarly: true})
      .then(() => {
        setIsvalid(true);
        setValidationErrors({
          cashTagValue: [],
        });
      })
      .catch((e: Error) => {
        setValidationError(e.message);
        toast.error(e.message);
      });
  };
  const [
    requestWalletActivationForMerchantMutation,
    {loading: activationLoading},
  ] = useRequestWalletActivationForMerchantMutation();

  const getUser = useCallback(async () => {
    await findUser({
      variables: {
        input: {
          handle: debouncedCashtag,
        },
      },
    });
  }, [debouncedCashtag, findUser]);

  useEffect(() => {
    if (debouncedCashtag.length > 3 && isValid) void getUser();
  }, [debouncedCashtag, getUser, isValid]);

  const onSubmit = useCallback(async () => {
    validateInput(debouncedCashtag);
    if (!isValid) return;
    const res = await requestWalletActivationForMerchantMutation({
      variables: {
        input: {
          phone: {
            number: user?.phone?.number || '',
            countryCode: user?.phone?.countryCode || '',
          },
          cashtag: debouncedCashtag,
        },
      },
    });
    if (res.data?.requestWalletActivationForMerchant) {
      navigate('/activation/pin');
    } else if (res.errors) {
      toast.error('Failed to activate wallet for merchant.');
    }
  }, [
    debouncedCashtag,
    isValid,
    navigate,
    requestWalletActivationForMerchantMutation,
    user?.phone?.countryCode,
    user?.phone?.number,
  ]);
  useEffect(() => {
    if (!data) return;
    setIsvalid(!data.userRegistrationStatus);
    setValidationError(
      data.userRegistrationStatus
        ? 'This ca$htag is already in use, please choose another one.'
        : ''
    );
  }, [data]);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue: string = e.target.value;
    const sanitizedValue: string = inputValue
      .replace(/^[^a-zA-Z]+/, '')
      .replace(/[^a-zA-Z0-9]/gi, '')
      .toLowerCase();

    if (inputValue !== sanitizedValue) {
      setValidationError(
        'Cashtag should contain only alphanumeric characters and start with a lowercase letter.'
      );
    } else {
      inputValue = sanitizedValue.slice(0, 15);
      setCashTagValue(inputValue);
      setCashtagLength(15 - inputValue.length);
      const isValidInput: boolean = inputValue.length > 3;
      setIsvalid(isValidInput);
      if (!isValidInput) {
        setValidationError('Cashtag should be at least 4 characters long.');
      } else {
        setValidationError('');
      }
    }
  };

  return (
    <>
      <div
        className="flex items-start min-h-screen sm:bg-none lg:bg-cover lg:bg-no-repeat lg:bg-center"
        style={{backgroundImage: `url(${CashTagBackground})`}}>
        <div className="sm:hidden lg:flex px-10 py-10">
          <LogoSvg />
        </div>
        <div className="flex justify-between lg:items-center sm:w-full lg:w-[480px] px-4 sm:mt-0 lg:mt-6">
          <div className="flex flex-col items-center sm:w-full sm:px-0.5 sm:py-20 lg:py-52">
            <h1 className="font-semibold text-black lg:text-3xl sm:text-5xl mb-4">
              Choose a Ca$htag for your business.
            </h1>
            <p className="font-normal text-black mb-4">
              This will be your unique name/ID for sending and receiving money
              to and from anyone
            </p>
            <TextInput
              placeholder="cashtag"
              type="text"
              containerStyle="w-full mb-4"
              onChange={handleInputChange}
              startAdornment={
                <span className="text-black sm:text-[10px] lg:text-[13px]">
                  $
                </span>
              }
              endAdornment={
                <span className="text-foggy sm:text-[10px] lg:text-[13px]">
                  {cashtagLength}
                </span>
              }
            />
            {!isValid ? (
              <p className="text-lazyRed text-base">{validationError}</p>
            ) : (
              isValid && (
                <div className="text-cashiaBlue text-sm mb-4 sm:ml-16 lg:ml-0 mr-80">
                  cashia.com/${debouncedCashtag}
                </div>
              )
            )}
            {data?.userRegistrationStatus === null &&
              validationErrors &&
              isValid && (
                <p className="text-cashiaBlue text-sm mb-4">
                  cashia.com/${debouncedCashtag}
                </p>
              )}
            <button
              className={cn(
                'w-full h-12 text-white font-bold rounded-lg shadow-lg focus:outline-none transition-colors duration-300 flex items-center justify-center bg-greyish hover:bg-greyish border-greyish',
                {
                  'bg-cashiaBlue hover:bg-cashiaBlue border-cashiaBlue':
                    isValid,
                }
              )}
              disabled={!isValid}
              onClick={onSubmit}>
              Continue
              <img src={ArrowForwardSharpIcon} className="px-2" />
            </button>
          </div>
        </div>
      </div>
      <LoadingModal open={activationLoading} />
    </>
  );
};

export default Cashtag;
