import React from 'react';
import {useNavigate} from 'react-router-dom';

import ArrowForwardSharpIcon from '../../assets/icons/forward_arrow_white.svg';
import {ReactComponent as Underline} from '../../assets/images/underline.svg';
import LogoBackground from '../../assets/images/wallet_background.svg';
import {ReactComponent as LogoSvg} from '../../assets/logos/cashia_logo.svg';

const WalletActivation = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="flex h-screen lg:bg-center lg:bg-no-repeat sm:bg-none lg:bg-cover"
        style={{backgroundImage: `url(${LogoBackground})`}}>
        <div className="flex flex-1 flex-col justify-center sm:px-2 lg:px-14 mt-3">
          <LogoSvg />
          <div className="flex flex-1 flex-col justify-center lg:mt-3 sm:mt-0">
            <div className="flex flex-col">
              <p className="sm:font-normal lg:font-semibold text-black sm:text-2xl lg:text-5xl tracking-wide sm:leading-20 lg:leading-24">
                Introducing the
              </p>
              <p className="sm:font-normal lg:font-semibold text-black sm:text-2xl lg:text-5xl tracking-wide sm:leading-20 lg:leading-24">
                Cashia Wallet
              </p>
              <p className="mt-4 mb-4 font-normal text-black text-base sm:text-lg leading-6 xs:leading-7 sm:leading-9">
                Activate your wallet to withdraw, transact and much more.
              </p>
              <Underline />
              <button
                onClick={() => navigate('/activation/verification')}
                className="flex items-center xs:mt-8 xs:mt-10 sm:mt-4 sm:w-72 sm:h-10 lg:w-80 lg:h-12 rounded-lg border-2 border-transparent xs:px-6 sm:px-8 py-2 xs:py-3 sm:py-4 text-base text-white justify-center bg-cashiaBlue hover:border-cashiaBlue hover:bg-cashiaBlue hover:text-white">
                Continue
                <img src={ArrowForwardSharpIcon} className="px-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletActivation;
