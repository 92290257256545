import {cn} from '../../utils/reusableFunctions';

type Props = {
  className?: string;
  iconStyle?: string;
  onClick?: () => void;
  icon: string | JSX.Element;
};

const IconButton = ({iconStyle, className, onClick, icon}: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn('cursor-pointer bg-transparent', className)}>
      {typeof icon === 'string' ? (
        <img src={icon} className={iconStyle} />
      ) : (
        icon
      )}
    </button>
  );
};

export default IconButton;
