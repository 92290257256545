import React from 'react';
import {useNavigate} from 'react-router-dom';

import {statusColor, getMethodNameImage} from './helper';
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import blueCards from '../../assets/icons/blueCards.svg';
import {ReactComponent as TrendingUpIconBlue} from '../../assets/icons/trendingup.svg';
import CircularProgress from '../../components/tailwind/Spinner';
import {
  TransactionsFieldsFragment,
  useTransactionsQuery,
} from '../../graphql/generated';
import formatDate from '../../utils/formatDate';
import formatMoney from '../../utils/formatMoney';
import {useUserAuth} from '../../utils/user';

type Acc = {
  date: string | Date;
  items: TransactionsFieldsFragment[];
}[];

const TrackUsage = () => {
  const navigate = useNavigate();
  const {user} = useUserAuth();
  const {data, loading, error} = useTransactionsQuery({
    variables: {
      input: {
        page: 1,
        pageSize: 30,
      },
    },
  });

  const groupedData = data?.transactions?.transactions?.reduce(
    (acc: Acc, item: TransactionsFieldsFragment) => {
      const date = (item.date as string).slice(0, 10);
      const existingItem = acc?.find((group) => group.date === date);
      if (!existingItem) {
        acc.push({date, items: [item]});
      } else {
        existingItem.items.push(item);
      }
      return acc;
    },
    []
  );

  const prettifyDate = (date: string | Date) => {
    const today = new Date().getDate();
    const dateToday = new Date(date).getDate();
    const yesterdayDate = new Date().getDate() - 1;
    switch (dateToday) {
      case today:
        return 'Today';
      case yesterdayDate:
        return 'Yesterday';
      default:
        return formatDate(date as Date, {
          withTime: false,
          withYear: true,
        });
    }
  };
  const goToWalletHome = () => navigate('/wallet');

  return (
    <>
      <div className="w-screen xl:w-full box-border relative">
        <div className="flex lg:justify-start sm:justify-between items-center lg:pl-10 pl-3 xl:shadow-none shadow-0px-4px-4px-blue-light xl:pb-0 w-full">
          <ArrowBackIcon
            className="lg:hidden sm:flex pl-[5px]"
            onClick={goToWalletHome}
          />
          <p className="font-metropolis sm:font-normal lg:font-semibold lg:text-3xl sm:text-lg xl:leading-10 xl:pt-12 pl-14 md:pl-0 xl:pl-[10px]">
            Track your usage
          </p>
          <div className="lg:hidden sm:flex pt-4 pl-20">
            <img
              src={blueCards}
              className="h-[47px] w-[47px]"
              onClick={goToWalletHome}
            />
          </div>
        </div>
        <div className="lg:hidden sm:flex h-[10px] w-full bg-lightenedGrey shadow-md" />
        {loading ? (
          <div className="flex flex-grow justify-center mt-50">
            <CircularProgress width={30} height={30} />
          </div>
        ) : !groupedData?.length ? (
          <div className=" flex justify-center items-center rounded-10 w-full py-[40px] mt-[20px] bg-lightBlue">
            <div className="w-200 flex flex-col justify-center items-center">
              <TrendingUpIconBlue className="" />
              <p className="text-center font-metropolis font-medium text-base leading-5 mt-[13px]">
                You have no transactions to track today.
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="sm:px-5 lg:px-14 pt-7 w-full space-y-10">
              {groupedData?.map((parent, i) => (
                <div key={i} className="space-y-3 w-full">
                  <p className="font-semibold lg:text-2xl sm:text-xl">
                    {prettifyDate(parent.date)}
                  </p>
                  {parent.items.map((item) => (
                    <div
                      key={item.id}
                      className="flex w-full border-t-2 border-whiteGreyish py-2 pt-4">
                      <div className="flex flex-1 items-center sm:gap-3 lg:gap-5">
                        <img
                          src={
                            getMethodNameImage(item, user?.id ? user?.id : '')
                              ?.logo
                          }
                          className="sm:h-[40px] sm:w-[40px] lg:w-[58px] lg:h-[58px] shadow-sunsetblue rounded-5"
                        />
                        <div className="flex flex-col gap-1">
                          <p className="font-metropolis font-normal md:font-medium xl:text-xl md:text-base leading-base md:leading-xl">
                            {
                              getMethodNameImage(item, user?.id ? user?.id : '')
                                ?.name
                            }
                          </p>
                          <p className="sm:flex lg:hidden text-foggy text-sm">
                            {formatDate(item.date as Date, {withTime: true})}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 justify-between pl-5 items-center">
                        <p className="sm:hidden lg:flex text-foggy">
                          {formatDate(item.date as Date, {withTime: true})}
                        </p>
                        <div className="sm:flex lg:hidden size-4" />
                        <p
                          className="font-metropolis font-normal lg:text-xl sm:text-base"
                          style={{color: statusColor(item.status)}}>
                          {item.amount.currencyCode}{' '}
                          {formatMoney(item.amount, 2)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
        {error && (
          <div className="flex flex-grow justify-center mt-50">
            <p>{error.message}</p>
          </div>
        )}
      </div>
    </>
  );
};
export default TrackUsage;
