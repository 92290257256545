import {Button, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as NotFoundIcon} from '../../assets/404.svg';
import Div from '../../components/common/Div';
import {colours} from '../../utils/constants';

const styles = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100svw',
    height: '100svh',
  },
  mainText: {
    fontSize: '50px',
    lineHeight: '50px',
    fontWeight: '600px',
    letter: '-3%',
  },
  subText: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: '500px',
    margin: '40px 40px',
  },
  button: {
    border: '1px solid #0C0D34',
    background: '#0C0D34',
    color: colours.white,
    borderRadius: '10px',
    height: '44px',
    width: '111px',
    '&: hover': {
      borderColor: '#0C0D34',
      color: '#0C0D34',
    },
  },
};
const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Div sx={styles.mainWrapper}>
      <NotFoundIcon />
      <Typography sx={styles.mainText}>Oops...</Typography>
      <Typography sx={styles.subText}>
        You seem to have fallen down the rabbit hole.
      </Typography>
      <Button
        sx={styles.button}
        onClick={() => {
          navigate('/');
        }}>
        Go home
      </Button>
    </Div>
  );
};

export default NotFound;
