import {cn} from '../../utils/reusableFunctions';

type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  containerStyle?: string;
  description?: string | React.ReactElement;
  descriptionStyle?: string;
  disabled?: boolean;
  onClick?: () => void;
  endAdornment?: React.ReactNode;
  error?: boolean;
  id?: string;
  inputStyle?: string;
  label?: string;
  labelStyle?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  type?: string;
  value?: string | number;
  startAdornment?: React.ReactNode;
};

const TextInput = ({
  label,
  description,
  labelStyle,
  descriptionStyle,
  containerStyle,
  inputStyle,
  startAdornment,
  endAdornment,
  placeholder,
  onClick,
  ...other
}: Props) => {
  return (
    <div className={cn('flex flex-col', containerStyle)} onClick={onClick}>
      <h3 className={cn('text-xl font-semibold leading-5', labelStyle)}>
        {label}
      </h3>
      {description && typeof description === 'string' ? (
        <p className={cn('pt-1 leading-5 text-darkGrey', descriptionStyle)}>
          {description}
        </p>
      ) : (
        description
      )}
      <div
        className={cn(
          'peer flex basis-full flex-row items-center bg-zinc-100 rounded-[10px] border border-stone-300  px-2.5 py-2 focus-within:!bg-white focus-within:ring-1 focus-within:ring-cashiaBlue',
          {'mt-2': description || label}
        )}>
        {startAdornment}
        <input
          className={cn(
            'peer flex basis-full appearance-none bg-zinc-100 rounded-[10px] px-2.5 py-2 text-sm font-medium text-black placeholder-foggy autofill:!bg-offWhite focus:!bg-white focus:outline-none',
            inputStyle
          )}
          placeholder={placeholder}
          {...other}
        />
        {endAdornment}
      </div>
    </div>
  );
};

export default TextInput;
