import {ApolloError} from '@apollo/client';
import {Alert, Snackbar} from '@mui/material';
import React, {useCallback, useMemo, useState} from 'react';

import {Props as DefaultProps, typeGuardApolloError} from './Error';
import {ErrorTypes} from '../../graphql/generated';
import {translateError} from '../../utils/graphql';

export type CustomErrorHandler = (apolloError: ApolloError) => {
  message?: string;
  swallow?: boolean;
  onClose?: () => void;
};

export type Props = Omit<DefaultProps, 'message' | 'error'> & {
  error?: ApolloError;
  handler?: CustomErrorHandler;
};

const GQLError = ({error, handler, isVisible, onClose}: Props) => {
  const [open, setOpen] = useState(true);
  const customTranslation = useMemo(() => {
    if (typeGuardApolloError(error)) {
      return handler?.(error);
    }
  }, [error, handler]);

  const onCloseHander = useCallback(() => {
    setOpen(false);
    onClose?.();
    customTranslation?.onClose?.();
  }, [customTranslation, onClose]);

  const message = useMemo(() => {
    if (
      error?.graphQLErrors.some(
        (err) => err.extensions.type === ErrorTypes.TokenExpired
      )
    ) {
      return undefined;
    }
    return (
      customTranslation?.message ||
      (error && typeGuardApolloError(error) ? translateError(error) : undefined)
    );
  }, [customTranslation?.message, error]);

  return (
    <>
      <Snackbar
        open={customTranslation ? !customTranslation : isVisible || open}
        autoHideDuration={6000}
        onClose={onCloseHander}>
        <Alert severity="error" onClose={onCloseHander}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GQLError;
