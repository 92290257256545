import {useState} from 'react';

import DeliveredReceipts from './components/DeliveredReceipts';
import Error from './components/Error';
import Loading from './components/Loading';
import OrderModal from './components/OrderModal';
import OrderTable from './components/OrderTable';
import useGetOrderBatchesHook from './hooks/useGetOrderBatchesHook';
import Div from '../../components/common/Div';
import {OrderBatchFieldsFragment, OrderStatus} from '../../graphql/generated';
import useWindowSize from '../inbox/hooks/useWindowSize';

const Delivered = () => {
  const {data, loadMore, count, loading, error} = useGetOrderBatchesHook([
    OrderStatus.Completed,
  ]);
  const {width} = useWindowSize();
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<OrderBatchFieldsFragment | undefined>();

  const selectOrder = (selectedOrder: OrderBatchFieldsFragment) => {
    setOrder(selectedOrder);
    setOpen(true);
  };
  const cancelModal = () => setOpen(false);
  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Div>
      <OrderTable
        orders={data}
        delivered
        selectOrder={selectOrder}
        loadMore={loadMore}
        count={count}
      />
      {width < 900 ? (
        <OrderModal
          open={open}
          cancelModal={cancelModal}
          order={order}
          delivered
        />
      ) : (
        <DeliveredReceipts open={open} setOpen={setOpen} order={order} />
      )}
    </Div>
  );
};

export default Delivered;
