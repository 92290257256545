import CircleIcon from '@mui/icons-material/Circle';
import {Avatar, Button, Divider, Typography} from '@mui/material';
import {useEffect} from 'react';

import Div from '../../../components/common/Div';
import {
  ActorFieldsFragment,
  NewMessageDocument,
  NewMessageFieldsFragment,
} from '../../../graphql/generated';
import {colours} from '../../../utils/constants';
import formatDate from '../../../utils/formatDate';
import {useInboxContext} from '../Inbox';

type Props = {
  hightLight?: boolean;
};

const ChatsSection = ({hightLight = false}: Props) => {
  const {
    chatRoom,
    setChatRoom,
    data,
    chatMembers,
    shortenLastMessage,
    setOpen,
    memberImg,
    error,
    subscribeToMore,
  } = useInboxContext();

  function todayChecker(date: string | Date) {
    return new Date().getDate() === new Date(date).getDate()
      ? formatDate(date as Date, {
          withTime: true,
          hour12: true,
          withDate: false,
        })
      : formatDate(date as Date, {
          withShortMonth: true,
          withTime: false,
        });
  }

  const mbName = (members: ActorFieldsFragment[]) => {
    const member = chatMembers(members)[0];
    switch (member?.__typename) {
      case 'Merchant':
        return member.name;
      default:
        return member?.user?.name;
    }
  };

  useEffect(() => {
    subscribeToMore &&
      subscribeToMore<{newMessage: NewMessageFieldsFragment}>({
        document: NewMessageDocument,
        updateQuery: (prev, {subscriptionData}) => {
          if (!subscriptionData) return prev;
          return Object.assign({}, prev, {
            chats: {
              ...prev.chats,
              edges: [
                ...prev.chats.edges.map((chat) =>
                  chat.node.id === subscriptionData.data.newMessage.chatId
                    ? {
                        ...chat,
                        node: {
                          ...chat.node,
                          lastMessage: subscriptionData.data.newMessage.message,
                          unreadMessagesCount:
                            chat?.node?.unreadMessagesCount + 1,
                        },
                      }
                    : chat
                ),
              ],
            },
          });
        },
      });
  }, [subscribeToMore]);

  if (error) {
    return (
      <Typography sx={styles.errText}>
        An Error Occured when loading your chatrooms.
      </Typography>
    );
  }

  return (
    <>
      {data?.chats.edges.length ? (
        <Div sx={styles.content}>
          {data?.chats.edges.map((item) => (
            <>
              <Div
                key={item.cursor}
                sx={[
                  styles.singleChart,
                  item.node.id === chatRoom?.node.id &&
                    hightLight &&
                    styles.highLightedChart,
                ]}
                onClick={() => {
                  setChatRoom(item);
                  setOpen(false);
                }}>
                <Div sx={styles.right}>
                  <Avatar src={memberImg(item.node.members)} sx={styles.avt} />
                  <Div>
                    <Typography sx={styles.smallText}>
                      {mbName(item.node.members)}
                    </Typography>
                    <Typography sx={[styles.smallText, styles.dimTextColor]}>
                      {!item.node.lastMessage
                        ? 'New chat room'
                        : item.node.lastMessage?.text &&
                          shortenLastMessage(item.node.lastMessage.text)}
                    </Typography>
                  </Div>
                </Div>
                {item.node.lastMessage && (
                  <Div
                    sx={{
                      ...styles.left,
                      color: item.node.lastMessage?.isRead
                        ? colours.foggy
                        : colours.smoothRed,
                    }}>
                    {item.node.lastMessage?.isRead || (
                      <CircleIcon fontSize="inherit" />
                    )}
                    <Typography sx={styles.exSmallText}>
                      {item.node.lastMessage?.date &&
                        todayChecker(`${item.node.lastMessage?.date}`)}
                    </Typography>
                  </Div>
                )}
              </Div>
              <Divider />
            </>
          ))}
          <Div sx={styles.empty} />
        </Div>
      ) : (
        <Div sx={styles.zero}>
          <Typography sx={styles.first}>You have no messages</Typography>
          <Typography sx={styles.second}>
            All messages from customers and the Cashia community will appear
            here
          </Typography>
          <Button variant="outlined" color="inherit" sx={styles.btn} href="/">
            Explore Cashia
          </Button>
        </Div>
      )}
    </>
  );
};

const styles = {
  title: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    marginLeft: '20px',
  },
  empty: {marginBottom: '150px'},
  errText: {color: colours.smoothRed, padding: '20px'},
  smallText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
  },
  avt: {width: '55px', height: '55px'},
  exSmallText: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: {md: '13px', xs: '14px'},
  },
  dimTextColor: {color: colours.foggy, fontWeight: 500},
  content: {overflowY: 'scroll', height: 'calc(100% - 50px)'},
  singleChart: {
    display: 'flex',
    padding: '20px',
    cursor: 'pointer',
  },
  highLightedChart: {
    background: colours.lightenedGrey,
  },
  right: {
    display: 'flex',
    gap: '15px',
    flex: 6,
  },
  left: {
    flex: 2,
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: {md: '7px', xs: '9px'},
    lineHeight: '9px',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '5px',
  },
  first: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
  zero: {
    padding: '23px',
    color: colours.black,
  },
  btn: {color: colours.black},
  second: {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '10px',
  },
};

export default ChatsSection;
