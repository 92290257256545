import {Modal, ModalContent, ModalOverlay} from '@cashiaApp/web-components';

interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  onOpenChange: () => void;
}

export default function ModalComponent({
  open,
  children,
  onOpenChange,
}: ModalProps) {
  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <ModalContent
        overlay={<ModalOverlay className="bg-modalPurpleBackDropBg" />}>
        {children}
      </ModalContent>
    </Modal>
  );
}
