import {toast} from '@cashiaApp/web-components';
import {LinearProgress} from '@mui/material';
import React, {useContext, useState, useEffect, PropsWithChildren} from 'react';

import {isMerchant, isUser} from './apollo/helpers';
import {useAuth} from './auth';
import {
  useMeQuery,
  UserFieldsFragment,
  MeFieldsFragment,
} from '../graphql/generated';

interface Context {
  user?: UserFieldsFragment;
  merchant?: MeFieldsFragment;
}

export const UserContext = React.createContext<Context>({});

export const useUserAuth = (): Context => useContext(UserContext);
const UserProvider = ({children}: PropsWithChildren) => {
  const [merchant, setMerchant] = useState<MeFieldsFragment>();
  const [user, setUser] = useState<UserFieldsFragment>();
  const {data, error, loading} = useMeQuery();
  const {isAuthenticated} = useAuth();

  useEffect(() => {
    if (isMerchant(data?.me) && isUser(data?.me?.user) && !error) {
      setUser(data?.me?.user || undefined);
      setMerchant(data?.me);
    }
  }, [data, error]);

  useEffect(() => {
    if (!error || !isAuthenticated) return;
    toast.error(`Unable to get user info. ${error.message}`);
  }, [error, isAuthenticated]);

  if (loading) return <LinearProgress data-cy="user-loading" />;
  return (
    <>
      <UserContext.Provider
        value={{
          user,
          merchant,
        }}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export default UserProvider;
