import NoPhoto from '../../assets/icons/noPhoto.svg';

type Variant = 'square' | 'circle';

type Props = {
  text?: string;
  subText?: string;
  url?: string;
  variant?: Variant;
};
const ImgWithText = ({text, subText, url, variant = 'square'}: Props) => {
  return (
    <div
      className={`flex items-center  ${
        variant === 'square' ? 'gap-3' : 'gap-2'
      }`}>
      <img
        className={`${
          variant === 'square'
            ? 'h-[35px] w-[35px] rounded-md'
            : 'h-[24px] w-[24px] rounded-full'
        } object-cover`}
        src={url || NoPhoto}
        alt=""
      />
      {subText ? (
        <div className="flex flex-col gap-0">
          <p className="text-base font-medium capitalize">{text}</p>
          <p className="text-xs font-normal text-foggy">{subText}</p>
        </div>
      ) : (
        <p>{text}</p>
      )}
    </div>
  );
};

export default ImgWithText;
