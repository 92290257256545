import {useNavigate} from 'react-router-dom';

import SocialLogin from './SocialLogin';
import SignupBgImage from '../../assets/images/signup_bg.svg';
import {ReactComponent as LogoSvg} from '../../assets/logos/cashia_logo.svg';

const Signup = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen flex">
      <div className="h-full sm:w-full lg:w-[35%] py-11 flex justify-center">
        <div className="h-full w-[80%] lg:w-[75%] flex flex-col justify-between">
          <LogoSvg />
          <div className="flex flex-col gap-12">
            <h1 className="text-neutral-800 text-[50px] font-semibold font-['Metropolis']">
              Sign up
            </h1>
            <p className="text-neutral-800 text-xl font-medium font-['Metropolis'] leading-normal">
              Join the Cashia Merchant platform. <br />A marketplace curated for
              you.
            </p>
            <SocialLogin />
            <p
              className="text-zinc-700 text-base font-normal"
              onClick={() => navigate('/login')}>
              Already have an account?{' '}
              <span className="text-cyan-500 font-medium cursor-pointer">
                Log in
              </span>
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-zinc-700 text-sm md:text-base font-normal">
              @{new Date().getFullYear()} Cashia inc.
            </p>
            <p
              className="text-right text-cyan-500 text-sm md:text-base font-medium"
              onClick={() => navigate('/terms')}>
              Terms of Use
            </p>
          </div>
        </div>
      </div>
      <div className="hidden lg:block h-full w-[65%] bg-cyan-500">
        <img src={SignupBgImage} className="h-full w-full object-cover" />
      </div>
    </div>
  );
};

export default Signup;
