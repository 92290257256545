import {
  ApolloError,
  OperationVariables,
  SubscribeToMoreOptions,
} from '@apollo/client';
import {Divider} from '@mui/material';
import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';

import ChatArea from './ChatArea';
import Chats from './Chats';
import Notification from './Notification';
import SmallScreen from './SmallScreen';
import useWindowSize from './hooks/useWindowSize';
import Div from '../../components/common/Div';
import LoadingModal from '../../components/common/LoadingModal';
import Navbar from '../../components/common/Navbar';
import {
  ActorFieldsFragment,
  ChatEdgeFieldsFragment,
  ChatsQuery,
  CursorInput,
  Exact,
  InputMaybe,
  useChatsQuery,
} from '../../graphql/generated';
import {useUserAuth} from '../../utils/user';

interface InboxContextType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: ChatsQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  chatRoom: ChatEdgeFieldsFragment | undefined;
  setChatRoom: React.Dispatch<
    React.SetStateAction<ChatEdgeFieldsFragment | undefined>
  >;
  shortenLastMessage: (msg: string) => string;
  chatMembers: (members: ActorFieldsFragment[]) => ActorFieldsFragment[];
  handleToggle: () => void;
  memberImg: (mbs: ActorFieldsFragment[]) => string;
  subscribeToMore?: <
    TSubscriptionData = ChatsQuery,
    TSubscriptionVariables extends OperationVariables = Exact<{
      input?: InputMaybe<CursorInput> | undefined;
    }>
  >(
    options: SubscribeToMoreOptions<
      ChatsQuery,
      TSubscriptionVariables,
      TSubscriptionData
    >
  ) => () => void;
}

const InboxContext = createContext<InboxContextType>({
  open: false,
  setOpen: () => false,
  data: undefined,
  loading: false,
  error: undefined,
  chatRoom: undefined,
  setChatRoom: () => undefined,
  shortenLastMessage: () => '',
  chatMembers: () => [],
  handleToggle: () => null,
  memberImg: () => '',
});

export const useInboxContext = () => useContext(InboxContext);

const Inbox = () => {
  const {width} = useWindowSize();
  const {merchant} = useUserAuth();
  const [open, setOpen] = useState(false);
  const [chatRoom, setChatRoom] = useState<ChatEdgeFieldsFragment>();
  const {data, loading, error, subscribeToMore} = useChatsQuery({
    variables: {
      input: {
        first: 50,
      },
    },
  });

  const chatMembers = (members: ActorFieldsFragment[]) => {
    return members.filter((item) => item.user?.id !== merchant?.user?.id);
  };

  const shortenLastMessage = (msg: string) => {
    if (msg.length < 20) return msg;
    return `${msg.slice(0, 15)}...`;
  };

  const memberImg = (mbs: ActorFieldsFragment[]): string => {
    const member = chatMembers(mbs)[0];
    switch (member?.__typename) {
      case 'Customer':
        return member.avatar?.small || '';
      case 'Merchant':
        return member.logo?.small || '';
      default:
        return '';
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  useLayoutEffect(() => {
    if (width < 900) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [width]);

  return (
    <Div>
      <Navbar />

      <Divider sx={styles.display} />
      <Div sx={styles.main}>
        <InboxContext.Provider
          value={{
            open,
            setOpen,
            data,
            chatRoom,
            setChatRoom,
            loading,
            error,
            chatMembers,
            shortenLastMessage,
            handleToggle,
            memberImg,
            subscribeToMore,
          }}>
          {loading ? (
            <LoadingModal open={loading} />
          ) : (
            <>
              {open ? (
                <SmallScreen />
              ) : (
                <>
                  <Chats />
                  <ChatArea />
                  <Notification />
                </>
              )}
            </>
          )}
        </InboxContext.Provider>
      </Div>
    </Div>
  );
};

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'none',
    height: {md: 'calc(100svh - 64px)', xs: '100svh'},
  },
  display: {display: {md: 'flex', xs: 'none'}},
};

export default Inbox;
