import {CashiaApolloError} from './types';
import {ErrorTypes} from '../graphql/generated';

const readableErrors: Record<ErrorTypes, string> = {
  BIOMETRICS_VERIFICATION_FAILED: 'Invalid biometric signature',
  INSUFFICIENT_CREDIT: 'Insufficient credit',
  INVALID_OTP: 'Invalid OTP',
  MULTIPLE_MERCHANT_PRODUCT:
    'You are trying to buy products for multiple merchants which is unsupported at the time',
  NOT_AUTHORIZED: 'Not authorized',
  NOT_AVAILABLE: 'The product you are buying is not available anymore',
  NOT_FOUND: 'Resource not found',
  NOT_REGISTERED: 'You are not registered',
  SERVER_ERROR: 'Server error, please try again later',
  TOKEN_EXPIRED: 'Token expired',
  TOKEN_INVALID: 'Token invalid',
  TRANSACTION_FAILED: 'Transaction failed',
  VALIDATION_ERROR: 'Validation error',
  WRONG_WALLET_PIN: 'Wrong wallet PIN',
  WALLET_INACTIVE: 'Your wallet is inactive',
  INVALID_PHONE_NUMBER: 'Your phone number is invalid',
  ACCOUNT_LOCKED: '',
  AMOUNT_LIMIT_EXCEEDED: '',
  DAILY_LIMIT_EXCEEDED: '',
  DELIVERY_CREATION_FAILED: '',
  DUPLICATE_ADDRESS_LOCATION: '',
  DUPLICATE_WISHLIST_NAME: '',
  INVALID_AMOUNT: '',
  PAYMENT_METHOD_NOT_FOUND: '',
  PAYMENT_METHOD_PROCESSING_FAILED: '',
  WALLET_LIMIT_EXCEEDED: '',
  INVALID_SAME_RECIPIENT: '',
  [ErrorTypes.AuthenticationCodeFailed]: '',
  [ErrorTypes.ProtectedField]: '',
  [ErrorTypes.CybersourceError]: '',
};

const defaultError = 'There was an error processing your request';

export const translateError = (apolloError: CashiaApolloError): string => {
  for (const err of apolloError.graphQLErrors) {
    const reason = readableErrors[err.extensions?.type as ErrorTypes];
    if (reason) {
      return reason;
    }
  }
  return defaultError;
};
