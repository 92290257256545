import React, {ReactNode} from 'react';

import Navbar from '../../components/common/Navbar';

interface ProfileLayoutProps {
  children: ReactNode;
}

const ProfileLayout: React.FC<ProfileLayoutProps> = ({children}) => {
  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <div className="sm:hidden w-full lg:flex overflow-none">
        <Navbar />
      </div>
      <div className="flex flex-col lg:mx-20 overflow-y-scroll h-full pb-24">
        {children}
      </div>
    </div>
  );
};

export default ProfileLayout;
