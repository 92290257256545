import {CircularProgress, Typography} from '@mui/material';
import {useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import DeliveryVan from '../../../assets/icons/delivery-van.svg';
import Div from '../../../components/common/Div';
import Button from '../../../components/tailwind/Button';
import {CourierType} from '../../../graphql/generated';
import {colours} from '../../../utils/constants';
import useGetCouriers from '../hooks/useGetCouriers';

const CouriersWidget = () => {
  const navigate = useNavigate();
  const [entriesEmpty, setEntriesEmpty] = useState(false);
  const {totalEntries, activeEntries, loading} = useGetCouriers(
    CourierType.Rider,
    undefined
  );

  useLayoutEffect(() => {
    setEntriesEmpty(totalEntries === 0 && !loading);
  }, [totalEntries, loading]);

  return (
    <Div
      sx={{
        width: '100%',
        border: '1px solid rgba(230, 229, 229, 1)',
        padding: [1, 2],
        marginBottom: 4,
        borderRadius: 2,
        background: entriesEmpty ? 'rgba(243, 246, 249, 1)' : '',
      }}>
      {entriesEmpty ? (
        <Div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: [0, 5],
          }}>
          <img src={DeliveryVan} className="h-14 w-14" />
          <Typography
            variant="body1"
            sx={{textAlign: 'center', padding: [0, 2]}}>
            Have your own delivery service? Add your <br />
            first personal courier service
          </Typography>
          <Button
            label="Add a Courier"
            className="bg-cashiaBlue"
            labelStyle="text-neutral-50 text-base font-normal px-1"
            onClick={() => {
              // TODO: Add courier functionality
            }}
          />
        </Div>
      ) : (
        <>
          <Div
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Typography sx={styles.courierText}>My Couriers</Typography>
            <Button
              label="View Couriers"
              className="bg-cashiaBlue"
              labelStyle="text-neutral-50 text-base font-normal px-5 md:px-7"
              onClick={() => navigate('/orders/my-couriers')}
            />
          </Div>
          {loading ? (
            <Div className="flex justify-center items-center">
              <CircularProgress color="primary" size={45} className="mb-10" />
            </Div>
          ) : (
            <Div
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 2,
                color: colours.cashiaBlue,
              }}>
              <Div
                sx={{
                  backgroundColor: 'rgba(247, 247, 247, 1)',
                  marginRight: 2,
                  borderRadius: 2,
                  padding: 2,
                }}>
                <Typography variant="body1"> Total Couriers </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '600',
                  }}>
                  {totalEntries}
                </Typography>
              </Div>
              <Div
                sx={{
                  backgroundColor: 'rgba(247, 247, 247, 1)',
                  borderRadius: 2,
                  padding: 2,
                }}>
                <Typography variant="body1">Active Couriers</Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '600',
                  }}>
                  {activeEntries}
                </Typography>
              </Div>
            </Div>
          )}
        </>
      )}
    </Div>
  );
};

const styles = {
  courierText: {
    display: 'flex',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    letterSpacing: '-0.03em',
  },
};

export default CouriersWidget;
